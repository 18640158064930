import React, { useEffect, useState } from 'react';
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker';

function WGSketch(props) {
  const {
    onChange,
    isGradient,
    isEmailPage,
    presetColors,
    className = '',
    isColourPalette,
    isBackgroundColor = false,
  } = props;
  const [color, setColor] = useState(props.color || '');
  const colorObject = useColorPicker(color, setColor);
  const { setSolid, setGradient } = colorObject;

  useEffect(() => {
    if (color) {
      onChange(colorObject);
    }
  }, [color, isBackgroundColor]);

  useEffect(() => {
    if (isBackgroundColor && !isEmailPage) {
      setGradient(color);
    } else {
      setSolid(color);
    }
  }, [color, isBackgroundColor]);

  return (
    <div className={`sketch-picker ${className} bg-white`}>
      <ColorPicker
        value={color}
        onChange={setColor}
        presets={presetColors}
        hidePresets={isGradient || isColourPalette}
        hideColorTypeBtns={isEmailPage || !isBackgroundColor}
      />
    </div>
  );
}

export default WGSketch;
