/* eslint-disable react/jsx-key -- react-table adds the "key" prop */
import React from 'react';
import { useTable, useSortBy } from 'react-table';
import { Table } from 'reactstrap';
import { TableProps } from '../../../../../ts/interfaces';

const TableComponent: React.FC<TableProps> = ({ data, columns, className }) => {
  const {
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    getTableBodyProps,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  const assignReactKeyToChildren = (d) => React.Children.toArray(d);

  return (
    <Table {...getTableProps()} className={className}>
      <thead>
        {assignReactKeyToChildren(
          headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {' '}
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <i className="fa fa-chevron-circle-down" aria-hidden="true" />
                        : <i className="fa fa-chevron-circle-up" aria-hidden="true" />
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          )),
        )}
      </thead>
      <tbody {...getTableBodyProps()}>
        {assignReactKeyToChildren(
          rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} style={{ minWidth: `${cell.column.minWidth}px` }}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          }),
        )}
      </tbody>
    </Table>
  );
};

export default TableComponent;
