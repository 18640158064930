import { createStore } from './rootStore';
import { apiSlice } from './common/api/apiSlice';
import User from './common/models/User';
import UserRecentItem from './common/models/UserRecentItem';

export const addRecentItem = (item: UserRecentItem) => {
  const store = createStore();
  const w = window as any;

  w.recentItems ||= {
    lastIdentity: null,
    timeout: null,
    items: [],
    push: (item: UserRecentItem) => {
      const ts = new Date().getTime();
      // The type should always be set, but the item_id might be falsey, ie null or undefined, that's why we only do a
      // double-equals check below rather than a threequals.
      // eslint-disable-next-line eqeqeq
      const index = w.recentItems.items.findIndex((i: UserRecentItem) => item.type === i.type && item.item_id == i.item_id);

      if (index > -1) {
        w.recentItems.items[index].ts = ts;
        w.recentItems.items[index].path = window.location.pathname;
      } else {
        w.recentItems.items.push({ ...item, ts, path: window.location.pathname });
      }

      // sort by timestamp and remove all but the most recent 30 items
      w.recentItems.items = w.recentItems.items.sort((a: UserRecentItem, b: UserRecentItem) => (b.ts || 0) > (a.ts || 0) ? 1 : -1).slice(0, 30);

      // only send the list if it has changed. we figure that out by creating an "identity" which is sort of a hash
      // of the list's contents.
      const identity = w.recentItems.items.map((i: UserRecentItem) => `${i.type}-${i.item_id}`).join(',');
      if (identity !== w.recentItems.lastIdentity) {
        w.recentItems.lastIdentity = identity;

        // the list has changed, queue up the send
        clearTimeout(w.recentItems.timeout);
        w.recentItems.timeout = setTimeout(() => w.recentItems.send(), 1000);
      }
    },
    send: async () => {
      const userWrapper = await store.dispatch(apiSlice.endpoints.getUserProfile.initiate({}));
      const user = (userWrapper.data as { data: User }).data;
      if (!user?.id) {
        return;
      }
      store.dispatch(apiSlice.endpoints.updateRecentItems.initiate({
        userId: user.id,
        items: w.recentItems.items,
      }));
    },
  };

  w.recentItems.push(item);
};
