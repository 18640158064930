import React from 'react';
import { Label, FormGroup } from 'reactstrap';
import { getSelectedOption } from '../../../../../Utils';
import CustomSelect from '../../../components/common/custom-select';
import { visibilityOptions } from '../../../components/common/advancedSettings';

function PopupVisibility(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label>Popup Visible To</Label>
      <CustomSelect
        placeholder="Visible to"
        value={getSelectedOption(visibilityOptions, params.popup_visible_to)}
        options={visibilityOptions}
        onChange={(e) => setParams({ ...params, popup_visible_to: e.value })}
      />
    </FormGroup>
  );
}

export default PopupVisibility;
