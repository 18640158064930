import React, { useEffect, useState } from 'react';
import {
  Card,
  CardColumns,
  CardBody,
} from 'reactstrap';
import HeaderComponent from '../../../components/common/header-component';
import { settingRoutes, replacePathParams } from '../../../constants/routes';
import BottomActionToolbar from '../../../components/common/toolbar';
import { GoogleAnalyticsProperty } from '../../../components/fields/site';
import SaveAndContinue from '../../../components/common/saveAndContinue';
import withRouter from '../../../helpers/withRouter';
import { useUpdateSiteMutation } from '../../../../../common/api/apiSlice';
import UserFeaturePage from '../../../components/common/user-feature-page';
import { useInstance, useSite } from '../../../../../common/hooks';
import { get } from 'lodash-es';
import { addRecentItem } from '../../../../../UtilsTS';
import './EditSite.scss';
function SiteSettingGoogleAnalytics(props) {
  const [params, setParams] = useState({});

  const site = useSite();
  const instance = useInstance();
  const siteId = get(site, 'id', null);
  const instanceId = get(instance, 'id', null);
  const [updateSite] = useUpdateSiteMutation();

  useEffect(() => {
    if (site) {
      setParams(site);
    }
  }, [site]);

  const onSave = () => {
    updateSite({
      id: siteId,
      instance_id: instanceId,
      ...params,
    });

    return true;
  };

  if (instanceId) {
    addRecentItem({
      instance_id: instanceId,
      type: 'settings-google-analytics',
    });
  }

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'Google Analytics', url: '' },
  ];

  return (
    <div className="EditSite">
      <HeaderComponent
        setPath={{
          headingName: 'Google Analytics',
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Back',
          backToPath: settingRoutes.siteList,
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
      />
      <UserFeaturePage feature="list_all_google_analytics_properties">
        <CardColumns>
          <Card>
            <CardBody>
              <GoogleAnalyticsProperty params={params} setParams={setParams} />
            </CardBody>
          </Card>
        </CardColumns>

        <BottomActionToolbar
          component={(
            <SaveAndContinue
              onSave={onSave}
              onContinue={replacePathParams(settingRoutes.siteList, [], props)}
            />
          )}
        />
      </UserFeaturePage>
    </div>
  );
}

export default withRouter(SiteSettingGoogleAnalytics);
