import React from 'react';

const UserLayout = (props) => {
  const { children } = props;

  return (
    <div className="login-background">
      <div className="fixed-background" />
      <main>
        <div className="container">{children}</div>
      </main>
    </div>
  );
};

export default UserLayout;
