import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Label, Input, Button, CardBody } from 'reactstrap';
import { isArray, isEmpty, map, get, cloneDeep } from 'lodash-es';
import Select from 'react-select';
import { getPageHierarchy } from '../../../../components/common/PageHierarchy';
import {
  formFieldType,
  formItemFields,
  formSectionFields,
  databaseFieldType,
  includeFieldOptions,
  formSectionInputType,
} from '../../../../../../common/schema/formSection';
import confirm from '../../../../components/common/confirm';
import {
  colourPalettePreset,
  displayError,
  getMaxValueByKey,
  getSelectedOption,
  selectBoxOptions,
  selectColorStyles,
} from '../../../../../../Utils';
import RadioButton from '../../../../components/common/radioButton';
import CheckBox from '../../../../components/common/checkBox';
import CustomButton from '../../../../components/common/button';
import CarbonCopyEmail from '../../../../components/common/carbonCopyEmail';
import withRouter from '../../../../helpers/withRouter';
import DragDropComponent from '../../../../components/common/reactSortable';
import {
  useGetSectionQuery,
  useGetMediaFilesQuery,
  useGetButtonStylesQuery,
  useSectionUpdateMutation,
  useGetInstanceDataQuery,
  useGetEmailSeriesQuery,
  useGetDatabaseFieldsQuery,
} from '../../../../../../common/api/apiSlice';
import AdvancedSettings from '../../../../components/common/advancedSettings';
import ColorPicker from '../../../../components/common/color-picker';
import { useSite, useUser } from '../../../../../../common/hooks';

export const returnTextAreaValues = (value) => {
  if (isArray(value)) {
    return value.join('\n');
  }
  return value;
};

export const checkboxView = (title, check, onClickMethod, key = title) => (
  <CheckBox
    key={key}
    title={title}
    checked={check}
    onChange={() => onClickMethod()}
  />
);

const convertValue = (v, type) => {
  if (type === 'text') {
    return [v];
  }
  if (['checkboxes', 'radio_button', 'drop_down_list'].includes(type)) {
    return v.replace(/\r\n/g, '\n').split('\n');
  }
  return v;
};

function FormSection(props) {
  const {
    section: { id: sectionId },
    isEmailPage,
    saveCounter,
    params: { instanceId, siteId },
    updateStylesheet,
  } = props;

  const [pageOption, setPageOptions] = useState([]);

  const [imageOption, setImageOptions] = useState([]);

  const [databaseFieldOption, setDatabaseFieldOption] = useState([]);

  const { data: section } = useGetSectionQuery({ instanceId, sectionId });

  const [sectionUpdate] = useSectionUpdateMutation();

  const [params, setParams] = useState(null);

  useEffect(() => {
    if (section) {
      setParams(cloneDeep(section));
    }
  }, [section]);

  const onSave = async (payload) => {
    const tempPayload = {
      ...payload,
      instance_id: instanceId,
      privacy_policy_color: colourPalettePreset(payload?.privacy_policy_color, presetColours),
      form_items_attributes: payload.form_items,
    };

    delete tempPayload.form_items;
    if ((tempPayload.submit_button_type === formItemFields.IMAGE) && tempPayload.button_image_id === null) {
      displayError('Please select image.');
    } else if (tempPayload.redirect_to_page && (tempPayload.redirect_page_id === null)) {
      displayError('Please select redirect page.');
    } else {
      await sectionUpdate(tempPayload);

      updateStylesheet();
    }
  };

  useEffect(() => {
    if (saveCounter === 0)
      return;

    (async () => {
      onSave(params);
    })();
  }, [saveCounter]);

  const tempState = { ...params };

  const user = useUser();

  const site = useSite();

  const presetColours = get(site, 'colors', []);

  const { data: mediaToolData } = useGetMediaFilesQuery(instanceId);

  const { data: buttonStyles } = useGetButtonStylesQuery(siteId);

  const styleOptions = selectBoxOptions(buttonStyles, 'name', 'id');

  const formItems = get(params, 'form_items', []);

  const tempFormItems = [...formItems];

  const { data: sectionsData = [] } = useGetInstanceDataQuery(instanceId, { skip: isEmpty(instanceId) });
  const { data: emailSeries = [] } = useGetEmailSeriesQuery(instanceId, { skip: isEmpty(instanceId) });

  const { data: fieldsData = [] } = useGetDatabaseFieldsQuery({ instanceId }, { skip: isEmpty(instanceId) });

  useEffect(() => {
    if (mediaToolData && !isEmpty(mediaToolData.media)) {
      const { media } = mediaToolData;
      const getOptions = map(media, (ele) => ({ label: ele.name, value: ele.id }));
      setImageOptions(getOptions);
    }
  }, [mediaToolData]);

  useEffect(() => {
    if (!isEmpty(sectionsData)) {
      // Some instances and sites have numeric IDs,
      // and comparing with === doesn't work because the URL parameter is a string.

      const getSiteData = sectionsData.filter((item) => item.id == instanceId)[0].sites;

      const getPageList = getSiteData.filter((item) => item.id == siteId)[0].pages;

      const getPageData = getPageHierarchy(getPageList, '');
      setPageOptions(getPageData);
    }
  }, [instanceId, siteId, sectionsData]);

  useEffect(() => {
    if (fieldsData && !isEmpty(fieldsData)) {
      const getOptions = map(fieldsData, (ele) => (
        {
          label: ele.attributes.field_name,
          value: ele.id,
          type: ele.attributes.field_type,
        }
      ));
      getOptions.unshift({ label: 'None', value: null, type: 'none' });
      setDatabaseFieldOption(getOptions);
    }
  }, [fieldsData]);

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setParams({ ...params, [name]: value });
  };

  const onHandleChangeFieldType = (e, index, keyName) => {
    const { value } = e;
    if (keyName !== formSectionFields.DB_FIELD && keyName !== formSectionFields.INCLUDE_IN_SMS) {
      tempFormItems[index] = {
        ...tempFormItems[index],
        [formSectionFields.DB_FIELD]: null,
      };
    } else if (keyName === formSectionFields.DB_FIELD) {
      tempFormItems[index] = {
        ...tempFormItems[index],
        [keyName]: value,
        values: fieldsData.filter((item) => item.id === value)[0]?.attributes?.values,
      };
    }

    tempFormItems[index] = {
      ...tempFormItems[index],
      [keyName]: value,
    };

    tempState.form_items = tempFormItems;

    setParams(tempState);
  };

  const onHandleImageChange = (e, type) => {
    const { value } = e;
    if (type === formItemFields.IMAGE) {
      setParams({ ...params, button_image_id: value });
    } else {
      setParams({ ...params, button_hover_image_id: value });
    }
  };

  const onHandlePageChange = (e) => {
    const { value } = e;
    setParams({ ...params, redirect_page_id: value });
  };

  const onHandleChangeItemsCheckBox = (index, type) => {
    tempFormItems[index] = {
      ...tempFormItems[index],
      [type]: !tempFormItems[index][type],
    };
    tempState.form_items = tempFormItems;
    setParams(tempState);
  };

  const onHandleAddItem = () => {
    const newItem = {
      name: '',
      placeholder: '',
      default_value: '',
      is_required: false,
      section_form_id: id,
      break_values_in_newline: true,
      order: getMaxValueByKey(params?.form_items, 'order'),
      type: formSectionInputType.SINGLE_LINE,
    };
    onSave({ ...params, form_items: [...params.form_items, newItem] });
  };

  const onHandleFormItemDelete = async (id) => {
    const result = await confirm({
      title: (<strong>Confirm!</strong>),
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      const i = tempFormItems.findIndex((c) => c.id === id);
      tempFormItems[i].is_deleted = true;
      tempFormItems[i].deleted_by_id = user?.id;
      tempFormItems[i].deleted_at = new Date().toUTCString();

      tempState.form_items = tempFormItems;
      let counter = 0;
      onSave({ ...params, form_items: tempFormItems.map((s) => ({ ...s, order: s.is_deleted ? null : ++counter })) });
    }
  };

  const onChangeFormObjVal = (e, index, type) => {
    const { name, value } = e.target;
    const tempArr = tempFormItems.filter((item) => !item.is_deleted);
    tempArr[index] = {
      ...tempArr[index],
      [name]: convertValue(value, type),
    };
    tempState.form_items = [...tempArr, ...tempFormItems.filter((item) => item.is_deleted)];
    setParams({ ...params, form_items: tempState.form_items });
  };

  const getPopulateDatabaseOption = (type) => {
    switch (type) {
      case formSectionInputType.SINGLE_LINE:
        return databaseFieldOption.filter((ele) => ele.type === databaseFieldType.SHORT_TEXT
          || ele.type === databaseFieldType.LONG_TEXT
          || ele.type === databaseFieldType.NUMERIC
          || ele.type === databaseFieldType.EMAIL
          || ele.type === databaseFieldType.DATE
          || ele.type === databaseFieldType.URL
          || ele.type === databaseFieldType.NONE);
      case formSectionInputType.MULTI_LINE:
        return databaseFieldOption.filter((ele) => ele.type === databaseFieldType.SHORT_TEXT
          || ele.type === databaseFieldType.LONG_TEXT
          || ele.type === databaseFieldType.NONE);
      case formSectionInputType.DROPDOWN:
        return databaseFieldOption.filter((ele) => ele.type === databaseFieldType.SELECT_LIST
          || ele.type === databaseFieldType.NONE);
      case formSectionInputType.RADIO:
        return databaseFieldOption.filter((ele) => ele.type === databaseFieldType.RADIO_BUTTON
          || ele.type === databaseFieldType.NONE);
      case formSectionInputType.CHECKBOX:
        return databaseFieldOption.filter((ele) => ele.type === formSectionInputType.CHECKBOX
          || ele.type === databaseFieldType.NONE);
      case formSectionInputType.DATE:
        return databaseFieldOption.filter((ele) => ele.type === databaseFieldType.DATE
          || ele.type === databaseFieldType.NONE);
      case formSectionInputType.FILE_UPLOAD:
        return [];
      default: return databaseFieldOption;
    }
  };

  const onHandleDrop = (newState) => {
    const temp = [...formItems];
    newState.forEach((element, i) => {
      const getIndex = temp.findIndex((c) => c.id === element.id);
      if (getIndex > -1) {
        temp[getIndex].order = i + 1;
      }
    });
    onSave({ ...params, form_items: [...temp].sort((a, b) => (a.order > b.order ? 1 : -1)) });
  };

  const showFormItems = () => {
    if (undefined !== formItems) {
      const returnData = map(formItems.filter((item) => !item.is_deleted), (ele, index) => {
        const {
          name,
          type,
          values,
          placeholder,
          is_required,
          other_option,
          include_in_sms,
          database_field_id,
          break_values_in_newline,
        } = ele;

        const getTextAreaActive = (type === formSectionInputType.CHECKBOX
          || type === formSectionInputType.RADIO
          || type === formSectionInputType.DROPDOWN
          || type === formSectionInputType.TEXT);

        return (
          <Col
            id={`toolbarEditor_${index}`}
            className="custom-cursor-pointer-draggable mb-5"
            key={ele.id}
            lg={4}
          >
            <Card key={index} className="rounded">
              <CardBody>
                <Row className="handle-sortable">
                  <Col>
                    <h3 className="m-0">{name || `Form object ${index + 1}`}</h3>
                  </Col>
                  <Col>
                    <ul className="custom-ul text-end">
                      <li className="drag-handle">
                        <Button className="custom-simple-txt-btn">
                          <i className="fal fa-arrows-alt" />
                        </Button>
                      </li>
                      <li>
                        <Button className="custom-simple-txt-btn " onClick={() => onHandleFormItemDelete(ele.id)}>
                          <i className="fal fa-trash text-danger" />
                        </Button>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={12}>
                    <Label className="w-100">
                      Name:
                      <Input
                        type="text"
                        name="name"
                        value={name || ''}
                        onChange={(e) => onChangeFormObjVal(e, index)}
                      />
                    </Label>
                  </Col>
                  <Col lg={12}>
                    <Label className="w-100">
                      Type:
                      <Select
                        value={getSelectedOption(formFieldType, type)}
                        onChange={(e) => onHandleChangeFieldType(e, index, formSectionFields.TYPE)}
                        options={formFieldType}
                        styles={selectColorStyles}
                      />
                    </Label>
                  </Col>
                  {getTextAreaActive && (
                    <Col lg={12}>
                      <Label className="w-100">
                        Values:
                        <Input
                          type="textarea"
                          name="values"
                          value={returnTextAreaValues(values) || ''}
                          onChange={(e) => onChangeFormObjVal(e, index, type)}
                          disabled={database_field_id !== null}
                        />
                      </Label>
                    </Col>
                  )}
                  {type === formSectionInputType.HIDDEN
                  && (
                    <Col lg={12}>
                      <Label className="w-100">
                        Default Value:
                        <Input
                          type="text"
                          name="default_value"
                          value={ele.default_value || ''}
                          onChange={(e) => onChangeFormObjVal(e, index)}
                        />
                      </Label>
                    </Col>
                  )}
                  {(
                    field_label_position === formItemFields.ABOVE_FIELD
                    && (type === formSectionInputType.SINGLE_LINE
                      || type === formSectionInputType.MULTI_LINE
                    ))
                    && (
                      <Col lg={12}>
                        <Label className="w-100">
                          Placeholder:
                          <Input
                            type="text"
                            name="placeholder"
                            value={placeholder || ''}
                            onChange={(e) => onChangeFormObjVal(e, index)}
                          />
                        </Label>
                      </Col>
                    )}
                  {add_to_database
                  && (
                    <Col lg={12}>
                      <Label className="w-100">
                        Populate Database Field:
                        <Select
                          value={getSelectedOption(databaseFieldOption, database_field_id)}
                          onChange={
                            (e) => onHandleChangeFieldType(e, index, formSectionFields.DB_FIELD)
                          }
                          options={getPopulateDatabaseOption(type)}
                          styles={selectColorStyles}
                        />
                      </Label>
                    </Col>
                  )}
                  <Col lg={12}>
                    <Label className="w-100">
                      Include in SMS
                      <Select
                        value={getSelectedOption(includeFieldOptions, include_in_sms)}
                        onChange={
                          (e) => onHandleChangeFieldType(e, index, formSectionFields.INCLUDE_IN_SMS)
                        }
                        options={includeFieldOptions}
                        styles={selectColorStyles}
                      />
                    </Label>
                  </Col>
                  {(type === formSectionInputType.CHECKBOX
                    || type === formSectionInputType.RADIO)
                    && (
                      <Col lg={12}>
                        {checkboxView(
                          'Add other option with text box?',
                          other_option,
                          () => onHandleChangeItemsCheckBox(index, formSectionFields.OTHER_OPTION),
                        )}
                      </Col>
                    )}
                  {type !== formSectionInputType.TEXT && (
                    <Col lg={12}>
                      {checkboxView(
                        'Is this a required field?',
                        is_required,
                        () => onHandleChangeItemsCheckBox(index, formSectionFields.IS_REQUIRED),
                      )}
                    </Col>
                  )}
                  {[formSectionInputType.CHECKBOX, formSectionInputType.RADIO].includes(type) && (
                    <Col lg={12}>
                      {checkboxView(
                        'Show items on a single line?',
                        break_values_in_newline,
                        () => onHandleChangeItemsCheckBox(index, formSectionFields.BREAK_LINE),
                      )}
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        );
      });
      return (
        <DragDropComponent
          className="row"
          handle=".drag-handle"
          keyName="form_item_ids"
          returnData={returnData}
          list={map(formItems, (x) => ({ ...x, chosen: true }))}
          setNewState={(newState) => onHandleDrop(newState)}
        />
      );
    }
    return (
      <Card>
        <CardBody>
          <p>Nothing found.</p>
        </CardBody>
      </Card>
    );
  };

  const onHandleEmailChange = (email) => {
    setParams({ ...params, email_addresses: email });
  };

  const emailSeriesOptions = (data) => map(data, (e) => (
    { label: e.name, value: e.id }
  ));

  const onEmailSeriesChange = (e) => {
    const d = Array.isArray(e) ? e.map((x) => x.value) : [];
    setParams({ ...params, email_series_ids: d });
  };

  if (isEmpty(params)) {
    return <></>;
  }

  const {
    id,
    redirect_page_id,
    send_sms,
    send_email,
    button_text,
    mobile_number,
    add_to_database,
    button_image_id,
    button_style_id,
    email_series_ids,
    redirect_to_page,
    display_dialog_box,
    dialog_box_message,
    submit_button_type,
    email_subject_line,
    field_label_position,
    button_hover_image_id,
    include_privacy_policy,
  } = params;

  return (
    <>
      <AdvancedSettings
        siteId={siteId}
        section={params}
        setSection={setParams}
        instanceId={instanceId}
        isEmailPage={isEmailPage}
        handleSaveBreakpoint={(e) => setParams({ ...params, break_point: e })}
        onHandleSectionIsOffline={() => setParams({ ...params, is_offline: !params?.is_offline })}
        onChangeInitialVisibility={() => setParams({ ...params, initial_visibility: !params?.initial_visibility })}
        onHandleChangeVisibility={(e) => setParams({ ...params, visible_to: e.value })}
      />
      <Row>
        <Col>
          <Card className="rounded h-100">
            <CardBody>
              <h2 className="mb-4 fw-bold">Display Settings</h2>
              <h3 className="fw-bold">Field Label Position</h3>
              <RadioButton
                name="field_label_position"
                title="Above field"
                className="me-3"
                checked={field_label_position === formItemFields.ABOVE_FIELD}
                onChange={() => setParams({
                  ...params,
                  field_label_position: formItemFields.ABOVE_FIELD,
                })}
              />
              {' '}
              <RadioButton
                name="field_label_position"
                title="Inside field"
                className="me-3"
                checked={field_label_position === formItemFields.INSIDE_FIELD}
                onChange={() => setParams({
                  ...params,
                  field_label_position: formItemFields.INSIDE_FIELD,
                })}
              />
              <h3 className="fw-bold">Submit Button</h3>
              <h3>
                {!isEmpty(styleOptions)
                  ? (
                      <>
                        Do you want to use a button style, text, or an image for the submit button?
                      </>
                    )
                  : (
                      <>
                        Do you want to use text or an image for the submit button?
                      </>
                    )}
              </h3>

              {!isEmpty(styleOptions)
                ? (
                    <>
                      <RadioButton
                        name="submit_button_type"
                        title="Button style"
                        className="me-3"
                        checked={submit_button_type === formItemFields.BUTTON_STYLE}
                        onChange={() => setParams({
                          ...params,
                          submit_button_type: formItemFields.BUTTON_STYLE,
                          button_image_id: null,
                        })}
                      />
                      {' '}
                    </>
                  )
                : ''}

              <RadioButton
                name="submit_button_type"
                title="Text"
                className="me-3"
                checked={submit_button_type === formItemFields.TEXT}
                onChange={() => setParams({
                  ...params,
                  submit_button_type: formItemFields.TEXT,
                  button_image_id: null,
                })}
              />
              {' '}
              <RadioButton
                name="submit_button_type"
                title="Image"
                className="me-3"
                checked={submit_button_type === formItemFields.IMAGE}
                onChange={() => setParams({
                  ...params,
                  submit_button_type: formItemFields.IMAGE,
                })}
              />
              {submit_button_type === formItemFields.BUTTON_STYLE
                ? (
                    <Row>
                      <Col>
                        <Label for="button_style_id">Button style</Label>
                        <Select
                          name="button_style_id"
                          value={getSelectedOption(styleOptions, button_style_id)}
                          onChange={(e) => setParams({ ...params, button_style_id: e.value })}
                          options={styleOptions}
                          isSearchable
                          styles={selectColorStyles}
                        />
                      </Col>
                    </Row>
                  )
                : ''}
              {submit_button_type === formItemFields.TEXT
                ? (
                    <Col lg={6}>
                      <Input
                        type="text"
                        name="button_text"
                        id="button_text"
                        placeholder="Text"
                        value={button_text || ''}
                        onChange={(e) => onHandleChange(e)}
                      />
                    </Col>
                  )
                : ''}
              {submit_button_type === formItemFields.IMAGE
                ? (
                    <Row>
                      <Col>
                        <Label for="button_image_id">Image</Label>
                        <Select
                          name="button_image_id"
                          value={getSelectedOption(imageOption, button_image_id)}
                          onChange={(e) => onHandleImageChange(e, formItemFields.IMAGE)}
                          options={imageOption}
                          isSearchable
                          styles={selectColorStyles}
                        />
                      </Col>
                      <Col>
                        <Label for="button_hover_image_id">Rollover</Label>
                        <Select
                          name="button_hover_image_id"
                          value={getSelectedOption(imageOption, button_hover_image_id)}
                          onChange={(e) => onHandleImageChange(e, formItemFields.ROLLOVER)}
                          options={imageOption}
                          isSearchable
                          styles={selectColorStyles}
                        />
                      </Col>
                    </Row>
                  )
                : ''}
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="rounded">
            <CardBody>
              <h2 className="mb-4 fw-bold">Actions</h2>
              <Row>
                <Col lg={12}>
                  {checkboxView(
                    'Send Email',
                    send_email,
                    () => setParams({ ...params, send_email: !send_email }),
                  )}
                  {send_email
                  && (
                    <Row>
                      <Col lg={12} className="mb-2">
                        <Input
                          type="text"
                          name="email_subject_line"
                          id="email_subject_line"
                          value={email_subject_line || ''}
                          onChange={(e) => onHandleChange(e)}
                          placeholder="Subject"
                        />
                      </Col>
                      <Col lg={12} className="mb-2">
                        <CarbonCopyEmail
                          placeholder="To"
                          id="email_addresses"
                          name="email_addresses"
                          selectedEmails={params.email_addresses}
                          onChange={(e) => onHandleEmailChange(e)}
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6}>
                  {checkboxView(
                    'Send SMS',
                    send_sms,
                    () => setParams({ ...params, send_sms: !send_sms }),
                  )}
                  {send_sms
                  && (
                    <Input
                      type="text"
                      name="mobile_number"
                      id="mobile_number"
                      value={mobile_number || ''}
                      onChange={(e) => onHandleChange(e)}
                      placeholder="Phone Number"
                      className="mb-2"
                    />
                  )}
                </Col>
                <Col lg={6} md={6}>
                  {checkboxView(
                    'Redirect User',
                    redirect_to_page,
                    () => setParams({
                      ...params,
                      redirect_to_page: !redirect_to_page,
                      redirect_page_id: redirect_to_page ? redirect_page_id : null,
                    }),
                  )}
                  {redirect_to_page
                  && (
                    <Select
                      name="redirect_page_id"
                      value={getSelectedOption(pageOption, redirect_page_id)}
                      onChange={(e) => onHandlePageChange(e)}
                      options={pageOption}
                      isSearchable
                      styles={selectColorStyles}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6}>
                  {checkboxView(
                    'Add user to database',
                    add_to_database,
                    () => setParams({ ...params, add_to_database: !add_to_database }),
                  )}
                  {add_to_database
                  && (
                    <Select
                      isMulti
                      value={
                        emailSeriesOptions(emailSeries).filter((obj) => email_series_ids.includes(
                          obj.value,
                        ))
                      }
                      onChange={(e) => onEmailSeriesChange(e)}
                      options={emailSeriesOptions(emailSeries)}
                      isSearchable
                      className="mb-2"
                      styles={selectColorStyles}
                    />
                  )}
                </Col>
                <Col lg={6} md={6}>
                  {checkboxView(
                    'Display a dialog box message',
                    display_dialog_box,
                    () => setParams({ ...params, display_dialog_box: !display_dialog_box }),
                  )}
                  {display_dialog_box
                  && (
                    <Input
                      type="textarea"
                      name="dialog_box_message"
                      id="dialog_box_message"
                      value={dialog_box_message || ''}
                      onChange={(e) => onHandleChange(e)}
                      placeholder="Message"
                      className="mb-2"
                    />
                  )}
                </Col>
                <Col>
                  {checkboxView(
                    'Do you want to include a link to the Privacy Policy below the form?',
                    include_privacy_policy,
                    () => setParams({ ...params, include_privacy_policy: !include_privacy_policy }),
                  )}
                </Col>
                <Col lg={12} className="BreakPoint align-items-center">
                  <Label for="privacy_policy_color" className="mt-3">
                    <h3 className="fw-bold">
                      Privacy Policy link colour (leave blank to use default sector link colour):
                    </h3>
                  </Label>
                  <ColorPicker
                    color={get(params, 'privacy_policy_color', '')}
                    onChange={({ target: { value } }) => onHandleChange(
                      { target: { value, name: 'privacy_policy_color' } },
                    )}
                    onDeleteValue={() => onHandleChange({ target: { value: '', name: 'privacy_policy_color' } })}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Card className="mt-3 mb-4 rounded">
        <CardBody>
          <Row className="text-center">
            <Col lg={12} className="mb-2">
              <h2 className="fw-bold">Form Items</h2>
            </Col>
            <Col className="d-flex align-items-center justify-content-center">
              <CustomButton
                color="info"
                className="add-new-button button-md fw-bold"
                title="ADD ITEM"
                icon="fa fa-plus"
                onClick={() => onHandleAddItem()}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {showFormItems()}
    </>
  );
}
export default withRouter(FormSection);
