import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash-es';
import macroReplacerString from './macroReplacerString';

// The elements returned by this component are recreated by SectionContent, this means we can't add any event handlers.
const TextBlockMedia = ({ media }) => {
  const allFiles = useSelector((state) => state.pageData.file);
  const allBreakpoints = useSelector((state) => state.pageData.breakpoint);
  const allLinks = useSelector((state) => state.pageData.link);
  const allSections = useSelector((state) => state.pageData.section);
  const cdn_domain = useSelector((state) => state.pageData.cdn_domain);

  const {
    id,
    file_id,
    link_id,
    alt_text,
    media_url,
    phone_media_url,
    tablet_media_url,
    hover_image_id,
    tablet_image_id,
    phone_image_id,
    clickable_thumbnail,
  } = media;
  const mainFile = file_id ? allFiles.find((f) => f.id === file_id) : null;
  const tabletFile = tablet_image_id ? allFiles.find((f) => f.id === tablet_image_id) : null;
  const phoneFile = phone_image_id ? allFiles.find((f) => f.id === phone_image_id) : null;
  const hoverFile = hover_image_id ? allFiles.find((f) => f.id === hover_image_id) : null;
  const fileMap = { desktop: mainFile, tablet: tabletFile || mainFile, phone: phoneFile || mainFile };
  const breakpoint = allBreakpoints.find((b) => b.object_id === id) || { all_styles: { width: ['100%'] } };

  const getUrl = (file, width = null) => `https://${cdn_domain}/cdn-cgi/image/format=auto%2Cwidth=${width === null ? file.width : width}/https://${cdn_domain}/c/${file.id}/${file.filename}?h=${file.file_hash}`;

  const imageUrl = (device) => {
    switch (device) {
      case 'tablet':
        return tablet_media_url;
      case 'phone':
        return phone_media_url;
      default: return media_url;
    }
  };

  const srcset = (device) => {
    const srcs = {};
    const file = fileMap[device];
    if (!file) return '';

    const deviceStyles = breakpoint[`${device}_styles`];
    const allStyles = breakpoint.all_styles;

    let width = get(deviceStyles, 'width.0') || get(allStyles, 'width.0');
    let maxWidth = get(deviceStyles, 'max-width.0') || get(allStyles, 'max-width.0');
    let displayedWidth;

    if (maxWidth && maxWidth.match(/px/)) {
      displayedWidth = parseFloat(maxWidth, 10);
    } else if (width && width.match(/px/)) {
      displayedWidth = parseFloat(width, 10);
    } else {
      displayedWidth = file.width;
    }

    if (file.width > displayedWidth) {
      srcs[`${getUrl(file, displayedWidth * 2)} 2x`] = 1;
    }

    srcs[`${getUrl(file, displayedWidth)} 1x`] = 1;

    return Object.keys(srcs).join(',');
  };

  let el = null;
  let hoverEl = null;

  if (mainFile?.mime_type?.match(/^image\//)) {
    el = (
      !tablet_image_id && !phone_image_id
        ? (
            <img
              className={`original-src tb-media bp-${id}${clickable_thumbnail ? ' clickable-thumbnail' : ''}`}
              srcSet={srcset('desktop')}
              src={imageUrl('all')}
              data-src={imageUrl('all')}
              alt={alt_text}
              style={{ aspectRatio: `${mainFile.width} / ${mainFile.height}`, objectFit: 'contain' }}
            />
          )
        : (['desktop', 'tablet', 'phone'].map((device) => (
            <img
              key={device}
              className={`original-src only-${device} tb-media bp-${id}${clickable_thumbnail ? ' clickable-thumbnail' : ''}`}
              srcSet={srcset(device)}
              src={imageUrl(device)}
              data-src={imageUrl('all')}
              alt={alt_text}
              style={{ aspectRatio: `${fileMap[device].width} / ${fileMap[device].height}`, objectFit: 'contain' }}
            />
          )))
    );
  } else if (mainFile?.mime_type?.match(/^video\//)) {
    el = (
      <video controls preload="auto">
        <source src alt={alt_text} type="video/mp4" />
      </video>
    );
  }

  if (hoverFile) {
    // We use the main file's aspect-ratio so that the page doesn't jump around if the normal and hover images are
    // different sizes. We set object-fit: contain in show_style.css.erb to avoid the hover image being stretched.
    hoverEl = (
      <img
        className={`hover-image tb-media bp-${id}`}
        src={getUrl(hoverFile)}
        alt={alt_text}
        style={{ aspectRatio: `${mainFile.width} / ${mainFile.height}`, objectFit: 'contain' }}
      />
    );
  }

  el = (
    <span className={`image-container${hoverEl ? ' has-hover' : ''}`}>
      {el}
      {hoverEl}
    </span>
  );

  if (link_id) {
    const linkObject = allLinks.find((l) => l.id === link_id);
    if (linkObject) {
      let className;
      let originId = null;

      if (linkObject.type === 'toggle_visibility_of_section') {
        const targetSection = allSections.find((s) => s.id === linkObject.section_id);
        if (targetSection) {
          originId = targetSection.origin_id;
        }

        className = 'toggleVisibility';
      } else if (linkObject.link_target === 'framebox') {
        className = 'showModal';
      }

      el = (
        <a
          href={macroReplacerString(linkObject.url)}
          target={linkObject.link_target === '_blank' ? '_blank' : '_self'}
          className={className}
          data-origin-id={originId}
          rel={linkObject.link_target === '_blank' ? 'noreferrer' : ''}
        >
          {el}
        </a>
      );
    }
  }

  return el;
};

export default TextBlockMedia;
