import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup } from 'reactstrap';

interface DropdownOption {
  value: string | number;
  label: string;
}

interface DropdownButtonGroupProps {
  labelPrefix?: string;
  options: DropdownOption[];
  selectedOption: DropdownOption;
  onOptionSelect: (option: DropdownOption) => void;
  buttonSize?: 'sm' | 'md' | 'lg' | 'xs';
  outline?: boolean;
  outlineColor?: string;
  className?: string;
  placeholder?: string;
}

const DropdownButtonGroup: React.FC<DropdownButtonGroupProps> = ({
  labelPrefix = '',
  options,
  selectedOption,
  onOptionSelect,
  outline = false,
  buttonSize = 'xs',
  outlineColor = 'dark',
  className = '',
  placeholder = 'Select...',
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const label = selectedOption?.label
    ? labelPrefix
      ? `${labelPrefix} : ${selectedOption?.label}`
      : selectedOption?.label
    : placeholder;

  return (
    <ButtonGroup className={className}>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle caret size={buttonSize} outline={outline} color={outlineColor}>
          {label}
        </DropdownToggle>
        <DropdownMenu>
          {options.map((item) => (
            <DropdownItem
              key={item.value}
              onClick={() => onOptionSelect(item)}
              active={item.value === selectedOption?.value}
            >
              {item.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </ButtonGroup>
  );
};

export default DropdownButtonGroup;
