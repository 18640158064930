import React from 'react';
import {
  Input,
  Label,
  FormGroup,
  UncontrolledTooltip,
} from 'reactstrap';
import { isEmpty } from 'lodash-es';

function GoogleSiteVerification(props) {
  const { params, setParams } = props;

  const valid = params.google_site_verification?.every((v) => {
    if (isEmpty(v)) {
      return true;
    }

    return `${v}`.match(/<meta /) && `${v}`.match(/name=["']?google-site-verification/) && `${v}`.match(/content=["']?.*/);
  });

  return (
    <FormGroup>
      <Label className="has-tooltip" id="googleSiteVerificationHelp">Google Site Verification Meta Tags</Label>
      <UncontrolledTooltip
        placement="right"
        target="googleSiteVerificationHelp"
        autohide={false}
        style={{ textAlign: 'left' }}
      >
        The site verification meta tag from Google Search Console. If you need to enter multiple tags, enter each tag on a separate line.
        <br />
        <br />
        For example:
        {' '}
        <code>
          &lt;meta name=&quot;google-site-verification&quot; content=&quot;......&quot; /&gt;
        </code>
        <br />
        <br />
        <a
          href="https://support.google.com/webmasters/answer/9008080#meta_tag_verification&amp;zippy=%2Chtml-tag"
          target="_blank"
          rel="noreferrer"
        >
          More information
        </a>
      </UncontrolledTooltip>
      <Input
        type="textarea"
        style={{ fontFamily: 'monospace' }}
        value={params.google_site_verification?.join('\n')}
        valid={valid}
        invalid={!valid}
        onChange={(e) => {
          const { value } = e.target;
          setParams({ ...params, google_site_verification: value.split(/\n+/) });
        }}
      />
    </FormGroup>
  );
}

export default GoogleSiteVerification;
