import React from 'react';
import { isEmpty, get } from 'lodash-es';
import DropdownButtonGroup from 'Dashboard/src/components/common/DropdownButtonGroup';
import {
  getDataFieldOptions,
  getSelectedOption,
  getSpecialFieldIdByName,
} from '../../../../../Utils';
import { useGetDatabaseFieldsQuery } from '../../../../../common/api/apiSlice';
import { useInstance } from 'common/hooks';

interface DataFieldSelectBoxProps {
  labelPrefix?: string;
  onChange: (selectedOption: any) => void;
  selectedId?: string | null;
}

const DataFieldSelectBox: React.FC<DataFieldSelectBoxProps> = ({
  onChange,
  selectedId = null,
  labelPrefix = '',
}) => {
  const instance = useInstance();
  const instanceId = get(instance, 'id', null);
  const { data: dataFieldSelectOptions, isLoading, isError } = useGetDatabaseFieldsQuery(
    { instanceId },
    { skip: isEmpty(instanceId) },
  );

  if (isLoading) return <p>Loading...</p>;
  if (isError || !dataFieldSelectOptions) return <p>Error loading data fields.</p>;

  const options = getDataFieldOptions(dataFieldSelectOptions);
  const defaultSelectedId = isEmpty(selectedId) ? getSpecialFieldIdByName(options, 'Join Date') : selectedId;
  const selectedOption = getSelectedOption(options, defaultSelectedId);

  return (
    <DropdownButtonGroup
      outline
      labelPrefix={labelPrefix}
      options={options}
      selectedOption={selectedOption}
      onOptionSelect={onChange}
    />
  );
};

export default DataFieldSelectBox;
