import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Label,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  some, remove, reject, isEqual, maxBy, get, isEmpty, uniqBy,
} from 'lodash-es';
import GridLayout from 'react-grid-layout';
import classnames from 'classnames';
import 'react-grid-layout/css/styles.css';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/snippets/html';
import 'ace-builds/src-noconflict/theme-github';
import CustomButton from 'Dashboard/src/components/common/button';
import CustomSelect from 'Dashboard/src/components/common/custom-select';
import ColorPicker from 'Dashboard/src/components/common/color-picker';
import CheckBox from 'Dashboard/src/components/common/checkBox';
import {
  BackgroundPositionX,
  BackgroundPositionY,
  BackgroundRepeat,
  BackgroundSize,
} from 'Dashboard/src/components/common/textStyles';
import CustomTooltip from 'Dashboard/src/components/common/CustomTooltip';
import './TemplateEditorGrid.scss';
import { MediaSelectBox } from 'Dashboard/src/components/fields/site';
import { useGetTemplatesQuery } from 'common/api/apiSlice';
import { onKeyDownPrevent } from './TemplateSector';
import { useSite } from '../../../../../../common/hooks';

const devices = ['desktop', 'tablet', 'phone'];

const colors = [
  ['59BC6C', 'FFFFFF'],
  ['45AFD0', 'FFFFFF'],
  ['AA92C0', 'FFFFFF'],
  ['A3C8D1', 'FFFFFF'],
  ['C1E8B5', '333333'],
  ['FFECBA', '333333'],
  ['F5B7B3', '333333'],
  ['9D56C7', 'FFFFFF'],
  ['69B5D6', 'FFFFFF'],
  ['81EB78', 'FFFFFF'],
  ['D6BA5E', 'FFFFFF'],
  ['FA6E5A', 'FFFFFF'],
  ['9312C7', 'FFFFFF'],
  ['2090D6', 'FFFFFF'],
  ['29EB2B', 'FFFFFF'],
  ['D6AF15', 'FFFFFF'],
  ['FA2F05', 'FFFFFF'],
];

export const getBackgroundColor = (i) => `#${colors[i % colors.length][0]}`;
export const getForegroundColor = (i) => `#${colors[i % colors.length][1]}`;

function StyleElement(props) {
  const {
    element,
    style,
    template,
    editingSector,
    activeTab,
    onStyleChange,
    onRemoveStyle,
  } = props;

  const site = useSite();

  // Need to figure out if there are any device-specific styles set, otherwise the default view is "all".
  const deviceStyles = new Set();
  devices.forEach((device) => {
    template[`${device}_layout`].forEach((sector) => {
      if (sector.label !== editingSector.label) {
        return;
      }
      sector[`${element}_styles`] ||= {};
      deviceStyles.add(sector[`${element}_styles`][style]);
    });
  });
  const [activeDevice, setActiveDevice] = useState(deviceStyles.size > 1 ? activeTab : 'all');

  const [dropdownOpen, setDropdownOpen] = useState(false);

  // If the user clicks the "link" icon, we should copy the desktop value to tablet and mobile.
  useEffect(() => {
    if (activeDevice === 'all') {
      template.desktop_layout.forEach((sector) => {
        if (sector.label === editingSector.label) {
          const value = editingSector[`${element}_styles`][style];
          if (value !== undefined) {
            onStyleChange('all', style, value);
          }
        }
      });
    }
  }, [activeDevice]);

  let name;
  let styleElement;

  let value;
  // If we are on the "all" setting, use the currently active tab.
  template[`${activeDevice === 'all' ? activeTab : activeDevice}_layout`].forEach((sector) => {
    if (sector.label === editingSector.label) {
      value = sector[`${element}_styles`][style];
    }
  });

  switch (style) {
    case 'width':
      name = 'Width';

      if (!value) {
        value = '100%';
      }

      styleElement = (
        <InputGroup style={{ maxWidth: '200px' }}>
          <Input
            type="number"
            value={value.replace(/[^0-9]/g, '')}
            onChange={(e) => onStyleChange(activeDevice, style, value.replace(/[0-9]*/, e.target.value))}
          />
          <ButtonDropdown
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
          >
            <DropdownToggle
              caret
              color="primary"
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} // Reactstrap bug
            >
              {value.match(/px/) ? 'px' : '%'}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => onStyleChange(activeDevice, style, value.replace(/[^0-9]+/, 'px'))}>
                px
              </DropdownItem>
              <DropdownItem onClick={() => onStyleChange(activeDevice, style, value.replace(/[^0-9]+/, '%'))}>
                %
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </InputGroup>
      );

      break;
    case 'padding-top':
    case 'padding-bottom':
    case 'padding-left':
    case 'padding-right':
      name = {
        'padding-top': 'Padding top',
        'padding-bottom': 'Padding bottom',
        'padding-left': 'Padding left',
        'padding-right': 'Padding right',
      }[style];

      if (!value) {
        value = '0px';
      }

      styleElement = (
        <InputGroup style={{ maxWidth: '200px' }}>
          <Input
            type="number"
            value={value.replace(/[^0-9]/g, '')}
            onChange={(e) => onStyleChange(activeDevice, style, value.replace(/[0-9]*/, e.target.value))}
          />
          <ButtonDropdown
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
          >
            <DropdownToggle
              caret
              color="primary"
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} // Reactstrap bug
            >
              {value.match(/px/) ? 'px' : '%'}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => onStyleChange(activeDevice, style, value.replace(/[^0-9]+/, 'px'))}>
                px
              </DropdownItem>
              <DropdownItem onClick={() => onStyleChange(activeDevice, style, value.replace(/[^0-9]+/, '%'))}>
                %
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </InputGroup>
      );

      break;
    case 'background':
      name = 'Background colour';

      styleElement = (
        <ColorPicker
          isBackgroundColor
          isEmailPage={false}
          color={value || ''}
          presetColors={get(site, 'colors', [])}
          onChange={(e) => onStyleChange(activeDevice, style, e.target.value)}
          onDeleteValue={() => onStyleChange(activeDevice, style, '')}
        />
      );

      break;
    case 'background-image':
      name = 'Background image';

      styleElement = (
        <MediaSelectBox
          value={value}
          onchange={(e) => onStyleChange(activeDevice, style, e ? e.value : null)}
        />
      );

      break;
    case 'background-repeat':
      name = 'Background repeat';

      styleElement = (
        <BackgroundRepeat
          value={value}
          onChange={(e) => onStyleChange(activeDevice, style, e.value)}
        />
      );

      break;
    case 'background-size':
      name = 'Background size';

      styleElement = (
        <BackgroundSize
          value={value}
          onChange={(e) => onStyleChange(activeDevice, style, e.value)}
        />
      );

      break;
    case 'background-position-x':
      name = 'Background horizontal position';

      styleElement = (
        <BackgroundPositionX
          value={value}
          onChange={(e) => onStyleChange(activeDevice, style, e.value)}
        />
      );

      break;
    case 'background-position-y':
      name = 'Background vertical position';

      styleElement = (
        <BackgroundPositionY
          value={value}
          onChange={(e) => onStyleChange(activeDevice, style, e.value)}
        />
      );

      break;
    default:
      styleElement = <></>;
      break;
  }

  return (
    <>
      <Row className="BreakPoint align-items-center mb-2">
        <Col>
          {name}
          <ul>
            <li
              className="d-flex align-items-center cursor-pointer"
              onClick={() => onRemoveStyle(element, style)}
            >
              <i className="fa fa-times" style={{ color: 'red' }} />
            </li>
            <li>
              {styleElement}
            </li>
            <li className="ms-1 bp-responsivebtn d-flex align-items-center">
              {activeDevice !== 'all' && (
                <>
                  {some(template.desktop_layout, (s) => s.label === editingSector.label) && (
                    <Button
                      className={`breakpoint-txt-btn ${activeDevice === 'desktop'
                        ? 'custom-text-color-blue'
                        : ''} ms-2`}
                      onClick={() => setActiveDevice('desktop')}
                    >
                      <i className="fal fa-desktop-alt fa-2x" aria-hidden="true" />
                    </Button>
                  )}
                  {some(template.tablet_layout, (s) => s.label === editingSector.label) && (
                    <Button
                      className={`breakpoint-txt-btn ${activeDevice === 'tablet' ? 'custom-text-color-blue' : ''} ms-2`}
                      onClick={() => setActiveDevice('tablet')}
                    >
                      <i className="fal fa-tablet-alt fa-2x" aria-hidden="true" />
                    </Button>
                  )}
                  {some(template.phone_layout, (s) => s.label === editingSector.label) && (
                    <Button
                      className={`breakpoint-txt-btn ${activeDevice === 'phone' ? 'custom-text-color-blue' : ''} ms-2`}
                      onClick={() => setActiveDevice('phone')}
                    >
                      <i className="fal fa-mobile-alt fa-2x" aria-hidden="true" />
                    </Button>
                  )}
                  <span style={{ margin: '0 2.5px', fontSize: '12px' }}>┊</span>
                </>
              )}
              <Button
                className={`breakpoint-txt-btn ${activeDevice === 'all' ? 'custom-text-color-blue' : ''} ms-2`}
                onClick={() => {
                  if (activeDevice === 'all')
                    setActiveDevice(activeTab);
                  else
                    setActiveDevice('all');
                }}
              >
                <i className="fal fa-fw fa-link fa-2x" aria-hidden="true" />
              </Button>
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
}

function TemplateEditorGrid({
  template, onChange,
}) {
  const [stateTemplate, setStateTemplate] = useState(template);
  const [activeTab, setActiveTab] = useState('desktop');
  const [layout, setLayout] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingSector, setEditingSector] = useState(null);
  const [widthDropdownOpen, setWidthDropdownOpen] = useState(false);

  const site = useSite();
  const { data: templates } = useGetTemplatesQuery(
    { siteId: site?.id, instanceId: site?.instance_id },
    { skip: isEmpty(site) },
  );

  let newSectorLabelRef;

  useEffect(() => {
    setStateTemplate(template);
  }, [template]);

  useEffect(() => {
    setLayout(stateTemplate[`${activeTab}_layout`]);
  }, [stateTemplate, activeTab]);

  const onLayoutChange = (items) => {
    // we don't get the label and styles from RGL, need to copy them from layout.
    layout?.forEach((l, i) => {
      items[i].label = l.label;
      items[i].mirror_of = l.mirror_of;
      items[i].wrapper_styles = l.wrapper_styles;
      items[i].sector_styles = l.sector_styles;
      items[i].custom_html = l.custom_html;
      items[i].sticky = l.sticky;
      items[i].sticky_threshold = l.sticky_threshold;
      items[i].overlay = l.overlay;
      items[i].is_primary = l.is_primary;
      items[i].parallax = l.parallax;
      items[i].parallax_speed = l.parallax_speed;
    });

    // Avoid recursion
    if (isEqual(items, layout)) {
      return;
    }

    setLayout([...items]);
    setStateTemplate({ ...stateTemplate, [`${activeTab}_layout`]: items });
    onChange({ ...stateTemplate, [`${activeTab}_layout`]: items });
  };

  const getSectorOptions = () => {
    const sectorNames = new Set();
    [stateTemplate.desktop_layout, stateTemplate.tablet_layout,
      stateTemplate.phone_layout].flat().forEach((sector) => {
      sectorNames.add(sector.label);
    });
    return [...sectorNames].map((s) => ({ label: s, value: s }));
  };

  // We only want options in the dropdown lists that are not already in use.
  const styleOptions = [
    { label: 'Width', value: 'width' },
    { label: 'Padding top', value: 'padding-top' },
    { label: 'Padding bottom', value: 'padding-bottom' },
    { label: 'Padding left', value: 'padding-left' },
    { label: 'Padding right', value: 'padding-right' },
    { label: 'Background colour', value: 'background' },
    { label: 'Background image', value: 'background-image' },
    { label: 'Background repeat', value: 'background-repeat' },
    { label: 'Background size', value: 'background-size' },
    { label: 'Background horizontal position', value: 'background-position-x' },
    { label: 'Background vertical position', value: 'background-position-y' },
  ];

  // Wrappers should not be able to have a width set, they should always be 100% of the device width.
  const wrapperStyleOptions = [...reject(styleOptions, (o) => o.value === 'width')];
  const sectorStyleOptions = [...styleOptions];

  if (stateTemplate) {
    devices.forEach((device) => {
      stateTemplate[`${device}_layout`]?.forEach((sector) => {
        if (!editingSector || sector.label !== editingSector.label) {
          return;
        }
        sector.wrapper_styles ||= {};
        sector.sector_styles ||= {};
        Object.keys(sector.wrapper_styles).forEach((s) => remove(wrapperStyleOptions, (o) => o.value === s));
        Object.keys(sector.sector_styles).forEach((s) => remove(sectorStyleOptions, (o) => o.value === s));
      });
    });
  }

  const addWrapperStyle = (s) => {
    devices.forEach((device) => {
      stateTemplate[`${device}_layout`]?.forEach((sector) => {
        if (sector.label !== editingSector.label) {
          return;
        }
        sector.wrapper_styles ||= {};
        sector.wrapper_styles[s] = null;
        setStateTemplate({ ...stateTemplate, [`${device}_layout`]: stateTemplate[`${device}_layout`] });
      });
    });
  };

  const addSectorStyle = (s) => {
    devices.forEach((device) => {
      stateTemplate[`${device}_layout`]?.forEach((sector) => {
        if (sector.label !== editingSector.label) {
          return;
        }
        sector.sector_styles ||= {};
        sector.sector_styles[s] = null;
        setStateTemplate({ ...stateTemplate, [`${device}_layout`]: stateTemplate[`${device}_layout`] });
      });
    });
  };

  const getStyleElement = (s, element) => (
    <StyleElement
      siteId={stateTemplate.site_id}
      element={element}
      style={s}
      editingSector={editingSector}
      template={stateTemplate}
      activeTab={activeTab}
      onStyleChange={(deviceToSet, style, value) => {
        if (deviceToSet === 'all') {
          devices.forEach((device) => {
            stateTemplate[`${device}_layout`]?.forEach((sector, i) => {
              if (sector.label === editingSector.label) {
                stateTemplate[`${device}_layout`][i][`${element}_styles`] ||= {};
                stateTemplate[`${device}_layout`][i][`${element}_styles`][style] = value;
              }
            });
            setStateTemplate({ ...stateTemplate, [`${device}_layout`]: stateTemplate[`${device}_layout`] });
          });
        } else {
          stateTemplate[`${deviceToSet}_layout`].forEach((sector, i) => {
            if (sector.label === editingSector.label) {
              stateTemplate[`${deviceToSet}_layout`][i][`${element}_styles`] ||= {};
              stateTemplate[`${deviceToSet}_layout`][i][`${element}_styles`][style] = value;
            }
          });
          setStateTemplate({ ...stateTemplate, [`${deviceToSet}_layout`]: stateTemplate[`${deviceToSet}_layout`] });
        }
      }}
      onRemoveStyle={(element, style) => {
        devices.forEach((device) => {
          stateTemplate[`${device}_layout`]?.forEach((sector, i) => {
            if (sector.label === editingSector.label) {
              delete stateTemplate[`${device}_layout`][i][`${element}_styles`][style];
            }
          });
          setStateTemplate({ ...stateTemplate, [`${device}_layout`]: stateTemplate[`${device}_layout`] });
        });
      }}
      key={s}
    />
  );

  let wrapperStyleElements = [];
  let sectorStyleElements = [];

  if (stateTemplate) {
    const wrapperStyles = new Set();
    const sectorStyles = new Set();
    devices.forEach((device) => {
      stateTemplate[`${device}_layout`]?.forEach((sector) => {
        sector.wrapper_styles ||= {};
        sector.sector_styles ||= {};
        if (editingSector && sector.label === editingSector.label) {
          Object.keys(sector.wrapper_styles).forEach((s) => wrapperStyles.add(s));
          Object.keys(sector.sector_styles).forEach((s) => sectorStyles.add(s));
        }
      });
    });

    wrapperStyleElements = [...wrapperStyles].map((s) => getStyleElement(s, 'wrapper'));
    sectorStyleElements = [...sectorStyles].map((s) => getStyleElement(s, 'sector'));
  }

  return (
    <>
      <Card>
        <CardBody>
          <Nav tabs className="separator-tabs ms-0 mb-5 custom-cursor-pointer">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'desktop' })}
                onClick={() => setActiveTab('desktop')}
              >
                Desktop
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'tablet' })}
                onClick={() => setActiveTab('tablet')}
              >
                Tablet
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'phone' })}
                onClick={() => setActiveTab('phone')}
              >
                Mobile
              </NavLink>
            </NavItem>
            <NavItem className="ms-auto">
              <CustomButton
                color="primary"
                size="sm"
                className="my-2"
                onClick={() => {
                  const newIndex = maxBy(layout, (s) => parseInt(s.i, 10));
                  const newItem = {
                    i: `${newIndex ? parseInt(newIndex.i) + 1 : 1}`,
                    label: `Sector${newIndex ? parseInt(newIndex.i) + 1 : 1}`,
                    wrapper_styles: {},
                    sector_styles: {},
                    w: 12,
                    h: 1,
                    x: 0,
                    y: Infinity,
                  };
                  setLayout([...layout, newItem]);
                }}
                title="Add sector"
                icon="fal fa-plus"

              />
            </NavItem>
          </Nav>

          <Card className="mb-4">
            <CardBody>
              <CardTitle>Settings</CardTitle>
              <Label for="width" className="has-tooltip" id="widthHelp">Maximum width</Label>
              <UncontrolledTooltip placement="right" target="widthHelp" style={{ textAlign: 'left' }}>
                This is the maximum width for any row in the template.
                Individual sectors can still be given widths less than this number.
              </UncontrolledTooltip>
              <InputGroup style={{ maxWidth: '200px' }}>
                <Input
                  type="number"
                  value={stateTemplate[`${activeTab}_width`]?.replace(/[^0-9]/g, '')}
                  onChange={(e) => {
                    setStateTemplate({ ...stateTemplate, [`${activeTab}_width`]: stateTemplate[`${activeTab}_width`]?.replace(/[0-9]*/, e.target.value) });
                    onChange({ ...stateTemplate, [`${activeTab}_width`]: stateTemplate[`${activeTab}_width`]?.replace(/[0-9]*/, e.target.value) });
                  }}
                />
                <ButtonDropdown
                  isOpen={widthDropdownOpen}
                  toggle={() => setWidthDropdownOpen(!widthDropdownOpen)}
                >
                  <DropdownToggle
                    caret
                    color="primary"
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} // Reactstrap bug
                  >
                    {stateTemplate[`${activeTab}_width`]?.match(/px/) ? 'px' : '%'}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => {
                      const oldWidth = stateTemplate[`${activeTab}_width`];
                      setStateTemplate({ ...stateTemplate, [`${activeTab}_width`]: oldWidth.replace(/[^0-9]+/, 'px') });
                      onChange({ ...stateTemplate, [`${activeTab}_width`]: oldWidth.replace(/[^0-9]+/, 'px') });
                    }}
                    >
                      px
                    </DropdownItem>
                    <DropdownItem onClick={() => {
                      const oldWidth = stateTemplate[`${activeTab}_width`];
                      setStateTemplate({ ...stateTemplate, [`${activeTab}_width`]: oldWidth.replace(/[^0-9]+/, '%') });
                      onChange({ ...stateTemplate, [`${activeTab}_width`]: oldWidth.replace(/[^0-9]+/, '%') });
                    }}
                    >
                      %
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </InputGroup>
            </CardBody>
          </Card>

          <GridLayout
            className="layout"
            layout={layout}
            cols={12}
            rowHeight={60}
            width={1200}
            margin={[0, 0]}
            isBounded
            onLayoutChange={onLayoutChange}
          >
            {layout?.map((item, i) => (
              <div
                key={item.i}
                style={{
                  backgroundColor: getBackgroundColor(i),
                  color: getForegroundColor(i),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {
                  item.mirror_of
                    ? `${templates?.find((t) => t.id === item.mirror_of[0])?.name} >> ${item.mirror_of[1]}`
                    : item.label
                }
                <i
                  className="fa fa-cog ms-2 cursor-pointer"
                  onClick={() => {
                    item.index = i;
                    setEditingSector(item);
                    setStateTemplate(stateTemplate);
                    setShowModal(true);
                  }}
                />
                <i
                  className="fa fa-trash ms-2 cursor-pointer"
                  onClick={() => {
                    remove(layout, (l) => l.i === item.i);
                    setLayout([...layout]);
                  }}
                />
              </div>
            ))}
          </GridLayout>
        </CardBody>
      </Card>
      {showModal && (
        <Modal isOpen className="sector-options">
          <ModalHeader toggle={() => setShowModal(false)}><b>Sector Options</b></ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Mirror a sector from another template:</Label>
                  <CustomSelect
                    isClearable
                    value={{
                      label: (
                        editingSector.mirror_of
                          ? `${templates?.find((t) => t.id === editingSector.mirror_of[0])?.name} >> ${editingSector.mirror_of[1]}`
                          : null
                      ),
                      value: editingSector.mirror_of || '',
                    }}
                    onChange={(e) => {
                      setEditingSector({
                        ...editingSector,
                        mirror_of: e?.value || null,
                        label: e?.value?.at(1) || null,
                      });

                      // Also need to change the label in the template object.
                      const tempLayout = stateTemplate[`${activeTab}_layout`];
                      const sectorIndex = tempLayout.findIndex((s) => s.i === editingSector.i);
                      if (sectorIndex > -1) {
                        tempLayout[sectorIndex].mirror_of = e?.value || null;
                        tempLayout[sectorIndex].label = e?.value?.at(1) || null;
                        setStateTemplate({ ...stateTemplate, [`${activeTab}_layout`]: tempLayout });
                      }
                    }}
                    options={
                      templates?.filter((t) => t.id !== template.id)?.flatMap((t) => (
                        uniqBy(
                          [...t.desktop_layout, ...t.tablet_layout, ...t.phone_layout].map((s) => (
                            { label: `${t.name} >> ${s.label}`, value: [t.id, s.label] }
                          )),
                          (o) => o.label,
                        )
                      ))
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>or, use a sector from another device layout:</Label>
                  <CustomSelect
                    isDisabled={!isEmpty(editingSector.mirror_of)}
                    value={{ label: editingSector.label, value: editingSector.label }}
                    onChange={(e) => {
                      setEditingSector({ ...editingSector, label: e.value, mirror_of: null });

                      // Also need to change the label in the template object.
                      const tempLayout = stateTemplate[`${activeTab}_layout`];
                      const sectorIndex = tempLayout.findIndex((s) => s.i === editingSector.i);
                      if (sectorIndex > -1) {
                        tempLayout[sectorIndex].label = e.value;
                        setStateTemplate({ ...stateTemplate, [`${activeTab}_layout`]: tempLayout });
                      }
                    }}
                    options={reject(getSectorOptions(), (s) => some(stateTemplate[`${activeTab}_layout`], (s2) => s.label === s2.label))}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>or, create a new sector name:</Label>
                  <Input
                    type="text"
                    value={editingSector.label || ''}
                    disabled={!isEmpty(editingSector.mirror_of)}
                    onKeyDown={onKeyDownPrevent}
                    onChange={(e) => {
                      setEditingSector({ ...editingSector, label: e.target.value, mirror_of: null });

                      // Also need to change the label in the template object.
                      const tempLayout = stateTemplate[`${activeTab}_layout`];
                      const sectorIndex = tempLayout.findIndex((s) => s.i === editingSector.i);
                      if (sectorIndex > -1) {
                        tempLayout[sectorIndex].label = e.target.value;
                        setStateTemplate({ ...stateTemplate, [`${activeTab}_layout`]: tempLayout });
                      }
                    }}
                    innerRef={(node) => { newSectorLabelRef = node; }}
                  />
                </FormGroup>
              </Col>
            </Row>
            {isEmpty(editingSector.mirror_of) && (
              <>
                <Row>
                  <Col>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3>Wrapper Styles</h3>

                    <FormGroup>
                      <Label>Add a new style:</Label>
                      <CustomSelect
                        onChange={(e) => addWrapperStyle(e.value)}
                        options={wrapperStyleOptions}
                      />
                    </FormGroup>

                    {wrapperStyleElements}
                  </Col>
                  <Col>
                    <h3>Sector Styles</h3>

                    <FormGroup>
                      <Label>Add a new style:</Label>
                      <CustomSelect
                        onChange={(e) => addSectorStyle(e.value)}
                        options={sectorStyleOptions}
                      />
                    </FormGroup>

                    {sectorStyleElements}
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col>
                <hr />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <h3>Primary Sector</h3>

                <CheckBox
                  title="Primary sector"
                  checked={editingSector.is_primary}
                  onChange={() => {
                    const index = stateTemplate.desktop_layout.findIndex((s) => s.label === editingSector.label);
                    if (index > -1) {
                      const isPrimary = !editingSector.is_primary;
                      setEditingSector({ ...editingSector, is_primary: isPrimary });

                      stateTemplate.desktop_layout[index].is_primary = isPrimary;

                      // Make all other sectors non-primary
                      stateTemplate.desktop_layout.forEach((s, i) => {
                        if (i !== index) {
                          stateTemplate.desktop_layout[i].is_primary = false;
                        }
                      });

                      setStateTemplate({ ...stateTemplate, desktop_layout: stateTemplate.desktop_layout });
                    }
                  }}
                />
              </Col>
              <Col>
                <h3>Sticky</h3>

                <CheckBox
                  title="Sticky sector"
                  checked={editingSector.sticky}
                  onChange={() => {
                    const index = stateTemplate[`${activeTab}_layout`].findIndex((s) => s.label === editingSector.label);
                    if (index > -1) {
                      const sticky = !editingSector.sticky;
                      setEditingSector({ ...editingSector, sticky });

                      stateTemplate[`${activeTab}_layout`][index].sticky = sticky;
                      setStateTemplate({ ...stateTemplate, [`${activeTab}_layout`]: stateTemplate[`${activeTab}_layout`] });
                    }
                  }}
                />
                {editingSector.sticky && (
                  <FormGroup>
                    <CustomTooltip name="StickyThreshold">
                      <Label className="has-tooltip">
                        Sticky Threshold
                      </Label>
                    </CustomTooltip>
                    <InputGroup>
                      <Input
                        type="number"
                        value={editingSector.sticky_threshold}
                        style={{ maxWidth: '100px' }}
                        onChange={(e) => {
                          const { value } = e.target;
                          const index = stateTemplate[`${activeTab}_layout`].findIndex((s) => s.label === editingSector.label);
                          if (index > -1) {
                            setEditingSector({ ...editingSector, sticky_threshold: parseInt(value, 10) });

                            stateTemplate[`${activeTab}_layout`][index].sticky_threshold = parseInt(value, 10);
                            setStateTemplate({ ...stateTemplate, [`${activeTab}_layout`]: stateTemplate[`${activeTab}_layout`] });
                          }
                        }}
                      />
                      <InputGroupText>
                        px
                      </InputGroupText>
                    </InputGroup>
                  </FormGroup>
                )}
              </Col>
              <Col>
                <h3>Add Overlay</h3>

                <CheckBox
                  title="Add overlay sector"
                  checked={editingSector.overlay}
                  onChange={() => {
                    const index = stateTemplate[`${activeTab}_layout`].findIndex((s) => s.label === editingSector.label);
                    if (index > -1) {
                      const overlay = !editingSector.overlay;
                      setEditingSector({ ...editingSector, overlay });

                      stateTemplate[`${activeTab}_layout`][index].overlay = overlay;
                      setStateTemplate({ ...stateTemplate, [`${activeTab}_layout`]: stateTemplate[`${activeTab}_layout`] });
                    }
                  }}
                />
              </Col>
              {editingSector.overlay && (
                <Col>
                  <h3>Parallax</h3>

                  <CheckBox
                    title="Enable parallax"
                    checked={editingSector.parallax}
                    onChange={() => {
                      const index = stateTemplate[`${activeTab}_layout`].findIndex((s) => s.label === editingSector.label);
                      if (index > -1) {
                        const parallax = !editingSector.parallax;
                        setEditingSector({ ...editingSector, parallax });

                        stateTemplate[`${activeTab}_layout`][index].parallax = parallax;
                        setStateTemplate({ ...stateTemplate, [`${activeTab}_layout`]: stateTemplate[`${activeTab}_layout`] });
                      }
                    }}
                  />
                  {editingSector.parallax && (
                    <>
                      <FormGroup>
                        <CustomTooltip name="ParallaxSpeed">
                          <Label className="has-tooltip">
                            Speed
                          </Label>
                        </CustomTooltip>
                        <Input
                          type="number"
                          value={typeof editingSector.parallax_speed === 'undefined' ? 0.5 : editingSector.parallax_speed}
                          style={{ maxWidth: '100px' }}
                          step="any"
                          min={-10}
                          max={10}
                          onChange={(e) => {
                            const { value } = e.target;
                            const index = stateTemplate[`${activeTab}_layout`].findIndex((s) => s.label === editingSector.label);
                            if (index > -1) {
                              setEditingSector({ ...editingSector, parallax_speed: parseFloat(value, 10) });

                              stateTemplate[`${activeTab}_layout`][index].parallax_speed = parseFloat(value, 10);
                              setStateTemplate({ ...stateTemplate, [`${activeTab}_layout`]: stateTemplate[`${activeTab}_layout`] });
                            }
                          }}
                        />
                      </FormGroup>
                    </>
                  )}
                </Col>
              )}
              {/* Empty space instead of parallax column if not using an overlay sector so that the layout doesn't jump around */}
              {!editingSector.overlay && <Col />}
            </Row>
            <Row>
              <Col>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>Custom HTML</h3>

                <FormGroup>
                  <Label>(used on all devices)</Label>
                  <AceEditor.default
                    className="ace-editor"
                    mode="html"
                    theme="xcode"
                    name="template-editor"
                    onChange={(value) => {
                      const index = stateTemplate.desktop_layout.findIndex((s) => s.label === editingSector.label);
                      if (index > -1) {
                        setEditingSector({ ...editingSector, custom_html: value });

                        stateTemplate.desktop_layout[index].custom_html = value;
                        setStateTemplate({ ...stateTemplate, desktop_layout: stateTemplate.desktop_layout });
                      }
                    }}
                    onBlur={(e, code) => (e, code)}
                    height="200px"
                    width="100%"
                    value={(() => {
                      const index = stateTemplate.desktop_layout.findIndex((s) => s.label === editingSector.label);
                      if (index > -1) {
                        return stateTemplate.desktop_layout[index].custom_html;
                      }

                      return '';
                    })()}
                    highlightActiveLine
                    showGutter
                    fontSize={12}
                    setOptions={{
                      enableSnippets: true,
                      showLineNumbers: true,
                      tabSize: 2,

                      // This disables validation but keeps syntax highlighting.
                      // Validation would be great, but unfortunately we can't do things like this:
                      // <head>
                      //   {{{fragment-Head}}}
                      // </head>
                      // because Ace thinks that's not a valid format.
                      useWorker: false,

                    }}
                    editorProps={{ $blockScrolling: true }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => setShowModal(false)}>
              <b>Cancel</b>
            </Button>
            <Button
              color="success"
              onClick={() => {
                layout[editingSector.index].label = newSectorLabelRef.value !== ''
                  ? newSectorLabelRef.value
                  : editingSector.label;
                layout[editingSector.index].wrapper_styles = editingSector.wrapper_styles;
                layout[editingSector.index].sector_styles = editingSector.sector_styles;
                layout[editingSector.index].custom_html = editingSector.custom_html;
                layout[editingSector.index].sticky = editingSector.sticky;
                layout[editingSector.index].overlay = editingSector.overlay;
                layout[editingSector.index].is_primary = editingSector.is_primary;

                setLayout([...layout]);
                onLayoutChange(layout);
                setShowModal(false);
              }}
            >
              <b>Save</b>
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

export default TemplateEditorGrid;
