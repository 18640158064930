import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Row, Input } from 'reactstrap';
import HeaderComponent from '../../../../components/common/header-component';
import { settingRoutes, siteCenter, replacePathParams } from '../../../../constants/routes';
import CustomButton from '../../../../components/common/button';
import CustomSelect from '../../../../components/common/custom-select';
import { isEmpty, get, concat, cloneDeep } from 'lodash-es';
import SaveAndContinue from '../../../../components/common/saveAndContinue';
import withRouter from '../../../../helpers/withRouter';
import { useUpdateSiteMutation } from '../../../../../../common/api/apiSlice';
import BottomActionToolbar from '../../../../components/common/toolbar';
import ColorPicker from '../../../../components/common/color-picker';
import { useInstance, useSite } from '../../../../../../common/hooks';
import { addRecentItem } from '../../../../../../UtilsTS';
import { createColumnHelper } from '@tanstack/react-table';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

export const colorNames = [
  'Primary',
  'Secondary',
  'Tertiary',
  'Black',
  'White',
  'Grey',
];

function ColourPalette(props) {
  const site = useSite();
  const instance = useInstance();
  const siteId = get(site, 'id', null);
  const instanceId = get(instance, 'id', null);
  const [updateSite] = useUpdateSiteMutation();

  const [colors, setColors] = useState([]);

  useEffect(() => {
    if (site) {
      setColors(cloneDeep(site?.colors));
    }
  }, [site]);

  const onHandleAdd = () => {
    const sendParams = {
      id: colors.length + 1,
      label: '',
      hex: '#ffffff',
      gradient: '',
      isGradient: false,
    };
    setColors([...colors, sendParams]);
  };

  const onHandleChange = (e, index, keyName) => {
    const { value, isGradient } = e.target;
    setColors((prevColors) =>
      prevColors.map((color, idx) =>
        idx === index
          ? { ...color, [keyName]: value, is_gradient: isGradient }
          : color,
      ),
    );
  };

  const columnHelper = createColumnHelper();

  const renderLabelCell = ({ row, getValue }) => {
    const d = row.original;
    const currentLabel = getValue();
    const isCustom = d.custom || colorNames.indexOf(currentLabel) === -1;

    return (
      <CustomSelect
        value={
          isCustom
            ? { label: 'Custom', value: 'Custom' }
            : { label: currentLabel, value: currentLabel }
        }
        onChange={(e) => {
          if (e.value === 'Custom') {
            onHandleChange({ target: { value: '' } }, row.index, 'label');
          } else {
            onHandleChange({ target: { value: e.value } }, row.index, 'label');
          }
        }}
        options={concat(
          colorNames.map((n) => ({ label: n, value: n })),
          [{ label: 'Custom', value: 'Custom' }],
        )}
      />
    );
  };

  const renderRealLabelCell = ({ row }) => {
    const d = row.original;
    const currentLabel = get(d, 'label', '');
    const isCustom = d.custom || colorNames.indexOf(currentLabel) === -1;

    return (
      isCustom && (
        <Input
          placeholder="Label"
          className="h-40px"
          type="text"
          value={currentLabel}
          onChange={(e) => onHandleChange(e, row.index, 'label')}
        />
      )
    );
  };

  const renderColourCell = ({ row }) => {
    const d = row.original;
    const colorKey = d.is_gradient ? 'gradient' : 'hex';

    return (
      <ColorPicker
        isColourPalette
        color={get(d, colorKey, '')}
        onChange={(e) =>
          onHandleChange(e, row.index, e.target.isGradient ? 'gradient' : 'hex')}
        onDeleteValue={() =>
          onHandleChange(
            {
              target: {
                value: '#ffffffff',
                isGradient: false,
                gradient: '',
              },
            },
            row.index,
            colorKey,
          )}
      />
    );
  };

  const tableColumns = useMemo(() => [
    columnHelper.accessor((row) => row.label, {
      id: 'label',
      header: 'Label',
      cell: renderLabelCell,
    }),
    columnHelper.display({
      id: 'realLabel',
      header: '',
      cell: renderRealLabelCell,
    }),
    columnHelper.accessor((row) => row.colours, {
      id: 'colours',
      header: 'Colour',
      cell: renderColourCell,
    }),
  ], []);

  const onSave = () => {
    const updatedColors = colors.map((color) =>
      color.is_gradient
        ? { ...color, hex: '' }
        : { ...color, gradient: '' },
    );

    const sendParams = {
      colors: updatedColors,
      id: siteId,
      instance_id: instanceId,
    };
    updateSite(sendParams);
  };

  if (instanceId && siteId) {
    addRecentItem({
      instance_id: instanceId,
      site_id: siteId,
      type: 'colour-palette',
    });
  }

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Colour Palette',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Dashboard',
        backToPath: settingRoutes.dashboard,
        showBreadcrumb: false,
        staticBreadcrumbData: [
          {
            name: 'Dashboard',
            url: replacePathParams(settingRoutes.dashboard, [], props),
          },
          {
            name: 'Site Centre',
            url: replacePathParams(siteCenter.pageList, [], props),
          },
          {
            name: 'Design Options',
            url: replacePathParams(siteCenter.designOption, [], props),
          },
          { name: 'Colour Palette', url: '' },
        ],
      }}
      />
      <Card className="mb-4">
        <CardBody>
          <Row className="BreakPoint align-items-center">
            <ReactTable8
              columns={tableColumns}
              data={colors}
            />
          </Row>
        </CardBody>
      </Card>
      <BottomActionToolbar
        component={(
          <>
            <CustomButton
              color="info"
              className="common-info-button button-md fw-bold"
              title="ADD COLOUR"
              icon="fa fa-plus"
              onClick={() => onHandleAdd()}
            />
            {' '}
            <SaveAndContinue
              isDisabled={isEmpty(colors)}
              onSave={onSave}
              onContinue={replacePathParams(siteCenter.designOption, [], props)}
            />
          </>
        )}
      />
    </>
  );
}

export default withRouter(ColourPalette);
