import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Row, Col, Label, Card, CardBody, CardTitle, FormGroup, Button, ButtonGroup,
} from 'reactstrap';
import { cloneDeep, get, isEmpty, map } from 'lodash-es';
import { displayError, selectColorStyles } from '../../../../../../Utils';
import withRouter from '../../../../helpers/withRouter';
import { ComponentFields } from '../../design-options/components/component';
import DeviceButtons from '../../../../components/common/deviceButtons';
import {
  useGetComponentCategoriesQuery,
  useGetComponentQuery,
  useGetComponentsQuery,
  useGetSectionQuery,
  useSectionUpdateMutation,
} from '../../../../../../common/api/apiSlice';
import AdvancedSettings from '../../../../components/common/advancedSettings';
import { useUser } from '../../../../../../common/hooks';
import { useAppSelector } from '../../../../../../rootStore';

const filterFieldType = [
  { label: 'Colours', value: 'color' },
  { label: 'Text', value: 'text' },
  { label: 'Images/Icons', value: 'image' },
  { label: 'Dimensions/Layout', value: 'dimension' },
  { label: 'Buttons', value: 'buttons' },
];

const textFields = [
  'text',
  'rich_text',
  'font_family',
  'font_style',
  'font_weight',
  'font_size',
  'link',
  'text_style',
];

const imagesIconFields = [
  'image',
  'icon',
  'background_position_x',
  'background_position_y',
  'background_size',
  'background_repeat',
];

const dimensionsLayoutFields = [
  'number',
  'length',
  'bool',
];

const buttonsFields = [
  'button_style',
];

const colourFields = [
  'color',
];

function ComponentPreview(props) {
  const {
    siteId,
    section,
    component: { id, name },
    onSelect,
    height,
  } = props;

  const domain = useAppSelector((state) => state.pageData.admin_domain);

  const adminDomain = domain ? `https://${domain}` : '';

  const uniqueId = `component-${crypto.randomUUID()}`;

  const previewUrl = `${adminDomain}/api/v1/components/${id}/preview?unique_id=${uniqueId}&site_id=${siteId}&section_id=${section?.id}&scss_vars=${section.scss_vars ? encodeURIComponent(JSON.stringify(section.scss_vars)) : ''}&html_vars=${section.html_vars ? encodeURIComponent(JSON.stringify(section.html_vars)) : ''}`;

  return (
    <>
      {onSelect ? name : ''}

      <iframe
        title="component-preview"
        id={uniqueId}
        src={previewUrl}
        style={{ width: '100%', height: height || '200px' }}
      />

      {onSelect && (
        <Button
          color="primary"
          onClick={onSelect}
        >
          Select
        </Button>
      )}
    </>
  );
}

function ComponentSection(props) {
  const {
    section: { id: sectionId },
    isEmailPage,
    saveCounter,
    params: { instanceId, siteId },
    updateStylesheet,
  } = props;

  const user = useUser();
  const userHasDraftAccess = user.hasFeature('use_draft_components');

  const { data: section } = useGetSectionQuery({ instanceId, sectionId });

  const [sectionUpdate] = useSectionUpdateMutation();

  const [params, setParams] = useState(null);

  const [selectedFilter, setFilter] = useState([]);

  const [categoryId, setCategoryId] = useState(null);

  // This counter is incremented whenever the order of sub-items changes. The rich text editor is uncontrolled, so we need to completely tear it down and recreate it when we reorder sub-items.
  const [orderCounter, setOrderCounter] = useState(0);

  useEffect(() => {
    if (section) {
      setParams(cloneDeep(section));
    }
  }, [section]);

  const onSave = async (payload) => {
    let isValid = true;

    const subItems = component.sub_items;
    const componentHTMLVars = component.html_vars;

    for (let index = 0; index < componentHTMLVars.length; index += 1) {
      const element = componentHTMLVars[index];
      const subItemName = element?.sub_item;
      const htmlName = element?.html_name;
      const charLimit = get(element, 'char_limit', '');
      const itemIndex = subItems.findIndex((e) => e === subItemName);
      const key = 'sub_item' in element ? `${subItemName}_${itemIndex + 1}_${htmlName}` : htmlName;

      if (payload?.html_vars && !isEmpty(params?.html_vars[key]) && key in payload.html_vars && charLimit && payload.html_vars[key]?.replace(/(<([^>]+)>)/ig, '').length > charLimit) {
        displayError(`Character limit for "${element.name}" field exceeded.`);
        isValid = false;
      }

      if (element.type === 'image' && payload?.html_vars) {
        if (`${key}_tablet` in payload.html_vars && !isEmpty(payload.html_vars[`${key}_tablet`]) && isEmpty(payload.html_vars[`${key}_phone`])) {
          displayError(`${element?.name} requires a phone device image.`);
          isValid = false;
        }

        if (`${key}_phone` in payload.html_vars && !isEmpty(payload.html_vars[`${key}_phone`]) && isEmpty(payload.html_vars[`${key}_tablet`])) {
          displayError(`${element?.name} requires a tablet device image.`);
          isValid = false;
        }
      }
    }

    if (isValid) {
      await sectionUpdate({
        ...payload,
        instance_id: instanceId,
      });

      updateStylesheet();
    }
  };

  useEffect(() => {
    if (saveCounter) {
      (async () => {
        onSave(params);
      })();
    }
  }, [saveCounter]);

  const tempState = { ...params };

  const { component_id } = tempState;

  const [component, setComponent] = useState(null);
  const [activeDevice, setActiveDevice] = useState('desktop');

  const { data: componentCategories = [] } = useGetComponentCategoriesQuery();

  const { data: components = [] } = useGetComponentsQuery({ component_category_id: categoryId }, { skip: isEmpty(categoryId) });

  const { data } = useGetComponentQuery(component_id, { skip: isEmpty(component_id) });

  useEffect(() => {
    if (data) {
      setComponent(data);
    }
  }, [data]);

  const getItemCounts = () => {
    if (!component) {
      return {};
    }

    const itemCounts = {};

    component.sub_items.forEach((item) => {
      const prefixes = {};
      for (let count = 1; count <= 999; count++) {
        Object.keys(tempState.html_vars).forEach((key) => {
          if (key.match(`^${item}_${count}_`) && !isEmpty(tempState.html_vars[key])) {
            prefixes[`^${item}_${count}_`] = true;
          }
        });
      }
      itemCounts[item] = Object.keys(prefixes).length;
    });

    return itemCounts;
  };

  const onChangeVariables = (value, keyName, type, activeDevice) => {
    const tempVars = { ...tempState[type] };
    if (activeDevice === 'all') {
      tempVars[keyName] = value;
      delete tempVars[`${keyName}_desktop`];
      delete tempVars[`${keyName}_tablet`];
      delete tempVars[`${keyName}_phone`];
    } else if (isEmpty(activeDevice)) {
      tempVars[keyName] = value;
    } else {
      delete tempVars[keyName];
      tempVars[`${keyName}_${activeDevice}`] = value;
    }
    setParams({ ...tempState, [type]: tempVars });
  };

  const filterVariables = (variableType, data = []) => {
    if (isEmpty(selectedFilter)) {
      return component[variableType];
    }

    for (let index = 0; index < selectedFilter.length; index += 1) {
      const element = selectedFilter[index];
      switch (element) {
        case 'text':
          data = [...data, ...component[variableType].filter((item) => textFields.includes(item?.type))];
          break;
        case 'color':
          data = [...data, ...component[variableType].filter((item) => colourFields.includes(item?.type))];
          break;
        case 'image':
          data = [...data, ...component[variableType].filter((item) => imagesIconFields.includes(item?.type))];
          break;
        case 'dimension':
          data = [...data, ...component[variableType].filter((item) => dimensionsLayoutFields.includes(item?.type))];
          break;
        case 'buttons':
          data = [...data, ...component[variableType].filter((item) => buttonsFields.includes(item?.type))];
          break;
        default:
          data = component[variableType];
          break;
      }
    }
    return data;
  };

  const renderHTMLSettings = (subItem = null, count = null) => {
    const options = isEmpty(subItem) ? filterVariables('html_vars') : component?.html_vars;
    return options.map((v, index) => {
      if (subItem && subItem !== v.sub_item) {
        return null;
      }

      if (!subItem && v.sub_item) {
        return null;
      }

      if (v.displayed_if) {
        const displayedIfField = options.find((o) => o.html_name === v.displayed_if);
        const displayedIfKey = subItem && subItem === displayedIfField?.sub_item ? `${subItem}_${count}_${v.displayed_if}` : v.displayed_if;
        let value = tempState?.html_vars[displayedIfKey];
        if (typeof value === 'undefined') {
          value = component.html_vars.find((h) => h.html_name === v?.displayed_if)?.default;
        }
        if (value !== v.checked) {
          return null;
        }
      }

      const key = subItem ? `${subItem}_${count}_${v.html_name}` : v.html_name;

      return v.type === 'rich_text'
        // "index" is the index of the variable, count is the "index" of the field. We need to use "count" as the index
        // here otherwise all rich text editors in sub-items would have the same index.
        ? (
            <Row key={key} className="w-100">
              <Col className="mb-4">
                <Label>{v.name}</Label>
                <ComponentFields
                  params={props?.params}
                  item={v}
                  index={count}
                  keyName={key}
                  orderCounter={orderCounter}
                  state={tempState.html_vars}
                  onChange={(value, activeDevice) => onChangeVariables(value, key, 'html_vars', activeDevice)}
                />
              </Col>
            </Row>
          )
        : (
            <Col xl={3} key={key} className="mb-4">
              {v.type !== 'bool' && <Label>{v.name}</Label>}
              <ComponentFields
                params={props?.params}
                index={index}
                item={v}
                keyName={key}
                state={tempState.html_vars}
                isResponsive={
                  (v?.type === 'image' && v?.support_responsive_image)
                  || v?.type === 'number_responsive'
                }
                onChange={(value, activeDevice) => onChangeVariables(value, key, 'html_vars', activeDevice)}
              />
            </Col>
          );
    });
  };

  const renderSCSSSettings = (subItem = null, count = null) => {
    const options = isEmpty(subItem) ? filterVariables('scss_vars') : component?.scss_vars;
    return options.map((v, index) => {
      if (subItem && subItem !== v.sub_item) {
        return null;
      }

      if (v.displayed_if) {
        const displayedIfKey = subItem ? `${subItem}_${count}_${v.displayed_if}` : v.displayed_if;
        let value = tempState?.html_vars[displayedIfKey];
        if (typeof value === 'undefined') {
          value = component.html_vars.find((h) => h.html_name === v?.displayed_if)?.default;
        }
        if (value !== v.checked) {
          return null;
        }
      }

      const key = subItem ? `${subItem}_${count}_${v.scss_name}` : v.scss_name;

      return (
        <Col xl={3} key={key} className="mb-4">
          <Label>{v.name}</Label>
          <ComponentFields
            params={props?.params}
            index={index}
            item={v}
            type="scss_vars"
            keyName={key}
            state={tempState.scss_vars}
            isResponsive={v?.type === 'length_responsive' || v?.type === 'number_responsive'}
            onChange={(value, activeDevice) => onChangeVariables(value, key, 'scss_vars', activeDevice)}
          />
        </Col>
      );
    });
  };

  const itemCounts = getItemCounts();

  const onHandleMove = (e, count, moveUp) => {
    component.html_vars.map((v) => {
      if ('sub_item' in v && v.sub_item === e) {
        const key = `${e}_${count}_${v.html_name}`;
        let tempVar;
        const keyName = `${e}_${moveUp ? count - 1 : count + 1}_${v.html_name}`;
        tempVar = tempState.html_vars[key];
        tempState.html_vars[key] = tempState.html_vars[keyName];
        tempState.html_vars[keyName] = tempVar;
      }
    });
    onSave(tempState);
    setOrderCounter(orderCounter + 1);
  };

  const onFilterChange = (e) => {
    if (selectedFilter.includes(e)) {
      const tempState = [...selectedFilter];
      const index = tempState.findIndex((s) => s === e);
      tempState.splice(index, 1);
      setFilter(tempState);
    } else {
      setFilter([...selectedFilter, e]);
    }
  };

  return (
    <>
      <AdvancedSettings
        siteId={siteId}
        instanceId={instanceId}
        isEmailPage={isEmailPage}
        section={params}
        setSection={setParams}
        handleSaveBreakpoint={(e) => setParams({ ...params, break_point: e })}
        onHandleSectionIsOffline={() => setParams({ ...params, is_offline: !params?.is_offline })}
        onChangeInitialVisibility={() => setParams({ ...params, initial_visibility: !params?.initial_visibility })}
        onHandleChangeVisibility={(e) => setParams({ ...params, visible_to: e.value })}
      />
      <Card>
        <CardBody>
          {component && (
            <>
              <b>
                Current component:
              </b>
              {' '}
              {component.name}
              {' '}
              &mdash;
              {' '}
              <a
                href="#"
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  setComponent(null);
                  setParams({ ...tempState, component_id: null });
                }}
              >
                Change
              </a>
              <br />
              <br />

              <Card className="mb-3">
                <CardBody>
                  <CardTitle>
                    Preview
                    <DeviceButtons onClick={(e) => setActiveDevice(e)} activeDevice={activeDevice} />
                  </CardTitle>
                  <div className={`device-container device-${activeDevice}`}>
                    <ComponentPreview
                      siteId={siteId}
                      section={tempState}
                      component={component}
                    />
                  </div>
                </CardBody>
              </Card>

              <Card className="ComponentSettings mb-3">
                <CardBody>
                  <CardTitle>
                    Settings
                  </CardTitle>
                  <div className="mb-4">
                    <ButtonGroup>
                      {map(filterFieldType, (f, i) => (
                        <Button
                          key={`filter-${i}`}
                          color="primary"
                          outline
                          onClick={() => onFilterChange(f?.value)}
                          active={selectedFilter.includes(f?.value)}
                          className="text-center"
                        >
                          {f.label}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </div>
                  <Row>
                    {renderHTMLSettings()}
                    {renderSCSSSettings()}
                  </Row>
                </CardBody>
              </Card>

              {component.sub_items.map((item, index) => (
                <Card key={index} className="ComponentSettings mb-3">
                  <CardBody>
                    <CardTitle>
                      {item}
                    </CardTitle>

                    <Row className="mb-4">
                      <Col>
                        <Button
                          color="primary"
                          onClick={() => {
                            const tempHTMLVars = tempState.html_vars;
                            const newHTMLVars = {};

                            for (let key in tempHTMLVars) {
                              if (!key.startsWith(`${item}_`)) {
                                newHTMLVars[key] = tempHTMLVars[key];
                                continue;
                              }

                              const keyPart = key.replace(`${item}_`, '');
                              const count = parseInt(keyPart.substring(0, keyPart.indexOf('_')));
                              const varName = keyPart.substring(keyPart.indexOf('_') + 1);

                              newHTMLVars[`${item}_${count + 1}_${varName}`] = tempHTMLVars[key];
                            }

                            newHTMLVars[`${item}_1_dummy`] = 'dummy';
                            onSave({ ...tempState, html_vars: newHTMLVars });
                          }}
                        >
                          <i className="fa fa-plus" />
                          {' '}
                          Add
                        </Button>
                      </Col>
                    </Row>

                    {itemCounts[item] > 0 && [...Array(itemCounts[item])].map((e, i) => (
                      <Card key={i} className="mb-4">
                        <CardBody>
                          <Row>
                            <Col className="d-flex col-md-1">
                              {i !== 0 && (
                                <Button className="custom-simple-txt-btn me-2" onClick={() => onHandleMove(item, i + 1, true)}>
                                  <i className="fa-solid fa-circle-arrow-up" />
                                </Button>
                              )}
                              {' '}
                              {[...Array(itemCounts[item])].length !== i + 1 && (
                                <Button className="custom-simple-txt-btn" onClick={() => onHandleMove(item, i + 1, false)}>
                                  <i className="fa-solid fa-circle-arrow-down" />
                                </Button>
                              )}
                            </Col>
                            <Col>
                              <h3>
                                Item
                                {' '}
                                {i + 1}
                                {' - '}
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    // This is a bit complicated... we need to reorder the list of subitems.
                                    const tempHTMLVars = { ...tempState.html_vars };
                                    const tempSCSSVars = { ...tempState.scss_vars };

                                    // These hold the settings for all subitems of this type, after we have deleted the item that the user wants to delete.
                                    const newItemHTMLVars = {};
                                    const newItemSCSSVars = {};

                                    let counter = 1;

                                    for (let n = 1; n <= 999; n++) {
                                      // These hold the settings for this particular item.
                                      const thisItemHTMLVars = {};
                                      const thisItemSCSSVars = {};

                                      Object.keys(tempHTMLVars).forEach((key) => {
                                        if (key.startsWith(`${item}_${n}_`)) {
                                          if (n !== i + 1) {
                                            thisItemHTMLVars[key.replace(`${item}_${n}_`, '')] = tempHTMLVars[key];
                                          }
                                        }
                                      });

                                      Object.keys(tempSCSSVars).forEach((key) => {
                                        if (key.startsWith(`${item}_${n}_`)) {
                                          if (n !== i + 1) {
                                            thisItemSCSSVars[key.replace(`${item}_${n}_`, '')] = tempSCSSVars[key];
                                          }
                                        }
                                      });

                                      // We now have the list of settings for this particular item. We then apply them to the settings for all items of this type.
                                      if (Object.keys(thisItemHTMLVars).length > 0) {
                                        Object.keys(thisItemHTMLVars).forEach((key) => {
                                          newItemHTMLVars[`${item}_${counter}_${key}`] = thisItemHTMLVars[key];
                                        });

                                        Object.keys(thisItemSCSSVars).forEach((key) => {
                                          newItemSCSSVars[`${item}_${counter}_${key}`] = thisItemSCSSVars[key];
                                        });

                                        counter++;
                                      }
                                    }

                                    // We then delete all settings of this type from the main object and then add the new settings.
                                    Object.keys(tempHTMLVars).forEach((key) => {
                                      if (key.startsWith(`${item}_${i + 1}_`)) {
                                        delete tempHTMLVars[key];
                                      }
                                    });

                                    Object.keys(tempSCSSVars).forEach((key) => {
                                      if (key.startsWith(`${item}_${i + 1}_`)) {
                                        delete tempSCSSVars[key];
                                      }
                                    });

                                    Object.assign(tempHTMLVars, newItemHTMLVars);
                                    Object.assign(tempSCSSVars, newItemSCSSVars);

                                    onSave({ ...tempState, html_vars: tempHTMLVars, scss_vars: tempSCSSVars });
                                    setOrderCounter(orderCounter + 1);
                                  }}
                                >
                                  Remove
                                </a>
                              </h3>
                              <div className="display-flex-grid">
                                {renderHTMLSettings(item, i + 1)}
                                {renderSCSSSettings(item, i + 1)}
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    ))}
                    {itemCounts[item] > 0 && (
                      <Row>
                        <Col>
                          <Button
                            color="primary"
                            onClick={() => {
                              const tempHTMLVars = tempState.html_vars;
                              tempHTMLVars[`${item}_${itemCounts[item] + 1}_dummy`] = 'dummy';
                              onSave({ ...tempState, html_vars: tempHTMLVars });
                            }}
                          >
                            <i className="fa fa-plus" />
                            {' '}
                            Add
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              ))}
            </>
          )}

          {!component && (
            <>
              <Row className="mt-4">
                <Col>
                  <FormGroup>
                    <Label className="w-100">
                      Component Category:
                    </Label>
                    <Select
                      options={componentCategories.map((c) => ({ label: c.name, value: c.id }))}
                      onChange={(e) => setCategoryId(e.value)}
                      styles={selectColorStyles}
                    />
                  </FormGroup>
                </Col>
              </Row>

              {/*
                the c.id === component_id below is so that if another user who has access to draft components has
                previously selected a draft, then a different user should still see that section in the list.
              */}
              {components.filter((c) => userHasDraftAccess || (c.published && !c.deprecated) || c.id === component_id).map((component) => (
                <Card key={component.id} className="mb-4">
                  <CardBody>
                    <Row>
                      <Col>
                        <ComponentPreview
                          siteId={siteId}
                          section={tempState}
                          component={component}
                          onSelect={() => {
                            setComponent(component);
                            setParams({ ...tempState, component_id: component.id });
                          }}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ))}
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}
export default withRouter(ComponentSection);
