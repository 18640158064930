import React from 'react';
import { useState } from 'react';
import HeaderComponent from 'Dashboard/src/components/common/header-component';
import { settingRoutes, replacePathParams, mainRoutes } from 'Dashboard/src/constants/routes';
import withRouter from 'Dashboard/src/helpers/withRouter';
import { get, isEmpty, map } from 'lodash-es';
import { Card, CardBody, Modal, ModalBody, ModalHeader, ModalFooter, Table, Progress } from 'reactstrap';
import DateTimeFormat from 'Dashboard/src/components/common/DateTimeFormat';
import CustomButton from 'Dashboard/src/components/common/button';
import confirm from 'Dashboard/src/components/common/confirm';
import { displaySuccess } from '../../../../../Utils';
import Pagination from 'Dashboard/src/components/common/custom-pagination';
import { DateTime } from 'luxon';
import { useBackgroundJobCancelMutation, useGetBGJobProgressQuery } from '../../../../../common/api/apiSlice';
import './index.scss';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

const getProgressColor = (status) => {
  switch (status) {
    case 'Running':
      return 'info';
    case 'Completed':
      return 'success';
    case 'Error':
      return 'danger';
    default: return 'warning';
  }
};

function BackgroundJobs(props) {
  const { params: { instanceId, pageNo }, navigate } = props;
  const [showModal, setModal] = useState(false);
  const [error, setError] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  const { data: jobs = [] } = useGetBGJobProgressQuery(
    { instanceId, pageSize, pageNo },
    {
      skip: isEmpty(instanceId),
      pollingInterval: 2000,
    },
  );

  const [cancelJob] = useBackgroundJobCancelMutation();

  const pagination = get(jobs, 'pagination', []);
  const progressJob = get(jobs, 'progress_jobs', []);

  const onHandleCancel = async (d) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure you want to cancel?',
      confirmText: 'Confirm',
      confirmColor: 'danger',
      cancelColor: 'success',
      cancelText: 'No',
    });

    if (result) {
      const response = await cancelJob({ id: d?.id, canceled: true, instance_id: instanceId });
      if (response && response?.data?.meta?.is_success) {
        displaySuccess(`The ${d?.job_type} for job ${d?.id} has been canceled.`);
      }
    }
  };

  const onHandleToggle = () => {
    setError(null);
    setModal(!showModal);
  };

  const jobPercentage = (d) => {
    const shouldAnimate = get(d, 'indeterminate_progress', false);
    const val = shouldAnimate ? 100 : (d.progress_current / (d.progress_max === 0 ? 100 : d.progress_max) * 100).toFixed();
    return (
      <div className="progress-column">
        <Progress
          value={val}
          color={getProgressColor(d.status)}
          animated={(['Running', 'Queued'].includes(d.status)) || (!['Completed'].includes(d.status) && shouldAnimate)}
        >
          {!shouldAnimate && `${val}%`}
        </Progress>
      </div>
    );
  };

  const tableColumns = [
    {
      header: 'Started Time',
      accessorKey: 'created_at',
      cell: (column) => <DateTimeFormat datetime={column.getValue()} localeString={DateTime.DATETIME_MED} />,
    },
    {
      header: 'Job Type',
      accessorKey: 'job_type',
    },
    {
      header: 'Status',
      accessorKey: 'status',
    },
    {
      header: 'Progress',
      accessorKey: 'progress_current',
      cell: ({ row }) => jobPercentage(row.original),
    },
    {
      header: 'Report',
      id: 'report',
      cell: ({ row }) => (
        <CustomButton
          size="xs"
          title=""
          icon="fa-solid fa-file"
          color="info"
          disabled={['Running', 'Queued', 'Canceled'].includes(row.original.status) || ['InstanceDuplicateJob'].includes(row.original.job_type)}
          onClick={() => navigate(
            replacePathParams(settingRoutes.backgroundJobsReport, [
              { key: 'instanceId', value: instanceId },
              { key: 'reportType', value: get(row.original, 'job_type', '') },
              { key: 'reportId', value: get(row.original, 'id', null) },
            ], props),
          )}
        />
      ),
    },
    {
      header: 'Error',
      id: 'error',
      cell: ({ row }) => (
        <CustomButton
          size="xs"
          title=""
          icon="fa-solid fa-circle-exclamation"
          color="warning"
          disabled={isEmpty(row.original.job_errors)}
          onClick={() => {
            onHandleToggle();
            setError(row.original.job_errors);
          }}
        />
      ),
    },
    {
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => (
        <CustomButton
          size="xs"
          title="Cancel"
          color="danger"
          disabled={['Completed', 'Canceled'].includes(row.original.status)}
          onClick={() => onHandleCancel(row.original)}
        />
      ),
    },
  ];

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    { name: 'Settings', url: replacePathParams(mainRoutes.setting, [], props) },
    { name: 'Jobs', url: '' },
  ];

  return (
    <div className="backgroundJobs">
      <HeaderComponent setPath={{
        headingName: 'Background Jobs',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: '',
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Pagination
        pageSize={pageSize}
        type={['job', 'jobs']}
        currentPage={Number(pageNo)}
        records={progressJob}
        onChangePage={(p) => {
          navigate(replacePathParams(
            settingRoutes.backgroundJobs,
            [{ key: 'pageNo', value: p }],
            props,
          ));
        }}
        getPagination={pagination}
        changePageSize={(e) => setPageSize(e)}
      />
      <Card>
        <CardBody>
          <ReactTable8
            data={progressJob}
            columns={tableColumns}
          />
        </CardBody>
      </Card>
      {showModal && !isEmpty(error) && (
        <Modal isOpen={showModal} toggle={onHandleToggle}>
          <ModalHeader toggle={onHandleToggle}>Errors</ModalHeader>
          <ModalBody>
            <Table>
              <tbody>
                <tr>
                  <th>Email</th>
                  <th>Error</th>
                </tr>
                {map(error, (e, index) => (
                  <tr key={index}>
                    <td>{e.email}</td>
                    <td>{e.error}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <CustomButton
              size="xs"
              title="Cancel"
              color="danger"
              onClick={() => onHandleToggle()}
            />
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
}

export default withRouter(BackgroundJobs);
