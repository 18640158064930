import React, { useState, useEffect } from 'react';
import HeaderComponent from '../../components/common/header-component';
import { Row, Col, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DropzoneComponent } from 'react-dropzone-component';
import ReactDOMServer from 'react-dom/server';
import confirm from '../../components/common/confirm';
import { displaySuccess, displayError } from '../../../../Utils';
import Pagination from '../../components/common/custom-pagination';
import { settingRoutes, replacePathParams, mediaCenterRoutes } from '../../constants/routes';
import { baseURL } from '../../../../api';
import { omit, isEmpty, get } from 'lodash-es';
import withRouter from '../../helpers/withRouter';
import Listing from './Listing';
import { useNavigate } from 'react-router';
import { addRecentItem } from '../../../../UtilsTS';
import {
  useLazyCheckBeforeDeleteQuery,
  useFileDeleteMutation,
  useGetFilesQuery,
  useFileRenameMutation,
  useFileUpdateMutation,
  useFileCreateMutation,
  useFileFolderDeleteMutation,
} from '../../../../common/api/apiSlice';

const Upload = (props) => {
  const {
    galleryType,
    setUploadedFileData,
    params: { pageNo, folderId, instanceId },
  } = props;

  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [mediaId, setMediaId] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [isFolder, setIsFolder] = useState(false);
  const [modal, setModal] = useState(false);
  const [isEditAction, setIsEditAction] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [uploaderWindow, setUploaderWindow] = useState(false);
  const [orderBy, setOrderBy] = useState('created_at');
  const [orderDirection, setOrderDirection] = useState('desc');
  const [rewriteValue, setRewriteValue] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [dropzone, setDropzone] = useState({});
  const [fileData, setFileData] = useState({});

  const navigate = useNavigate();

  let sendParams = {
    file_folder_id: folderId,
    instance_id: instanceId,
    page: pageNo,
    filter_value: filterValue,
    page_size: pageSize,
    order_by: orderBy,
    order_direction: orderDirection,
  };

  if (isEmpty(folderId)) {
    sendParams = omit(sendParams, ['file_folder_id']);
  }

  const [checkBeforeDelete] = useLazyCheckBeforeDeleteQuery();
  const { data, refetch } = useGetFilesQuery(sendParams, { skip: isEmpty(instanceId) });
  const [fileDelete] = useFileDeleteMutation();
  const [fileRename] = useFileRenameMutation();
  const [fileUpdate] = useFileUpdateMutation();
  const [fileCreate] = useFileCreateMutation();
  const [fileFolderDelete] = useFileFolderDeleteMutation();

  const pagination = get(data, 'pagination', {});
  const mediaData = get(data, 'files', []);
  const folderName = get(data, 'folder_name', '');
  const parentFolders = get(data, 'parent_folders', []);

  if (instanceId && (!folderId || folderName)) {
    addRecentItem({
      instance_id: instanceId,
      type: folderId ? 'media-center-folder' : 'media-center',
      item_id: folderId,
    });
  }

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    if (rewriteValue && !isEmpty(fileData)) {
      dropzone.removeFile(fileData);
      dropzone.addFile(fileData);
    }
  }, [rewriteValue, fileData]);

  const getSortMediaData = (fieldName, sortingDirection) => {
    let fieldNameForSorting;
    if (fieldName === 'name') {
      fieldNameForSorting = 'filename';
    } else if (fieldName === 'date') {
      fieldNameForSorting = 'created_at';
    } else if (fieldName === 'size') {
      fieldNameForSorting = 'length';
    } else {
      fieldNameForSorting = '';
    }
    const directionValue = sortingDirection ? 'desc' : 'asc';
    setOrderBy(fieldNameForSorting);
    setOrderDirection(directionValue);
  };

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  const onHandleFolderNameChange = (e) => {
    const { target: { value } } = e;
    setNewFolderName(value);
  };

  const onChangeSearchValue = (e) => {
    const { target: { value } } = e;
    setFilterValue(value);
  };

  const onHandleDeleteMedia = async (id, type) => {
    let result;
    try {
      if (type !== true) {
        const checkParams = { instanceId, objectId: id, objectType: 'WGFile' };
        const checkResponse = await checkBeforeDelete(checkParams);
        if (checkResponse.data.meta.is_success) {
          const { data: { data: { total, desc } } } = checkResponse;
          result = await confirm({
            title: <b>Confirm!</b>,
            message: (
              <>
                <b>Total Uses:</b>
                {' '}
                {`${total}`}
                <p>
                  {' '}
                  {desc.map((val) => <li key={val}>{val}</li>)}
                </p>
                <p>Are you sure?</p>
              </>
            ),
            confirmText: 'Yes',
            confirmColor: 'success',
            cancelColor: 'btn btn-danger',
            cancelText: 'No',
          });
        }
      } else {
        result = await confirm({
          title: <b>Confirm!</b>,
          message: 'Are you sure?',
          confirmText: 'Yes',
          confirmColor: 'success',
          cancelColor: 'btn btn-danger',
          cancelText: 'No',
        });
      }
      if (result) {
        let response = null;
        const sendParams = { id, instance_id: instanceId };
        if (type === true) {
          response = await fileFolderDelete(sendParams);
        } else {
          response = await fileDelete(sendParams);
        }
        if (response && response?.data?.meta?.is_success) {
          displaySuccess(response?.data?.meta?.messages);
        }
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  const handleAddFolder = () => {
    setNewFolderName('');
    setIsFolder(true);
    toggle();
  };

  const onHandleCreateFolder = async () => {
    const sendParams = {
      instance_id: instanceId,
      file_folder_id: folderId,
      name: newFolderName,
    };
    const response = await fileCreate(sendParams);
    if (response && response?.data?.meta?.is_success) {
      setModal(!modal);
      displaySuccess(response?.data?.meta?.messages);
    }
  };

  const onHandleRename = async () => {
    const allowedFiles = /^([a-zA-Z0-9\s_\\.\-:])+.doc|.docx|.pdf|.xls|.xlsx|.png|.jpg|.jpeg|.gif$/;
    const sendParams = {
      id: mediaId,
      instance_id: instanceId,
      name: newFolderName,
    };

    let response = null;

    if (isFolder) {
      response = await fileUpdate(sendParams);
    } else if ((!allowedFiles.test(newFolderName))) {
      response = await fileRename(sendParams);
    } else {
      displayError('Sorry, you can\'t change extensions');
    }
    if (response && response?.data?.meta?.is_success) {
      setModal(!modal);
      setIsFolder(!isFolder);
      setIsEditAction(!isEditAction);
      setMediaId('');
      setNewFolderName('');
      displaySuccess(response?.data?.meta?.messages);
    }
  };

  const onHandleEnter = (event) => {
    if (event.keyCode === 13) {
      if (isEditAction) {
        onHandleRename();
      } else {
        onHandleCreateFolder();
      }
    }
  };

  const onEditMediaItem = (data) => {
    const { id, filename, is_folder } = data;
    setModal(!modal);
    setNewFolderName(filename);
    setIsEditAction(true);
    setIsFolder(is_folder);
    setMediaId(id);
  };

  const onHandlePageChange = (e) => {
    if (folderId) {
      navigate(
        replacePathParams(
          mediaCenterRoutes.mediaCenterFolder,
          [
            { key: 'folderId', value: folderId },
            { key: 'pageNo', value: e },
          ], props,
        ),
      );
    } else {
      navigate(
        replacePathParams(mediaCenterRoutes.mediaCenterDefault, [
          { key: 'pageNo', value: e },
        ], props),
      );
    }
  };

  const dragDrop = () => {
    setUploaderWindow(false);
  };

  const dragEnter = (data) => {
    const { isTrusted } = data;
    setUploaderWindow(isTrusted);
  };

  const dragOver = (data) => {
    const { isTrusted } = data;
    setUploaderWindow(isTrusted);
  };

  const onSuccess = (data) => {
    displaySuccess(JSON.parse(data.xhr.response).messages);
    if (setUploadedFileData !== undefined) {
      setUploadedFileData(JSON.parse(data.xhr.response).data);
    }
    const mainDiv = document.getElementsByClassName('filepicker dropzone')[0];
    setTimeout(() => {
      mainDiv.removeChild(data.previewElement);
    }, 10000);
    refetch();
  };

  const onError = async (data) => {
    const mainDiv = document.getElementsByClassName('filepicker dropzone')[0];
    if (data.xhr === undefined) {
      const { previewTemplate: { textContent } } = data;
      displayError(textContent);
      setTimeout(() => {
        mainDiv.removeChild(data.previewElement);
      }, 3000);
    } else {
      const { xhr: { response } } = data;
      const fileName = data.upload.filename;
      if (response.includes('File already exists')) {
        const dialog = await confirm({
          title: (<strong>Confirm!</strong>),
          message: `File: ${fileName} already exists, do you want to overwrite the file?`,
          confirmText: 'Yes',
          confirmColor: 'success',
          cancelColor: 'btn btn-danger',
          cancelText: 'No',
          object: true,
        });
        const result = await dialog.open();
        if (result) {
          setFileData(data);
          setRewriteValue(true);
        } else {
          mainDiv.removeChild(data.previewElement);
        }
      } else if (response.includes('Invalid file format')) {
        displayError('Can not upload this file, Invalid file format.');
        setTimeout(() => {
          mainDiv.removeChild(data.previewElement);
        }, 3000);
      }
    }
  };

  const getMediaCenterBreadCrumb = () => {
    const crumbs = [
      {
        name: 'Dashboard',
        url: replacePathParams(settingRoutes.dashboard, [], props),
      },
    ];

    if (folderId) {
      crumbs.push({
        name: 'Media Centre',
        url: replacePathParams(mediaCenterRoutes.mediaCenterDefault, [
          { key: 'pageNo', value: '1' },
        ], props),
      });

      parentFolderBreadcrumbs(parentFolders).forEach((folderCrumb) => {
        crumbs.push(folderCrumb);
      });

      crumbs.push({
        name: folderName,
        url: '',
      });
    } else {
      crumbs.push({ name: 'Media Centre', url: '' });
    }

    return crumbs;
  };

  const parentFolderBreadcrumbs = (parentFoldersData) => {
    return parentFoldersData.map((folder) => (
      {
        name: folder.folder_name,
        url: replacePathParams(
          mediaCenterRoutes.mediaCenterFolder,
          [
            { key: 'folderId', value: folder.id },
            { key: 'pageNo', value: '1' },
          ], props,
        ),
      }
    ));
  };

  const changePageSize = (size) => {
    setPageSize(size);
  };

  const dropzoneComponentConfig = {
    postUrl: `${baseURL}/instances/${instanceId}/files/?file_folder_id=${folderId}&rewrite=${rewriteValue}&context=media`,
  };

  const eventHandlers = {
    init: (d) => setDropzone(d),
    drop: dragDrop,
    dragenter: dragEnter,
    dragover: dragOver,
    success: onSuccess,
    error: onError,
  };

  const djsConfig = {
    dictFileTooBig: 'File is too big, max filesize: 1GB.',
    maxFilesize: 1000,
    thumbnailHeight: 65,
    maxThumbnailFilesize: 11111,
    timeout: 300000,
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className="dz-preview dz-file-preview mb-3">
        <div className="d-flex flex-row ">
          <div className="p-0 w-30 position-relative">
            <div className="preview-container">
              <img data-dz-thumbnail className="img-thumbnail border-0" alt="" />
              <i className="fal fa-file preview-icon" />
            </div>
          </div>
          <div className="ps-3 pt-2 pe-2 pb-1 w-70 dz-details position-relative">
            <div>
              {' '}
              <span data-dz-name />
              {' '}
            </div>
            <div className="text-primary text-extra-small" data-dz-size />
            <div
              className="progress progress-striped active"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="0"
            >
              <div className="progress-bar progress-bar-success dz-upload" data-dz-uploadprogress />
            </div>
            <div className="dz-error-message"><span data-dz-errormessage /></div>
          </div>
        </div>
      </div>,
    ),
    headers: { 'My-Awesome-Header': 'header value' },
  };

  return (
    galleryType
      ? (
          <DropzoneComponent
            config={dropzoneComponentConfig}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}
          >
            <div id="dropzone" style={uploaderWindow ? { width, height } : {}} />
          </DropzoneComponent>
        )
      : (
          <>
            <HeaderComponent setPath={{
              headingName: 'Media Centre',
              addNewBtnName: '',
              addNewPath: '',
              backBtnName: 'Dashboard',
              backToPath: settingRoutes.dashboard,
              staticBreadcrumbData: getMediaCenterBreadCrumb(),
            }}
            />
            <Row className="media-upload">
              <DropzoneComponent
                config={dropzoneComponentConfig}
                eventHandlers={eventHandlers}
                djsConfig={djsConfig}
              >
                <div id="dropzone" style={uploaderWindow ? { width, height } : {}} />
              </DropzoneComponent>
            </Row>

            <hr />

            {pagination !== null
              ? (
                  <>
                    <Row>
                      <Col className="search-sm d-inline-block float-md-left me-1 mb-1 align-top">
                        <Input
                          placeholder="Search by Name"
                          name="search"
                          type="text"
                          id="search"
                          value={filterValue}
                          onChange={onChangeSearchValue}
                        />
                      </Col>
                      <Col className="text-end">
                        <Button
                          color="info"
                          onClick={() => handleAddFolder()}
                          className="top-right-button me-1 btn btn-primary custom-simple-icon"
                        >
                          <i className="fal fa-folder-plus fa-lg" />
                          <b className="ms-1">  Add folder</b>
                        </Button>
                      </Col>
                    </Row>
                    <Pagination
                      type={['record', 'records']}
                      sortingField={orderBy}
                      sortingDirection={orderDirection}
                      currentPage={Number(pageNo)}
                      records={mediaData}
                      onChangePage={onHandlePageChange}
                      getPagination={pagination}
                      pageSize={pageSize}
                      changePageSize={changePageSize}
                    />
                  </>
                )
              : ''}

            <Listing
              getSortMediaData={getSortMediaData}
              data={mediaData}
              handleDelete={onHandleDeleteMedia}
              onEditMediaItem={onEditMediaItem}
            />
            <Modal isOpen={modal} toggle={toggle} autoFocus={false}>
              <ModalHeader toggle={toggle}>
                {isFolder
                  ? (
                      <b>{isEditAction ? 'Edit Folder' : 'New Folder'}</b>
                    )
                  : (
                      <b>Edit File</b>
                    )}
              </ModalHeader>
              <ModalBody>
                <b>Please enter a name:</b>
                <Input
                  autoFocus
                  type="text"
                  value={newFolderName || ''}
                  onChange={onHandleFolderNameChange}
                  onKeyDown={onHandleEnter}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={toggle}><b>Cancel</b></Button>
                {
                  isEditAction
                    ? <Button color="success" onClick={onHandleRename}><b>Update</b></Button>
                    : <Button color="success" onClick={onHandleCreateFolder}><b>Create</b></Button>
                }
              </ModalFooter>
            </Modal>
          </>
        )
  );
};

export default withRouter(Upload);
