import React, { useEffect, useState, useRef } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormGroup,
  Label,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import SaveAndContinue from '../../../../components/common/saveAndContinue';
import withRouter from '../../../../helpers/withRouter';
import { useSectionUpdateMutation } from '../../../../../../common/api/apiSlice';

function SectionNotes(props) {
  const {
    section,
    onClose,
    params: { instanceId },
  } = props;

  const ref = useRef(null);

  const [notes, setNotes] = useState(section.notes);

  const [sectionUpdate] = useSectionUpdateMutation();

  const save = async () => {
    let sendParams = ({ ...section, instance_id: instanceId, notes });
    delete sendParams.break_point;
    await sectionUpdate(sendParams);
  };

  const autoHeightTextArea = (e) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  useEffect(() => {
    setTimeout(() => {
      autoHeightTextArea({ target: ref.current });
    }, 1000);
  }, []);

  return (
    <Modal
      className="section-notes-modal"
      isOpen={true}
      size="lg"
      autoFocus={false}
    >
      <ModalBody>
        <FormGroup>
          <Label>Section Notes</Label>
          <Input
            innerRef={ref}
            type="textarea"
            spellCheck="false"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            onKeyDown={autoHeightTextArea}
            autoFocus={true}
          />
        </FormGroup>

        <FormGroup>
          <Label>Section Macro</Label>
          <InputGroup>
            <Input
              value={`(Section:${section.origin_id})`}
              readOnly={true}
            />
            <InputGroupText
              onClick={() => {
                navigator.clipboard.writeText(`(Section:${section.origin_id})`);
              }}
            >
              <i className="fal fa-clipboard-list" />
            </InputGroupText>
          </InputGroup>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          color="danger"
          onClick={onClose}
          className="fw-bold"
        >
          CANCEL
        </Button>
        <SaveAndContinue
          onSave={save}
          onContinue={onClose}
        />
      </ModalFooter>
    </Modal>
  );
}

export default withRouter(SectionNotes);
