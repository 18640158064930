import React, { useState } from 'react';
import CustomSelect from '../../../components/common/custom-select';
import HeaderComponent from '../../../components/common/header-component';
import {
  replacePathParams, settingRoutes, mainRoutes, userRoutes,
} from '../../../constants/routes';
import {
  Card, CardBody, Row, Col,
} from 'reactstrap';
import { useGetUsersQuery, useDeleteUserMutation } from '../../../../../common/api/apiSlice';
import { isEmpty, map } from 'lodash-es';
import CustomButton from '../../../components/common/button';
import confirm from '../../../components/common/confirm';
import { displayError, getSelectedOption } from '../../../../../Utils';
import UserFeaturePage from 'Dashboard/src/components/common/user-feature-page';
import { useParams } from 'react-router';
import withRouter from '../../../helpers/withRouter';

const getOptions = (data) => {
  if (!isEmpty(data)) {
    return map(data, (item) => ({
      label: `${item.full_name} (${item.user_name} / ${item.email})`,
      value: item.id,
    }));
  }
  return [];
};

function UserList(props) {
  const params = useParams();
  const [userId, setUserId] = useState(null);
  const { instanceId } = params;
  const { navigate } = props;
  const { data: users } = useGetUsersQuery();
  const [deleteUser] = useDeleteUserMutation();

  const commonPathParams = (path) => replacePathParams(path, [], props);

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: commonPathParams(settingRoutes.dashboard) },
    { name: 'Settings', url: commonPathParams(mainRoutes.setting) },
    { name: 'Users', url: '' },
  ];

  const onHandleEdit = (id) => {
    if (isEmpty(id)) {
      displayError('Please select a user.');
    } else {
      navigate(replacePathParams(userRoutes.userProfile, [
        { key: 'userId', value: id },
      ], props));
    }
  };

  const onHandleAddUser = () => {
    navigate(replacePathParams(userRoutes.userCreate, [], props));
  };

  const onHandleDeleteUser = async (id) => {
    if (isEmpty(id)) {
      displayError('Please select a user.');
    } else {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });
      if (result) {
        deleteUser({ instance_id: instanceId, id });
        setUserId(null);
      }
    }
  };

  if (!users) {
    return <></>;
  }

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Users',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: commonPathParams(settingRoutes.dashboard),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card>
        <CardBody>
          <UserFeaturePage feature="view_all_users">
            <Row className="d-flex align-items-center">
              <Col>
                <CustomSelect
                  value={getSelectedOption(getOptions(users), userId)}
                  isSearchable
                  options={getOptions(users)}
                  onChange={(e) => setUserId(e.value)}
                />
              </Col>
              <Col>
                <CustomButton
                  size="xs"
                  color="info"
                  icon="fal fa-user-edit"
                  title="Edit User"
                  className="custom-simple-icon"
                  onClick={() => onHandleEdit(userId)}
                />
                {' '}
                <CustomButton
                  size="xs"
                  color="danger"
                  icon="fal fa-trash-alt"
                  title="Delete User"
                  className="custom-simple-icon"
                  onClick={() => onHandleDeleteUser(userId)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <CustomButton
                  size="xs"
                  color="info"
                  icon="fal fa-user-plus"
                  title="Add User"
                  className="custom-simple-icon"
                  onClick={() => onHandleAddUser()}
                />
              </Col>
            </Row>
          </UserFeaturePage>
        </CardBody>
      </Card>
    </>
  );
}
export default withRouter(UserList);
