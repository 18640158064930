import { Paragraph as TiptapParagraph } from '@tiptap/extension-paragraph';
import { mergeAttributes } from '@tiptap/core';

export const Paragraph = TiptapParagraph.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      customStyle: {
        default: null,
        parseHTML: (el) => (el as HTMLSpanElement).getAttribute('data-custom-style'),
        renderHTML: (attrs) => ({ 'data-custom-style': attrs.customStyle }),
      },
    };
  },

  parseHTML() {
    return [{ tag: 'div' }];
  },

  renderHTML(opts) {
    const { HTMLAttributes } = opts;
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});

export default Paragraph;
