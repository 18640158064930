import React, { useState } from 'react';
import {
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Container,
  Row,
  Col,
  Badge,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { displaySuccess } from '../../../../../Utils';
import { get } from 'lodash-es';
import Domain from 'common/models/Domain';
import Page from 'common/models/Domain';
import CustomButton from '../../../components/common/button';
import confirm from '../../../components/common/confirm';
import PageSelectBox from '../../../components/fields/page/PageSelectBox';
import {
  useGetPagesQuery,
  useGetOrphanPagesQuery,
  useDomainEnableEmailMutation,
  useDomainEligibilityCheckQuery,
  useIssueDomainCertificateMutation,
  useDeleteDomainMutation,
  useUpdateDomainMutation,
  useMakeDomainPrimaryMutation,
} from 'common/api/apiSlice';
import { useInstance, useSite } from 'common/hooks';

function DomainListingDomain({ domain }: { domain: Domain }) {
  if (!domain?.id) return null;

  const instance = useInstance();
  const site = useSite();

  const { data: pages }: { data: Page[] } = useGetPagesQuery({ instanceId: instance?.id, siteId: site?.id }, { skip: !instance || !site });
  const { data: orphanPages }: { data: Page[] } = useGetOrphanPagesQuery({ instanceId: instance?.id, siteId: site?.id }, { skip: !instance || !site });
  const { data: checkData } = useDomainEligibilityCheckQuery(domain);
  const [domainEnableEmail] = useDomainEnableEmailMutation();
  const [issueDomainCertificate] = useIssueDomainCertificateMutation();
  const [deleteDomain] = useDeleteDomainMutation();
  const [updateDomain] = useUpdateDomainMutation();
  const [makeDomainPrimary] = useMakeDomainPrimaryMutation();
  const [modal, setModal] = useState<string | null>(null);

  const pagesById = {};

  const addPageDetails = (ps) => {
    ps.forEach(page => {
      pagesById[page.id] = page;

      if (page.children)
        addPageDetails(page.children);
    });
  };

  if (pages)
    addPageDetails(pages);

  if (orphanPages)
    addPageDetails(orphanPages);

  const onUpdate = async (payload) => {
    const result = await updateDomain(payload);
    if (result && result?.data?.meta?.is_success) {
      displaySuccess(result?.data?.meta?.messages);
    }
  };

  let newSPFRecord = 'v=spf1 a mx include:_spf.webgenius.co.nz ~all';

  if (checkData?.txt_records && checkData?.txt_records.length > 0) {
    const existingSPFRecord = checkData?.txt_records.find((record: string) => record.includes('v=spf1'));

    if (existingSPFRecord) {
      const parts = existingSPFRecord.split(' ');
      const allPart = parts.pop();
      parts.push('include:_spf.webgenius.co.nz');
      parts.push(allPart);
      newSPFRecord = parts.join(' ');
    }
  }

  return (
    <AccordionItem key={domain.id}>
      <AccordionHeader targetId={domain.id}>
        <Container fluid>
          <Row>
            <Col md="4">
              {domain.domain_name}
            </Col>
            <Col md="4" className="text-normal">
              {domain.serve_page_id && (
                <>
                  Serves Page:
                  {' '}
                  {pagesById[domain.serve_page_id]
                    ? (
                        <a href={pagesById[domain.serve_page_id].absolute_url} target="_blank" rel="noreferrer">
                          {pagesById[domain.serve_page_id].name}
                        </a>
                      )
                    : 'unknown'}
                </>
              )}
              {domain.redirect_page_id && (
                <>
                  Redirects To Page:
                  {' '}
                  {pagesById[domain.redirect_page_id]
                    ? (
                        <a href={pagesById[domain.redirect_page_id].absolute_url} target="_blank" rel="noreferrer">
                          {pagesById[domain.redirect_page_id].name}
                        </a>
                      )
                    : 'unknown'}
                </>
              )}
            </Col>
            <Col md="4" className="text-right">
              <div className="ms-auto">
                {domain.is_primary && (
                  <Badge pill color="primary" className="mb-1">
                    <i className="fal fa-star me-2" />
                    Primary
                  </Badge>
                )}
                {' '}
                {checkData && (
                  <>
                    {domain.isConfigurationCorrect(checkData)
                      ? (
                          <Badge pill color="success" className="mb-1">
                            <i className="fal fa-check me-2" />
                            Config OK
                          </Badge>
                        )
                      : (
                          <Badge pill color="danger" className="mb-1">
                            <i className="fal fa-times me-2" />
                            Config Error
                          </Badge>
                        )}
                  </>
                )}
                {' '}
                {domain.platform === 1 && (
                  <Badge pill color="warning" className="mb-1">
                    <i className="fal fa-tombstone me-2" />
                    Legacy Platform
                  </Badge>
                )}
                {' '}
                {domain.is_email && (
                  <Badge pill color="primary" className="mb-1">
                    <i className="fal fa-envelope me-2" />
                    Email
                  </Badge>
                )}
                {' '}
                {checkData?.ping_check && checkData?.is_cloudflare && (
                  <Badge pill color="" style={{ backgroundColor: '#f63' }} className="mb-1">
                    <i className="fab fa-cloudflare me-2" />
                    Cloudflare
                  </Badge>
                )}
                {' '}
                {checkData?.ping_check && !checkData?.is_cloudflare && domain.certificate_valid && (
                  <Badge pill color="success" className="mb-1">
                    <i className="fal fa-lock me-2" />
                    HTTPS
                  </Badge>
                )}
                {' '}
                {checkData?.ping_check && !checkData?.is_cloudflare && !domain.certificate_valid && (
                  <Badge pill color="danger" className="mb-1">
                    <i className="fal fa-lock me-2" />
                    No HTTPS
                  </Badge>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </AccordionHeader>
      <AccordionBody accordionId={domain.id}>
        <Container>
          <Row className="mb-3">
            <Col md="6">
              <Card className="h-100">
                <CardBody>
                  <CardTitle tag="h5">
                    Web Details
                  </CardTitle>
                  {domain.isRootDomain() && checkData?.a_records.length === 1 && checkData?.a_records[0] === '112.109.67.241' && (
                    <p>
                      <i className="fal fa-fw fa-check" style={{ width: '2.5em' }} />
                      {' '}
                      Root domain pointing at Web Genius IP address
                    </p>
                  )}
                  {domain.isRootDomain() && !checkData?.is_cloudflare && (checkData?.a_records.length !== 1 || get(checkData, 'a_records.0') !== '112.109.67.241') && (
                    <p>
                      <i className="fal fa-fw fa-xmark" style={{ width: '2.5em' }} />
                      {' '}
                      Root domain not pointing at Web Genius IP address
                    </p>
                  )}
                  {!domain.isRootDomain() && domain.isCNAMECorrect(checkData) && (
                    <p>
                      <i className="fal fa-fw fa-check" style={{ width: '2.5em' }} />
                      {' '}
                      Sub-domain pointing at CNAME
                    </p>
                  )}
                  {!domain.isRootDomain() && !checkData?.is_cloudflare && !domain.isCNAMECorrect(checkData) && (
                    <p>
                      <i className="fal fa-fw fa-xmark" style={{ width: '2.5em' }} />
                      {' '}
                      Sub-domain not pointing at CNAME
                    </p>
                  )}
                  {checkData?.ping_check && checkData?.is_cloudflare && (
                    <p>
                      <i className="fab fa-fw fa-cloudflare" style={{ width: '2.5em' }} />
                      {' '}
                      Cloudflare
                    </p>
                  )}
                  {checkData?.ping_check
                    ? (
                        <p>
                          <i className="fal fa-fw fa-server" style={{ width: '2.5em' }} />
                          {' '}
                          Web Genius Hosted
                        </p>
                      )
                    : (
                        <p>
                          <span className="fa-fw fa-stack fa-1x">
                            <i className="fal fa-server fa-stack-1x"></i>
                            <i className="fal fa-ban fa-stack-2x"></i>
                          </span>
                          {' '}
                          Website Externally Hosted
                        </p>
                      )}
                  {checkData?.ping_check && !checkData?.is_cloudflare && domain.certificate_valid && (
                    <p>
                      <i className="fal fa-fw fa-lock" style={{ width: '2.5em' }} />
                      {' '}
                      HTTPS Certificate Issued
                      {domain.certificate_expiry && (
                        <>
                          {' '}
                          (expires
                          {' '}
                          {new Date(domain.certificate_expiry).toLocaleDateString()}
                          )
                        </>
                      )}
                    </p>
                  )}
                  {checkData?.ping_check && !checkData?.is_cloudflare && !domain.certificate_valid && (
                    <p>
                      <i className="fal fa-fw fa-exclamation-triangle" style={{ width: '2.5em' }} />
                      {' '}
                      No HTTPS Certificate
                    </p>
                  )}
                  {!domain.is_primary && checkData?.ping_check && (
                    <>
                      {!domain.redirect_page_id && (
                        <FormGroup>
                          <Label>
                            Serve a page:
                          </Label>
                          <PageSelectBox
                            params={domain}
                            keyName="serve_page_id"
                            onChange={(e) => onUpdate({ ...domain, serve_page_id: e ? e.value : null })}
                          />
                        </FormGroup>
                      )}
                      {!domain.serve_page_id && (
                        <FormGroup>
                          <Label>
                            Redirect to a page:
                          </Label>
                          <PageSelectBox
                            params={domain}
                            keyName="redirect_page_id"
                            onChange={(e) => onUpdate({ ...domain, redirect_page_id: e ? e.value : null })}
                          />
                        </FormGroup>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="h-100">
                <CardBody className="email-details">
                  <CardTitle tag="h5">
                    Email Details
                  </CardTitle>
                  {domain.isDKIMCorrect(checkData) && (
                    <p>
                      <i className="fal fa-fw fa-check" style={{ width: '2.5em' }} />
                      {' '}
                      DKIM CNAME record set up correctly
                    </p>
                  )}
                  {!domain.isDKIMCorrect(checkData) && (
                    <p>
                      <i className="fal fa-fw fa-xmark" style={{ width: '2.5em' }} />
                      {' '}
                      <a href="#" onClick={() => setModal('dkim')}>
                        DKIM CNAME record not set up correctly
                      </a>
                    </p>
                  )}
                  {domain.isSPFCorrect(checkData) && (
                    <p>
                      <i className="fal fa-fw fa-check" style={{ width: '2.5em' }} />
                      {' '}
                      SPF TXT record set up correctly
                    </p>
                  )}
                  {!domain.isSPFCorrect(checkData) && (
                    <p>
                      <i className="fal fa-fw fa-xmark" style={{ width: '2.5em' }} />
                      {' '}
                      <a href="#" onClick={() => setModal('spf')}>
                        SPF TXT record not set up correctly
                      </a>
                    </p>
                  )}
                  {domain.isDMARCCorrect(checkData) && (
                    <p>
                      <i className="fal fa-fw fa-check" style={{ width: '2.5em' }} />
                      {' '}
                      DMARC TXT record set up correctly
                    </p>
                  )}
                  {!domain.isDMARCCorrect(checkData) && (
                    <p>
                      <i className="fal fa-fw fa-xmark" style={{ width: '2.5em' }} />
                      {' '}
                      <a href="#" onClick={() => setModal('dmarc')}>
                        DMARC TXT record not set up correctly
                      </a>
                    </p>
                  )}
                  {!domain.is_email && domain.canUseForEmail(checkData) && (
                    <>
                      <CustomButton
                        size="xs"
                        title="Use Domain For Email"
                        color="primary"
                        icon="fal fa-edit"
                        className="custom-simple-icon"
                        onClick={async () => {
                          const result = await confirm({
                            title: <b>Confirm!</b>,
                            message: 'Are you sure you want to use this domain for sending email?',
                            confirmText: 'Yes',
                            confirmColor: 'success',
                            cancelColor: 'btn btn-danger',
                            cancelText: 'No',
                          });

                          if (result)
                            domainEnableEmail(domain);
                        }}
                      />
                    </>
                  )}
                  {!domain.canUseForEmail(checkData) && (
                    <>
                      Domain is not eligible to be used for email.
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="h-100">
                <CardBody className="domain-action-buttons">
                  {checkData?.ping_check && !domain.is_primary && !domain.isRootDomain() && (
                    <CustomButton
                      size="xs"
                      title="Set as Primary Domain"
                      color="primary"
                      icon="fal fa-star"
                      className="custom-simple-icon"
                      onClick={async () => {
                        const result = await confirm({
                          title: <b>Confirm!</b>,
                          message: 'Are you sure you want to set this domain as the primary domain?',
                          confirmText: 'Yes',
                          confirmColor: 'success',
                          cancelColor: 'btn btn-danger',
                          cancelText: 'No',
                        });

                        if (result)
                          makeDomainPrimary(domain);
                      }}
                    />
                  )}
                  {' '}
                  {checkData?.ping_check && !domain.certificate_valid && (
                    <CustomButton
                      size="xs"
                      title="Enable HTTPS"
                      color="primary"
                      icon="fal fa-lock"
                      className="custom-simple-icon"
                      onClick={async () => {
                        issueDomainCertificate(domain);
                      }}
                    />
                  )}
                  {' '}
                  {checkData?.ping_check && domain.platform === 1 && (
                    <CustomButton
                      size="xs"
                      title="Change to New Platform"
                      color="primary"
                      icon="fal fa-star"
                      className="custom-simple-icon"
                      onClick={() => {
                        updateDomain({ ...domain, platform: 2 });
                      }}
                    />
                  )}
                  {' '}
                  {checkData?.ping_check && domain.platform === 2 && (
                    <CustomButton
                      size="xs"
                      title="Change to Old Platform"
                      color="primary"
                      icon="fal fa-tombstone"
                      className="custom-simple-icon"
                      onClick={() => {
                        updateDomain({ ...domain, platform: 1 });
                      }}
                    />
                  )}
                  {' '}
                  {!domain.is_primary && (
                    <CustomButton
                      size="xs"
                      title="Delete Domain"
                      color="danger"
                      icon="fal fa-trash"
                      className="custom-simple-icon"
                      style={{ background: '#dc3545' }}
                      onClick={async () => {
                        const result = await confirm({
                          title: <b>Confirm!</b>,
                          message: 'Are you sure you want to delete this domain?',
                          confirmText: 'Yes',
                          confirmColor: 'success',
                          cancelColor: 'btn btn-danger',
                          cancelText: 'No',
                        });

                        if (result)
                          deleteDomain(domain);
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </AccordionBody>

      {modal && (
        <Modal isOpen={true} toggle={() => setModal(null)} size="lg">
          <ModalHeader toggle={() => setModal(null)}>DNS Details</ModalHeader>
          <ModalBody>
            {modal === 'spf' && (
              <>
                <p>
                  Add the following record to your DNS settings:
                </p>
                <p>
                  <strong>Record Type:</strong>
                  {' '}
                  <pre>
                    TXT
                  </pre>
                </p>
                <p>
                  <strong>Host:</strong>
                  {' '}
                  <pre>
                    {domain.domain_name}
                  </pre>
                </p>
                <p>
                  <strong>Data:</strong>
                  {' '}
                  <pre>
                    {newSPFRecord}
                  </pre>
                </p>
              </>
            )}
            {modal === 'dkim' && (
              <>
                <p>
                  Add the following record to your DNS settings:
                </p>
                <p>
                  <strong>Record Type:</strong>
                  {' '}
                  <pre>
                    CNAME
                  </pre>
                </p>
                <p>
                  <strong>Host:</strong>
                  {' '}
                  <pre>
                    wg._domainkey.
                    {domain.domain_name}
                  </pre>
                </p>
                <p>
                  <strong>Points to:</strong>
                  {' '}
                  <pre>
                    client._domainkey.webgenius.co.nz
                  </pre>
                </p>
              </>
            )}
            {modal === 'dmarc' && (
              <>
                <p>
                  Add the following record to your DNS settings:
                </p>
                <p>
                  <strong>Record Type:</strong>
                  {' '}
                  <pre>
                    TXT
                  </pre>
                </p>
                <p>
                  <strong>Host:</strong>
                  {' '}
                  <pre>
                    _dmarc.
                    {domain.domain_name}
                  </pre>
                </p>
                <p>
                  <strong>Data:</strong>
                  {' '}
                  <pre>
                    v=DMARC1; p=none; rua=mailto:mail@webgenius.co.nz; ruf=mailto:mail@webgenius.co.nz; fo=1
                  </pre>
                </p>
              </>
            )}
          </ModalBody>
        </Modal>
      )}
    </AccordionItem>
  );
}

export default DomainListingDomain;
