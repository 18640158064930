import { useGetMasterPagesQuery } from '../../../../../common/api/apiSlice';
import CustomSelect from '../../../components/common/custom-select';
import { getSelectedOption } from '../../../../../Utils';
import React from 'react';
import { FormGroup } from 'reactstrap';
import { isEmpty } from 'lodash-es';

const selectOptionArr = (options) => options.map((opt) => ({ label: opt.name, value: opt.id }));

function MasterPageSelectBox(props) {
  const { params, setParams, keyName, siteId, instanceId } = props;
  const { data } = useGetMasterPagesQuery({ siteId, instanceId }, { skip: isEmpty(siteId) || isEmpty(instanceId) });
  const masterPages = data || [];

  return (
    <FormGroup>
      <CustomSelect
        isClearable
        options={selectOptionArr(masterPages)}
        isSearch={false}
        className="flex-grow-1"
        name={keyName}
        value={getSelectedOption(selectOptionArr(masterPages), params[keyName])}
        onChange={(e) => {
          setParams({ ...params, [keyName]: e ? e.value : e });
        }}
      />
    </FormGroup>
  );
}

export default MasterPageSelectBox;
