import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  CardTitle,
  CardBody,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import zxcvbn from 'zxcvbn';
import { isEmpty, get, map, omit, filter } from 'lodash-es';
import { useGetRolesQuery, useGetUserQuery, useCreateUserMutation, useUpdateUserMutation, useGetInstancesQuery } from '../../../../../common/api/apiSlice';
import { displayError, validateEmail, setPageTitle, displaySuccess } from '../../../../../Utils';
// import './index.scss';
import CustomButton from '../../../components/common/button';
import HeaderComponent from '../../../components/common/header-component';
import { mainRoutes, userRoutes, settingRoutes, replacePathParams } from '../../../constants/routes';
import CustomSelect from '../../../components/common/custom-select';
import { getInstanceOptions, MenuList } from '../../../containers/navs/InstanceSelection';
import CheckBox from '../../../components/common/checkBox';
import SaveAndContinue from '../../../components/common/saveAndContinue';
import BottomActionToolbar from '../../../components/common/toolbar';
import { TimeZone } from '../../../components/fields/site';
import { useParams } from 'react-router';
import withRouter from '../../../helpers/withRouter';
import SetGmailSignatureModal from './SetGmailSignatureModal';
import { useUser } from '../../../../../common/hooks';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

export const createPasswordLabel = (result) => {
  switch (result.score) {
    case 0:
      return 'Weak';
    case 1:
      return 'Weak';
    case 2:
      return 'Fair';
    case 3:
      return 'Good';
    case 4:
      return 'Strong';
    default:
      return 'Weak';
  }
};

export const validatePasswords = (data) => {
  const { password, password_confirmation, score } = data;
  if (!password || score < 3) {
    displayError('Please enter a strong and valid password.');
    return false;
  }
  if (password && password !== password_confirmation) {
    displayError('Password and confirm password does not match.');
    return false;
  }
  return true;
};

function UserProfile(props) {
  const params = useParams();
  const { navigate } = props;
  const { instanceId, userId } = params;
  const [profileData, setProfileData] = useState({});
  const [testedResult, setTestedResult] = useState({});
  const [selectedInstance, setInstance] = useState(null);
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);
  const { data: editingUser } = useGetUserQuery(userId, { skip: isEmpty(userId) });
  const [createUser] = useCreateUserMutation();
  const [updateUser, { isSuccess, meta }] = useUpdateUserMutation();
  const { data: instances = [] } = useGetInstancesQuery();
  const { data: roles } = useGetRolesQuery();

  const loggedInUser = useUser();

  const isEditingSelf = loggedInUser?.id === editingUser?.id;
  const selectedRole = roles?.find((role) => role.id === profileData.role_id);
  const availableRoles = [];

  switch (loggedInUser?.role?.name) {
    case 'Client':
      break;
    case 'Sales Adviser':
      break;
    case 'Implementer':
      break;
    case 'Finance':
      availableRoles.push('Client', 'Sales Adviser');
      break;
    case 'Project Manager':
      availableRoles.push('Client');
      break;
    case 'System Administrator':
      availableRoles.push('Client', 'Sales Adviser', 'Implementer', 'Finance', 'Project Manager');
      if (isEditingSelf) {
        availableRoles.push('System Administrator');
      }
      break;
  }

  useEffect(() => {
    if (isSuccess && meta.messages) {
      displaySuccess(meta.messages);
    }
  }, [isSuccess]);

  useEffect(() => {
    setPageTitle(`User Profile: ${editingUser?.full_name}`);
  }, [editingUser]);

  useEffect(() => {
    if (editingUser) {
      setProfileData(editingUser);
    }
  }, [editingUser]);

  // Set default role for new users to the Client role
  useEffect(() => {
    if (isEmpty(roles)) {
      return;
    }

    if (isEmpty(profileData?.role_id)) {
      const role = roles.find((r) => r.name === 'Client');
      if (role) {
        setProfileData({ ...profileData, role_id: role.id });
      }
    }
  }, [roles]);

  const handleSubmit = async () => {
    const {
      id,
      role_id,
      email,
      modules,
      full_name,
      time_zone,
      password,
      user_name,
      user_instances,
      password_confirmation,
    } = profileData;

    let sendParams = {
      id,
      role_id,
      email,
      modules,
      user_name,
      full_name,
      time_zone,
      instance_id: instanceId,
      instances_attributes: profileData?.role?.all_instances ? [] : user_instances,
    };
    if (!validateEmail(email)) {
      displayError('Enter a valid email address.');
    } else if (!selectedRole?.all_instances && isEmpty(user_instances)) {
      displayError('You must have at least one instance');
    } else if (userId) {
      updateUser(sendParams);
      return true;
    } else if (validatePasswords({ ...profileData, ...testedResult })) {
      sendParams.password = password;
      sendParams.password_confirmation = password_confirmation;
      sendParams = omit(sendParams, ['id']);
      const result = await createUser(sendParams);

      // We have just created a new user, navigate to the edit page so that subsequent saves don't try to create
      // another new record.
      navigate(replacePathParams(userRoutes.userProfile, [{ key: 'userId', value: result.data.data.id }], props));
    }
    return false;
  };

  const handleKeyDown = (evt, value) => {
    if (['Enter', 'Tab', ',', ' '].includes(evt.key)) {
      evt.preventDefault();
      const email = value.trim();
      if (!validateEmail(email)) {
        displayError('Enter a valid email address.');
      }
    }
  };

  const onHandleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === 'password') {
      const result = zxcvbn(value);
      setTestedResult(result);
    }
    setProfileData({ ...profileData, [name]: value });
  };

  const onChangeModules = (e) => {
    const { checked, value } = e.target;
    let tempModules;
    if (checked) {
      tempModules = [...profileData.modules, value];
    } else {
      tempModules = profileData.modules.filter((e) => e !== value);
    }
    setProfileData({ ...profileData, modules: tempModules });
  };

  const onHandleAddInstance = (e) => {
    e.preventDefault();
    if (isEmpty(selectedInstance)) {
      displayError('Please select instance to add');
    } else {
      const userInstances = [...(profileData.user_instances || [])];
      userInstances.push({ instance_id: selectedInstance.value });
      setProfileData({ ...profileData, user_instances: userInstances });
      setInstance(null);
    }
  };

  const onHandleDeleteInstance = (e, id) => {
    e.preventDefault();
    const tempState = { ...profileData };
    map(tempState.user_instances, (ele, index) => {
      if ('id' in ele && ele.instance_id === id) {
        tempState.user_instances[index] = { ...ele, _destroy: true };
      } else if (ele.instance_id === id) {
        const filteredArray = profileData.user_instances.filter((item) => item.instance_id !== id);
        tempState.user_instances = filteredArray;
      }
    });
    setProfileData(tempState);
  };

  const tableColumns = [
    {
      id: 'instanceName',
      header: 'Instance Name',
      accessorFn: (row) => {
        if (isEmpty(instances)) {
          return '';
        }
        const instance = instances.find((i) => i.id === row.instance_id);
        return instance ? instance.company_name : '';
      },
      cell: (info) => info.getValue(),
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <CustomButton
          size="xs"
          title=" "
          color="danger"
          icon="fal fa-trash"
          className="custom-simple-icon"
          style={{ background: '#dc3545' }}
          onClick={(e) => onHandleDeleteInstance(e, row.original.instance_id)}
        />
      ),
    },
  ];

  const onHandleChangePassword = (e) => {
    e.preventDefault();
    const {
      id,
      role_id,
      password,
      password_confirmation,
    } = profileData;

    const sendParams = {
      id,
      role_id, // Need to send the role ID because it's required in the user model
      password,
      password_confirmation,
      instance_id: instanceId,
    };

    if (validatePasswords({ ...profileData, ...testedResult })) {
      updateUser(sendParams);
    }
  };

  const modules = [
    { value: 'site', label: 'Site Centre' },
    { value: 'stats', label: 'Stats Centre' },
    { value: 'data', label: 'Data Centre' },
    { value: 'media', label: 'Media Centre' },
    { value: 'message', label: 'Message Centre' },
    { value: 'delivery', label: 'Delivery Centre' },
    { value: 'review', label: 'Review Centre' },
  ];

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    !isEditingSelf && { name: 'Settings', url: replacePathParams(mainRoutes.setting, [], props) },
    !isEditingSelf && { name: 'Users', url: replacePathParams(userRoutes.usersList, [], props) },
    { name: 'User Profile', url: '' },
  ];

  const password = get(profileData, 'password', '');
  const passwordConfirmation = get(profileData, 'password_confirmation', '');
  const email = get(profileData, 'email', '');

  // We are using the create_new_users feature to determine whether the user can edit users other than themselves.
  if (!isEditingSelf && !loggedInUser?.hasFeature('create_new_users')) {
    return <></>;
  }

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'User Profile',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: '',
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Form autoComplete="off">
        <Row>
          <Col lg={6} className="mb-4">
            <Card className="h-100">
              <CardBody>
                <CardTitle>General</CardTitle>
                <Row>
                  <Col lg={12} md={12}>
                    <FormGroup>
                      <Label>Full Name</Label>
                      <InputGroup>
                        <InputGroupText><i className="fal fa-user" aria-hidden="true" /></InputGroupText>
                        <Input
                          type="text"
                          name="full_name"
                          value={get(profileData, 'full_name', '')}
                          onChange={(e) => onHandleChange(e)}
                          data-lpignore={true}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg={12} md={12}>
                    <FormGroup>
                      <Label>User Name</Label>
                      <InputGroup>
                        <InputGroupText><i className="fal fa-user" aria-hidden="true" /></InputGroupText>
                        <Input
                          type="text"
                          name="user_name"
                          value={get(profileData, 'user_name', '')}
                          onChange={(e) => onHandleChange(e)}
                          data-lpignore={true}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg={12} md={12}>
                    <FormGroup>
                      <Label>Email</Label>
                      <InputGroup>
                        <InputGroupText><i className="fal fa-envelope" aria-hidden="true" /></InputGroupText>
                        <Input
                          required
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => onHandleChange(e)}
                          onKeyDown={(e) => handleKeyDown(e, email)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  {availableRoles.length > 0 && (
                    <Col lg={12} md={12}>
                      <FormGroup>
                        <Label>Role</Label>
                        <InputGroup>
                          <CustomSelect
                            components={{ MenuList }}
                            isSearchable
                            className="flex-grow-1"
                            options={
                              availableRoles?.map((availableRole) => ({
                                label: availableRole,
                                value: roles?.find((role) => role.name === availableRole)?.id,
                              }))
                            }
                            value={roles?.filter((r) => r.id === profileData.role_id).map((r) => ({ label: r.name, value: r.id }))[0]}
                            onChange={(e) => setProfileData({ ...profileData, role_id: e.value })}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  )}
                  <Col lg={12} md={12}>
                    <TimeZone params={profileData} setParams={setProfileData} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {/* {loggedInUser?.hasFeature('create_new_users') && ( */}
          <Col lg={6} className="mb-4">
            <Card className="h-100">
              <CardBody>
                <CardTitle>Instances</CardTitle>
                {/* {selectedRole && selectedRole.all_instances
                    ? (
                        <>
                          Currently selected role has access to all instances.
                        </>
                      )
                    : ( */}
                <Row>
                  <Col lg={12} md={12}>
                    <FormGroup>
                      <Label>Instance</Label>
                      <InputGroup>
                        <InputGroupText><i className="fal fa-atlas" aria-hidden="true" /></InputGroupText>
                        <CustomSelect
                          components={{ MenuList }}
                          isSearchable
                          className="flex-grow-1 me-2"
                          options={getInstanceOptions(instances)}
                          value={selectedInstance}
                          onChange={(e) => setInstance(e)}
                        />
                        <CustomButton
                          size="sm"
                          color="success"
                          className="common-success-button button-sm fw-bold"
                          title="Add"
                          onClick={(e) => onHandleAddInstance(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                    {!isEmpty(profileData.user_instances)
                    && (
                      <ReactTable8
                        columns={tableColumns}
                        data={filter(profileData.user_instances, (f) => !f._destroy)}
                      />
                    )}
                  </Col>
                </Row>
                {/* )} */}
              </CardBody>
            </Card>
          </Col>
          {/* )} */}
          <Col lg={6} className="ChangePassword mb-4">
            <Card className="h-100">
              <CardBody>
                <CardTitle>Password</CardTitle>
                <Row>
                  <Col lg={12} md={12}>
                    <FormGroup>
                      <Label>New Password</Label>
                      <InputGroup>
                        <InputGroupText><i className="fal fa-key" aria-hidden="true" /></InputGroupText>
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          value={password}
                          onChange={(e) => onHandleChange(e)}
                          autoComplete="new-password"
                        />
                      </InputGroup>
                      {password && (
                        <div className="password-strength-meter">
                          <progress
                            className={
                              `password-strength-meter-progress strength-${createPasswordLabel(testedResult)}`
                            }
                            value={testedResult.score || 1}
                            max="4"
                          />
                          <span
                            className={`password-strength-meter-label strength-${createPasswordLabel(testedResult)}`}
                          >
                            {password && (
                              <>
                                {createPasswordLabel(testedResult)}
                              </>
                            )}
                          </span>
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg={12} md={12}>
                    <FormGroup>
                      <Label>Confirm New Password</Label>
                      <InputGroup>
                        <InputGroupText><i className="fal fa-key" aria-hidden="true" /></InputGroupText>
                        <Input
                          type="password"
                          name="password_confirmation"
                          value={passwordConfirmation}
                          onChange={(e) => onHandleChange(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  {userId
                  && (
                    <Col lg={12} md={12} className="text-center">
                      <CustomButton
                        size="sm"
                        color="success"
                        className="common-success-button button-md fw-bold"
                        title="Change Password"
                        onClick={(e) => onHandleChangePassword(e)}
                      />
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
          {loggedInUser?.hasFeature('create_new_users') && (
            <>
              <Col lg={6} className="mb-4">
                <Card className="h-100">
                  <CardBody>
                    <CardTitle>Modules</CardTitle>
                    <Row>
                      <Col>
                        {map(modules, (ele) => (
                          <FormGroup key={ele.value}>
                            <CheckBox
                              name={ele.value}
                              title={ele.label}
                              value={ele.value}
                              checked={profileData?.modules?.includes(ele.value)}
                              onChange={(e) => onChangeModules(e)}
                            />
                          </FormGroup>
                        ))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </>
          )}
        </Row>
      </Form>
      <BottomActionToolbar
        component={(
          <>
            {loggedInUser?.hasFeature('create_login_qr_codes')
            && (
              <CustomButton
                title="LOGIN QR CODE"
                color="success"
                className="common-success-button button-md fw-bold me-2"
                onClick={() => {
                  window.open(`/api/v1/qrcode/image?user_id=${userId}&exp=1209600&preapproved=1`); // 14 days
                }}
              />
            )}

            {loggedInUser?.hasFeature('add_gmail_signature')
            && profileData && profileData.id && profileData.email.match(/@webgenius\.co\.nz$/) && (
              <>
                <CustomButton
                  title="SET GMAIL SIGNATURE"
                  style={{ width: 'auto', padding: '0 20px' }}
                  color="success"
                  className="common-success-button button-md fw-bold me-2"
                  onClick={() => setSignatureModalOpen(true)}
                />
                <SetGmailSignatureModal
                  user={profileData}
                  isOpen={signatureModalOpen}
                  toggle={() => setSignatureModalOpen(!signatureModalOpen)}
                />
              </>
            )}

            <SaveAndContinue
              onSave={handleSubmit}
              onContinue={replacePathParams(userRoutes.usersList, [], props)}
            />
          </>
        )}
      />
    </>
  );
}

export default withRouter(UserProfile);
