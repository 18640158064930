import { getCookie } from '../../../Utils';
import { get } from 'lodash-es';
import { PathParam, PathProps, RouteMap } from '../../../ts/interfaces';

// solution for edit and pagination number routes
export const replacePathParams = (
  path: string,
  pathParams: PathParam[] = [],
  props: PathProps = {},
) => {
  let pathToGo = path;

  if (pathParams && pathParams.length > 0) {
    pathParams.forEach((url) => {
      pathToGo = pathToGo.replace(`:${url.key}`, encodeURIComponent(String(url.value)));
    });
  }

  // Use params or cookie if the instance or site ID is required.

  if (pathToGo.includes(':instanceId')) {
    const instanceId = get(props, 'instanceId') || getCookie('INSTANCE_ID');
    if (instanceId) {
      pathToGo = pathToGo.replace(':instanceId', instanceId);
    }
  }

  if (pathToGo.includes(':siteId')) {
    const siteId = get(props, 'siteId') || getCookie('SITE_ID');
    if (siteId) {
      pathToGo = pathToGo.replace(':siteId', siteId);
    }
  }

  return pathToGo;
};

// Data center pages
export const dataCenter: RouteMap = {
  addMember: '/data-centre/instance/:instanceId/site/:siteId/member',
  editMember: '/data-centre/instance/:instanceId/site/:siteId/member/:memberId',
  allMembers: '/data-centre/instance/:instanceId/site/:siteId/members/page/:pageNo',
  showMemberSearch: '/data-centre/instance/:instanceId/site/:siteId/member/search/:searchId/page/:pageNo',
  memberSearchById: '/data-centre/instance/:instanceId/site/:siteId/member/search/:searchId',
  memberSearch: '/data-centre/instance/:instanceId/site/:siteId/member/search',
  savedSearch: '/data-centre/instance/:instanceId/site/:siteId/saved-searches',
  editDatabaseField: '/data-centre/instance/:instanceId/site/:siteId/database-fields/:id',
  databaseFields: '/data-centre/instance/:instanceId/site/:siteId/database-fields',
  importMembers: '/data-centre/instance/:instanceId/site/:siteId/import-members',
};

export const mediaCenterRoutes: RouteMap = {
  mediaCenterFolder: '/media-centre/instance/:instanceId/site/:siteId/folder/:folderId/page/:pageNo',
  mediaCenterDefault: '/media-centre/instance/:instanceId/site/:siteId/page/:pageNo',
  mediaCenter: '/media-centre',
};

// setting pages
export const settingRoutes: RouteMap = {
  siteList: '/settings/instance/:instanceId/site/:siteId/sites',
  siteSettingsGeneral: '/settings/instance/:instanceId/site/:siteId/general',
  siteSettingsProducts: '/settings/instance/:instanceId/site/:siteId/products',
  siteSettingsPhoneNumbers: '/settings/instance/:instanceId/site/:siteId/phone-numbers',
  siteSettingsSEO: '/settings/instance/:instanceId/site/:siteId/seo',
  siteSettingsGoogleAnalytics: '/settings/instance/:instanceId/site/:siteId/google-analytics',
  siteSettingsOtherGoogleServices: '/settings/instance/:instanceId/site/:siteId/other-google-services',
  siteSettingsGoogleReviewEngine: '/settings/instance/:instanceId/site/:siteId/google-review-engine',
  siteSettingsReCAPTCHA: '/settings/instance/:instanceId/site/:siteId/recaptcha',
  siteSettingsInstantContact: '/settings/instance/:instanceId/site/:siteId/instant-contact',
  siteSettingsPrivacyPolicy: '/settings/instance/:instanceId/site/:siteId/privacy-policy',
  siteSettingsPopup: '/settings/instance/:instanceId/site/:siteId/popup',
  siteSettingsSiteTextSearch: '/settings/instance/:instanceId/site/:siteId/site-text-search',
  siteProfile: '/settings/instance/:instanceId/site/:siteId/site-profile',
  siteBriefings: '/settings/instance/:instanceId/site/:siteId/briefings/page/:pageNo',
  siteBriefingForm: '/settings/instance/:instanceId/site/:siteId/briefing-form/:id',
  instanceSettings: '/settings/instance/:instanceId/site/:siteId/instance-settings',
  trash: '/settings/instance/:instanceId/site/:siteId/trash/page/:pageNo',
  logs: '/settings/instance/:instanceId/site/:siteId/logs/page/:pageNo',
  loginRoute: '/user/login',
  dashboard: '/dashboards/instance/:instanceId/site/:siteId',
  duplicateInstance: '/settings/instance/:instanceId/site/:siteId/duplicate-instance',
  importInstance: '/settings/instance/:instanceId/site/:siteId/import-instance',
  automatedReports: '/settings/instance/:instanceId/site/:siteId/automated-reports',
  roiReports: '/settings/instance/:instanceId/site/:siteId/roi-reports',
  tools: '/settings/instance/:instanceId/site/:siteId/tools',
  toolGoogleVisibility: '/settings/instance/:instanceId/site/:siteId/tools/google-visibility',
  toolCurrentVisibilityScore: '/settings/instance/:instanceId/site/:siteId/tools/current-visibility-score',
  reports: '/settings/instance/:instanceId/site/:siteId/reports',
  backgroundJobs: '/settings/instance/:instanceId/site/:siteId/background-jobs/page/:pageNo',
  backgroundJobsReport: '/settings/instance/:instanceId/report/:reportType/:reportId',
  services: '/settings/instance/:instanceId/site/:siteId/services',
  componentCategoryList: '/settings/instance/:instanceId/component-category-list',
  componentList: '/settings/instance/:instanceId/component-list/category/:categoryId',
  componentEdit: '/settings/instance/:instanceId/category/:categoryId/component/:componentId',
  componentCreate: '/settings/instance/:instanceId/category/:categoryId/component',
};

export const siteCenter: RouteMap = {
  pageList: '/site-centre/instance/:instanceId/site/:siteId/pages',
  domainList: '/site-centre/instance/:instanceId/site/:siteId/domains',
  templateList: '/site-centre/instance/:instanceId/site/:siteId/design-options/template-list',
  editTemplate: '/site-centre/instance/:instanceId/site/:siteId/design-options/template-edit/:id',
  editTemplateGrid: '/site-centre/instance/:instanceId/site/:siteId/design-options/template-edit-grid/:id',
  templateFragmentList: '/site-centre/instance/:instanceId/site/:siteId/design-options/template-fragments',
  addTemplateFragment: '/site-centre/instance/:instanceId/site/:siteId/design-options/fragment-create',
  editTemplateFragment: '/site-centre/instance/:instanceId/site/:siteId/design-options/fragment-edit/:id',
  textStyleList: '/site-centre/instance/:instanceId/site/:siteId/design-options/text-styles',
  addTextStyle: '/site-centre/instance/:instanceId/site/:siteId/design-options/text-style',
  editTextStyle: '/site-centre/instance/:instanceId/site/:siteId/design-options/text-style/:id',
  colourPalette: '/site-centre/instance/:instanceId/site/:siteId/design-options/colour-palette',
  buttons: '/site-centre/instance/:instanceId/site/:siteId/design-options/buttons',
  editButton: '/site-centre/instance/:instanceId/site/:siteId/design-options/buttons-edit/:id',
  designOption: '/site-centre/instance/:instanceId/site/:siteId/design-options',
  styleOverrides: '/site-centre/instance/:instanceId/site/:siteId/design-options/style-overrides',
  customCodeListing: '/site-centre/instance/:instanceId/site/:siteId/design-options/custom-code',
  customCodeAdd: '/site-centre/instance/:instanceId/site/:siteId/design-options/custom-code/add',
  customCodeUpdate: '/site-centre/instance/:instanceId/site/:siteId/design-options/custom-code/:id',
  pageVersionEdit: '/site-centre/instance/:instanceId/site/:siteId/page/:pageId/page-version/:pageVersionId/type/:pageType',
  pageVersionHistory: '/site-centre/instance/:instanceId/site/:siteId/page/:pageId/page-version/:pageVersionId/history/page/:pageNo',
  urlList: '/site-centre/instance/:instanceId/site/:siteId/page/:pageId/page-version/:pageVersionId/url',
  businessDirectory: '/site-centre/instance/:instanceId/site/:siteId/business-directory',
  editAllForms: '/site-centre/instance/:instanceId/site/:siteId/forms',
  main: '/site-centre',
  sectionEditing: '/instance/:instanceId/site/:siteId/section/:sectionId/type/:type',
};

export const mainRoutes: RouteMap = {
  dataCenter: '/data-centre/instance/:instanceId/site/:siteId',
  setting: '/settings/instance/:instanceId',
  qrCode: '/qrcode/instance/:instanceId/site/:siteId',
  unsubscribeFromAll: '/unsubscribe-from-all/instance/:instanceId/site/:siteId',
  test: '/test/instance/:instanceId/site/:siteId',
};

export const statsCenterRoutes: RouteMap = {
  statsCenter: '/stats-centre/instance/:instanceId/site/:siteId',
  main: '/stats-centre',
};

export const contentCenterRoutes: RouteMap = {
  contentCenter: '/content-centre/instance/:instanceId/site/:siteId',
  contentTopicBriefing: '/content-centre/instance/:instanceId/site/:siteId/topic-briefing/:id',
  contentBriefingForm: '/content-centre/instance/:instanceId/site/:siteId/content-briefing-form/:id',
  generateDraftContent: '/content-centre/instance/:instanceId/site/:siteId/generate-draft-content/:id',
  main: '/content-centre',
};

export const deliveryCenterRoutes: RouteMap = {
  newSeries: '/delivery-centre/instance/:instanceId/site/:siteId/new-series',
  editSeries: '/delivery-centre/instance/:instanceId/site/:siteId/email-series/:id',
  emailSeries: '/delivery-centre/instance/:instanceId/site/:siteId/email-series',
  bulkEmail: '/delivery-centre/instance/:instanceId/site/:siteId/bulk-email',
  deliveryCenter: '/delivery-centre/instance/:instanceId/site/:siteId',
};

export const messageCenterRoutes: RouteMap = {
  messageCenterEmailPage: '/message-centre/instance/:instanceId/site/:siteId/page/:pageId/page-version/:pageVersionId/type/:pageType',
  messageCenter: '/message-centre/instance/:instanceId/site/:siteId',
  messageCenterMain: '/message-centre',
};

export const userRoutes: RouteMap = {
  wgUser: '/wg-user/instance/:instanceId/site/:siteId',
  userProfile: '/wg-user/instance/:instanceId/site/:siteId/profile/user/:userId',
  userCreate: '/wg-user/instance/:instanceId/site/:siteId/profile/add-user',
  usersList: '/wg-user/instance/:instanceId/site/:siteId/listing',
};

export const reviewCenterRoutes: RouteMap = {
  reviewCenter: '/review-centre/instance/:instanceId/site/:siteId',
};

export const getPageEditUrl = (
  id: string | number,
  pageId: string | number,
  pageType: string,
  props: PathProps = {},
): string => {
  const path = pageType === 'email' ? messageCenterRoutes.messageCenterEmailPage : siteCenter.pageVersionEdit;

  // Ensure the path is a string before passing it to replacePathParams
  const url = typeof path === 'string'
    ? replacePathParams(path, [
      { key: 'pageVersionId', value: id },
      { key: 'pageId', value: pageId },
      { key: 'pageType', value: pageType },
    ], props)
    : ''; // Handle case where path is not a string
  return url;
};

export const allRoutes = (): RouteMap => {
  const routes: RouteMap = {};

  [
    dataCenter,
    siteCenter,
    mainRoutes,
    userRoutes,
    settingRoutes,
    statsCenterRoutes,
    mediaCenterRoutes,
    reviewCenterRoutes,
    messageCenterRoutes,
    deliveryCenterRoutes,
  ].forEach((s) => {
    Object.keys(s).forEach((k) => routes[k] = s[k]);
  });

  return routes;
};
