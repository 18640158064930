import React from 'react';
import { useGetEmailSeriesQuery, useCancelMemberEmailSeriesSubscriptionMutation } from 'common/api/apiSlice';
import { isEmpty, sortBy } from 'lodash-es';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DateTime } from 'luxon';
import DateTimeFormat from 'Dashboard/src/components/common/DateTimeFormat';
import TableComponent from 'Dashboard/src/components/common/Table';
import confirm from 'Dashboard/src/components/common/confirm';
import { displayError, displaySuccess } from 'Utils';
import { useInstance } from 'common/hooks';

const MemberEmailSeriesSubscription = (props) => {
  const { toggle, member } = props;
  const instance = useInstance();
  const [cancelMemberEmailSeriesSubscription] = useCancelMemberEmailSeriesSubscriptionMutation();
  const { data: emailSeries = [] } = useGetEmailSeriesQuery(instance?.id, { skip: isEmpty(instance?.id) });

  const dataTableColumns = [
    {
      Header: 'Series',
      id: 'series',
      accessor: (sub) => emailSeries?.find((e) => e?.id === sub?.email_series_id)?.name || '',
    },
    {
      Header: 'Subscription date',
      id: 'date',
      accessor: (sub) => <DateTimeFormat datetime={sub.created_at} localeString={DateTime.DATETIME_MED} />,
    },
    {
      Header: 'Emails sent',
      id: 'sent',
      accessor: (sub) => sub.sent_email_series_emails?.length,
    },
    {
      Header: 'Finished',
      id: 'finished',
      accessor: (sub) => sub?.finished ? <i className="fal fa-check" /> : '',
    },
    {
      Header: 'Cancel',
      id: 'cancel',
      accessor: (sub) => {
        if (sub.finished) {
          return '';
        }

        return (
          <Button
            color="link"
            className="text-danger"
            onClick={async () => {
              try {
                const result = await confirm({
                  title: <b>Confirm!</b>,
                  message: 'Are you sure you want to cancel this subscription?',
                  confirmText: 'Yes',
                  confirmColor: 'success',
                  cancelColor: 'btn btn-danger',
                  cancelText: 'No',
                });
                if (result) {
                  const result = await cancelMemberEmailSeriesSubscription({
                    instance_id: instance?.id,
                    ...sub,
                  });
                  if (result && result?.data?.meta?.is_success) {
                    displaySuccess(result?.data?.meta?.messages);
                  }
                }
              } catch (error) {
                displayError(`${error?.name}: ${error?.message}`);
              }
            }}
          >
            Cancel
          </Button>
        );
      },
    },
  ];

  const sortedSubscriptions = sortBy(member?.email_series_subscriptions, 'created_at').reverse();

  return (
    <Modal isOpen={true} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Email Series Subscriptions</ModalHeader>
      <ModalBody>
        <TableComponent columns={dataTableColumns} data={sortedSubscriptions || []} />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={toggle}>
          <b>CLOSE</b>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MemberEmailSeriesSubscription;
