import React from 'react';
import TableComponent from 'Dashboard/src/components/common/Table';
import HeaderComponent from 'Dashboard/src/components/common/header-component';
import { replacePathParams, settingRoutes, siteCenter } from 'Dashboard/src/constants/routes';
import withRouter from 'Dashboard/src/helpers/withRouter';
import { Button, Card, CardBody } from 'reactstrap';
import BottomActionToolbar from 'Dashboard/src/components/common/toolbar';
import { useNavigate, useParams } from 'react-router';
import { useDeleteCustomCodeMutation, useGetCustomCodesQuery } from 'common/api/apiSlice';
import { isEmpty } from 'lodash-es';
import confirm from 'Dashboard/src/components/common/confirm';
import { displayError, displaySuccess } from '../../../../../../Utils';
import { addRecentItem } from '../../../../../../UtilsTS';

function CustomCodeListing(props) {
  const navigate = useNavigate();
  const { siteId, instanceId } = useParams();

  const { data } = useGetCustomCodesQuery(siteId, { skip: isEmpty(siteId) });

  const [deleteCustomCode] = useDeleteCustomCodeMutation();

  const onDelete = async (id) => {
    try {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });
      if (result) {
        const result = await deleteCustomCode({ siteId, id });
        if (result && result?.data?.meta?.is_success) {
          displaySuccess(result?.data?.meta?.messages);
        }
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const dataTableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (d) => d.file_name,
    },
    {
      Header: 'Language',
      id: 'language',
      accessor: (d) => d.language,
      Cell: ({ value: l }) => {
        if (l === 'javascript') {
          return 'JavaScript';
        }
        if (l === 'scss') {
          return 'SCSS';
        }
        if (l === 'css') {
          return 'CSS';
        }
        if (l === 'html') {
          return 'HTML';
        }
        return '';
      },
    },
    {
      Header: 'Action',
      id: 'action',
      Cell: ({ row: { original: d } }) => (
        <>
          <Button
            className="custom-simple-icon"
            color="primary"
            size="xs"
            onClick={() => navigate(replacePathParams(siteCenter.customCodeUpdate, [{ key: 'id', value: d?.id }], props))}
          >
            <i className="fal fa-pen" />
          </Button>
          {' '}
          <Button
            className="custom-simple-icon"
            color="danger"
            size="xs"
            onClick={() => onDelete(d?.id)}
          >
            <i className="fal fa-trash" />
          </Button>
        </>
      ),
      disableSortBy: true,
    },
  ];

  if (instanceId && siteId) {
    addRecentItem({
      instance_id: instanceId,
      site_id: siteId,
      type: 'custom-codes',
    });
  }

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    {
      name: 'Site Centre',
      url: replacePathParams(siteCenter.pageList, [], props),
    },
    {
      name: 'Design Options',
      url: replacePathParams(siteCenter.designOption, [], props),
    },
    { name: 'Custom Code', url: '' },
  ];

  return (
    <>
      <HeaderComponent
        setPath={{
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          headingName: 'Custom Code',
          addNewBtnName: '',
          addNewPath: '',
        }}
      />
      <Card className="mb-4">
        <CardBody>
          <TableComponent
            columns={dataTableColumns}
            data={data || []}
          />
        </CardBody>
      </Card>
      <BottomActionToolbar
        component={(
          <Button
            color="info fw-bold"
            onClick={() => navigate(replacePathParams(siteCenter.customCodeAdd, [], props))}
          >
            <i className="fa fa-plus" />
            {' '}
            ADD
          </Button>
        )}
      />
    </>
  );
}

export default withRouter(CustomCodeListing);
