import React from 'react';
import {
  Input,
  Label,
  FormGroup,
  InputGroup,
  UncontrolledTooltip,
} from 'reactstrap';

function PhoneNumber(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label className="has-tooltip" id="phoneNumberHelp">Phone Number</Label>
      <UncontrolledTooltip placement="right" target="phoneNumberHelp" style={{ textAlign: 'left' }}>
        This is the company&apos;s real phone number.
      </UncontrolledTooltip>
      <InputGroup>
        <Input
          type="text"
          value={params.phone_number}
          onChange={(e) => { setParams({ ...params, phone_number: e.target.value }); }}
        />
      </InputGroup>
    </FormGroup>
  );
}

export default PhoneNumber;
