import React from 'react';
import { Input, Label } from 'reactstrap';
import { map } from 'lodash-es';

export function Radio({ options, value, onChange, style = 'd-block' }) {
  return map(options, (option) => (
    <Label key={option} className={`${style} me-3`}>
      <Input
        type="radio"
        value={option}
        checked={option === value}
        onChange={(e) => onChange(e.target.value)}
      />
      {' '}
      {option}
    </Label>
  ));
}
