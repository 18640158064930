import React from 'react';
import type { Editor } from '@tiptap/react';
import { Row, Col } from 'reactstrap';
import CustomTooltip from 'Dashboard/src/components/common/CustomTooltip';
import { useUser } from 'common/hooks';
import TextStyleListing from 'Dashboard/src/components/common/textStyles';
import TextStyle from 'common/models/TextStyle';

function MenuItem({
  icon,
  title,
  action,
  isActive = null,
  className = '',
}: {
  icon: string;
  title: string;
  action?: (e) => any;
  isActive?: (() => boolean) | null;
  className?: string;
}) {
  return (
    <button
      className={`custom-simple-txt-btn btn btn-secondary${isActive && isActive() ? ' is-active' : ''} ${className}`}
      onClick={action}
      title={title}
    >
      {
        icon === '' && (
          <span>
            {' '}
            {title}
          </span>
        )
      }
      <i className={`fal fa-${icon}`} />
    </button>
  );
}

interface ClickParams {
  type: string;
  move?: boolean;
}

export default function MenuBar({
  index,
  editor,
  features,
  onClick,
  textBlock,
  textBlockCount,
}: {
  index: number;
  editor: Editor;
  features: string[];
  onClick: (params: ClickParams) => void;
  textBlock: any;
  textBlockCount: number;
}) {
  const hasFeature = (f: string) => features.indexOf(f) > -1;
  const user = useUser();

  const items = [
    {
      icon: 'bold',
      title: 'Bold',
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive('bold'),
    },
    {
      icon: 'italic',
      title: 'Italic',
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive('italic'),
    },
    {
      icon: 'align-left',
      title: 'Align Left',
      action: () => editor.chain().focus().setTextAlign('left').run(),
      isActive: () => editor.isActive({ textAlign: 'left' }),
    },
    {
      icon: 'align-center',
      title: 'Align Centre',
      action: () => editor.chain().focus().setTextAlign('center').run(),
      isActive: () => editor.isActive({ textAlign: 'center' }),
    },
    {
      icon: 'align-right',
      title: 'Align Right',
      action: () => editor.chain().focus().setTextAlign('right').run(),
      isActive: () => editor.isActive({ textAlign: 'right' }),
    },
    {
      icon: 'list-ul',
      title: 'Bullet List',
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive('bulletList'),
    },
    {
      icon: 'list-ol',
      title: 'Ordered List',
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.isActive('orderedList'),
    },
    {
      icon: 'window-minimize',
      title: 'Horizontal Rule',
      action: () => editor.chain().focus().setHorizontalRule().run(),
    },
    {
      icon: 'link',
      title: 'Link',
      action: () => onClick({ type: 'link' }),
      isActive: () => editor.isActive('link'),
    },
    {
      icon: 'fal fa-file-image',
      title: 'Media',
      action: () => onClick({ type: 'media' }),
      isActive: () => editor.isActive('media'),
      displayIf: hasFeature('media'),
    },
    {
      icon: 'fal fa-code',
      title: 'Source Code',
      action: () => onClick({ type: 'code' }),
      isActive: () => editor.isActive('code'),
    },
    {
      icon: 'fal fa-head-side-brain',
      title: 'AI',
      action: () => onClick({ type: 'ai' }),
      isActive: () => editor.isActive('ai'),
      displayIf: hasFeature('ai'),
    },
    {
      icon: 'fa-solid fa-arrow-up',
      title: 'Move Up',
      align: 'right',
      action: () => onClick({ type: 'reorder', move: true }),
      isActive: () => editor.isActive('reorder'),
      displayIf: hasFeature('reorder') && index !== 0,
    },
    {
      icon: 'fa-solid fa-arrow-down',
      title: 'Move Down',
      align: 'right',
      action: () => onClick({ type: 'reorder', move: false }),
      isActive: () => editor.isActive('reorder'),
      displayIf: hasFeature('reorder') && textBlockCount !== index + 1,
    },
    {
      icon: 'fal fa-clock-rotate-left',
      title: 'History',
      align: 'right',
      action: () => onClick({ type: 'history' }),
      isActive: () => editor.isActive('history'),
      displayIf: hasFeature('history') && user?.hasFeature(['view_object_history', 'view_textblock_history']),
    },
    {
      icon: 'fal fa-arrows-alt',
      title: 'Drag',
      align: 'right',
      className: 'handle-sortable',
      action: (e) => e.preventDefault(),
      isActive: () => editor.isActive('drag'),
      displayIf: hasFeature('reorder'),
    },
    {
      icon: textBlock?.is_offline ? 'fal fa-eye-slash' : 'fal fa-eye',
      title: textBlock?.is_offline ? 'Online' : 'Offline',
      align: 'right',
      action: () => onClick({ type: 'is_offline' }),
      isActive: () => editor.isActive(textBlock?.is_offline ? 'offline' : 'online'),
      displayIf: hasFeature('offline'),
    },
    {
      icon: 'fal fa-trash',
      title: 'Trash',
      align: 'right',
      action: () => onClick({ type: 'trash' }),
      isActive: () => editor.isActive('trash'),
      displayIf: hasFeature('delete'),
    },
    // Our old editors don't have undo/redo. They may be useful in future so leaving these here.
    // {
    //   icon: 'arrow-go-back-line',
    //   title: 'Undo',
    //   action: () => editor.chain().focus().undo().run(),
    // },
    // {
    //   icon: 'arrow-go-forward-line',
    //   title: 'Redo',
    //   action: () => editor.chain().focus().redo().run(),
    // },
  ];

  const handleChange = (style: TextStyle) => {
    // I (MP) apologise for this ugliness. When pasting from somewhere that is not an RTE3 editor we need to match the
    // currently selected text style. I can't figure out how to get the style ID in the RichTextEditor3 component, so
    // we set it here.
    (window as any).styleIds ||= {};
    (window as any).styleIds[textBlock.id] = style.id;

    editor.chain().focus().setCustomStyle(style.id).run();
  };

  return (
    <div className="editor__header">
      <Row>
        {hasFeature('text_style') && (
          <Col className="col-3 pe-0">
            <div
              style={{
                float: 'left',
                padding: '12px',
                borderRadius: '0.1rem',
                border: '1px solid var(--separator-color)',
                height: '45.19px',
                marginRight: '12px',
                fontWeight: 600,
                position: 'relative',
                zIndex: 1,
                cursor: 'default',
              }}
            >
              <CustomTooltip name="MultiStyleTextblock">
                <span style={{ color: 'var(--theme-color-1)' }}>
                  T
                </span>
                <span style={{ color: '#58bc6d' }}>
                  t
                </span>
              </CustomTooltip>
            </div>
            <TextStyleListing
              style={editor.getAttributes('CustomStyle').customStyle || editor.getAttributes('paragraph').customStyle}
              onChange={handleChange}
            />
          </Col>
        )}
        <Col className="col-9 col">
          <ul className="d-flex h-100">
            {items.map((item, index) => (
              (item?.displayIf ?? true) && (
                <li
                  key={index}
                  className={item.align === 'right' ? 'common-button' : ''}
                >
                  <MenuItem {...item} />
                </li>
              )
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
}
