import React, { Fragment } from 'react';
import { WithWizard } from 'react-albus';
import { NavLink } from 'react-router-dom';
import './index.scss';
import { Button } from 'reactstrap';

function WizardTopNavigation(props) {
  const { topNavClick, disableNav, className } = props;
  const getClassName = (steps, step, index, stepItem) => {
    if (steps.indexOf(step) === index) {
      return 'step-doing';
    }
    if (steps.indexOf(step) > index || stepItem.isDone) {
      stepItem.isDone = true;
      return 'step-done';
    }
    return 'step';
  };

  const itemClick = (stepItem, push) => {
    if (disableNav) {
      return;
    }
    topNavClick(stepItem, push);
  };

  if (!topNavClick) {
    return (
      <WithWizard
        render={({ next, previous, step, steps }) => (
          <div className="example-buttons">
            {steps.indexOf(step) < steps.length - 1 && (
              <Button className="btn-fluid margin-1-b" onClick={next}>
                Next
              </Button>
            )}

            {steps.indexOf(step) > 0 && (
              <Button className="btn-fluid btn-secondary" onClick={previous}>
                Back
              </Button>
            )}
          </div>
        )}
      />
    );
  }

  return (
    <WithWizard render={({
      step, steps, push,
    }) => (
      <ul className={`d-flex align-items-center justify-content-center top-nav ${className}${disableNav ? ' disabled' : ''}`}>
        {steps.map((stepItem, index) => {
          if (!stepItem.hideTopNav) {
            return (
              <li key={stepItem.id} className={getClassName(steps, step, index, stepItem)}>
                <NavLink to="#" className="nav-link" onClick={() => itemClick(stepItem, push)}>
                  <span className="fw-bold">{stepItem.name}</span>
                  {stepItem.desc && <small>{stepItem.desc}</small>}
                </NavLink>
              </li>
            );
          }
          return <Fragment key={stepItem.id} />;
        })}
      </ul>
    )}
    />
  );
}

export default WizardTopNavigation;
