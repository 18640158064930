import React from 'react';
import { DateTime } from 'luxon';
import { isEmpty, get } from 'lodash-es';
import { useUser } from '../../../../common/hooks';

function DTZone({ datetime, localeString }) {
  const user = useUser();
  const time_zone = get(user, 'time_zone', 'Pacific/Auckland');

  if (isEmpty(datetime)) {
    return '(unknown)';
  }

  // We want to accept anything that is thrown at us, so try a bunch of different formats.
  const formats = [
    (t) => DateTime.fromJSDate(t),
    (t) => DateTime.fromFormat(t, 'y-MM-dd HH:mm:ss ZZZ', { zone: time_zone }), // 2023-08-18 03:14:30 +0000
    (t) => DateTime.fromFormat(t, 'y-MM-dd\'T\'HH:mm:ss.SSSZZZZ', { zone: time_zone }), // 2023-08-18T03:14:30.200Z
    (t) => DateTime.fromISO(t),
  ];
  let dt;
  formats.find((f) => {
    dt = f(datetime);
    return !dt.invalid;
  });

  return (
    <span>
      {dt.toLocaleString(localeString)}
    </span>
  );
}

export default DTZone;

export const dateTimeString = (datetime, timeZone, localeString) => {
  if (isEmpty(datetime)) {
    return '(unknown)';
  }

  // We want to accept anything that is thrown at us, so try a bunch of different formats.
  const formats = [
    (t) => DateTime.fromJSDate(t),
    (t) => DateTime.fromFormat(t, 'y-MM-dd HH:mm:ss ZZZ', { zone: timeZone }), // 2023-08-18 03:14:30 +0000
    (t) => DateTime.fromFormat(t, 'y-MM-dd\'T\'HH:mm:ss.SSSZZZZ', { zone: timeZone }), // 2023-08-18T03:14:30.200Z
    (t) => DateTime.fromISO(t),
  ];
  let dt;
  formats.find((f) => {
    dt = f(datetime);
    return !dt.invalid;
  });

  return dt.toLocaleString(localeString);
};
