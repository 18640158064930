import React, { useState, useEffect, ChangeEvent, Fragment, useRef } from 'react';
import { replacePathParams, statsCenterRoutes, settingRoutes, contentCenterRoutes } from 'Dashboard/src/constants/routes';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { addRecentItem } from 'UtilsTS';
import { cloneDeep, isEmpty } from 'lodash-es';
import HeaderComponent from '../../components/common/header-component';
import confirm from '../../components/common/confirm';
import { useParams } from 'react-router';
import withRouter from '../../helpers/withRouter';
import {
  useGetContentTopicQuery,
  useGetContentTopicBriefingQuery,
  useUpdateContentTopicMutation,
  useGenerateContentTopicQuestionMutation,
} from 'common/api/apiSlice';
import { default as ContentTopic } from 'common/models/ContentTopic';
import BottomActionToolbar from '../../components/common/toolbar';
import CustomButton from '../../components/common/button';
import SaveAndContinue from '../../components/common/saveAndContinue';

// Automatically set textarea height depending on the number of lines.
const autoHeightTextArea = (e) => {
  e.target.style.height = 'inherit';
  e.target.style.height = `${e.target.scrollHeight}px`;
};

const AutoHeightTextArea = (
  { name, value, onChange, style }: { name: string; value: string; onChange: (e: ChangeEvent<HTMLInputElement>) => void; style?: any },
) => (
  <Input
    type="textarea"
    name={name}
    value={value}
    onChange={onChange}
    style={style}
  />
);

function ContentBriefingForm(props) {
  const { navigate } = props;
  const params = useParams();
  const { instanceId, siteId } = params;
  const { data: ct } = useGetContentTopicQuery(
    {
      siteId: siteId || '',
      id: params.id || '',
    },
    { skip: isEmpty(siteId) || isEmpty(params.id) },
  );
  const { data: ctb } = useGetContentTopicBriefingQuery(
    {
      siteId: siteId || '',
      id: ct?.content_topic_briefing_id || '',
    },
    { skip: isEmpty(siteId) || isEmpty(ct?.content_topic_briefing_id) },
  );
  const [editingCt, setEditingCt] = useState<ContentTopic | null>(ct);
  const [updateContentTopic] = useUpdateContentTopicMutation();
  const [generateContentTopicQuestion] = useGenerateContentTopicQuestionMutation();
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (ct) {
      setEditingCt(ct);
    }
  }, [ct]);

  useEffect(() => {
    if (ref.current) {
      ref.current.querySelectorAll('textarea').forEach((t) => {
        autoHeightTextArea({ target: t });
      });
    }
  }, [editingCt]);

  addRecentItem({
    instance_id: instanceId,
    site_id: siteId,
    type: 'content-center',
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditingCt((prev) => new ContentTopic({
      ...prev,
      [name]: value,
    }));
  };

  const update = async () => {
    if (!editingCt) {
      return false;
    }

    const result = await updateContentTopic(editingCt);
    if (result) {
      return true;
    }
  };

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Content Centre',
      url: replacePathParams(contentCenterRoutes.contentCenter, [], props),
    },
    {
      name: ctb?.business_name || '',
      url: replacePathParams(contentCenterRoutes.contentTopicBriefing, [{ key: 'id', value: ctb?.id }], props),
    },
    { name: 'Content Brief Form', url: '' },
  ];

  if (!ctb || !editingCt) {
    return <></>;
  }

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Content Brief Form',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Dashboard',
        backToPath: statsCenterRoutes.contentCenter,
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card className="mt-3 mb-4 rounded" innerRef={ref}>
        <CardBody>
          <CardTitle>
            {editingCt.name}
            <br />
            <br />
            Type:
            {' '}
            {editingCt?.typeText()}
            {' Page'}
          </CardTitle>
          <Row>
            <Col>
              <FormGroup>
                <Label>Topic</Label>
                <Input name="name" value={editingCt.name || ''} onChange={onChange} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Page name</Label>
                <Input name="page_name" value={editingCt.page_name || ''} onChange={onChange} />
              </FormGroup>
            </Col>
          </Row>
          {editingCt.type === 'article' && (
            <>
              {editingCt.questions?.map((q, i) => (
                <Fragment key={i}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          Question
                          {' '}
                          {i + 1}
                          {' - '}
                          <a
                            href="#"
                            onClick={async (e) => {
                              e.preventDefault();

                              if (q.question || q.answer) {
                                const result = await confirm({
                                  title: <b>Confirm!</b>,
                                  message: 'Are you sure you want to delete this question?',
                                  confirmText: 'Yes',
                                  confirmColor: 'success',
                                  cancelColor: 'btn btn-danger',
                                  cancelText: 'No',
                                });
                                if (!result)
                                  return;
                              }

                              const tempQuestions = cloneDeep(editingCt.questions || []);
                              if (!tempQuestions[i]) return;
                              tempQuestions.splice(i, 1);
                              setEditingCt((p) => new ContentTopic({ ...p, questions: tempQuestions }));
                            }}
                          >
                            Delete
                          </a>
                        </Label>
                        <Input
                          name="name"
                          value={q.question || ''}
                          onChange={(e) => {
                            const tempQuestions = cloneDeep(editingCt.questions || []);
                            if (!tempQuestions[i]) return;
                            tempQuestions[i].question = e.target.value;
                            setEditingCt((p) => new ContentTopic({ ...p, questions: tempQuestions }));
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Answer</Label>
                        <AutoHeightTextArea
                          name="answer"
                          value={q.answer || ''}
                          onChange={(e) => {
                            const tempQuestions = cloneDeep(editingCt.questions || []);
                            if (!tempQuestions[i]) return;
                            tempQuestions[i].answer = e.target.value;
                            setEditingCt((p) => new ContentTopic({ ...p, questions: tempQuestions }));
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Fragment>
              ))}
              <Row className="mb-2">
                <Col>
                  <CustomButton
                    color="success"
                    className="common-success-button fw-bold"
                    title="ADD BLANK QUESTION"
                    onClick={() => {
                      const tempQuestions = cloneDeep(editingCt.questions || []);
                      tempQuestions.push({ question: '', answer: '' });
                      setEditingCt((p) => new ContentTopic({ ...p, questions: tempQuestions }));
                    }}
                  />
                  {' '}
                  <CustomButton
                    color="success"
                    className="common-success-button fw-bold"
                    title="GENERATE NEW QUESTION"
                    onClick={async () => {
                      const saveResult = await updateContentTopic(editingCt);
                      if (saveResult) {
                        const questionResult = await generateContentTopicQuestion(editingCt);
                        console.log(questionResult);
                        if (questionResult) {
                          setEditingCt(questionResult.data.data);
                        }
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Client comments</Label>
                    <Input name="client_comments" value={editingCt.client_comments || ''} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Internal info</Label>
                    <Input name="internal_info" value={editingCt.internal_info || ''} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Primary keywords</Label>
                    <Input name="primary_keywords" value={editingCt.primary_keywords || ''} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Secondary keywords</Label>
                    <Input name="secondary_keywords" value={editingCt.secondary_keywords || ''} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Related keywords</Label>
                    <Input name="related_keywords" value={editingCt.related_keywords || ''} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}

          {editingCt.type === 'product_service' && (
            <>
              ASAP Form from Dominator Brief goes here
            </>
          )}
        </CardBody>
      </Card>

      <BottomActionToolbar
        component={(
          <>
            <SaveAndContinue
              onSave={update}
              onContinue={() => {
                navigate(replacePathParams(contentCenterRoutes.contentTopicBriefing, [{ key: 'id', value: ctb?.id }], props));
              }}
            />
            {' '}
            <CustomButton
              color="success"
              className="common-success-button fw-bold"
              title="GENERATE DRAFT PAGE CONTENT"
              onClick={async () => {
                const result = await update();
                if (result) {
                  navigate(replacePathParams(contentCenterRoutes.generateDraftContent, [{ key: 'id', value: ct?.id }], props));
                }
              }}
            />
          </>
        )}
      />
    </>
  );
}

export default withRouter(ContentBriefingForm);
