import React from 'react';
import {
  Input,
  Label,
  FormGroup,
  UncontrolledTooltip,
} from 'reactstrap';

function PriceRange(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label className="has-tooltip" id="priceRangeHelp">Price Range</Label>
      <UncontrolledTooltip placement="right" target="priceRangeHelp" autohide={false} style={{ textAlign: 'left' }}>
        Used in
        {' '}
        <a
          href="https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data"
          target="_blank"
          rel="noreferrer"
        >
          page microdata
        </a>
        .
        <br />
        <br />
        The price range of the business. For example:
        {' '}
        <b>$10-15</b>
        {' '}
        or
        {' '}
        <b>$$$</b>
        .
        <br />
        <br />
        <a
          href="https://developers.google.com/search/docs/advanced/structured-data/local-business#:~:text=The%20relative%20price%20range%20of%20a%20business"
          target="_blank"
          rel="noreferrer"
        >
          More information
        </a>
      </UncontrolledTooltip>
      <Input
        type="text"
        value={params.price_range || ''}
        onChange={(e) => {
          const { value } = e.target;
          setParams({ ...params, price_range: value });
        }}
      />
    </FormGroup>
  );
}

export default PriceRange;
