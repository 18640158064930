import React from 'react';
import './index.scss';

function BottomActionToolbar(props) {
  const { component } = props;
  return (
    <div className="BottomActionToolbar foreground-color depth-1">{component}</div>
  );
}

export default BottomActionToolbar;
