import React, { useRef } from 'react';
import './index.scss';

// This component is used to create an email input with a fixed domain. The value can include a domain, but it will be
// stripped out.
//
// Because this component uses contentEditable, it is not a controlled component. If the parent component changes the
// value prop, the value will not be updated in the input. This means you need to make sure you're not rendering the
// component with a blank value before the real value gets populated.
function EmailInputWithDomain({ value, onChange, domain }: { value: string; onChange: (value: string) => void; domain: string }) {
  const defaultValue = useRef(value.replace(/@.*/, ''));

  return (
    <div>
      <div className="email-input-with-domain">
        <div
          className="email-left"
          contentEditable
          dangerouslySetInnerHTML={{ __html: defaultValue.current }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === '@' || e.key === ' ') {
              e.preventDefault();
            }
          }}
          onInput={(e) => {
            const email = (e.target as HTMLElement).innerHTML
              .replace(/@.*/, '')
              .replace(/[\r\n]*/, '')
            ;
            onChange(email);
          }}
        />
        <div className="email-right">
          @
          {domain}
        </div>
      </div>
    </div>
  );
}

export default EmailInputWithDomain;
