import React, { useEffect } from 'react';
import { setPageTitle } from '../../../../../Utils';
import withRouter from '../../../helpers/withRouter';
import Upload from '../../media-uploads/Upload';

const MediaFolder = () => {
  useEffect(() => {
    setPageTitle('Media Centre');
  }, []);

  return (
    <Upload type="media-center" />
  );
};

export default withRouter(MediaFolder);
