import React, { useEffect } from 'react';
import HeaderComponent from '../../components/common/header-component';
import { settingRoutes, deliveryCenterRoutes, replacePathParams } from '../../constants/routes';
import { Card, CardBody } from 'reactstrap';
import { get, map, isEmpty } from 'lodash-es';
import CustomButton from '../../components/common/button';
import confirm from '../../components/common/confirm';
import BottomActionToolbar from '../../components/common/toolbar';
import { useNavigate, useParams } from 'react-router-dom';
import withRouter from '../../helpers/withRouter';
import { useDeleteEmailSeriesMutation, useGetEmailSeriesQuery, useUpdateEmailSeriesMutation } from '../../../../common/api/apiSlice';
import { displaySuccess } from '../../../../Utils';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

function EmailSeriesListing(props) {
  const { instanceId } = useParams();
  const navigate = useNavigate();
  const { data: emailSeries = [] } = useGetEmailSeriesQuery(instanceId, { skip: isEmpty(instanceId) });
  const [deleteSeries, { isSuccess: isDeleted, meta: deletedMeta }] = useDeleteEmailSeriesMutation();
  const [updateSeries, { isSuccess: isUpdated, meta: updatedMeta }] = useUpdateEmailSeriesMutation();

  useEffect(() => {
    if (isDeleted) {
      displaySuccess(deletedMeta.messages);
    }
    if (isUpdated) {
      displaySuccess(updatedMeta.messages);
    }
  }, [isDeleted, isUpdated]);

  const onHandleEdit = (id) => {
    navigate(
      replacePathParams(
        deliveryCenterRoutes.editSeries,
        [
          { key: 'id', value: id },
        ], props,
      ),
    );
  };

  const onPause = (series) => {
    updateSeries({ ...series, paused: series.paused ? false : true });
  };

  const onHandleDelete = async (id) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      const sendParams = {
        id,
        instance_id: instanceId,
      };
      deleteSeries(sendParams);
    }
  };

  const tableColumns = [
    {
      accessorKey: 'name',
      header: 'Series Name',
    },
    {
      accessorKey: 'email_series_triggers_list',
      header: 'Triggers',
      cell: ({ row }) => (
        map(get(row, 'original.email_series_triggers_list', []), (item, index) => (
          <span className="d-block" key={index}>
            {item}
          </span>
        ))
      ),
    },
    {
      accessorKey: 'paused',
      header: 'Status',
      cell: (column) => column.getValue() ? 'Paused' : 'Active',
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <>
          <CustomButton
            size="xs"
            title=" "
            color="primary"
            icon="fal fa-pen"
            className="custom-simple-icon"
            onClick={() => onHandleEdit(row.original.id)}
          />
          {' '}
          <CustomButton
            size="xs"
            title=" "
            color="primary"
            icon={`fal fa-${row.original.paused ? 'play' : 'pause'}`}
            className="custom-simple-icon"
            onClick={() => onPause(row.original)}
          />
          {' '}
          <CustomButton
            size="xs"
            title=" "
            color="danger"
            icon="fal fa-trash"
            className="custom-simple-icon"
            style={{ background: '#dc3545' }}
            onClick={() => onHandleDelete(row.original.id)}
          />
        </>
      ),
    },
  ];

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Delivery Centre',
      url: replacePathParams(deliveryCenterRoutes.deliveryCenter, [], props),
    },
    { name: 'Email Series', url: '' },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        addNewPath: '',
        addNewBtnName: '',
        staticBreadcrumbData,
        showBreadcrumb: false,
        backBtnName: 'Dashboard',
        headingName: 'Email Series',
        backToPath: replacePathParams(deliveryCenterRoutes.deliveryCenter, [], props),
      }}
      />
      <Card>
        <CardBody>
          <ReactTable8
            data={emailSeries || []}
            columns={tableColumns}
            enableSorting={true}
          />
        </CardBody>
      </Card>
      <BottomActionToolbar
        component={(
          <CustomButton
            color="success"
            className="common-success-button button-md fw-bold"
            title="ADD"
            onClick={() => navigate(replacePathParams(deliveryCenterRoutes.newSeries, [], props))}
          />
        )}
      />
    </>
  );
}

export default withRouter(EmailSeriesListing);
