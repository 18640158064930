import React, { useEffect, useState } from 'react';
import {
  Card, CardTitle, CardBody, Row, Col, Input, Button,
} from 'reactstrap';
import { setPageTitle } from '../../../../../Utils';
import CtaReportFormSubmission from '../../../views/cta-reports/ctaReportFormSubmission';
import CtaLogIncomingCall from '../../../views/cta-reports/ctaLogIncomingCall';
import CtaReportChat from '../../../views/cta-reports/ctaReportChat';
import { useUpdateInstanceMutation } from '../../../../../common/api/apiSlice';
import { isEmpty } from 'lodash-es';
import { useInstance, useUser } from '../../../../../common/hooks';

function DashboardView() {
  const [notes, setNotes] = useState('');
  const instance = useInstance();

  const [updateInstance] = useUpdateInstanceMutation();

  const user = useUser();

  useEffect(() => setPageTitle('Dashboard'), []);

  useEffect(() => {
    if (instance) {
      setNotes(instance.notes);
    }
  }, [instance]);

  if (isEmpty(instance)) {
    return <></>;
  }

  const onHandleSubmit = () => {
    updateInstance({ id: instance.id, notes });
  };

  return (
    <>
      <Row>
        <Col>
          <h1>Dashboard</h1>
        </Col>
      </Row>

      { user?.hasFeature('instance_notes') && (
        <Card className="rounded mb-4">
          <CardBody>
            <CardTitle>Client Notes</CardTitle>
            <Input
              className="mb-2"
              rows={6}
              type="textarea"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
            <Button
              color="success"
              onClick={onHandleSubmit}
              type="submit"
              className="common-success-button button-md fw-bold"
            >
              SAVE
            </Button>
          </CardBody>
        </Card>
      )}

      {instance && instance.cta_types.indexOf('CTALogFormSubmission') > -1 && (
        <Card className="rounded mb-4">
          <CardBody>
            <CardTitle>Leads</CardTitle>
            <CtaReportFormSubmission />
          </CardBody>
        </Card>
      )}

      {instance && instance.cta_types.indexOf('CTALogIncomingCall') > -1 && (
        <Card className="rounded mb-4">
          <CardBody>
            <CardTitle>Call Logs</CardTitle>
            <CtaLogIncomingCall />
          </CardBody>
        </Card>
      )}

      {instance && instance.cta_types.indexOf('CTALogChat') > -1 && (
        <Card className="rounded mb-4">
          <CardBody>
            <h2>Chats</h2>
            <CtaReportChat />
          </CardBody>
        </Card>
      )}
    </>
  );
}

export default DashboardView;
