import React, { useState, useEffect } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/snippets/javascript';
import 'ace-builds/src-noconflict/mode-sass';
import 'ace-builds/src-noconflict/snippets/sass';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/ext-searchbox';

function FragmentCodeEditor({
  editorData, validateEditor, defaultComponentValue,
}) {
  const [annotations] = useState([]);
  const [editor] = useState();
  const [templateData, setTemplateData] = useState('');
  const nextAnnotations = [...annotations.filter(({ custom }) => !custom)];

  useEffect(() => {
    if (editor) {
      editor.getSession().setAnnotations(nextAnnotations);
    }
  }, [editor, JSON.stringify(nextAnnotations)]);

  const onUpdateEditorValue = (e, code) => {
    const data = code.getValue();
    editorData(data);
  };

  const setContent = (data) => {
    setTemplateData(data);
  };

  return (
    <AceEditor.default
      className="ace-editor"
      mode="html"
      theme="xcode"
      name="code-editor"
      onChange={(value) => setContent(value)}
      onBlur={(e, code) => onUpdateEditorValue(e, code)}
      height="500px"
      width="100%"
      value={templateData || defaultComponentValue}
      highlightActiveLine
      showGutter
      fontSize={16}
      setOptions={{
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
      }}
      editorProps={{ $blockScrolling: true }}
      onValidate={validateEditor}
    />
  );
}

export default FragmentCodeEditor;
