import React from 'react';
import {
  Input,
  Label,
  FormGroup,
  InputGroup,
  UncontrolledTooltip,
} from 'reactstrap';
import { every, trim } from 'lodash-es';

function TrackingPhoneNumbers(props) {
  const { params, setParams } = props;

  const p = params.tracking_phone_numbers;
  const valid = p === '' || every((p || []), (n) => {
    if (trim(n) === '') {
      return true;
    }

    if (n.match(/^6[4|1][1-9]\d{7}$/)) {
      return true;
    }

    if (n.match(/^\d{6}$/)) {
      return true;
    }

    return false;
  });

  return (
    <FormGroup>
      <Label className="has-tooltip" id="trackingPhoneNumbersHelp">Tracking Phone Numbers, one per line</Label>
      <UncontrolledTooltip placement="right" target="trackingPhoneNumbersHelp" style={{ textAlign: 'left' }}>
        Tracking phone numbers, either in DID format (649XXXXXXX), or CID format (XXXXXX).
      </UncontrolledTooltip>
      <InputGroup>
        <Input
          type="textarea"
          valid={valid}
          invalid={!valid}
          value={(params.tracking_phone_numbers || []).join('\n')}
          onChange={(e) => { setParams({ ...params, tracking_phone_numbers: e.target.value.split(/\n+/) }); }}
        />
      </InputGroup>
    </FormGroup>
  );
}

export default TrackingPhoneNumbers;
