import React from 'react';
import { useUser } from '../../../../../common/hooks';
import { isArray, isString } from 'lodash-es';

function UserFeatureComponent({ children, feature }) {
  const user = useUser();

  if (!user) {
    return <></>;
  }

  // feature can be an array, if it is then we render the component if the user has ANY of the requested features enabled.
  if (isArray(feature) && feature.some((f) => user.hasFeature(f))) {
    return children;
  }

  if (isString(feature) && user.hasFeature(feature)) {
    return children;
  }

  return <></>;
}

export default UserFeatureComponent;
