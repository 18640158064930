import CustomSelect from '../../../components/common/custom-select';
import { getPageHierarchy } from '../../../components/common/PageHierarchy';
import { getSelectedOption } from '../../../../../Utils';
import React from 'react';
import { useParams } from 'react-router';
import { useGetPagesQuery, useGetOrphanPagesQuery } from '../../../../../common/api/apiSlice';

function PageSelectBox(props) {
  const { instanceId, siteId } = useParams();
  const { params, onChange, keyName } = props;

  const insId = params?.instance_id ? params?.instance_id : instanceId;

  const sId = params?.site_id ? params?.site_id : siteId;

  const { data: pages } = useGetPagesQuery({ instanceId: insId, siteId: sId });
  const { data: orphanPages } = useGetOrphanPagesQuery({ instanceId: insId, siteId: sId });

  const pageOptions = getPageHierarchy(pages, '');
  const orphanPageOptions = getPageHierarchy(orphanPages, '');

  const pageOptionData = [
    { label: 'Pages', options: pageOptions },
    { label: 'Orphan Pages', options: orphanPageOptions },
  ];

  return (
    <CustomSelect
      isClearable
      name={keyName}
      options={pageOptionData}
      className="flex-grow-1"
      onChange={(e) => onChange(e)}
      value={getSelectedOption([...pageOptions, ...orphanPageOptions], params[keyName])}
    />
  );
}

export default PageSelectBox;
