import { isInteger } from 'lodash-es';
const SearchExpressionValidator = (event, searchParams) => {
  const expressionStr = event.currentTarget.value;
  const matchOperands = expressionStr.match(/\d+/g)?.map(Number) || [];
  const matchOperators = expressionStr.replace(/AND|and/g, '+').replace(/OR|or/g, '-').match(/[+-]/g) || [];
  const fieldRowCount = searchParams.length;
  const errorMessage = { message: 'Invalid Expression!', activeError: false };
  const stack = [];

  // Validate parentheses and correct operand/operator structure
  for (let i = 0; i < expressionStr.length; i++) {
    const char = expressionStr[i];
    const prevChar = expressionStr[i - 1] || '';

    if (char === '(') {
      if (!isNaN(prevChar)) {
        errorMessage.message = 'An operator is required before an opening parenthesis.';
        errorMessage.activeError = true;
        break;
      }
      stack.push(char);
    } else if (char === ')') {
      if (stack.pop() !== '(') {
        errorMessage.message = 'Mismatched closing parenthesis.';
        errorMessage.activeError = true;
        break;
      }
    } else if (isInteger(Number(char)) && prevChar === '(') {
      errorMessage.message = 'Parentheses must contain an operator between operands.';
      errorMessage.activeError = true;
      break;
    }
  }

  // Final check for unmatched opening parentheses
  if (!errorMessage.activeError && stack.length > 0) {
    errorMessage.message = 'Unmatched opening parenthesis.';
    errorMessage.activeError = true;
  }

  // Validate operand/operator presence and constraints
  if (!errorMessage.activeError) {
    if (!matchOperands.length) {
      errorMessage.message = 'The expression must include operands.';
      errorMessage.activeError = true;
    } else if (matchOperands.some((num) => num > fieldRowCount || num === 0)) {
      errorMessage.message = 'Operands must be between 1 and the number of available fields.';
      errorMessage.activeError = true;
    } else if (matchOperators.length !== matchOperands.length - 1) {
      errorMessage.message = 'There must be exactly one operator between each pair of operands.';
      errorMessage.activeError = true;
    }
  }

  // Return error details if any errors found, otherwise return false
  if (errorMessage.activeError) {
    return { errorStatus: errorMessage.activeError, errorMessage, expressionStr };
  }

  return false;
};

export default SearchExpressionValidator;
