import React from 'react';
import CustomSelect from 'Dashboard/src/components/common/custom-select';
import iconList from 'Dashboard/src/icons-trimmed.json';
import { isEmpty, map } from 'lodash-es';
import { Button, ButtonGroup } from 'reactstrap';
import { getSelectedOption, selectColorStyles } from '../../../../Utils';

const IconSelector = (props) => {
  const {
    value,
    onChange,
    disableDuotone,
  } = props;

  const options = Object.keys(iconList).map((iconName) => {
    let prefix;
    if (iconList[iconName].styles && iconList[iconName].styles.length === 1 && iconList[iconName].styles[0] === 'brands') {
      prefix = 'fa-brands';
    } else {
      prefix = 'fa';
    }

    return {
      label: (
        <span>
          <i className={`me-2 ${prefix} fa-${iconName}`} />
          {' '}
          {iconList[iconName].label}
        </span>
      ),
      value: iconName,
      terms: iconList[iconName].search?.terms,
    };
  });

  options.unshift({ label: 'None', value: null, terms: [] });

  let selectedStyle = typeof value === 'string' ? value.split(' ')[0]?.replace('fa-', '') : null;
  const selectedIcon = typeof value === 'string' ? value.split(' ')[1]?.replace('fa-', '') : null;
  const selectedFamily = (typeof value === 'string' ? value.split(' ')[2]?.replace('fa-', '') : null) || 'classic';

  // duotone is only supported in the classic family
  if (selectedStyle === 'duotone' && selectedFamily !== 'classic') {
    selectedStyle = 'solid';
  }

  const iconsSearchFilter = (option, input) => {
    if (option.data.terms.includes(input)) {
      return true;
    }
    const candidate = `${option.label} ${option.value}`.toLowerCase();

    return candidate.indexOf(input.toLowerCase()) > -1;
  };

  const isBrandIcon = (icon) => iconList[icon]?.styles && iconList[icon]?.styles.length === 1 && iconList[icon]?.styles[0] === 'brands';

  const families = ['classic'];
  if (selectedStyle !== 'duotone') {
    families.push('sharp');
  }

  const styles = ['solid', 'regular', 'light', 'thin'];
  if (selectedFamily === 'classic' && !disableDuotone) {
    styles.push('duotone');
  }

  return (
    <>
      <CustomSelect
        isSearchable
        filterOption={iconsSearchFilter}
        onChange={(e) => {
          if (isEmpty(e.value)) {
            onChange(null);
          } else if (isBrandIcon(e.value)) {
            // Our regex requires the style in the first capture group, but brand icons don't have a style. We use "NotAStyle" as a placeholder.
            onChange(`fa-NotAStyle fa-${e.value} fa-brands`);
          } else {
            onChange(`fa-${selectedStyle} fa-${e.value} fa-${selectedFamily === 'brands' ? 'classic' : selectedFamily}`);
          }
        }}
        styles={selectColorStyles}
        options={options}
        value={getSelectedOption(options, selectedIcon)}
      />
      {selectedIcon && !isBrandIcon(selectedIcon) && (
        <>
          <br />
          <ButtonGroup className="me-2 mb-2">
            {map(families, (family, idx) => (
              <Button
                key={idx}
                color="primary"
                outline
                onClick={() => onChange(`fa-${selectedStyle} fa-${selectedIcon} fa-${family}`)}
                active={selectedFamily === family || (!selectedFamily && family === 'classic')}
                className="text-center"
                style={{ borderRadius: '0.75rem', paddingLeft: '0.75rem', paddingRight: '0.75rem' }}
              >
                {family}
                <br />
                <i className={`fa-${selectedStyle} fa-${selectedIcon} fa-${family}`} />
              </Button>
            ))}
          </ButtonGroup>
          <br />
          <ButtonGroup>
            {map(styles, (style, idx) => (
              <Button
                key={idx}
                color="primary"
                outline
                onClick={() => onChange(`fa-${style} fa-${selectedIcon} fa-${selectedFamily}`)}
                active={selectedStyle === style}
                className="text-center"
                style={{ borderRadius: '0.75rem', paddingLeft: '0.75rem', paddingRight: '0.75rem' }}
              >
                {style}
                <br />
                <i className={`fa-${style} fa-${selectedIcon} fa-${selectedFamily}`} />
              </Button>
            ))}
          </ButtonGroup>
        </>
      )}
    </>
  );
};

export default IconSelector;
