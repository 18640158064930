import React from 'react';
import {
  Label,
  FormGroup,
} from 'reactstrap';
import Switch from 'rc-switch';

function PopupStyle(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label>Include Bootstrap frame</Label>
      <Switch
        className="custom-switch custom-switch-primary"
        checked={params.popup_options.frame !== false}
        onChange={(e) => setParams({ ...params, popup_options: { ...params.popup_options, frame: e } })}
      />
    </FormGroup>
  );
}

export default PopupStyle;
