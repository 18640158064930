import React from 'react';
import {
  Input,
  Label,
  FormGroup,
  UncontrolledTooltip,
} from 'reactstrap';

function SiteDescription(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label className="has-tooltip" id="siteDescriptionHelp">Site Description</Label>
      <UncontrolledTooltip placement="right" target="siteDescriptionHelp" style={{ textAlign: 'left' }}>
        {`This is used on the business directory page,
        and is also appended to page titles to generate the page description.`}
      </UncontrolledTooltip>
      <Input
        type="textarea"
        value={params.site_description || ''}
        onChange={(e) => {
          const { value } = e.target;
          setParams({ ...params, site_description: value });
        }}
      />
    </FormGroup>
  );
}

export default SiteDescription;
