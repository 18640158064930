import React, { useState } from 'react';
import HeaderComponent from '../../../components/common/header-component';
import {
  Col,
  Row,
  Card,
  Form,
  Label,
  Button,
  CardBody,
  FormGroup,
} from 'reactstrap';
import { replacePathParams, settingRoutes, mainRoutes } from '../../../constants/routes';
import CustomSelect from '../../../components/common/custom-select';
import { filter, isEmpty } from 'lodash-es';
import { displayError, getSelectedOption } from '../../../../../Utils';
import { useGetInstancesQuery, useGetSitesQuery, useImportInstanceFromLegacyMutation } from '../../../../../common/api/apiSlice';
import UserFeaturePage from 'Dashboard/src/components/common/user-feature-page';
import withRouter from '../../../helpers/withRouter';

function ImportInstance(props) {
  const { navigate } = props;

  const [instanceId, setInstanceId] = useState(null);
  const [siteId, setSiteId] = useState(null);

  const { data: instances = [] } = useGetInstancesQuery();

  const instanceOptions = instances?.map((i) => ({ label: i.company_name, value: i.id, platform_status: i.platform_status }));

  const { data: sites } = useGetSitesQuery(instanceId, { skip: isEmpty(instanceId) });
  const siteOptions = sites?.map((s) => ({ label: s.label, value: s.id }));

  const [importInstanceFromLegacy] = useImportInstanceFromLegacyMutation();

  const commonPathParams = (path) => replacePathParams(path, [], props);

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: commonPathParams(settingRoutes.dashboard) },
    { name: 'Settings', url: commonPathParams(mainRoutes.setting) },
    { name: 'Import Instance From Legacy', url: '' },
  ];

  const onHandleSubmit = async () => {
    if (!instanceId) {
      displayError('Please select instance to import.');
    } else if (!siteId) {
      displayError('Please select site to import.');
    } else {
      importInstanceFromLegacy({ instanceId, siteId });

      navigate(replacePathParams(
        settingRoutes.backgroundJobs,
        [
          { key: 'instanceId', value: instanceId },
          { key: 'siteId', value: siteId },
          { key: 'pageNo', value: '1' },
        ],
        props,
      ));
    }
  };

  if (isEmpty(instances)) {
    return <></>;
  }

  return (
    <div>
      <HeaderComponent setPath={{
        headingName: 'Import Instance From Legacy',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: '',
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card>
        <CardBody>
          <UserFeaturePage feature="import_instance">
            <Form>
              <Row>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="display_name">
                      Instance to import
                      <span className="text-danger">*</span>
                    </Label>
                    <CustomSelect
                      isSearchable
                      className="flex-grow-1 me-2"
                      options={filter(instanceOptions, (i) => i.platform_status === 'old')}
                      value={getSelectedOption(instanceOptions, instanceId)}
                      onChange={(e) => setInstanceId(e.value)}
                    />
                  </FormGroup>
                </Col>
                {siteOptions && (
                  <Col lg={12}>
                    <FormGroup>
                      <Label for="display_name">
                        Site
                        <span className="text-danger">*</span>
                      </Label>
                      <CustomSelect
                        isSearchable
                        className="flex-grow-1 me-2"
                        options={siteOptions}
                        value={getSelectedOption(siteOptions, siteId)}
                        onChange={(e) => setSiteId(e.value)}
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col lg={12}>
                  <FormGroup>
                    <Button
                      color="success"
                      onClick={onHandleSubmit}
                    >
                      <b>Import</b>
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </UserFeaturePage>
        </CardBody>
      </Card>
    </div>
  );
}

export default withRouter(ImportInstance);
