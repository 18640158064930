import React from 'react';
import { Card, CardBody, Button, Row } from 'reactstrap';
import HeaderComponent from 'Dashboard/src/components/common/header-component';
import { settingRoutes, siteCenter, replacePathParams } from 'Dashboard/src/constants/routes';
import confirm from 'Dashboard/src/components/common/confirm';
import CustomButton from 'Dashboard/src/components/common/button';
import { preview as buttonPreview } from 'common/api/button';
import { get, isEmpty } from 'lodash-es';
import { useAddButtonStyleMutation, useDeleteButtonStyleMutation, useGetButtonStylesQuery } from 'common/api/apiSlice';
import { displaySuccess } from '../../../../../../Utils';
import BottomActionToolbar from 'Dashboard/src/components/common/toolbar';
import { addRecentItem } from '../../../../../../UtilsTS';
import { useInstance, useSite } from '../../../../../../common/hooks';
import withRouter from '../../../../helpers/withRouter';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

function Buttons(props) {
  const instance = useInstance();
  const site = useSite();
  const siteId = get(site, 'id', null);
  const instanceId = get(instance, 'id', null);

  const [addButtonStyle] = useAddButtonStyleMutation();
  const [deleteButtonStyle] = useDeleteButtonStyleMutation();

  const { data: buttonStyles } = useGetButtonStylesQuery(siteId, { skip: isEmpty(siteId) });

  const onCopyAction = async (button) => {
    const sendParams = {
      site_id: siteId,
      name: `Copy of ${button.name}`,
      css: button.css,
      hover_css: button.hover_css,
    };
    const result = await addButtonStyle(sendParams);
    if (result && result?.data?.meta?.is_success) {
      displaySuccess(result?.data?.meta?.messages);
    }
  };

  const onDeleteAction = async (button) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });
    if (result) {
      const result = await deleteButtonStyle({ site_id: siteId, id: button.id });
      if (result && result?.data?.meta?.is_success) {
        displaySuccess(result?.data?.meta?.messages);
      }
    }
  };

  const onHandleAdd = async () => {
    addButtonStyle({ site_id: siteId, name: 'New Button' });
  };

  const onEditAction = (button) => {
    const { navigate } = props;
    navigate(replacePathParams(
      siteCenter.editButton,
      [
        { key: 'id', value: button.id },
      ], props,
    ));
  };

  if (instanceId && siteId) {
    addRecentItem({
      instance_id: instanceId,
      site_id: siteId,
      type: 'buttons',
    });
  }

  const tableColumns = [
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Preview',
      accessorKey: 'preview',
      cell: ({ row }) => buttonPreview(row.original, get(site, 'colors', [])),
    },
    {
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => (
        <>
          <Button
            className="custom-simple-icon"
            color="primary"
            size="xs"
            onClick={() => onEditAction(row.original)}
          >
            <i className="fal fa-pen" />
          </Button>
          &nbsp;
          <Button
            className="custom-simple-icon"
            color="primary"
            size="xs"
            onClick={() => onCopyAction(row.original)}
          >
            <i className="fal fa-copy" />
          </Button>
          &nbsp;
          <Button
            className="custom-simple-icon"
            color="danger"
            size="xs"
            onClick={() => onDeleteAction(row.original)}
          >
            <i className="fal fa-trash" />
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Buttons',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Dashboard',
        backToPath: settingRoutes.dashboard,
        showBreadcrumb: false,
        staticBreadcrumbData: [
          {
            name: 'Dashboard',
            url: replacePathParams(settingRoutes.dashboard, [], props),
          },
          {
            name: 'Site Centre',
            url: replacePathParams(siteCenter.pageList, [], props),
          },
          {
            name: 'Design Options',
            url: replacePathParams(siteCenter.designOption, [], props),
          },
          { name: 'Buttons', url: '' },
        ],
      }}
      />
      <Card className="mb-4">
        <CardBody>
          <Row className="BreakPoint align-items-center">
            <ReactTable8 columns={tableColumns} data={buttonStyles || []} />
          </Row>
        </CardBody>
      </Card>
      <BottomActionToolbar
        component={(
          <CustomButton
            color="info"
            className="add-new-button button-lg fw-bold"
            title="ADD BUTTON STYLE"
            icon="fa fa-plus"
            onClick={() => onHandleAdd()}
          />
        )}
      />
    </>
  );
}

export default withRouter(Buttons);
