import React from 'react';
import {
  Input,
  Label,
  FormGroup,
} from 'reactstrap';

function DefaultFormMobileNumber(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label for="default_form_mobile_number">Default Form Mobile Number</Label>
      <Input
        type="text"
        name="default_form_mobile_number"
        id="default_form_mobile_number"
        value={params.default_form_mobile_number || ''}
        onChange={(e) => {
          const { value } = e.target;
          setParams({ ...params, default_form_mobile_number: value });
        }}
      />
    </FormGroup>
  );
}

export default DefaultFormMobileNumber;
