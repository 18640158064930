import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Row, Col, Label, Card, CardBody } from 'reactstrap';
import { cloneDeep, get, isEmpty, map } from 'lodash-es';
import { getSelectedOption, selectBoxOptions, selectColorStyles } from '../../../../../../Utils';
import withRouter from '../../../../helpers/withRouter';
import CustomSelect from '../../../../components/common/custom-select';
import { ComponentFields } from '../../design-options/components/component';
import {
  useGetPagesQuery,
  useGetOrphanPagesQuery,
  useGetSectionQuery,
  useGetSectionsQuery,
  useSectionUpdateMutation,
  useGetComponentQuery,
} from '../../../../../../common/api/apiSlice';
import PageSelectBox from '../../../../components/fields/page/PageSelectBox';
import AdvancedSettings from '../../../../components/common/advancedSettings';

function MirrorSection(props) {
  const {
    section: { id: sectionId },
    isEmailPage,
    saveCounter,
    params: { instanceId, siteId },
    updateStylesheet,
  } = props;

  const [tempSection, setTempSection] = useState(null);
  const [pId, setPageId] = useState(null);

  const { data: pages } = useGetPagesQuery({ instanceId, siteId }, { skip: isEmpty(instanceId) });
  const { data: orphanPages } = useGetOrphanPagesQuery({ instanceId, siteId }, { skip: isEmpty(instanceId) });

  const { data: section } = useGetSectionQuery({ instanceId, sectionId });

  const [sectionUpdate] = useSectionUpdateMutation();

  useEffect(() => {
    if (section) {
      setTempSection(cloneDeep(section));
    }
  }, [section]);

  const { data: mirroredSection } = useGetSectionQuery(
    { instanceId, sectionId: tempSection?.mirror_of_id },
    { skip: !tempSection?.mirror_of_id },
  );

  const componentId = get(mirroredSection, 'component_id', null);

  const { data: component } = useGetComponentQuery(componentId, { skip: isEmpty(componentId) });

  useEffect(() => {
    if (mirroredSection?.page_id && mirroredSection.page_id !== pId) {
      setPageId(mirroredSection.page_id);
    }
  }, [mirroredSection]);

  const overriddenHtmlVars = get(tempSection, 'overridden_html_vars', {});
  const overriddenScssVars = get(tempSection, 'overridden_scss_vars', {});

  const pageVersionId = (() => {
    if (!pages || !orphanPages) {
      return null;
    }

    const addChildren = (p) => [p, ...(p.children ? p.children.map((c) => addChildren(c)) : [])];
    const pageList = [...addChildren(pages[0]), ...orphanPages].flat(99);
    const page = pageList.find((p) => p.id === pId);
    if (page) {
      return page.page_version.id;
    }

    return null;
  })();

  const { data: sections } = useGetSectionsQuery({
    siteId, pageId: pId, pageVersionId: pageVersionId, includeContent: true,
  }, { skip: !pId || !pageVersionId });

  const onSave = async () => {
    await sectionUpdate({
      ...tempSection,
      instance_id: instanceId,
    });

    updateStylesheet();
  };

  useEffect(() => {
    if (saveCounter) {
      (async () => {
        await onSave();
      })();
    }
  }, [saveCounter]);

  const getSectionOptions = () => {
    if (!sections) {
      return [];
    }

    return [...sections]
      .filter((s) => !s.column_section_id)
      .sort((a, b) => (a.sector === b.sector ? (a.order - b.order) : (a.sector > b.sector ? 1 : -1)))
      .map((section) => {
        let labelName = '';
        const sectorName = `${section.sector} >> Section ${section.order}`;

        if (!section.content) {
          labelName = `${sectorName}: (Empty)`;
        } else if (!section.is_offline && section.content) {
          labelName = `${sectorName}: ${section.content}`;
        } else {
          labelName = `${sectorName}: (Offline)`;
        }

        return (
          {
            label: labelName,
            value: section.origin_id,
            type: section.type,
          }
        );
      });
  };

  useEffect(() => {
    if (mirroredSection?.type === 'SectionComponent') {
      setTempSection({
        ...tempSection,
        mirror_of_id: tempSection.mirror_of_id,
        overridden_html_vars: overriddenHtmlVars,
        overridden_scss_vars: overriddenScssVars,
      });
    }
  }, [mirroredSection?.type, tempSection?.mirror_of_id]);

  const onHandleSectionChange = (e) => {
    const { value, type } = e;
    if (type === 'SectionComponent') {
      setTempSection({
        ...tempSection,
        mirror_of_id: value,
        overridden_html_vars: overriddenHtmlVars,
        overridden_scss_vars: overriddenScssVars,
      });
    } else {
      setTempSection({
        ...tempSection,
        mirror_of_id: value,
      });
    }
  };

  const onChangeOverrideVars = (value, varType) => {
    let tempObj = {};
    const keyName = varType === 'html_vars' ? 'html_name' : 'scss_name';
    if (isEmpty(Object.keys(tempSection[`overridden_${varType}`]))) {
      tempObj = { [value[0][keyName]]: '' };
    } else {
      Object.keys(tempSection[`overridden_${varType}`]).forEach((ele) => {
        value.forEach((e) => {
          if (ele === e.value) {
            tempObj = { [ele]: tempSection[`overridden_${varType}`][ele], ...tempObj };
          } else {
            tempObj = { [e[keyName]]: '', ...tempObj };
          }
          if (e[keyName] in tempSection[`overridden_${varType}`]) {
            tempObj[e[keyName]] = tempSection[`overridden_${varType}`][e[keyName]];
          }
        });
      });
    }
    setTempSection({ ...tempSection, [`overridden_${varType}`]: tempObj });
  };

  const onChangeVariables = (e, styleName, varType) => {
    setTempSection({ ...tempSection, [`overridden_${varType}`]: { ...tempSection[`overridden_${varType}`], [styleName]: e } });
  };

  const renderVariables = (headingName, variableType) => {
    const keyName = variableType === 'html_vars' ? 'html_name' : 'scss_name';
    const overrideOptions = selectBoxOptions(component[variableType], 'name', 'type');
    const variables = Object.keys(tempSection[`overridden_${variableType}`]).filter((v) => !v.sub_item);
    return (
      <>
        <Row className="mb-4">
          <Col>
            <Label>
              Override
              {' '}
              {headingName}
              {' '}
              Variables:
            </Label>
            <CustomSelect
              isMulti
              className="w-100"
              onChange={(e) => onChangeOverrideVars(e, variableType)}
              options={overrideOptions}
              value={overrideOptions.filter((obj) => Object.keys(tempSection[`overridden_${variableType}`]).includes(obj[keyName]))}
            />
          </Col>
        </Row>
        <Row>
          {(map(variables, (style) => {
            const currentStyle = component[variableType].filter((ele) => ele[keyName] === style);
            return (
              <Col md={currentStyle?.[0]?.type === 'rich_text' ? 12 : 6} className="mb-4">
                <Label>{get(currentStyle, '[0].name', '')}</Label>
                <ComponentFields
                  params={props?.params}
                  item={get(currentStyle, '[0]', {})}
                  onChange={(value) => onChangeVariables(value, style, variableType)}
                  keyName={style}
                  state={tempSection[`overridden_${variableType}`]}
                />
              </Col>
            );
          }))}
        </Row>
      </>
    );
  };

  if (isEmpty(tempSection)) {
    return <></>;
  }

  return (
    <>
      <AdvancedSettings
        siteId={siteId}
        instanceId={instanceId}
        isEmailPage={isEmailPage}
        section={tempSection}
        setSection={setTempSection}
        handleSaveBreakpoint={(e) => setTempSection({ ...tempSection, break_point: e })}
        onHandleSectionIsOffline={() => setTempSection({ ...tempSection, is_offline: !tempSection?.is_offline })}
        onChangeInitialVisibility={() => setTempSection({ ...tempSection, initial_visibility: !tempSection?.initial_visibility })}
        onHandleChangeVisibility={(e) => setTempSection({ ...tempSection, visible_to: e.value })}
      />
      <Row className="mt-4">
        <Col>
          <Label className="w-100">
            Page:
            <PageSelectBox
              params={{ page_id: pId, instance_id: instanceId, site_id: siteId }}
              keyName="page_id"
              onChange={(e) => setPageId(e ? e.value : null)}
            />
          </Label>
        </Col>
        <Col>
          <Label className="w-100">
            Section:
            <Select
              options={getSectionOptions()}
              onChange={onHandleSectionChange}
              value={getSelectedOption(getSectionOptions(), tempSection.mirror_of_id)}
              styles={selectColorStyles}
            />
          </Label>
        </Col>
      </Row>
      {!isEmpty(component) && !isEmpty(mirroredSection?.component_id) && mirroredSection?.type === 'SectionComponent' && (
        <Row className="mt-4">
          <Col>
            <Card>
              <CardBody>
                {renderVariables('HTML', 'html_vars')}
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardBody>
                {renderVariables('SCSS', 'scss_vars')}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

export default withRouter(MirrorSection);
