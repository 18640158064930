import React from 'react';
import {
  Input,
  Label,
  FormGroup,
} from 'reactstrap';

export function GoogleTagManagerId(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label for="google_tag_manager_id">Google Tag Manager ID</Label>
      <Input
        type="text"
        value={params.google_tag_manager_id || ''}
        onChange={(e) => {
          const { value } = e.target;
          setParams({ ...params, google_tag_manager_id: value.trim() });
        }}
      />
    </FormGroup>
  );
}

export function GoogleAdsAccountId(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label for="google_ads_account_id">Google Ads Account ID</Label>
      <Input
        type="text"
        value={params.google_ads_account_id || ''}
        onChange={(e) => {
          const { value } = e.target;
          setParams({ ...params, google_ads_account_id: value.trim() });
        }}
      />
    </FormGroup>
  );
}
