import React from 'react';
import { get } from 'lodash-es';
import {
  DefaultFormEmailAddresses,
  DefaultFormEmailSubject,
  PriceRange,
  MicrodataLogo,
  MicrodataImage,
  ReCAPTCHAThreshold,
  GoogleAnalyticsProperty,
  GoogleSiteVerification,
  PhoneNumber,
  DisplayedPhoneNumber,
  TrackingPhoneNumbers,
  GoogleReviewEngine,
  GoogleTagManagerId,
  GoogleAdsAccountId,
  InitialVisibilityPoints,
  InitialVisibilityPercentage,
  SiteDescription,
  TimeZone,
  MediaSelectBox,
  OrientationStatement,
  DefaultFormMobileNumber,
  MasterPageSelectBox,
  PrivacyPolicyText,
  LibraryVersions,
  UnsubscribePage,
  SiteDisplayName,
  InstantContactSettings,
} from '../components/fields/site/';
import InstanceName from '../components/fields/instance/InstanceName';
import {
  siteCenter,
  dataCenter,
  settingRoutes,
  deliveryCenterRoutes,
  replacePathParams,
  mediaCenterRoutes,
  statsCenterRoutes,
  contentCenterRoutes,
  reviewCenterRoutes,
  messageCenterRoutes,
} from './routes';
import SMSName from '../components/fields/instance/SMSName';
import { FormGroup, Label } from 'reactstrap';
import { useInstance, useIsMobile } from '../../../common/hooks';

const useMenuData = (props, user, recentItems, siteId) => {
  const instance = useInstance();
  const instanceId = get(instance, 'instance.id', '');

  const isMobile = useIsMobile();

  const iconMap = {
    'add-member':                    'fal fa-user',
    'edit-member':                   'fal fa-user-edit',
    'edit-email':                    'fal fa-envelope',
    'edit-page':                     'fal fa-page',
    'site-center':                   'fal fa-sitemap',
    'stats-center':                  'fal fa-chart-line',
    'content-center':                'fal fa-file-lines',
    'templates':                     'fal fa-file-code',
    'edit-template':                 'fal fa-file-code',
    'create-fragment':               'fal fa-book-open',
    'edit-fragment':                 'fal fa-book-open',
    'data-center':                   'fal fa-database',
    'all-members':                   'fal fa-users',
    'search-results':                'fal fa-user-friends',
    'style-overrides':               'fal fa-pencil-paintbrush',
    'text-styles':                   'fal fa-magic',
    'edit-text-style':               'fal fa-magic',
    'add-text-style':                'fal fa-magic',
    'colour-palette':                'fal fa-palette',
    'buttons':                       'fal fa-rectangle',
    'edit-button':                   'fal fa-rectangle',
    'custom-codes':                  'fal fa-pen-to-square',
    'add-custom-code':               'fal fa-pen-to-square',
    'edit-custom-code':              'fal fa-pen-to-square',
    'design-options':                'fal fa-diamond',
    'business-directory':            'fal fa-book',
    'edit-all-forms':                'fal fa-pen-field',
    'settings-general':              'fal fa-cog',
    'settings-products':             'fal fa-bag-shopping',
    'settings-phone-number':         'fal fa-phone',
    'settings-seo':                  'fal fa-display-chart-up',
    'settings-google-analytics':     'fal fa-chart-mixed',
    'settings-google-services':      'fab fa-google',
    'settings-google-review-engine': 'fal fa-star-sharp',
    'settings-recaptcha':            'fal fa-robot',
    'settings-instant-contact':      'fal fa-comments',
    'settings-privacy-policy':       'fal fa-shield-halved',
    'settings-popup':                'fal fa-browser',
    'settings-site-text-search':     'fal fa-magnifying-glass',
    'media-center':                  'fal fa-image',
    'media-center-folder':           'fal fa-image',
    'review-center':                 'fal fa-star-sharp',
    'domains':                       'fal fa-globe',
  };

  const items = [
    {
      id: 'recent',
      sideNav: true,
      display: () => true,
      icon: 'fal fa-clock-rotate-left',
      label: 'menu.recentItems',
      to: false,
      activeRoutes: [],
      subs: recentItems?.map((item) => ({
        id: `${item.type}-${item.item_id}`,
        icon: iconMap[item.type],
        label: item.friendly_name,
        intl: false,
        to: item.path,
        activeRoutes: false,
      })),
    },
    {
      id: 'dashboards',
      sideNav: true,
      display: () => true,
      icon: 'fal fa-tachometer-alt',
      label: 'menu.dashboards',
      to: replacePathParams(settingRoutes.dashboard, [], props),
      activeRoutes: [settingRoutes.dashboard],
    },
    {
      id: 'site-centre',
      sideNav: true,
      display: () => !isMobile && user?.hasModule('site'),
      icon: 'fal fa-sitemap',
      label: 'menu.sitecenter',
      to: replacePathParams(siteCenter.pageList, [], props),
      subs: [
        {
          id: 'pages',
          icon: 'fal fa-files',
          label: 'menu.pages',
          to: replacePathParams(siteCenter.pageList, [], props),
          activeRoutes: [
            siteCenter.pageList,
            siteCenter.pageVersionEdit,
            siteCenter.pageVersionHistory,
          ],
        },
        {
          id: 'domains',
          display: () => !isMobile && user?.hasFeature('domain_management'),
          icon: 'fal fa-globe',
          label: 'menu.domains',
          to: replacePathParams(siteCenter.domainList, [], props),
          activeRoutes: [siteCenter.domainList],
        },
        {
          id: 'design-options',
          display: () => !isMobile && user?.hasFeature('design_options'),
          icon: 'fal fa-diamond',
          label: 'menu.designOptions',
          to: replacePathParams(siteCenter.designOption, [], props),
          activeRoutes: [
            siteCenter.designOption,
            siteCenter.templateList,
            siteCenter.editTemplate,
            siteCenter.templateFragmentList,
            siteCenter.addTemplateFragment,
            siteCenter.editTemplateFragment,
            siteCenter.textStyleList,
            siteCenter.addTextStyle,
            siteCenter.editTextStyle,
            siteCenter.colourPalette,
            siteCenter.buttons,
            siteCenter.editButton,
            siteCenter.styleOverrides,
          ],
        },
        {
          id: 'business-directory',
          display: () => !isMobile && user?.hasFeature('manage_business_directory_listing'),
          icon: 'fal fa-book',
          label: 'menu.businessDirectory',
          to: replacePathParams(siteCenter.businessDirectory, [], props),
          activeRoutes: [siteCenter.businessDirectory],
        },
        {
          id: 'all-forms',
          display: () => !isMobile && user?.hasFeature('edit_all_forms'),
          icon: 'fal fa-pen-field',
          label: 'menu.edit-all-forms',
          to: replacePathParams(siteCenter.editAllForms, [], props),
          activeRoutes: [siteCenter.editAllForms],
        },
        {
          id: 'setting',
          icon: 'fal fa-cog',
          label: 'menu.setting',
          subs: [
            {
              display: () => !isMobile && user?.hasFeature([
                'general_site_settings',
                'set_default_form_email_addresses',
                'set_default_form_subject',
                'set_price_range',
              ]),
              icon: 'fal fa-cog',
              label: 'menu.general',
              to: replacePathParams(settingRoutes.siteSettingsGeneral, [], props),
            },
            {
              display: () => !isMobile && user?.hasFeature('site_products'),
              icon: 'fal fa-bag-shopping',
              label: 'menu.products',
              to: replacePathParams(settingRoutes.siteSettingsProducts, [], props),
            },
            {
              display: () => !isMobile && user?.hasFeature('set_phone_numbers'),
              icon: 'fal fa-phone',
              label: 'menu.phone-numbers',
              to: replacePathParams(settingRoutes.siteSettingsPhoneNumbers, [], props),
            },
            {
              display: () => !isMobile && user?.hasFeature([
                'general_site_settings',
                'seo_settings',
                'set_price_range',
                'set_microdata',
              ]),
              icon: 'fal fa-display-chart-up',
              label: 'menu.seo',
              to: replacePathParams(settingRoutes.siteSettingsSEO, [], props),
            },
            {
              display: () => !isMobile && user?.hasFeature('list_all_google_analytics_properties'),
              icon: 'fal fa-chart-mixed',
              label: 'menu.google-analytics',
              to: replacePathParams(settingRoutes.siteSettingsGoogleAnalytics, [], props),
            },
            {
              display: () => !isMobile && user?.hasFeature('other_google_services'),
              icon: 'fab fa-google',
              label: 'menu.other-google-services',
              to: replacePathParams(settingRoutes.siteSettingsOtherGoogleServices, [], props),
            },
            {
              display: () => !isMobile && user?.hasModule('review') && user?.hasFeature('manage_gre'),
              icon: 'fal fa-star-sharp',
              label: 'menu.google-review-engine',
              to: replacePathParams(settingRoutes.siteSettingsGoogleReviewEngine, [], props),
            },
            {
              display: () => !isMobile && user?.hasFeature('recaptcha_settings'),
              icon: 'fal fa-robot',
              label: 'menu.reCAPTCHA',
              to: replacePathParams(settingRoutes.siteSettingsReCAPTCHA, [], props),
            },
            {
              display: () => !isMobile && user?.hasFeature('instant_contact_form_settings'),
              icon: 'fal fa-comments',
              label: 'menu.instant-contact',
              to: replacePathParams(settingRoutes.siteSettingsInstantContact, [], props),
            },
            {
              display: () => !isMobile && user?.hasFeature('privacy_policy_text'),
              icon: 'fal fa-shield-halved',
              label: 'menu.privacy-policy',
              to: replacePathParams(settingRoutes.siteSettingsPrivacyPolicy, [], props),
            },
            {
              display: () => !isMobile && user?.hasFeature('manage_site_wide_popup'),
              icon: 'fal fa-browser',
              label: 'menu.popup',
              to: replacePathParams(settingRoutes.siteSettingsPopup, [], props),
            },
            {
              display: () => !isMobile && user?.hasFeature('manage_site_text_search'),
              icon: 'fal fa-magnifying-glass',
              label: 'menu.site-text-search',
              to: replacePathParams(settingRoutes.siteSettingsSiteTextSearch, [], props),
            },
          ],
        },
      ],
    },
    {
      id: 'content-centre',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('content_briefings'),
      icon: 'fal fa-file-lines',
      label: 'menu.contentcenter',
      to: replacePathParams(contentCenterRoutes.contentCenter, [], props),
      activeRoutes: [contentCenterRoutes.contentCenter],
    },
    {
      id: 'stats-centre',
      sideNav: true,
      display: () => user?.hasModule('stats'),
      icon: 'fal fa-chart-line',
      label: 'menu.statscenter',
      to: replacePathParams(statsCenterRoutes.statsCenter, [], props),
      activeRoutes: [statsCenterRoutes.statsCenter],
    },
    {
      id: 'data-centre',
      sideNav: true,
      display: () => user?.hasModule('data'),
      icon: 'fal fa-database',
      label: 'menu.datacenter',
      to: '#',
      subs: [
        {
          id: 'add-member',
          icon: 'fal fa-fw fa-user',
          label: 'menu.add-member',
          to: replacePathParams(dataCenter.addMember, [], props),
          activeRoutes: [dataCenter.addMember],
        },
        {
          id: 'all-members',
          icon: 'fal fa-fw fa-users',
          label: 'menu.all-members',
          to: replacePathParams(dataCenter.allMembers, [
            { key: 'pageNo', value: '1' },
          ], props),
          activeRoutes: [dataCenter.allMembers],
        },
        {
          id: 'search',
          icon: 'fal fa-fw fa-user-friends',
          label: 'menu.member-search',
          to: replacePathParams(dataCenter.memberSearch, [], props),
          activeRoutes: [
            dataCenter.showMemberSearch,
            dataCenter.memberSearch,
            dataCenter.memberSearchById,
          ],
        },
        {
          id: 'saved-searches',
          icon: 'fal fa-fw fa-search',
          label: 'menu.saved-searches',
          to: replacePathParams(dataCenter.savedSearch, [], props),
          activeRoutes: [dataCenter.savedSearch],
        },
        {
          id: 'database-fields',
          icon: 'fal fa-fw fa-database',
          label: 'menu.databasefields',
          to: replacePathParams(dataCenter.databaseFields, [], props),
          activeRoutes: [
            dataCenter.databaseFields,
            dataCenter.editDatabaseField,
          ],
        },
        {
          id: 'import-members',
          icon: 'fal fa-fw fa-file-import',
          label: 'menu.importMembers',
          to: replacePathParams(dataCenter.importMembers, [], props),
        },
      ],
    },
    {
      id: 'media-centre',
      sideNav: true,
      display: () => !isMobile && user?.hasModule('media'),
      icon: 'fal fa-image',
      label: 'menu.mediacenter',
      to: replacePathParams(mediaCenterRoutes.mediaCenterDefault, [
        { key: 'pageNo', value: '1' },
      ], props),
      activeRoutes: [
        mediaCenterRoutes.mediaCenterDefault,
        mediaCenterRoutes.mediaCenterFolder,
        mediaCenterRoutes.mediaCenter,
      ],
    },
    {
      id: 'message-centre',
      sideNav: true,
      display: () => !isMobile && user?.hasModule('message'),
      icon: 'fal fa-envelope',
      label: 'menu.messagecenter',
      to: replacePathParams(messageCenterRoutes.messageCenter, [], props),
      activeRoutes: [
        messageCenterRoutes.messageCenter,
        messageCenterRoutes.messageCenterEmailPage,
      ],
    },
    {
      id: 'delivery-centre',
      sideNav: true,
      display: () => !isMobile && user?.hasModule('delivery'),
      icon: 'fal fa-paper-plane',
      label: 'menu.deliverycenter',
      to: '#',
      activeRoutes: [deliveryCenterRoutes.deliveryCenter],
      subs: [
        {
          id: 'email-series',
          icon: 'fal fa-envelope-open',
          label: 'menu.emailseries',
          to: replacePathParams(deliveryCenterRoutes.emailSeries, [], props),
          activeRoutes: [
            deliveryCenterRoutes.emailSeries,
            deliveryCenterRoutes.editSeries,
          ],
        },
        {
          id: 'bulk-email',
          icon: 'fal fa-envelopes-bulk',
          label: 'menu.bulk-email',
          to: replacePathParams(deliveryCenterRoutes.bulkEmail, [], props),
          activeRoutes: [deliveryCenterRoutes.bulkEmail],
        },
      ],
    },
    {
      id: 'review-centre',
      sideNav: true,
      display: () => !isMobile && user?.hasModule('review'),
      icon: 'fal fa-stars',
      label: 'menu.reviewcenter',
      to: replacePathParams(reviewCenterRoutes.reviewCenter, [], props),
      activeRoutes: [reviewCenterRoutes.reviewCenter],
    },
    {
      id: 'default-form-email-addresses',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('set_default_form_email_addresses'),
      icon: 'fal fa-at',
      label: 'menu.default-form-email-addresses',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <DefaultFormEmailAddresses
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'default-form-email-subject',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('set_default_form_subject'),
      icon: 'fal fa-at',
      label: 'menu.default-form-email-subject',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <DefaultFormEmailSubject
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'price-range',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('set_price_range'),
      icon: 'fal fa-dollar-sign',
      label: 'menu.price-range',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <PriceRange
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'microdata-logo',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('set_microdata'),
      icon: 'fal fa-image',
      label: 'menu.microdata-logo',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <MicrodataLogo
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'microdata-image',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('set_microdata'),
      icon: 'fal fa-image',
      label: 'menu.microdata-image',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <MicrodataImage
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'google-analytics-property',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('list_all_google_analytics_properties'),
      icon: 'fal fa-chart-mixed',
      label: 'menu.google-analytics-property',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <GoogleAnalyticsProperty
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'google-site-verification',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('other_google_services'),
      icon: 'fa-brands fa-google',
      label: 'menu.google-site-verification',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <GoogleSiteVerification
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'phone-number',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('set_phone_numbers'),
      icon: 'fal fa-phone',
      label: 'menu.phone-number',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <PhoneNumber
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'displayed-phone-number',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('set_phone_numbers'),
      icon: 'fal fa-phone',
      label: 'menu.displayed-phone-number',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <DisplayedPhoneNumber
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'tracking-phone-numbers',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('set_phone_numbers'),
      icon: 'fal fa-phone',
      label: 'menu.tracking-phone-numbers',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <TrackingPhoneNumbers
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'google-review-engine',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('manage_gre'),
      icon: 'fal fa-stars',
      label: 'menu.google-review-engine',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <GoogleReviewEngine
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'google-tag-manager-id',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('other_google_services'),
      icon: 'fal fa-tag',
      label: 'menu.google-tag-manager-id',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <GoogleTagManagerId
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'google-ads-account-id',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('other_google_services'),
      icon: 'fal fa-rectangle-ad',
      label: 'menu.google-ads-account-id',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <GoogleAdsAccountId
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'recaptcha-settings',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('recaptcha_settings'),
      icon: 'fal fa-robot',
      label: 'menu.recaptcha-settings',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <ReCAPTCHAThreshold
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'initial-visibility-points',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('seo_settings'),
      icon: 'fal fa-eye',
      label: 'menu.initial-visibility-points',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <InitialVisibilityPoints
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'initial-visibility-percentage',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('seo_settings'),
      icon: 'fal fa-eye',
      label: 'menu.initial-visibility-percentage',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <InitialVisibilityPercentage
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'site-description',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('general_site_settings'),
      icon: 'fal fa-circle-info',
      label: 'menu.site-description',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <SiteDescription
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'time-zone',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('general_site_settings'),
      icon: 'fal fa-location-dot',
      label: 'menu.time-zone',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <TimeZone
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'favicon-icon',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('general_site_settings'),
      icon: 'fal fa-image',
      label: 'menu.favicon-icon',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <FormGroup>
          <Label>Favourite Icon</Label>
          <MediaSelectBox
            value={siteParams?.favicon_id}
            onchange={(e) => setSiteParams({ ...siteParams, default_form_email_addresses: e ? e.value : null })}
          />
        </FormGroup>
      ),
    },
    {
      id: 'orientation-statement',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('general_site_settings'),
      icon: 'fal fa-table-pivot',
      label: 'menu.orientation-statement',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <OrientationStatement
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'form-mobile-number',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('set_default_form_email_addresses'),
      icon: 'fal fa-mobile',
      label: 'menu.form-mobile-number',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <DefaultFormMobileNumber
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'site-map-master-page',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('general_site_settings'),
      icon: 'fal fa-page',
      label: 'menu.site-map-master-page',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <MasterPageSelectBox
          siteId={siteId}
          params={siteParams}
          setParams={setSiteParams}
          instanceId={instanceId}
          keyName="site_map_master_page_id"
        />
      ),
    },
    {
      id: 'privacy-policy-master-page',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('privacy_policy_text'),
      icon: 'fal fa-page',
      label: 'menu.privacy-policy-master-page',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <MasterPageSelectBox
          siteId={siteId}
          params={siteParams}
          setParams={setSiteParams}
          instanceId={instanceId}
          keyName="privacy_policy_master_page_id"
        />
      ),
    },
    {
      id: 'privacy-policy-text',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('privacy_policy_text'),
      icon: 'fal fa-text',
      label: 'menu.privacy-policy-text',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <PrivacyPolicyText
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'library-versions',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('design_options'),
      icon: 'fal fa-code-branch',
      label: 'menu.library-versions',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <LibraryVersions
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'unsubscribe-page',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('general_site_settings'),
      icon: 'fal fa-rocket',
      label: 'menu.unsubscribe-page',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <UnsubscribePage
          params={siteParams}
          setParams={setSiteParams}
          siteId={siteId}
          instanceId={instanceId}
        />
      ),
    },
    {
      id: 'instance-name',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('set_instance_name'),
      icon: 'fal fa-file-signature',
      label: 'menu.instance-name',
      component: (instanceParams, setInstanceParams) => (
        <InstanceName
          params={instanceParams}
          setParams={setInstanceParams}
        />
      ),
    },
    {
      id: 'site-display-name',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('general_site_settings'),
      icon: 'fal fa-file-signature',
      label: 'menu.site-display-name',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <SiteDisplayName
          params={siteParams}
          setParams={setSiteParams}
        />
      ),
    },
    {
      id: 'site-text-search-master-page',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('manage_site_text_search'),
      icon: 'fal fa-page',
      label: 'menu.site-text-search-master-page',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <MasterPageSelectBox
          siteId={siteId}
          params={siteParams}
          setParams={setSiteParams}
          instanceId={instanceId}
          keyName="site_search_master_page_id"
        />
      ),
    },
    {
      id: 'instant-contact-settings',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('instant_contact_form_settings'),
      icon: 'fal fa-page',
      label: 'menu.instant-contact-settings',
      component: (instanceParams, setInstanceParams, siteParams, setSiteParams) => (
        <InstantContactSettings params={siteParams} setParams={setSiteParams} />
      ),
    },
    {
      id: 'instance-sms-name',
      sideNav: false,
      display: () => !isMobile && user?.hasFeature('set_instance_name'),
      icon: 'fal fa-file-signature',
      label: 'menu.instance-sms-name',
      component: (instanceParams, setInstanceParams) => (
        <SMSName params={instanceParams} setParams={setInstanceParams} />
      ),
    },

  ];

  return items
    .filter((i) => i.display())
    .map((i) => {
      if (i.subs) {
        i.subs = i.subs
          .filter((s) => s.display ? s.display() : true)
          .map((s) => {
            s.subs = s.subs?.filter((s) => s.display ? s.display() : true);
            return s;
          });
      }

      return i;
    });
};

export default useMenuData;
