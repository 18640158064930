import React from 'react';
import CustomSelect from 'Dashboard/src/components/common/custom-select';
import { isEmpty, map, get, filter } from 'lodash-es';
import CustomButton from 'Dashboard/src/components/common/button';
import { Container, Col, InputGroup, Label, Row } from 'reactstrap';
import { getSelectedOption, selectBoxOptions } from '../../../../../../Utils';
import CheckBox from 'Dashboard/src/components/common/checkBox';
import './StyleOverrideRow.scss';
import {
  BorderStyles,
  DisplayStyles,
  FontStyles,
  FontWeight,
  TextAlignStyle,
  TextDecorationStyle,
} from 'Dashboard/src/components/common/textStyles';
import { numberFieldWithUnitSelector } from 'Dashboard/src/views/site-centre/design-options/components/component';
import ColorPicker from 'Dashboard/src/components/common/color-picker';

const elementOptions = [
  { label: 'Sector', value: 'Sector' },
  { label: 'Text', value: 'Text' },
  { label: 'Links', value: 'Links' },
  { label: 'Links (hover)', value: 'LinksHover' },
  { label: 'Buttons', value: 'Buttons' },
];

const styleOptions = [
  { label: 'Colour (text)', value: 'color' },
  { label: 'Background colour', value: 'background' },
  { label: 'Display', value: 'display' },
  { label: 'Font weight', value: 'font-weight' },
  { label: 'Font style', value: 'font-style' },
  { label: 'Text align', value: 'text-align' },
  { label: 'Opacity', value: 'opacity' },
  { label: 'Border width', value: 'border-width' },
  { label: 'Border style', value: 'border-style' },
  { label: 'Text decoration line', value: 'text-decoration-line' },
  { label: 'Text decoration style', value: 'text-decoration-style' },
  { label: 'Text decoration colour', value: 'text-decoration-color' },
  { label: 'Text align', value: 'text-align' },
];

const textDecorationLineOptions = [
  { label: 'none', value: 'none' },
  { label: 'underline', value: 'underline' },
];

const getValueElement = (
  index,
  settingName,
  settingValue,
  onHandleChange,
) => {
  switch (settingName) {
    case 'opacity':
    case 'border-width':
      return (
        numberFieldWithUnitSelector(settingValue, (e) => onHandleChange(e, index, 'all_styles', settingName))
      );
    case 'display':
      return (
        <DisplayStyles
          className="w-100"
          value={settingValue}
          onChange={(e) => onHandleChange(e.value, index, 'all_styles', settingName)}
        />
      );
    case 'border-style':
      return (
        <BorderStyles
          className="w-100"
          value={settingValue}
          onChange={(e) => onHandleChange(e.value, index, 'all_styles', settingName)}
        />
      );
    case 'text-decoration-line':
      return (
        <CustomSelect
          className="w-100"
          options={textDecorationLineOptions}
          onChange={(e) => onHandleChange(e.value, index, 'all_styles', settingName)}
          value={getSelectedOption(textDecorationLineOptions, settingValue)}
        />
      );
    case 'text-decoration-style':
      return (
        <TextDecorationStyle
          className="w-100"
          value={settingValue}
          onChange={(e) => onHandleChange(e.value, index, 'all_styles', settingName)}
        />
      );
    case 'text-align':
      return (
        <TextAlignStyle
          className="w-100"
          value={settingValue}
          onChange={(e) => onHandleChange(e.value, index, 'all_styles', settingName)}
        />
      );
    case 'font-style':
      return (
        <FontStyles
          className="w-100"
          value={settingValue}
          onChange={(e) => onHandleChange(e.value, index, 'all_styles', settingName)}
        />
      );
    case 'font-weight':
      return (
        <FontWeight
          className="w-100"
          value={settingValue}
          onChange={(e) => onHandleChange(e.value, index, 'all_styles', settingName)}
        />
      );
    case 'color':
    case 'background':
    case 'text-decoration-color':
      return (
        <ColorPicker
          color={get({ settingValue }, 'settingValue', '')}
          onChange={(e) => onHandleChange(e.target.value, index, 'all_styles', settingName)}
          onDeleteValue={() => onHandleChange(null, index, 'all_styles', settingName)}
        />
      );
    default:
      return <></>;
  }
};

function StyleOverrideRow(props) {
  const {
    styles,
    templates,
    site,
    onHandleChange,
    onHandleRemoveRow,
    onHandleMultiDropdownChange,
  } = props;

  if (isEmpty(styles)) {
    return (
      <Row className="mt-4">
        <Col>
          No Style Overrides found, click the &quot;ADD OVERRIDE&quot; button below to add.
        </Col>
      </Row>
    );
  }

  const templateOptions = [{ label: 'All', value: -1 }, ...selectBoxOptions(templates, 'name', 'id')];

  return map(styles, (row, index) => {
    let selectedTemplates;

    if (isEmpty(row.template_ids)) {
      selectedTemplates = [];
    } else if (row.template_ids.indexOf(-1) > -1) {
      selectedTemplates = [{ label: 'All', value: -1 }];
    } else {
      selectedTemplates = templateOptions.filter((t) => row.template_ids.includes(t.value));
    }

    const sectors = new Set();

    if (isEmpty(row.template_ids) || row.template_ids.indexOf(-1) > -1) {
      templates.forEach((t) => t.sectors.forEach((s) => sectors.add(s)));
    } else {
      filter(templates, (t) => row.template_ids.indexOf(t.id) > -1).forEach((t) => t.sectors.forEach((s) => sectors.add(s)));
    }

    const sectorOptions = [{ label: 'All', value: -1 }, ...Array.from(sectors).map((s) => ({ label: s, value: s }))];

    let selectedSectors;

    if (isEmpty(row.sector)) {
      selectedSectors = [];
    } else if (row.sector.indexOf(-1) > -1) {
      selectedSectors = [{ label: 'All', value: -1 }];
    } else {
      selectedSectors = sectorOptions.filter((s) => row.sector.includes(s.value));
    }

    return (
      <Row key={index} className="StyleOverrideRow border-bottom pb-4">
        <Col md={11} className="mt-4">
          <Container fluid>
            <Row>
              <Col>
                <CustomSelect
                  isMulti
                  options={templateOptions}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  value={selectedTemplates}
                  onChange={(e) => onHandleMultiDropdownChange(e, index, 'template_ids')}
                />
              </Col>
              <Col>
                <CustomSelect
                  isMulti
                  options={sectorOptions}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  value={selectedSectors}
                  onChange={(e) => onHandleMultiDropdownChange(e, index, 'sector')}
                />
              </Col>
              <Col>
                <CustomSelect
                  options={elementOptions}
                  onChange={(e) => onHandleChange(e.value, index, 'element')}
                  value={getSelectedOption(elementOptions, row.element)}
                />
              </Col>
              <Col>
                <CustomSelect
                  isMulti
                  options={styleOptions}
                  onChange={(e) => onHandleChange(e, index, 'all_styles')}
                  value={styleOptions.filter((obj) => Object.keys(row.all_styles).includes(obj.value))}
                />
              </Col>
              <Col md={12}>
                <Row>
                  {map(Object.keys(row.all_styles), (style) => (
                    <Col key={style} md={3}>
                      <div>
                        <Label className="me-3">{style}</Label>
                        <CheckBox
                          title="Important"
                          checked={row.all_styles[style]?.[1]}
                          onChange={() => onHandleChange(
                            !row.all_styles[style]?.[1], index, 'all_styles', style, true,
                          )}
                        />
                      </div>
                      <InputGroup>
                        {getValueElement(
                          index,
                          style,
                          row.all_styles[style]?.[0],
                          onHandleChange,
                          site,
                        )}
                      </InputGroup>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          <CustomButton
            size="xs"
            color="danger"
            title=""
            onClick={() => onHandleRemoveRow(row.id || null, index)}
            icon="fas fa-times-circle"
          />
        </Col>
      </Row>
    );
  });
}

export default StyleOverrideRow;
