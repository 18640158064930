import React from 'react';
import { default as SectionCustomModel } from 'common/models/SectionCustom';

function SectionCustom({ section, onClick }: { section: SectionCustomModel; onClick: any }) {
  if (section.html) {
    // The server-side code returns the full section HTML including the <a> and <section> tags.
    return (
      <div
        dangerouslySetInnerHTML={{ __html: section.html }}
        onClick={() => onClick(section)}
      />
    );
  }

  return <></>;
}

export default SectionCustom;
