import React, { useState } from 'react';
import { Card, Alert, Button, CardBody, Input, Modal, ModalHeader, ModalBody, ModalFooter, Accordion } from 'reactstrap';
import { displayError, displaySuccess } from '../../../../../Utils';
import HeaderComponent from 'Dashboard/src/components/common/header-component';
import { replacePathParams, settingRoutes, siteCenter } from 'Dashboard/src/constants/routes';
import CustomButton from 'Dashboard/src/components/common/button';
import BottomActionToolbar from 'Dashboard/src/components/common/toolbar';
import { get, isEmpty } from 'lodash-es';
import withRouter from 'Dashboard/src/helpers/withRouter';
import DomainListingDomain from './DomainListingDomain';
import './DomainListing.scss';
import {
  useGetDomainsQuery,
  useAddNewDomainMutation,
} from 'common/api/apiSlice';
import { ParseResultType, parseDomain } from 'parse-domain';
import { useInstance, useSite } from '../../../../../common/hooks';
import UserFeaturePage from '../../../components/common/user-feature-page';
import { addRecentItem } from '../../../../../UtilsTS';

const validateDomain = (value) => {
  const parseResult = parseDomain(value);
  if (isEmpty(value) || parseResult.type !== ParseResultType.Listed) {
    return { ...parseResult, invalid: true };
  }
  return { ...parseResult, invalid: false };
};

function DomainListing(props) {
  const site = useSite();
  const instance = useInstance();
  const siteId = get(site, 'id', null);
  const { data: domains = [] } = useGetDomainsQuery(siteId, { skip: isEmpty(siteId) });
  const [addNewDomain] = useAddNewDomainMutation();
  const [modal, setModal] = useState(false);
  const [domain, setDomain] = useState('');

  if (instance?.id) {
    addRecentItem({
      instance_id: instance.id,
      type: 'domains',
    });
  }

  const onHandleAddDomain = async () => {
    const validationResult = validateDomain(domain);
    if (validationResult?.invalid) {
      displayError('Domain is invalid.');
    } else {
      const result = await addNewDomain({ site_id: siteId, domain_name: domain });
      if (result && result?.data?.meta?.is_success) {
        setDomain('');
        setModal(false);
        displaySuccess(result?.data?.meta?.messages);
      }
    }
  };

  const onHandleToggle = () => {
    setDomain('');
    setModal(!modal);
  };

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    { name: 'Site Centre', url: replacePathParams(siteCenter.pageList, [], props) },
    { name: 'Domains', url: '' },
  ];

  const [accordionOpen, setAccordionOpen] = useState('1');
  const toggleAccordion = (id) => {
    if (accordionOpen === id) {
      setAccordionOpen();
    } else {
      setAccordionOpen(id);
    }
  };

  if (domains) {
    const onOldPlatform = get(instance, 'platform_status', false) === 'old';

    return (
      <>
        <HeaderComponent setPath={{
          headingName: 'Domains',
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Back',
          backToPath: settingRoutes.dashboard,
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
        />
        <UserFeaturePage feature="domain_management">
          <Card className="domain-listing">
            <CardBody>
              <Accordion open={accordionOpen} toggle={toggleAccordion}>
                {domains.map((domain) => (
                  <DomainListingDomain
                    key={domain.id}
                    domain={domain}
                  />
                ))}
              </Accordion>
            </CardBody>
          </Card>
          <BottomActionToolbar
            component={(
              onOldPlatform
                ? (
                    <Alert color="danger" className="custom-alert-span">
                      This instance is hosted on the legacy Web Genius platform,
                      so you cannot make any changes to domains except for enabling HTTPS.
                    </Alert>
                  )
                : (
                    <CustomButton
                      color="info"
                      className="fw-bold"
                      title="ADD DOMAIN"
                      icon="fa fa-plus"
                      size="sm"
                      onClick={onHandleToggle}
                    />
                  )
            )}
          />
          {modal && (
            <Modal isOpen={modal} toggle={onHandleToggle} autoFocus={false}>
              <ModalHeader toggle={onHandleToggle}>New Domain</ModalHeader>
              <ModalBody>
                <Input
                  type="text"
                  value={domain}
                  placeholder="Domain Name"
                  onChange={(e) => setDomain(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      onHandleAddDomain();
                    }
                  }}
                  autoFocus={true}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={onHandleToggle}>
                  <b>CANCEL</b>
                </Button>
                <Button color="success" onClick={onHandleAddDomain}>
                  <b>CREATE</b>
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </UserFeaturePage>
      </>
    );
  }

  return <h1>Loading...</h1>;
}

export default withRouter(DomainListing);
