import React, { useEffect } from 'react';
import { displayError, displaySuccess, setPageTitle } from '../../../../../../Utils';
import HeaderComponent from '../../../../components/common/header-component';
import { settingRoutes, siteCenter, replacePathParams } from '../../../../constants/routes';
import TableComponent from '../../../../components/common/Table';
import { Button, Card, CardBody } from 'reactstrap';
import confirm from '../../../../components/common/confirm';
import withRouter from '../../../../helpers/withRouter';
import { addRecentItem } from '../../../../../../UtilsTS';
import {
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useDuplicateTemplatesMutation,
  useGetTemplatesQuery,
} from '../../../../../../common/api/apiSlice';
import { isEmpty, get } from 'lodash-es';

function TemplateList(props) {
  const { params: { siteId, instanceId } } = props;

  const { data: templates = [] } = useGetTemplatesQuery(
    { instanceId, siteId }, { skip: isEmpty(instanceId) || isEmpty(siteId) },
  );

  const [addTemplate, { data: createData }] = useCreateTemplateMutation();
  const [deleteTemplate, { data: deletedData }] = useDeleteTemplateMutation();
  const [duplicateTemplate, { data: duplicateData }] = useDuplicateTemplatesMutation();

  const createMeta = get(createData, 'meta', null);
  const deleteMeta = get(deletedData, 'meta', null);
  const duplicateMeta = get(duplicateData, 'meta', null);

  useEffect(() => {
    if (createMeta || deleteMeta || duplicateMeta) {
      const successMessage = createMeta?.messages || deleteMeta?.messages || duplicateMeta?.messages || '';
      displaySuccess(successMessage);
    }
  }, [createMeta, deleteMeta, duplicateMeta]);

  if (instanceId) {
    addRecentItem({
      instance_id: instanceId,
      type: 'templates',
    });
  }

  useEffect(() => {
    setPageTitle('Templates');
  }, []);

  const onDeleteAction = async (id, sId) => {
    try {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });
      if (result) {
        deleteTemplate({ id, instance_id: instanceId, site_id: sId });
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const onEditAction = (template, id) => {
    const { navigate } = props;
    navigate(replacePathParams(
      template.grid ? siteCenter.editTemplateGrid : siteCenter.editTemplate,
      [
        { key: 'id', value: id },
      ], props,
    ));
  };

  const createTemplate = async (grid) => {
    const html = grid ? '' : '<!doctype html>\n<html>\n  <head>\n  </head>\n  <body>\n  </body>\n</html>';
    const sendParams = {
      site_id: siteId,
      instance_id: instanceId,
      grid,
      html,
    };
    await addTemplate(sendParams);
  };

  const dataTableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (d) => d.name,
    },
    {
      Header: 'Template Type',
      id: 'template_type',
      accessor: (d) => (
        <>
          {d.template_type}
          {d.template_type === 'web' ? <i className={`ms-3 fa fa-fw fa-${d.grid ? 'grid' : 'code'}`} /> : ''}
        </>
      ),
    },
    {
      Header: 'Action',
      id: 'action',
      accessor: (d) => (
        <>
          <Button className="custom-simple-icon" color="primary" size="xs" onClick={() => onEditAction(d, d.id)}>
            <i className="fal fa-pen" />
          </Button>
          {' '}
          <Button
            className="custom-simple-icon"
            color="primary"
            size="xs"
            onClick={() => duplicateTemplate({ id: d.id, site_id: d.site_id })}
          >
            <i className="fal fa-copy" />
          </Button>
          {' '}
          <Button
            className="custom-simple-icon"
            color="danger"
            size="xs"
            onClick={() => onDeleteAction(d.id, d.site_id)}
          >
            <i className="fal fa-trash" />
          </Button>
        </>
      ),
    },
  ];

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    {
      name: 'Site Centre',
      url: replacePathParams(siteCenter.pageList, [], props),
    },
    {
      name: 'Design Options',
      url: replacePathParams(siteCenter.designOption, [], props),
    },
    { name: 'Template List', url: '' },
  ];

  return (
    <>
      <HeaderComponent
        setPath={{
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          headingName: 'Templates',
          addNewBtnName: '',
          addNewPath: '',
        }}
      />
      <Card className="mb-4">
        <CardBody>
          <TableComponent
            columns={dataTableColumns}
            data={templates}
          />
          <Button
            color="primary"
            className="me-3"
            onClick={() => createTemplate(false)}
          >
            <i className="fa fa-plus" />
            {' '}
            Add new (HTML)
          </Button>
          <Button
            color="primary"
            onClick={() => createTemplate(true)}
          >
            <i className="fa fa-plus" />
            {' '}
            Add new (Grid)
          </Button>
        </CardBody>
      </Card>
    </>
  );
}

export default withRouter(TemplateList);
