import React, { useEffect } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { setPageTitle } from '../../../../Utils';
import HeaderComponent from '../../components/common/header-component';
import { settingRoutes, deliveryCenterRoutes, replacePathParams } from '../../constants/routes';
import withRouter from '../../helpers/withRouter';

function DeliveryCenterHome(props) {
  const { navigate } = props;

  useEffect(() => {
    setPageTitle('Settings');
  }, []);

  const redirectToListing = (path) => {
    navigate(replacePathParams(path, [], props));
  };

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'Delivery Centre', url: '' },
  ];

  return (
    <>
      <HeaderComponent
        setPath={{
          addNewPath: '',
          addNewBtnName: '',
          staticBreadcrumbData,
          showBreadcrumb: false,
          headingName: 'Delivery Centre',
          backToPath: replacePathParams(settingRoutes.dashboard, [], props),
        }}
      />
      <Row className="settings-menu-outer">
        <Col className="col-xs-12 col-sm-6 col-md-6 col-lg-3 mb-4">
          <Button color="info" onClick={() => redirectToListing(deliveryCenterRoutes.newSeries)}>
            <i className="fal fa-plus" aria-hidden="true" />
            <p>New Series</p>
          </Button>
        </Col>
        <Col className="col-xs-12 col-sm-6 col-md-6 col-lg-3 mb-4">
          <Button color="info" onClick={() => redirectToListing(deliveryCenterRoutes.emailSeries)}>
            <i className="fa fa-envelope-open-o" aria-hidden="true" />
            <p>Email Series</p>
          </Button>
        </Col>
      </Row>
    </>
  );
}
export default withRouter(DeliveryCenterHome);
