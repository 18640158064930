import { isArray } from 'lodash-es';
import Base from './Base';

interface UserProps {
  id?: string | number;
  email?: string;
  modules?: string[];
  role?: {
    name: string;
    features?: string[];
  };
  feature_overrides?: string[];
}

class User extends Base {
  id?: string;
  email?: string;
  modules?: string[];
  role?: {
    name: string;
    features?: string[];
  };

  feature_overrides?: string[];

  constructor(props: UserProps) {
    super(props);
    this.id = String(props.id);
    this.email = String(props.email);
    this.modules = props.modules;
    this.role = props.role;
    this.feature_overrides = props.feature_overrides;
  }

  hasModule(module: string): boolean {
    return this.modules?.includes(module) ?? false;
  }

  hasFeature(feature: string | string[]): boolean {
    // System Administrators have access to all features. The only special case is custom_sections, which needs to be
    // explicitly added to the user or role. This is because custom sections give the ability to run arbitrary code on
    // the servers, so we want to be extra careful with it.
    if (this.role?.name === 'System Administrator' && feature !== 'custom_sections') {
      return true;
    }

    if (isArray(feature)) {
      // feature can be an array, if it is then we render the component if the user has ANY of the requested features enabled.
      return (feature as string[]).some((f) => this.hasFeature(f));
    }

    if (typeof feature === 'string') {
      if (this.feature_overrides?.includes(feature)) {
        return true;
      }

      return this.role?.features?.includes(feature) ?? false;
    }

    return false;
  }
}

export default User;
