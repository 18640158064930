export const DBFieldTypeOptions = [
  { value: 'email', label: 'Email' },
  { value: 'short_text', label: 'Short text' },
  { value: 'long_text', label: 'Long text' },
  { value: 'numeric', label: 'Numeric' },
  { value: 'date', label: 'Date' },
  { value: 'select_list', label: 'Dropdown' },
  { value: 'radio_button', label: 'Radio button' },
  { value: 'checkboxes', label: 'Checkbox' },
];

export const sectionTypes = (isColumn, pageType, user) => {
  const types = [
    { label: 'Content', value: 'SectionContent' },
  ];

  if (!isColumn) {
    types.push({ label: 'Column', value: 'SectionColumn' });
  }

  if (pageType === 'web') {
    types.push({ label: 'Component', value: 'SectionComponent' });
  }

  if (pageType === 'web') {
    types.push({ label: 'Form', value: 'SectionForm' });
    types.push({ label: 'Image Gallery', value: 'SectionImageGallery' });
    types.push({ label: 'Mirror', value: 'SectionMirror' });
    types.push({ label: 'Review Form', value: 'SectionReviewForm' });
    types.push({ label: 'Review List', value: 'SectionReviewList' });
  }

  if (user?.hasFeature('custom_sections')) {
    types.push({ label: 'Custom', value: 'SectionCustom' });
  }

  return types;
};

export const products = [
  'Dominator',
  'Dominator Starter',
  'Sales Page',
  'Firestarter',
  'SEO Pro',
  'SEO Pro FREE',
  'SEO Premium',
  'SEO Total',
  'SEO Nudge Sprint',
  'SEO Link Sprint',
  'SEO Content Pack',
  'Google Review Engine',
  'Google Ads',
  'ACE',
  'CMS Basic',
  'CMS Training',
];

export const sectionFriendlyNames = {
  SectionColumn: 'Column',
  SectionContent: 'Content',
  SectionComponent: 'Component',
  SectionForm: 'Form',
  SectionImageGallery: 'Image Gallery',
  SectionMirror: 'Mirror',
  SectionReviewForm: 'Review Form',
  SectionReviewList: 'Review List',
};
