import React from 'react';
import {
  Input,
  Label,
  FormGroup,
} from 'reactstrap';

function DefaultFormEmailSubject(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label for="default_form_email_subject">Default Form Email Subject</Label>
      <Input
        type="text"
        value={params.default_form_email_subject || ''}
        onChange={(e) => {
          const { value } = e.target;
          setParams({ ...params, default_form_email_subject: value });
        }}
      />
    </FormGroup>
  );
}

export default DefaultFormEmailSubject;
