import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { setIframeHeight } from './Utils';
import { Provider } from 'react-redux';
import { createStore } from './rootStore';
import AdminSite from './Dashboard/src/App';

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Root element not found');
}
declare global {
  interface Window {
    setIframeHeight: typeof setIframeHeight;
  }
}
window.setIframeHeight = setIframeHeight;

const App: React.FC = () => {
  return (
    <StrictMode>
      <Provider store={createStore()}>
        <AdminSite />
      </Provider>
    </StrictMode>
  );
};

const root = createRoot(rootElement);
root.render(<App />);
