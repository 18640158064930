import React from 'react';
import { useTable, useSortBy } from 'react-table';
import { Table } from 'reactstrap';
import { useUser } from '../../../../common/hooks';

function CTATable({
  columns, data, subRow,
}) {
  const {
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    visibleColumns,
    getTableBodyProps,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  const user = useUser();

  const assignReactKeyToChildren = (d) => React.Children.toArray(d);

  const getColumns = (d) => (!user?.hasFeature('view_spam_ctas') ? d.filter((el) => el.id !== 'Spam' && el.id !== 'Actions') : d);

  const getRowCells = (cells) => (
    !user?.hasFeature('view_spam_ctas') ? cells.filter((el) => el.column.id !== 'Spam' && el.column.id !== 'Actions') : cells
  );

  return (
    <Table {...getTableProps()}>
      <thead>
        {assignReactKeyToChildren(
          headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup-${headerGroupIndex}`}>
              {getColumns(headerGroup.headers).map((column, columnIndex) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={column.className}
                  key={`header-${columnIndex}`}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <i className="fa fa-chevron-circle-down" aria-hidden="true" />
                        : <i className="fa fa-chevron-circle-up" aria-hidden="true" />
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          )),
        )}
      </thead>
      <tbody {...getTableBodyProps()}>
        {assignReactKeyToChildren(
          rows.map((row, rowIndex) => {
            prepareRow(row);

            const subRowEl = typeof subRow === 'function' ? subRow(visibleColumns, row) : <></>;

            return (
              <React.Fragment key={`row-${rowIndex}`}>
                <tr
                  {...row.getRowProps()}
                  className="align-middle"
                  style={{ borderWidth: '1px 0 0 0', borderColor: '#f3f3f3', borderStyle: 'solid' }}
                >
                  {getRowCells(row.cells).map((cell, cellIndex) => {
                    let rowSpan;
                    if (typeof cell.column.rowSpan === 'function') {
                      rowSpan = cell.column.rowSpan(cell);
                    } else if (cell.column.rowSpan) {
                      rowSpan = cell.column.rowSpan;
                    } else {
                      rowSpan = 1;
                    }

                    return (
                      <td
                        {...cell.getCellProps()}
                        className={cell.column.className}
                        rowSpan={rowSpan}
                        style={{ border: 'none' }}
                        key={`cell-${cellIndex}`}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {subRowEl}
              </React.Fragment>
            );
          }),
        )}
      </tbody>
    </Table>
  );
}

export default CTATable;
