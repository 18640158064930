import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { DateTime } from 'luxon';
import DateTimeFormat from '../components/common/DateTimeFormat';
import { isEmpty, filter } from 'lodash-es';
import { useGetTextBlockHistoryQuery } from '../../../common/api/apiSlice';
import ReactTable8 from './common/Table8';

function SectionHistory(props) {
  const { setModal, modal, id, onRestore } = props;
  const { data: textBlockHistory } = useGetTextBlockHistoryQuery(id, { skip: isEmpty(id) });

  const tableColumns = [
    {
      accessorKey: 'updated_at',
      header: 'Date',
      cell: (column) => <DateTimeFormat datetime={column.getValue()} localeString={DateTime.DATE_MED} />,
    },
    {
      accessorKey: 'content',
      header: 'Content',
      cell: ({ row }) => <div dangerouslySetInnerHTML={{ __html: row?.original?.original?.block_text }} />,
    },
    {
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => <Button color="link" onClick={() => onRestore(row.original)}>Restore</Button>,
    },
  ];

  // We don't need any history records with blank text.
  const filteredHistory = filter(textBlockHistory, (h) => !isEmpty(h.modified.block_text));

  return modal
    ? (
        <Modal isOpen={modal} toggle={() => setModal(false)} size="xl" container={window.modalRef}>
          <ModalHeader toggle={() => setModal(false)}>Textblock History</ModalHeader>
          <ModalBody>
            {isEmpty(filteredHistory) && (
              <>No history to show</>
            )}
            {!isEmpty(filteredHistory) && (
              <ReactTable8
                columns={tableColumns}
                data={(filteredHistory || []).filter((e) => e?.modified?.block_text !== '')}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => setModal(false)}>
              <b>CANCEL</b>
            </Button>
          </ModalFooter>
        </Modal>
      )
    : '';
}

export default SectionHistory;
