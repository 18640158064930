import React from 'react';
import { validateEmail, validatePhoneNumber } from '../../../../Utils';

const getCTAFormFields = (el, isModal) => {
  if (el) {
    if (validatePhoneNumber(el.value)) {
      if (isModal) {
        return (
          <tr>
            <td>
              {el.key}
            </td>
            <td>
              <a href={`tel:${el.value}`}>
                <i className="far fa-phone-alt fw-bold" />
                {` ${el.value}`}
              </a>
            </td>
          </tr>
        );
      }

      return (
        <a href={`tel:${el.value}`}>
          <span style={{ color: 'var(--secondary-color)', paddingRight: '4px' }}>{` ${el.key}: `}</span>
          <i className="far fa-phone-alt fw-bold" />
          {' '}
          {el.value}
        </a>
      );
    }

    if (validateEmail(el.value)) {
      if (isModal) {
        return (
          <tr>
            <td>
              {el.key}
            </td>
            <td>
              <a href={`mailto:${el.value}`} target="_blank" rel="noreferrer">
                <i className="far fa-envelope fw-bold" />
                {` ${el.value}`}
              </a>
            </td>
          </tr>
        );
      }

      return (
        <a href={`mailto:${el.value}`} target="_blank" rel="noreferrer">
          <span style={{ color: 'var(--secondary-color)', paddingRight: '4px' }}>{` ${el.key}: `}</span>
          <i className="far fa-envelope fw-bold" />
          {' '}
          {el.value}
        </a>
      );
    }

    if (isModal) {
      return (
        <tr>
          <td>
            {el.key}
          </td>
          <td>
            {el.value}
          </td>
        </tr>
      );
    }

    return (
      <>
        <span style={{ color: 'var(--secondary-color)', paddingRight: '4px' }}>{` ${el.key}: `}</span>
        {' '}
        {Array.isArray(el.value) ? el.value.join(', ') : el.value }
      </>
    );
  }

  return '';
};

export default getCTAFormFields;
