import React from 'react';
import { useGetMemberEmailLogsQuery } from '../../../../../common/api/apiSlice';
import { isEmpty } from 'lodash-es';
import { useParams } from 'react-router';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DateTime } from 'luxon';
import DateTimeFormat from '../../../components/common/DateTimeFormat';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

const MemberEmailLogs = (props) => {
  const { member, toggle } = props;
  const { instanceId } = useParams();
  const { data } = useGetMemberEmailLogsQuery(
    { member_id: member?.id, instance_id: instanceId },
    { skip: isEmpty(member) },
  );
  const modal = !isEmpty(member);

  const tableColumns = [
    {
      accessorKey: 'created_at',
      header: 'Time sent',
      cell: (column) => <DateTimeFormat datetime={column.getValue()} localeString={DateTime.DATETIME_MED} />,
    },
    {
      accessorKey: 'batch_object_type',
      header: 'Type',
      cell: (column) => {
        switch (column.getValue()) {
          case 'BulkEmail':
            return 'Bulk Email';
          case 'EmailSeriesEmail':
            return 'Email Series';
          default:
            return 'Unknown';
        }
      },
    },
    {
      accessorKey: 'subject',
      header: 'Subject',
    },
    {
      accessorKey: 'opened',
      header: 'Opened',
      cell: (column) => <i className={`fa-solid ${column.getValue() ? 'fa-check success-green-color' : 'fa-xmark text-danger'}`} />,
    },
  ];

  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Emails sent</ModalHeader>
      <ModalBody>
        <ReactTable8 columns={tableColumns} data={data || []} />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={toggle}>
          <b>CLOSE</b>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MemberEmailLogs;
