import React, { useState, useEffect } from 'react';
import { replacePathParams, statsCenterRoutes, settingRoutes, contentCenterRoutes } from '../../constants/routes';
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
} from 'reactstrap';
import { addRecentItem } from '../../../../UtilsTS';
import { displaySuccess, displayError, selectColorStyles } from '../../../../Utils';
import { isEmpty } from 'lodash-es';
import Select from 'react-select';
import HeaderComponent from '../../components/common/header-component';
import { useParams } from 'react-router';
import withRouter from '../../helpers/withRouter';
import {
  useGetContentTopicQuery,
  useGetContentTopicBriefingQuery,
  useGetInstanceDataQuery,
  useGenerateContentFromContentTopicMutation,
} from '../../../../common/api/apiSlice';
import CustomButton from '../../components/common/button';
import RadioButton from '../../components/common/radioButton';
import { getPageHierarchy } from '../../components/common/PageHierarchy';
import CarbonCopyEmail from '../../components/common/carbonCopyEmail';
import { useUser, useSite } from '../../../../common/hooks';

function GenerateDraftContent(props) {
  const params = useParams();
  const { instanceId, siteId } = params;
  const { data: ct } = useGetContentTopicQuery(
    {
      siteId: siteId || '',
      id: params.id || '',
    },
    { skip: isEmpty(siteId) || isEmpty(params.id) },
  );
  const { data: ctb } = useGetContentTopicBriefingQuery(
    {
      siteId: siteId || '',
      id: ct?.content_topic_briefing_id || '',
    },
    { skip: isEmpty(siteId) || isEmpty(ct?.content_topic_briefing_id) },
  );
  const { data: instanceData } = useGetInstanceDataQuery(instanceId, { skip: isEmpty(instanceId) });
  const [generateContentFromContentTopic] = useGenerateContentFromContentTopicMutation();

  const user = useUser();
  const site = useSite();

  const [destination, setDestination] = useState('web-genius-page');
  const [parentId, setParentId] = useState(null);
  const [documentName, setDocumentName] = useState('');
  const [shareEmails, setShareEmails] = useState<string[]>([]);

  useEffect(() => {
    if (ct?.name) {
      setDocumentName(ct?.name);
    }
  }, [ct]);

  useEffect(() => {
    if (user?.email) {
      setShareEmails([user.email]);
    }
  }, [user]);

  addRecentItem({
    instance_id: instanceId,
    site_id: siteId,
    type: 'content-center',
  });

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Content Centre',
      url: replacePathParams(contentCenterRoutes.contentCenter, [], props),
    },
    {
      name: ctb?.business_name,
      url: replacePathParams(contentCenterRoutes.contentTopicBriefing, [{ key: 'id', value: ctb?.id }], props),
    },
    {
      name: ct?.name,
      url: replacePathParams(contentCenterRoutes.contentBriefingForm, [{ key: 'id', value: ct?.id }], props),
    },
  ];

  const pageData = instanceData
    ?.find((i) => i.id == instanceId)
    ?.sites
    ?.find((s) => s.id == siteId)
    ?.pages
    ?.filter((p) => p.parent_id || p.id === site?.home_page_id) || [];

  const pageOptionData = getPageHierarchy(pageData, '');
  const getSelectedOption = (options, selected) => options.filter(
    (opt) => opt.value === selected,
  )[0];

  return (
    <>
      <HeaderComponent
        setPath={{
          headingName: 'Generate Draft Content',
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Dashboard',
          backToPath: statsCenterRoutes.contentCenter,
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
      />
      <Card className="mt-3 mb-4 rounded">
        <CardBody>
          <FormGroup>
            <Label>
              Publish draft to
            </Label>
            <ul className="custom-ul-layout ms-3">
              <li>
                <RadioButton
                  title="Web Genius Page"
                  checked={destination === 'web-genius-page'}
                  onHandleChange={(e) => {
                    e.preventDefault();
                    setDestination('web-genius-page');
                  }}
                />
              </li>
              <li>
                <RadioButton
                  title="Google Doc"
                  checked={destination === 'google-doc'}
                  onHandleChange={(e) => {
                    e.preventDefault();
                    setDestination('google-doc');
                  }}
                />
              </li>
            </ul>
          </FormGroup>
          {destination === 'web-genius-page' && (
            <>
              <Select
                placeholder="Select parent of new page"
                onChange={(e) => setParentId(e.value)}
                options={pageOptionData}
                className="mb-4"
                value={getSelectedOption(pageOptionData, parentId)}
                styles={selectColorStyles}
              />
            </>
          )}
          {destination === 'google-doc' && (
            <>
              <FormGroup>
                <Label>
                  Document name
                </Label>
                <Input
                  type="text"
                  value={documentName}
                  onChange={(e) => setDocumentName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Share with
                </Label>
                <CarbonCopyEmail
                  selectedEmails={shareEmails}
                  onChange={(e) => setShareEmails(e)}
                />
              </FormGroup>
            </>
          )}
          <CustomButton
            color="success"
            className="common-success-button fw-bold"
            title="PUBLISH DRAFT CONTENT"
            onClick={async () => {
              const result = await generateContentFromContentTopic({
                contentTopic: ct,
                destination,
                documentName,
                shareEmails,
              });
              if (result.data.meta.is_success)
                displaySuccess(result.data.meta.messages);
              else
                displayError(result.data.meta.messages);
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default withRouter(GenerateDraftContent);
