/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

export default {
  /* 01.General */
  'general.copyright': 'Gogo React © 2018 All Rights Reserved.',

  /* 02.User Login, Logout, Register */
  'user.login-title': 'Login',
  'user.register': 'Register',
  'user.forgot-password': 'Forgot Password',
  'user.email': 'E-mail',
  'user.password': 'Password',
  'user.forgot-password-question': 'Forgot password?',
  'user.fullname': 'Full Name',
  'user.login-button': 'LOGIN',
  'user.register-button': 'REGISTER',
  'user.reset-password-button': 'RESET',
  'user.sending-email-button': 'SENDING..',
  'user.buy': 'BUY',
  'user.username': 'Username',
  'user.profile': 'User Profile',
  'user.new-password': 'New Password',
  'user.confirm-new-password': 'Confirm New Password',
  'user.set-password': 'Set Password',
  /* 03.Menu */
  'menu.app': 'Home',
  'menu.recentItems': 'Recent Items',
  'menu.dashboards': 'Dashboard',
  'menu.ui': 'UI',
  'menu.charts': 'Charts',
  'menu.chat': 'Chat',
  'menu.survey': 'Survey',
  'menu.todo': 'Todo',
  'menu.search': 'Search',
  'menu.docs': 'Docs',
  'menu.pages': 'Pages',
  'menu.domains': 'Domains',
  'menu.sitecenter': 'Site Centre',
  'menu.datacenter': 'Data Centre',
  'menu.mediacenter': 'Media Centre',
  'menu.all-members': 'All Members',
  'menu.member-search': 'Member Search',
  'menu.databasefields': 'Database Fields',
  'menu.add-member': 'Add Member',
  'menu.saved-searches': 'Saved Searches',
  'menu.deliverycenter': 'Delivery Centre',
  'menu.statscenter': 'Stats Centre',
  'menu.contentcenter': 'Content Centre',
  'menu.new-series': 'New Series',
  'menu.emailseries': 'Email Series',
  'menu.messagecenter': 'Message Centre',
  'menu.setting': 'Settings',
  'menu.designOptions': 'Design Options',
  'menu.businessDirectory': 'Business Directory',
  'menu.importMembers': 'Import Members',
  'menu.reviewcenter': 'Review Centre',
  'menu.go-live-date': 'Go-Live Date',
  'menu.default-form-email-addresses': 'Default Form Email Addresses',
  'menu.default-form-email-subject': 'Default Form Email Subject',
  'menu.price-range': 'Price Range',
  'menu.microdata-logo': 'Microdata Logo',
  'menu.microdata-image': 'Microdata Image',
  'menu.recaptcha-threshold': 'reCAPTCHA Bot Threshold',
  'menu.google-analytics-property': 'Google Analytics Property',
  'menu.google-site-verification': 'Google Site Verification Meta Tag',
  'menu.phone-number': 'Phone Number',
  'menu.displayed-phone-number': 'Displayed Phone Number',
  'menu.tracking-phone-numbers': 'Tracking Phone Numbers',
  'menu.google-review-engine': 'Google Review Engine',
  'menu.instance-name': 'Instance Name',
  'menu.google-tag-manager-id': 'Google Tag Manager ID',
  'menu.google-ads-account-id': 'Google Ads Account ID',
  'menu.recaptcha-settings': 'Google reCAPTCHA Settings',
  'menu.initial-visibility-points': 'Initial Visibility Points',
  'menu.initial-visibility-percentage': 'Initial Visibility Percentage',
  'menu.site-description': 'Site Description',
  'menu.time-zone': 'Time Zone',
  'menu.product': 'Product',
  'menu.guarantee-product': 'Guarantee Product',
  'menu.favicon-icon': 'Favourite Icon',
  'menu.orientation-statement': 'Orientation Statement',
  'menu.form-mobile-number': 'Default Form Mobile Number',
  'menu.site-map-master-page': 'Site Map Master Page',
  'menu.privacy-policy-master-page': 'Privacy Policy Master Page',
  'menu.guaranteed-keyword-phrases': 'Guaranteed Keyword Phrases',
  'menu.library-versions': 'Library Versions',
  'menu.unsubscribe-page': 'Unsubscribe Page',
  'menu.privacy-policy-text': 'Privacy Policy Text',
  'menu.bulk-email': 'Send Bulk Email',
  'menu.edit-all-forms': 'Edit All Forms',
  'menu.site-display-name': 'Site Display Name',
  'menu.site-text-search-master-page': 'Site Text Search Master Page',
  'menu.instant-contact-settings': 'Instant Contact Settings',
  'menu.instance-sms-name': 'Instance SMS Name',
  'menu.general': 'General',
  'menu.products': 'Products',
  'menu.phone-numbers': 'Phone Numbers',
  'menu.chatbot': 'Chatbot',
  'menu.seo': 'SEO',
  'menu.reCAPTCHA': 'reCAPTCHA',
  'menu.google-analytics': 'Google Analytics',
  'menu.other-google-services': 'Other Google Services',
  'menu.instant-contact': 'Instant Contact Button',
  'menu.privacy-policy': 'Privacy Policy',
  'menu.popup': 'Popup',
  'menu.site-text-search': 'Site Text Search',

  /* 04.Error Page */
  'pages.error-title': 'Ooops... looks like an error occurred!',
  'pages.error-code': 'Error code',
  'pages.go-back-home': 'GO BACK HOME',

};
