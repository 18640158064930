import React from 'react';
import { useAppSelector } from '../../rootStore';

const starTextHolder = 'Select a rating';

interface StarsRatingProps {
  onHandleClickStar?: (sentiment: string) => void;
  textHolder?: boolean;
  fontSize?: string;
  rating?: number;
}

const StarsRating: React.FC<StarsRatingProps> = ({ onHandleClickStar, textHolder, fontSize, rating }) => {
  const site = useAppSelector((state) => state.pageData.site || {});

  const { review_threshold = 0, review_star_labels } = site;

  const starText = document.getElementsByClassName('review-text-holder');
  const starRate = rating !== undefined;

  const onMouseEnter = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e.currentTarget.dataset.text) {
      starText[0].innerHTML = e.currentTarget.dataset.text;
    }
  };

  const onMouseLeave = () => {
    starText[0].innerHTML = starTextHolder;
  };

  const returnStars = () => (
    [...Array(5)].map((e, i) => {
      const index = i + 1;
      const sentiment = index >= review_threshold ? 'positive' : 'negative';
      return (
        <span
          key={`rating-${index}`}
          className={`star-rating__ico ${starRate && i < rating ? 'checked' : ''} ${starRate ? 'pe-none' : ''}`}
          data-sentiment={sentiment}
          style={{ fontSize: fontSize || 'inherit' }}
          data-text={review_star_labels?.[i]}
          title={`${index} out of 5 stars`}
          onClick={() => {
            if (onHandleClickStar !== undefined) {
              onHandleClickStar(sentiment);
            }
          }}
          onMouseEnter={(e) => onMouseEnter(e)}
          onMouseLeave={() => onMouseLeave()}
        >
          <label
            className="fas fa-star"
            htmlFor={`star-rating-${index}`}
            data-text={review_star_labels?.[i]}
          />
        </span>
      );
    }).reverse()
  );

  return (
    <>
      {returnStars()}
      {textHolder && <label className="review-text-holder d-block">{starTextHolder}</label>}
    </>
  );
};

export default StarsRating;
