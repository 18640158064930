import React, { useState } from 'react';
import 'react-grid-layout/css/styles.css';
import {
  Nav, NavItem, NavLink, Card, CardBody,
} from 'reactstrap';
import { isEmpty } from 'lodash-es';
import classnames from 'classnames';
import withRouter from '../../../../../helpers/withRouter';
import GridLayoutView from '../GridLayoutView';
import { getDeviceType } from '../../../../../../../Utils';

function FormSetting(props) {
  const { sectionData, updateContentStateData } = props;

  const tempState = { ...sectionData };

  const [toggleTab, setToggleTab] = useState('desktop');

  const onLayoutChange = (layout, deviceKey) => {
    tempState[deviceKey] = layout.map((obj) => ({
      x: obj.x,
      y: obj.y,
      w: obj.w,
      h: obj.h,
      i: obj.i,
      maxW: 12,
    }));
    updateContentStateData(tempState);
  };

  const onHandleToggleTab = (tab) => {
    if (toggleTab !== tab) {
      setToggleTab(tab);
    }
  };

  return (
    <>
      <Card>
        <CardBody>
          <Nav tabs className="separator-tabs ms-0 mb-5 custom-cursor-pointer">
            <NavItem>
              <NavLink
                className={classnames({ active: toggleTab === 'desktop' })}
                onClick={() => onHandleToggleTab('desktop')}
              >
                Desktop
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: toggleTab === 'tablet' })}
                onClick={() => onHandleToggleTab('tablet')}
              >
                Tablet
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: toggleTab === 'phone' })}
                onClick={() => onHandleToggleTab('phone')}
              >
                Mobile
              </NavLink>
            </NavItem>
          </Nav>
          {!isEmpty(sectionData)
          && (
            <GridLayoutView
              isDraggableResizable
              sectionData={sectionData}
              deviceKey={getDeviceType(toggleTab)}
              layoutChange={(e, y) => onLayoutChange(e, y)}
              sectorColor={sectionData.sector_color}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default withRouter(FormSetting);
