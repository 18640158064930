import React from 'react';
import { createRoot } from 'react-dom/client';
import Modal from './Modal';

export default class Confirm {
  constructor(options) {
    this.options = {
      message: 'Are you sure?',
      title: 'Warning!',
      confirmText: 'Ok',
      cancelText: 'Cancel',
      confirmColor: 'primary',
      cancelColor: '',
      object: true,
      ...options,
    };

    this.el = document.createElement('div');
    document.body.appendChild(this.el);
  }

  open() {
    const confirmPromise = new Promise((resolve) => {
      const root = createRoot(this.el);
      root.render(
        <Modal
          {...this.options}
          onClose={(result) => {
            resolve(result);
            root.unmount();
          }}
        />,
      );
    });

    return confirmPromise;
  }
}
