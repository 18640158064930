import React from 'react';
import HeaderComponent from '../../../../../components/common/header-component';
import { replacePathParams, siteCenter, settingRoutes } from '../../../../../constants/routes';
import { displaySuccess } from '../../../../../../../Utils';
import TableComponent from '../../../../../components/common/Table';
import { Button, Card, CardBody } from 'reactstrap';
import confirm from '../../../../../components/common/confirm';
import withRouter from '../../../../../helpers/withRouter';
import { useInstance, useSite } from '../../../../../../../common/hooks';
import { get } from 'lodash-es';
import { useGetTemplateFragmentsQuery, useTemplateFragmentDeleteMutation } from '../../../../../../../common/api/apiSlice';

function FragmentListings(props) {
  const instance = useInstance();
  const site = useSite();
  const siteId = get(site, 'id', null);
  const instanceId = get(instance, 'id', null);

  const { data: fragments = [] } = useGetTemplateFragmentsQuery({ siteId, instanceId }, { skip: !siteId || !instanceId });
  const [fragmentDelete] = useTemplateFragmentDeleteMutation();

  const onDeleteAction = async (id) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });
    if (result) {
      const sendParams = {
        id,
        instance_id: instanceId,
        site_id: siteId,
      };
      const response = await fragmentDelete(sendParams);
      if (response && response?.data?.meta?.is_success) {
        displaySuccess(response?.data?.meta?.messages);
      }
    }
  };

  const onEditAction = (id) => {
    const { navigate } = props;
    navigate(replacePathParams(
      siteCenter.editTemplateFragment,
      [
        { key: 'id', value: id },
      ], props,
    ));
  };

  const dataTableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (d) => d.name,
    },
    {
      Header: 'Action',
      id: 'action',
      accessor: (d) => (
        <>
          <Button className="custom-simple-icon" color="primary" size="xs" onClick={() => onEditAction(d.id)}>
            <i className="fal fa-pen" />
          </Button>
          {' '}
          <Button className="custom-simple-icon" color="danger" size="xs" onClick={() => onDeleteAction(d.id)}>
            <i className="fal fa-trash" />
          </Button>
        </>
      ),
    },
  ];

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Site Centre',
      url: replacePathParams(siteCenter.pageList, [], props),
    },
    {
      name: 'Design Options',
      url: replacePathParams(siteCenter.designOption, [], props),
    },
    { name: 'Template Fragments', url: '' },
  ];

  return (
    <>
      <HeaderComponent
        setPath={{
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          addNewBtnName: 'Add new',
          addNewPath: replacePathParams(siteCenter.addTemplateFragment, [
            { key: 'siteId', value: siteId },
            { key: 'instanceId', value: instanceId },
          ]),
          headingName: 'Template Fragments',
        }}
      />
      <Card className="mb-4">
        <CardBody>
          <TableComponent
            columns={dataTableColumns}
            data={fragments}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default withRouter(FragmentListings);
