import React from 'react';
import {
  Input,
  Label,
  FormGroup,
  UncontrolledTooltip,
} from 'reactstrap';

function ReCAPTCHAThreshold(props) {
  const { params, setParams } = props;

  if (typeof params.recaptcha_bot_detection_threshold === 'undefined') {
    return <></>;
  }

  return (
    <FormGroup>
      <Label className="has-tooltip" id="botDetectionThresholdHelp">Bot Detection Threshold</Label>
      <UncontrolledTooltip
        placement="right"
        target="botDetectionThresholdHelp"
        autohide={false}
        style={{ textAlign: 'left' }}
      >
        A number which controls how sensitive the site is to potential bots.
        <br />
        <br />
        The number must be between 0.0 and 1.0. The higher the number the more sensitive,
        and the more likely a form submission from a user will be marked as potentially spam.
        <br />
        <br />
        <a
          href="https://developers.google.com/recaptcha/docs/v3#interpreting_the_score"
          target="_blank"
          rel="noreferrer"
        >
          More information
        </a>
      </UncontrolledTooltip>
      <Input
        type="number"
        step="0.1"
        value={params.recaptcha_bot_detection_threshold}
        onChange={(e) => { setParams({ ...params, recaptcha_bot_detection_threshold: e.target.value }); }}
      />
    </FormGroup>
  );
}

export default ReCAPTCHAThreshold;
