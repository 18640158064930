import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import withRouter from '../helpers/withRouter';
import TopNav from '../containers/navs/Topnav';
import Sidebar from '../containers/navs/Sidebar';
import BigNav from '../containers/navs/BigNav';

function AppLayout(props) {
  const { history, children } = props;
  const [mainShowtemporary, setMainShowTemporary] = useState(false);
  const [newVersion, setNewVersion] = useState(false);
  const menuHidden = useSelector((state) => state.sidebar.menuHidden);
  const searchText = useSelector((s) => s.searchText.text);
  const menuRef = useRef('');
  const etagRef = useRef(null);
  const hashRef = useRef(null);

  // Check for a new version of the dashboard bundle on the server and display an alert if there is one. We previously
  // did this with just etags, but sometimes the server would respond with a 200 and a slightly different etag, even
  // though the file hadn't changed. So now we also check the hash of the file as an extra precaution.
  const checkForNewVersion = () => {
    const headers = etagRef.current ? { 'If-None-Match': etagRef.current } : {};
    fetch('/assets/build/admin/prod/admin.js', { headers }).then(async (response) => {
      if (response.status === 304) {
        // no-op, no new version
      } else if (response.ok) {
        if (etagRef.current === null) {
          etagRef.current = response.headers.get('etag');
        } else {
          // etag does not match, check if hash matches
          const body = await response.text();
          const encoder = new TextEncoder();
          const data = encoder.encode(body);
          const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
          const hashArray = Array.from(new Uint8Array(hashBuffer));
          const hash = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

          if (hashRef.current === null) {
            hashRef.current = hash;
          } else if (hash !== hashRef.current) {
            setNewVersion(true);
          }
        }
      } else {
        // Error getting JS file. Hopefully it was just a network error, we'll try again next time.
      }
    });
  };

  useEffect(() => {
    setInterval(checkForNewVersion, 30000);
  }, []);

  return (
    <div
      id="app-container"
      className={`
        app-layout
        ${menuHidden ? 'menu-hidden' : ''}
        ${mainShowtemporary ? 'main-show-temporary' : ''}
        ${searchText ? 'search-box-open' : ''}
      `}
    >
      {newVersion && (
        <div
          style={{
            position: 'fixed',
            zIndex: 1040,
            top: 0,
            width: '100%',
            padding: '12px',
            backgroundColor: '#cc3333',
            fontWeight: 700,
            color: 'white',
          }}
        >
          There is a new version of the dashboard available —
          {' '}
          <a
            href="#"
            style={{ color: 'white' }}
            onClick={() => window.location.reload()}
          >
            Reload now
          </a>
        </div>
      )}
      <TopNav
        history={history}
        setMainShowTemporary={setMainShowTemporary}
        menuRef={menuRef}
        props={props}
      />
      <Sidebar
        setMainShowTemporary={setMainShowTemporary}
        menuRef={menuRef}
        mainShowtemporary={mainShowtemporary}
      />
      {searchText && (
        <BigNav
          props={props}
        />
      )}
      <main className="container-spinner">
        <div className="container-fluid">
          {children}
        </div>
      </main>
    </div>
  );
}

export default withRouter(AppLayout);
