import React, { useEffect, useState } from 'react';
import { isEmpty, get, map, filter, cloneDeep } from 'lodash-es';
import {
  Card,
  CardBody,
  CardColumns,
  CardTitle,
  Container,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
  InputGroup,
} from 'reactstrap';
import AceEditor from 'react-ace';
import chroma from 'chroma-js';
import Switch from 'rc-switch';
import IconSelector from 'Dashboard/src/components/common/IconSelector';
import withRouter from '../../../../helpers/withRouter';
import { mainRoutes, settingRoutes, replacePathParams } from '../../../../constants/routes';
import HeaderComponent from '../../../../components/common/header-component';
import CustomSelect from '../../../../components/common/custom-select';
import {
  displayError, displaySuccess, getCookie, getSelectedOption, getUnitType, selectBoxOptions, selectColorStyles,
} from '../../../../../../Utils';
import BottomActionToolbar from '../../../../components/common/toolbar';
import TextStyleListing, {
  BackgroundPositionX,
  BackgroundPositionY,
  BackgroundRepeat,
  BackgroundSize,
  CSSUnitType,
  FontStyles,
  FontWeight,
  FontSize,
  Fonts,
  ButtonStylesDropdown,
} from '../../../../components/common/textStyles';
import CheckBox from '../../../../components/common/checkBox';
import RichTextEditor from '../../../../components/RichTextEditor';
import LinkToolBox from '../../Section/LinkToolBox';
import SaveAndContinue from '../../../../components/common/saveAndContinue';
import {
  useCreateComponentMutation,
  useGetComponentCategoriesQuery,
  useGetComponentCategoryQuery,
  useGetComponentQuery,
  useGetLinkDataQuery,
  useUpdateComponentMutation,
} from 'common/api/apiSlice';
import { colorNames } from '../colour-palette';
import { MediaSelectBox } from '../../../../components/fields/site';
import { returnTextAreaValues } from '../../Section/Form';
import ColorPicker from '../../../../components/common/color-picker';
import { useSite } from '../../../../../../common/hooks';
import UserFeaturePage from '../../../../components/common/user-feature-page';
import './index.scss';

const htmlVars = [
  { label: 'Boolean', value: 'bool' },
  { label: 'Text', value: 'text' },
  { label: 'Rich text', value: 'rich_text' },
  { label: 'Link', value: 'link' },
  { label: 'Icon', value: 'icon' },
  { label: 'Image', value: 'image' },
  { label: 'Number', value: 'number' },
  { label: 'Number (responsive)', value: 'number_responsive' },
  { label: 'Button style', value: 'button_style' },
  { label: 'Drop down', value: 'drop_down' },
  { label: 'Text style', value: 'text_style' },
];

const scssVars = [
  { label: 'Colour', value: 'color' },
  { label: 'Font family', value: 'font_family' },
  { label: 'Font style', value: 'font_style' },
  { label: 'Font weight', value: 'font_weight' },
  { label: 'Font size', value: 'font_size' },
  { label: 'Image', value: 'image' },
  { label: 'Horizontal background position', value: 'background_position_x' },
  { label: 'Vertical background position', value: 'background_position_y' },
  { label: 'Background size', value: 'background_size' },
  { label: 'Background repeat', value: 'background_repeat' },
  { label: 'Length', value: 'length' },
  { label: 'Length (responsive)', value: 'length_responsive' },
];

function LinkButton(props) {
  const { value, instanceId, onChange } = props;
  const [linkId, setLinkId] = useState(null);
  const [linkDetail, setLinkDetail] = useState(null);
  const [linkToolOpen, setLinkToolOpen] = useState(false);
  // The key is so that we know what to update after closing the link tool.
  const [key, setKey] = useState(null);

  const { data } = useGetLinkDataQuery({ instanceId, linkId: value }, { skip: isEmpty(instanceId) || isEmpty(value) });

  useEffect(() => {
    if (data) {
      setLinkDetail(data);
    }
  }, [data]);

  return (
    <>
      {linkDetail && <span className="d-block mb-3">{`Currently: ${linkDetail}`}</span>}
      <Button
        className="d-block"
        onClick={() => {
          setKey(key);
          setLinkId(value);
          setLinkToolOpen(true);
        }}
      >
        Set link
      </Button>
      {linkToolOpen && (
        <LinkToolBox
          params={props?.params}
          linkId={linkId}
          toggle={() => setLinkToolOpen(!linkToolOpen)}
          instanceId={instanceId}
          isEmailPage={false} // TODO
          selectedString={null}
          selectedTextBlockId={null}
          onLinkBoxSaveButton={(link) => {
            setLinkDetail(link?.link_detail);
            onChange(link.id);
            setLinkToolOpen(false);
          }}
        />
      )}
    </>
  );
}

export const numberFieldWithUnitSelector = (
  value,
  onChange,
  settingName,
  showSettingType = true,
  blankable = false,
  defaultUnit = 'px',
) => (
  <>
    <Input
      step={0.1}
      type="number"
      value={(value || (blankable ? '' : '0')).replace(/[^\d.-]/g, '')}
      onChange={(e) => {
        if (isEmpty(e.target.value)) {
          onChange('');
        } else {
          onChange(`${e.target.value}${getUnitType(value) || defaultUnit}`);
        }
      }}
    />
    {showSettingType && !isEmpty(value) && (
      <CSSUnitType
        value={getUnitType(value)}
        placeholder="Select Unit"
        excludeUnit={settingName === 'font-size' ? ['%'] : null}
        onChange={(e) => onChange(`${Number(value.replace(/[^\d.-]/g, ''))}${e}`)}
      />
    )}
  </>
);

const ComponentFields = withRouter((props) => {
  // The "preview" param is not named very well -- it's true if we are editing the component itself, and it will be
  // false if we are editing a component section.
  const {
    params: {
      instanceId = getCookie('INSTANCE_ID'),
      siteId = getCookie('SITE_ID'),
    },
    preview,
    item,
    index,
    onChange,
    state,
    keyName,
    orderCounter,
    isResponsive = false,
  } = props;

  const site = useSite();

  const presetColors = get(site, 'colors', []);

  let defaultActiveDevice;
  if (!isResponsive) {
    defaultActiveDevice = 'all';
  } else if (state[keyName] || state[`${keyName}_all`]) {
    defaultActiveDevice = 'all';
  } else {
    defaultActiveDevice = 'desktop';
  }

  const [activeDevice, setActiveDevice] = useState(defaultActiveDevice);
  const [showDevice, setShowDevice] = useState(defaultActiveDevice !== 'all');

  // For rich text fields, the caller (us) needs to keep track of the show source state. It is passed back in the value
  // that is returned to onUpdateTextBlock, so we need to keep track of it here.
  const [sourceMode, setSourceMode] = useState(false);

  const key = isResponsive && activeDevice !== 'all' ? `${keyName}_${activeDevice}` : keyName;

  useEffect(() => {
    if (item.type !== 'image') {
      return;
    }

    if (!isEmpty(state[`${key}_tablet`]) && !isEmpty(state[`${key}_phone`])) {
      setShowDevice(true);
      setActiveDevice('desktop');
    }
  }, [item, state]);

  // Would normally just use isEmpty here, but we want to check numbers and bools as well, and isEmpty(1) === true.
  const value = ((v2) => {
    if (typeof v2 === 'number' || typeof v2 === 'boolean') {
      return v2;
    }
    if (v2 === null || typeof v2 === 'undefined') {
      return item.default;
    }

    return state[key];
  })(state[key]);

  const validObj = {};
  const charLimit = get(item, 'char_limit', '');

  if (charLimit && value.length > charLimit) {
    validObj.invalid = true;
  }

  const characterLimitError = () => {
    if (charLimit && value.replace(/(<([^>]+)>)/ig, '').length > charLimit) {
      return (
        <span className="text-danger">
          {`${item?.name || 'Content'} should not be greater than ${charLimit} characters long.`}
        </span>
      );
    }
    return '';
  };

  const onHandleDeviceButton = () => {
    if (showDevice) {
      setActiveDevice('all');
      setShowDevice(false);
      onChange(state[key], 'all');
    } else {
      setActiveDevice('desktop');
      setShowDevice(true);
      onChange(state[key], 'desktop');
      onChange(state[key], 'tablet');
      onChange(state[key], 'phone');
    }
  };

  switch (item.type) {
    case 'text':
      return (
        <>
          <Input
            type="text"
            name="default"
            value={value}
            {...validObj}
            onChange={(e) => onChange(e.target.value)}
          />
          {characterLimitError()}
        </>
      );
    case 'color': {
      const colorOptions = (() => {
        if (preview) {
          return [...colorNames.map((c) => ({ label: c, value: c })), { label: 'Custom', value: 'Custom' }];
        }
        return [...presetColors.map((c) => ({ label: c.label, value: 0 - c.id })), { label: 'Custom', value: 'Custom' }];
      })();

      const selectedOption = (() => {
        const isColorName = colorNames.indexOf(value) > -1;
        const presetColor = presetColors.find((c) => c.label === value);

        if (preview) {
          return colorNames.indexOf(value) === -1 ? { label: 'Custom', value: 'Custom' } : { label: value, value };
        }
        if (presetColors.find((c) => 0 - c.id === value)) {
          return { label: presetColors.find((c) => 0 - c.id === value)?.label, value };
        }
        if (isColorName && !!presetColor) {
          return { label: value, value: 0 - presetColor.id };
        }
        return { label: 'Custom', value: 'Custom' };
      })();

      const colorPicker = (() => {
        const isColorNotFound = colorNames.indexOf(value) === -1;
        if (preview) {
          return isColorNotFound
            && (
              <ColorPicker
                color={value === 'Custom' ? '' : value}
                onChange={(e) => onChange(e.target.value)}
                onDeleteValue={() => onChange('#ffffff')}
              />
            );
        }
        if (isColorNotFound) {
          return (
            <ColorPicker
              color={value}
              onChange={(e) => onChange(e.target.value)}
              onDeleteValue={() => onChange('#ffffff')}
            />
          );
        }

        return null;
      })();

      return (
        <InputGroup>
          <CustomSelect
            className="w-100 mb-3"
            value={selectedOption}
            onChange={(e) => onChange(e.value)}
            options={colorOptions}
            styles={{
              valueContainer: (baseStyles, s) => {
                const stateValue = s.getValue();

                if (preview) {
                  if (get(stateValue, '0.value') === 'Custom') {
                    return { ...baseStyles, backgroundColor: value };
                  }
                } else {
                  if (get(stateValue, '0.value') === 'Custom') {
                    return { ...baseStyles, backgroundColor: value };
                  }
                  if (presetColors.find((c) => c.label === value)) {
                    const backgroundColor = presetColors.find((c) => c.label === value)?.hex;
                    return { ...baseStyles, backgroundColor };
                  }
                  if (presetColors.find((c) => 0 - c.id === value)) {
                    const backgroundColor = presetColors.find((c) => 0 - c.id === value)?.hex;
                    return { ...baseStyles, backgroundColor };
                  }
                }

                return baseStyles;
              },
              singleValue: (baseStyles, s) => {
                const stateValue = s.getValue();
                let textColor = 'black';

                if (preview) {
                  if (get(stateValue, '0.value') === 'Custom') {
                    try {
                      textColor = chroma.contrast(value, 'white') > 2 ? 'white' : 'black';
                    } catch {
                      // Ignoring the error
                    }

                    return { ...baseStyles, color: textColor };
                  }
                } else {
                  if (get(stateValue, '0.value') === 'Custom') {
                    try {
                      textColor = chroma.contrast(value, 'white') > 2 ? 'white' : 'black';
                    } catch {
                      // Ignoring the error
                    }

                    return { ...baseStyles, color: textColor };
                  }
                  if (presetColors.find((c) => c.label === value)) {
                    const backgroundColor = presetColors.find((c) => c.label === value)?.hex;
                    try {
                      textColor = chroma.contrast(backgroundColor, 'white') > 2 ? 'white' : 'black';
                    } catch {
                      // Ignoring the error
                    }

                    return { ...baseStyles, color: textColor };
                  }
                  if (presetColors.find((c) => 0 - c.id === value)) {
                    const backgroundColor = presetColors.find((c) => 0 - c.id === value)?.hex;
                    try {
                      textColor = chroma.contrast(backgroundColor, 'white') > 2 ? 'white' : 'black';
                    } catch {
                      // Ignoring the error intentionally
                    }

                    return { ...baseStyles, color: textColor };
                  }
                }

                return baseStyles;
              },
              option: (baseStyles, s) => {
                const { data: { value: stateValue } } = s;

                if (preview) {
                  if (value === 'Custom') {
                    const textColor = chroma.contrast(value, 'white') > 2 ? 'white' : 'black';

                    return {
                      ...baseStyles,
                      color: textColor,
                      backgroundColor: value,
                    };
                  }
                } else {
                  const backgroundColor = presetColors.find((c) => 0 - c.id === stateValue)?.hex;
                  if (backgroundColor) {
                    const textColor = chroma.contrast(backgroundColor, 'white') > 2 ? 'white' : 'black';
                    return { ...baseStyles, color: textColor, backgroundColor };
                  }
                }

                return baseStyles;
              },
            }}
          />
          {colorPicker}
        </InputGroup>
      );
    }
    case 'font_family':
      return (
        <Fonts
          font={value}
          templateType="web"
          onChange={(e) => onChange(e.value)}
        />
      );
    case 'font_style':
      return (
        <FontStyles
          value={value}
          onChange={(e) => onChange(e.value)}
        />
      );
    case 'font_weight':
      return (
        <FontWeight
          value={value}
          onChange={(e) => onChange(e.value)}
        />
      );
    case 'font_size':
      return (
        <>
          <InputGroup>
            <FontSize
              value={(value || '').replace(/[^\d.-]/g, '')}
              onChange={(e) => {
                if (isEmpty(e.target.value)) {
                  onChange('');
                } else {
                  onChange(`${e.target.value}${getUnitType(value)}`);
                }
              }}
            />
            <CSSUnitType
              disabled={isEmpty(value)}
              value={getUnitType(value)}
              placeholder="Select Unit"
              excludeUnit={['%']}
              onChange={(e) => onChange(`${Number(value.replace(/[^\d.-]/g, ''))}${e}`)}
            />
          </InputGroup>
        </>
      );
    case 'image':
      if (preview) {
        return (
          <Input
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        );
      }

      if (item && item?.support_responsive_image) {
        return (
          <Row className="BreakPoint align-items-center">
            <ul>
              <li className="w-100">
                <MediaSelectBox
                  value={value}
                  onchange={(e) => onChange(e ? e.value : null, activeDevice)}
                />
              </li>
              <li className="bp-responsivebtn d-flex" style={{ alignItems: 'center' }}>
                {showDevice && (
                  <>
                    <Button
                      className={
                        `breakpoint-txt-btn ${activeDevice === 'desktop' ? 'custom-text-color-blue' : ''}`
                      }
                      onClick={() => setActiveDevice('desktop')}
                    >
                      <i className="fal fa-fw fa-desktop fa-2x" aria-hidden="true" />
                    </Button>
                    <Button
                      className={
                        `breakpoint-txt-btn ${activeDevice === 'tablet' ? 'custom-text-color-blue' : ''}`
                      }
                      onClick={() => setActiveDevice('tablet')}
                    >
                      <i className="fal fa-fw fa-tablet-alt fa-2x" aria-hidden="true" />
                    </Button>
                    <Button
                      className={
                        `breakpoint-txt-btn ${activeDevice === 'phone' ? 'custom-text-color-blue' : ''}`
                      }
                      onClick={() => setActiveDevice('phone')}
                    >
                      <i className="fal fa-fw fa-mobile-alt fa-2x" aria-hidden="true" />
                    </Button>
                    <span style={{ margin: '0 2.5px', fontSize: '12px' }}>┊</span>
                  </>
                )}
                <Button
                  className={`breakpoint-txt-btn ${isEmpty(activeDevice) || activeDevice === 'all' ? 'custom-text-color-blue' : ''}
                              `}
                  onClick={onHandleDeviceButton}
                >
                  <i className="fal fa-fw fa-link fa-2x" aria-hidden="true" />
                </Button>
              </li>
            </ul>
          </Row>
        );
      }

      return (
        <MediaSelectBox
          value={value}
          onchange={(e) => onChange(e ? e.value : null, activeDevice)}
        />
      );

    case 'background_position_x':
      return (
        <BackgroundPositionX
          onChange={(e) => onChange(e.value)}
          styles={selectColorStyles}
          value={value}
        />
      );
    case 'background_position_y':
      return (
        <BackgroundPositionY
          onChange={(e) => onChange(e.value)}
          styles={selectColorStyles}
          value={value}
        />
      );
    case 'background_size':
      return (
        <BackgroundSize
          onChange={(e) => onChange(e.value)}
          styles={selectColorStyles}
          value={value}
        />
      );
    case 'background_repeat':
      return (
        <BackgroundRepeat
          onChange={(e) => onChange(e.value)}
          styles={selectColorStyles}
          value={value}
        />
      );
    case 'number': {
      const sValueNumber = value ? value.toString() : '0';
      return (
        <InputGroup>
          <Input
            step={0.1}
            type="number"
            value={Number(sValueNumber.replace(/[^\d.-]/g, ''))}
            onChange={(e) => onChange(e.target.value)}
          />
        </InputGroup>
      );
    }
    case 'number_responsive': {
      const sValue = value ? value.toString() : '0';

      return (
        <Row className="BreakPoint align-items-center">
          <ul>
            <li className="w-100">
              <InputGroup>
                <Input
                  step={0.1}
                  type="number"
                  value={Number(sValue.replace(/[^\d.-]/g, ''))}
                  onChange={(e) => onChange(e.target.value, activeDevice)}
                />
              </InputGroup>
            </li>
            <li className="bp-responsivebtn d-flex" style={{ alignItems: 'center' }}>
              {showDevice && (
                <>
                  <Button
                    className={
                      `breakpoint-txt-btn ${activeDevice === 'desktop' ? 'custom-text-color-blue' : ''}`
                    }
                    onClick={() => setActiveDevice('desktop')}
                  >
                    <i className="fal fa-fw fa-desktop fa-2x" aria-hidden="true" />
                  </Button>
                  <Button
                    className={
                      `breakpoint-txt-btn ${activeDevice === 'tablet' ? 'custom-text-color-blue' : ''}`
                    }
                    onClick={() => setActiveDevice('tablet')}
                  >
                    <i className="fal fa-fw fa-tablet-alt fa-2x" aria-hidden="true" />
                  </Button>
                  <Button
                    className={
                      `breakpoint-txt-btn ${activeDevice === 'phone' ? 'custom-text-color-blue' : ''}`
                    }
                    onClick={() => setActiveDevice('phone')}
                  >
                    <i className="fal fa-fw fa-mobile-alt fa-2x" aria-hidden="true" />
                  </Button>
                  <span style={{ margin: '0 2.5px', fontSize: '12px' }}>┊</span>
                </>
              )}
              <Button
                className={`breakpoint-txt-btn ${isEmpty(activeDevice) || activeDevice === 'all' ? 'custom-text-color-blue' : ''}
                            `}
                onClick={onHandleDeviceButton}
              >
                <i className="fal fa-fw fa-link fa-2x" aria-hidden="true" />
              </Button>
            </li>
          </ul>
        </Row>
      );
    }
    case 'length': {
      const sValueLength = value ? value.toString() : '';
      return (
        <InputGroup>
          {numberFieldWithUnitSelector(sValueLength, onChange, item.type, true, true)}
        </InputGroup>
      );
    }
    case 'length_responsive': {
      const sValueLength = value ? value.toString() : '';

      return (
        <Row className="BreakPoint align-items-center">
          <ul>
            <li className="w-100">
              <InputGroup>
                {numberFieldWithUnitSelector(
                  sValueLength,
                  (v) => onChange(v, activeDevice),
                  item.type,
                )}
              </InputGroup>
            </li>
            <li className="bp-responsivebtn d-flex" style={{ alignItems: 'center' }}>
              {showDevice && (
                <>
                  <Button
                    className={
                      `breakpoint-txt-btn ${activeDevice === 'desktop' ? 'custom-text-color-blue' : ''}`
                    }
                    onClick={() => setActiveDevice('desktop')}
                  >
                    <i className="fal fa-fw fa-desktop fa-2x" aria-hidden="true" />
                  </Button>
                  <Button
                    className={
                      `breakpoint-txt-btn ${activeDevice === 'tablet' ? 'custom-text-color-blue' : ''}`
                    }
                    onClick={() => setActiveDevice('tablet')}
                  >
                    <i className="fal fa-fw fa-tablet-alt fa-2x" aria-hidden="true" />
                  </Button>
                  <Button
                    className={
                      `breakpoint-txt-btn ${activeDevice === 'phone' ? 'custom-text-color-blue' : ''}`
                    }
                    onClick={() => setActiveDevice('phone')}
                  >
                    <i className="fal fa-fw fa-mobile-alt fa-2x" aria-hidden="true" />
                  </Button>
                  <span style={{ margin: '0 2.5px', fontSize: '12px' }}>┊</span>
                </>
              )}
              <Button
                className={`breakpoint-txt-btn ${isEmpty(activeDevice) || activeDevice === 'all' ? 'custom-text-color-blue' : ''}
                            `}
                onClick={onHandleDeviceButton}
              >
                <i className="fal fa-fw fa-link fa-2x" aria-hidden="true" />
              </Button>
            </li>
          </ul>
        </Row>
      );
    }
    case 'bool':
      return (
        <InputGroup>
          <CheckBox
            checked={!!value}
            title={item.name}
            onChange={() => onChange(!value)}
          />
        </InputGroup>
      );
    case 'rich_text':
      return (
        <>
          <RichTextEditor
            key={`${keyName}_${orderCounter}`}
            params={props?.params}
            index={index}
            features={['no_card']}
            countTextBlocks={1}
            isEmailPage={false} // TODO
            editorType="normal"
            editorId={`toolbarEditor_${item.name.replaceAll(/[^a-zA-Z0-9_]/g, '')}_${index}`}
            textBlockObj={{
              type: 'normal',
              block_text: value,
              sourceMode,
            }}
            onUpdateTextBlock={({ block_text, sourceMode }) => {
              onChange(block_text);
              setSourceMode(sourceMode);
            }}
          />
          {characterLimitError()}
        </>
      );
    case 'link': {
      return (
        <LinkButton
          params={props?.params}
          value={value}
          onChange={onChange}
          instanceId={instanceId}
        />
      );
    }
    case 'button_style': {
      return (
        <ButtonStylesDropdown
          value={value}
          siteId={siteId}
          styles={selectColorStyles}
          onChange={(e) => onChange(e.value)}
        />
      );
    }
    case 'icon': {
      return (
        <IconSelector
          value={value}
          onChange={onChange}
        />
      );
    }
    case 'drop_down': {
      if (preview) {
        return (
          <Input
            type="textarea"
            name="options"
            value={returnTextAreaValues(item?.options) || ''}
            onChange={(e) => onChange(e.target.value.replace(/\r\n/g, '\n').split('\n'))}
          />
        );
      }
      const options = map(item?.options, (v) => ({ label: v, value: v }));
      return (
        <CustomSelect
          options={options}
          styles={selectColorStyles}
          onChange={(e) => onChange(e.value)}
          value={getSelectedOption(options, value)}
        />
      );
    }
    case 'text_style': {
      return (
        <TextStyleListing
          style={value}
          onChange={(e) => onChange(e.value)}
        />
      );
    }
    default:
      return null;
  }
});

export { ComponentFields };

export const isValidCharLimit = (data) => {
  let isValid = true;
  for (let index = 0; index < data.length; index += 1) {
    if (data[index].char_limit && data[index].default.length > data[index].char_limit) {
      isValid = false;
    }
  }
  return isValid;
};

const isValidHTMLVariableName = (data) => {
  const foundDuplicateName = data.find((v, index) => (v?.html_name === ''
    ? v
    : data.find(
      (x, ind) => x.html_name === v.html_name && index !== ind,
    )));
  if (foundDuplicateName) {
    const message = foundDuplicateName?.html_name === ''
      ? `Variable name for the ${foundDuplicateName?.name} field should not be blank!`
      : `${foundDuplicateName?.html_name} html variable name is already taken.`;
    displayError(message);
    return false;
  }
  return true;
};

function Component(props) {
  const [params, setParams] = useState({});
  const { params: { categoryId, componentId, instanceId } } = props;

  const { data: componentCategories } = useGetComponentCategoriesQuery();
  const { data } = useGetComponentQuery(componentId, { skip: isEmpty(componentId) });
  const { data: category = {} } = useGetComponentCategoryQuery(params?.component_category_id, { skip: isEmpty(params?.component_category_id) });
  const [createComponent] = useCreateComponentMutation();
  const [updateComponent] = useUpdateComponentMutation();

  useEffect(() => {
    if (data) {
      setParams(cloneDeep(data));
    } else {
      setParams({
        name: 'New component',
        component_category_id: categoryId,
        sub_items: [],
        css_urls: [],
        js_urls: [],
        html_vars: [],
        scss_vars: [],
      });
    }
  }, [data]);

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setParams({ ...params, [name]: value });
  };

  const addNewVariable = (type) => {
    const tempState = { ...params };
    const obj = {
      default: '',
      name: '',
      [`${type}_name`]: '',
      type: null,
    };
    if (`${type}_vars` in tempState) {
      tempState[`${type}_vars`].push(obj);
    } else {
      tempState[`${type}_vars`] = [obj];
    }
    setParams(tempState);
  };

  const onHandleChangeField = (type, i, e) => {
    const { value, name } = e;
    const tempState = { ...params };
    tempState[`${type}_vars`][i][name] = value;
    setParams(tempState);
  };

  const deleteVariable = (i, type) => {
    const tempState = { ...params };
    tempState[`${type}_vars`].splice(i, 1);
    setParams(tempState);
  };

  const onChangeVariableName = (type, i, value) => {
    const tempState = { ...params };
    tempState[`${type}_vars`][i][`${type}_name`] = value;
    setParams(tempState);
  };

  const onHandleMove = (i, type, moveUp) => {
    const tempState = { ...params };
    const moveElement = tempState[`${type}_vars`].splice(i, 1)[0];
    const newIndex = moveUp ? i - 1 : i + 1;
    tempState[`${type}_vars`].splice(newIndex, 0, moveElement);
    setParams(tempState);
  };

  const reorderVariables = (index, type) => (
    <div className="d-grid">
      {index !== 0 && (
        <Button
          size="xs"
          color="success"
          className={`${params[`${type}_vars`].length === index + 1 ? 'me-2' : ''}`}
          onClick={() => onHandleMove(index, type, true)}
        >
          <i className="fa-solid fa-arrow-up" />
        </Button>
      )}
      {params[`${type}_vars`].length !== index + 1 && (
        <Button
          size="xs"
          color="success"
          className={`${index !== 0 ? 'mt-2' : 'me-2'}`}
          onClick={() => onHandleMove(index, type, false)}
        >
          <i className="fa-solid fa-arrow-down" />
        </Button>
      )}
    </div>
  );

  const renderVariables = (type) => map(params[`${type}_vars`], (item, index) => {
    const options = type === 'html' ? htmlVars : scssVars;
    const subItemOptions = (params.sub_items || []).map((i) => ({ label: i, value: i }));
    subItemOptions.unshift({ label: '(none)', value: null });

    const displayIfOptions = selectBoxOptions(
      filter(params.html_vars, (opt) => opt.type === 'bool' && opt.html_name !== item.html_name),
      'name',
      'html_name',
    );

    const columns = () => (
      <>
        {params.sub_items.length > 0 && (
          <Col>
            <FormGroup>
              <Label>Sub-item</Label>
              <CustomSelect
                value={getSelectedOption(subItemOptions, item.sub_item)}
                onChange={(e) => onHandleChangeField(type, index, { name: 'sub_item', value: e.value })}
                options={subItemOptions}
              />
            </FormGroup>
          </Col>
        )}
        <Col>
          <FormGroup>
            <Label>Type</Label>
            <CustomSelect
              value={getSelectedOption(options, item.type)}
              onChange={(e) => onHandleChangeField(type, index, { name: 'type', value: e.value })}
              options={options}
            />
          </FormGroup>
        </Col>
        {item.type === 'image' && (
          <Col>
            <Label>Support Responsive Image</Label>
            <CheckBox
              title=""
              display="d-block"
              checked={get(item, 'support_responsive_image', false)}
              onChange={() => onHandleChangeField(type, index, { name: 'support_responsive_image', value: !get(item, 'support_responsive_image', false) })}
            />
          </Col>
        )}
        <Col>
          <FormGroup>
            <Label>Name</Label>
            <Input
              type="text"
              name="name"
              value={get(item, 'name', '')}
              onChange={(e) => onHandleChangeField(type, index, { name: 'name', value: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>Variable name</Label>
            <Input
              type="text"
              name={`${type}_name`}
              value={get(item, `${type}_name`, '')}
              onChange={(e) => onChangeVariableName(type, index, e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>Displayed if</Label>
            <CustomSelect
              value={getSelectedOption(displayIfOptions, item?.displayed_if)}
              onChange={(e) => onHandleChangeField(type, index, { name: 'displayed_if', value: e?.value })}
              options={displayIfOptions}
              isClearable
            />
          </FormGroup>
          {item.displayed_if && (
            <FormGroup>
              <Label>is</Label>
              <CustomSelect
                value={getSelectedOption([
                  { label: 'Checked', value: true },
                  { label: 'Unchecked', value: false },
                ], item?.checked)}
                onChange={(e) => onHandleChangeField(type, index, { name: 'checked', value: e.value })}
                options={[
                  { label: 'Checked', value: true },
                  { label: 'Unchecked', value: false },
                ]}
              />
            </FormGroup>
          )}
        </Col>
        {['text', 'rich_text'].includes(item?.type) && (
          <Col>
            <FormGroup>
              <Label>Character limit</Label>
              <Input
                step={1}
                type="number"
                name="name"
                value={get(item, 'char_limit', '')}
                onChange={(e) => onHandleChangeField(type, index, { name: 'char_limit', value: parseInt(e.target.value, 10) })}
              />
            </FormGroup>
          </Col>
        )}
      </>
    );

    const defaultColumn = () => (
      <Col>
        <FormGroup>
          <Label>
            {item.type === 'drop_down' ? 'Options (one per line)' : 'Default'}
          </Label>
          <ComponentFields
            params={props?.params}
            preview
            index={index}
            item={item}
            keyName={`${type}_name`}
            onChange={(value) => onHandleChangeField(type, index, { name: item?.type === 'drop_down' ? 'options' : 'default', value })}
            state={params[`${type}_vars`]}
          />
        </FormGroup>
      </Col>
    );

    const reorderColumn = () => (
      <Col sm={1} className="d-flex align-items-center justify-content-center">
        {reorderVariables(index, type)}
        <Button onClick={() => deleteVariable(index, type)} color="danger" size="xs">
          <i className="fa-solid fa-circle-xmark" />
        </Button>
      </Col>
    );

    return (
      <Card key={index} className="mb-4">
        <CardBody>
          <CardTitle>
            {get(item, 'name', '')}
          </CardTitle>
          <Container fluid>
            {item.type === 'rich_text'
              ? (
                  <Row>
                    <Col>
                      <Container fluid>
                        <Row>
                          {columns()}
                        </Row>
                        <Row>
                          {defaultColumn()}
                        </Row>
                      </Container>
                    </Col>
                    {reorderColumn()}
                  </Row>
                )
              : (
                  <Row>
                    {columns()}
                    {!['link', 'text_style'].includes(item.type) && defaultColumn()}
                    {reorderColumn()}
                  </Row>
                )}
          </Container>
        </CardBody>
      </Card>
    );
  });

  const onSubmit = async () => {
    if (isValidHTMLVariableName(params?.html_vars)) {
      if (isValidCharLimit(params?.html_vars)) {
        let result = null;
        if (isEmpty(componentId)) {
          result = await createComponent({ ...params, instance_id: instanceId });
        } else {
          result = await updateComponent({ ...params, instance_id: instanceId });
        }
        if (result) {
          const { data: { meta: { is_success: isSuccess, messages } = {} } = {} } = result;
          if (isSuccess) {
            displaySuccess(messages);
          }
        }
      } else {
        displayError('Character limit exceeded.');
      }
    }
  };

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    { name: 'Settings', url: replacePathParams(mainRoutes.setting, [], props) },
    {
      name: 'Component Categories',
      url: replacePathParams(settingRoutes.componentCategoryList, [], props),
    },
    {
      name: category.name,
      url: replacePathParams(settingRoutes.componentList, [{ key: 'categoryId', value: params.component_category_id }], props),
    },
    { name: params.name, url: '' },
  ];

  return (
    <div className="Component">
      <HeaderComponent
        setPath={{
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          headingName: 'Components',
          addNewBtnName: '',
          addNewPath: '',
        }}
      />

      <UserFeaturePage feature="manage_components">
        <Card className="mb-4">
          <CardBody>
            <CardTitle>General</CardTitle>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Component name</Label>
                  <Input
                    type="text"
                    name="name"
                    value={get(params, 'name', '')}
                    onChange={(e) => onHandleChange(e)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Published</Label>
                  <Switch
                    className="custom-switch custom-switch-primary"
                    checked={params?.published || false}
                    onChange={(e) => setParams({ ...params, published: e })}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Deprecated</Label>
                  <Switch
                    className="custom-switch custom-switch-primary"
                    checked={params?.deprecated || false}
                    onChange={(e) => setParams({ ...params, deprecated: e })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Component category</Label>
                  <CustomSelect
                    value={componentCategories?.filter((c) => c.id === params.component_category_id)?.map((c) => ({ label: c.name, value: c.id })).at(0)}
                    onChange={(e) => setParams({ ...params, component_category_id: e.value })}
                    options={componentCategories?.map((c) => ({ label: c.name, value: c.id }))}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <CardColumns className="mb-4">
          <Card>
            <CardBody>
              <CardTitle>CSS URLs</CardTitle>
              {(params.css_urls || []).map((url, index) => (
                <Row key={index} className="align-items-center mb-4">
                  <Col md={11}>
                    <Input
                      type="text"
                      value={url}
                      onChange={(e) => {
                        const cssUrls = [...params.css_urls];
                        cssUrls[index] = e.target.value;
                        setParams({ ...params, css_urls: cssUrls });
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Button
                      onClick={() => {
                        const cssUrls = [...params.css_urls];
                        cssUrls.splice(index, 1);
                        setParams({ ...params, css_urls: cssUrls });
                      }}
                      color="danger"
                      size="xs"
                    >
                      <i className="fa-solid fa-circle-xmark" />
                    </Button>
                  </Col>
                </Row>
              ))}
              <Button
                onClick={() => {
                  setParams({ ...params, css_urls: [...params.css_urls, ''] });
                }}
                color="info"
                size="xs"
              >
                <i className="fa-solid fa-plus" />
                {' '}
                Add
              </Button>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle>JS URLs</CardTitle>
              {(params.js_urls || []).map((url, index) => (
                <Row key={index} className="align-items-center mb-4">
                  <Col md={11}>
                    <Input
                      type="text"
                      value={url}
                      onChange={(e) => {
                        const jsUrls = [...params.js_urls];
                        jsUrls[index] = e.target.value;
                        setParams({ ...params, js_urls: jsUrls });
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Button
                      onClick={() => {
                        const jsUrls = [...params.js_urls];
                        jsUrls.splice(index, 1);
                        setParams({ ...params, js_urls: jsUrls });
                      }}
                      color="danger"
                      size="xs"
                    >
                      <i className="fa-solid fa-circle-xmark" />
                    </Button>
                  </Col>
                </Row>
              ))}
              <Button
                onClick={() => {
                  setParams({ ...params, js_urls: [...params.js_urls, ''] });
                }}
                color="info"
                size="xs"
              >
                <i className="fa-solid fa-plus" />
                {' '}
                Add
              </Button>
            </CardBody>
          </Card>
        </CardColumns>

        <Card className="mb-4">
          <CardBody>
            <CardTitle>Sub-items</CardTitle>
            {(params.sub_items || []).map((url, index) => (
              <Row key={index} className="align-items-center mb-4">
                <Col md={11}>
                  <Input
                    type="text"
                    value={url}
                    onChange={(e) => {
                      const subItems = [...params.sub_items];
                      subItems[index] = e.target.value;
                      setParams({ ...params, sub_items: subItems });
                    }}
                  />
                </Col>
                <Col md={1}>
                  <Button
                    onClick={() => {
                      const subItems = [...params.sub_items];
                      subItems.splice(index, 1);
                      setParams({ ...params, js_urls: subItems });
                    }}
                    color="danger"
                    size="xs"
                  >
                    <i className="fa-solid fa-circle-xmark" />
                  </Button>
                </Col>
              </Row>
            ))}
            <Button
              onClick={() => {
                setParams({ ...params, sub_items: [...params.sub_items, ''] });
              }}
              color="info"
              size="xs"
            >
              <i className="fa-solid fa-plus" />
              {' '}
              Add
            </Button>
          </CardBody>
        </Card>

        <Card className="mb-4">
          <CardBody>
            <CardTitle>Code</CardTitle>
            <Row>
              <Col>
                <h2>HTML</h2>
                <AceEditor.default
                  mode="html"
                  theme="xcode"
                  onChange={(e) => setParams({ ...params, html: e })}
                  height="400px"
                  width="100%"
                  highlightActiveLine
                  fontSize={12}
                  // editorProps={{ $blockScrolling: true }}
                  value={get(params, 'html', '')}
                />
              </Col>
              <Col>
                <h2>SCSS</h2>
                <AceEditor.default
                  mode="scss"
                  theme="xcode"
                  onChange={(e) => setParams({ ...params, scss: e })}
                  height="400px"
                  width="100%"
                  highlightActiveLine
                  fontSize={12}
                  // editorProps={{ $blockScrolling: true }}
                  value={get(params, 'scss', '')}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <h2>JS</h2>
                <AceEditor.default
                  mode="javascript"
                  theme="xcode"
                  onChange={(e) => setParams({ ...params, boot_func: e })}
                  height="400px"
                  width="100%"
                  highlightActiveLine
                  fontSize={12}
                  value={get(params, 'boot_func', '')}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="mb-4">
          <CardBody>
            <h2>HTML Variables</h2>
          </CardBody>
        </Card>
        {renderVariables('html')}
        <Card className="mb-4">
          <CardBody className="text-center">
            <Button size="sm" color="info" onClick={() => addNewVariable('html')}>
              <i className="fa-solid fa-plus" />
              {' '}
              Add
            </Button>
          </CardBody>
        </Card>

        <Card className="mb-4">
          <CardBody>
            <h2>SCSS Variables</h2>
          </CardBody>
        </Card>
        {renderVariables('scss')}
        <Card className="mb-4">
          <CardBody className="text-center">
            <Button size="sm" color="info" onClick={() => addNewVariable('scss')}>
              <i className="fa-solid fa-plus" />
              {' '}
              Add
            </Button>
          </CardBody>
        </Card>

        <BottomActionToolbar
          component={(
            <SaveAndContinue
              onSave={onSubmit}
              onContinue={replacePathParams(settingRoutes.componentList, [
                { key: 'instanceId', value: instanceId },
                { key: 'categoryId', value: categoryId },
              ])}
            />
          )}
        />
      </UserFeaturePage>
    </div>
  );
}

export default withRouter(Component);
