import React from 'react';
import {
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';

export const commonStyleNumericField = (
  labelName,
  keyName,
  fieldValue,
  showPx,
  onHandleChangeProp,
) => (
  <div>
    <Label for={keyName}>{labelName}</Label>
    <InputGroup>
      <Input
        type="number"
        name={keyName}
        id={keyName}
        value={fieldValue || ''}
        onChange={onHandleChangeProp}
      />
      {showPx && <InputGroupText>px</InputGroupText>}
    </InputGroup>
  </div>
);

export const styleDisplayArrowGraphics = (
  labelName,
  keyName,
  onHandleChangeProp,
  fieldValue = [],
) => (
  <div>
    <Label>{labelName}</Label>
    <ul className="custom-ul">
      <li>
        <Label className="checkbox-container checkbox-radio-container mb-0">
          <Input
            checked={fieldValue.includes('Left')}
            type="checkbox"
            value="Left"
            name={keyName}
            onChange={onHandleChangeProp}
          />
          Left
          <span className="checkmark checkmark-checkbox" />
        </Label>
      </li>
      <li>
        <Label className="checkbox-container checkbox-radio-container mb-0">
          <Input
            checked={fieldValue.includes('Right')}
            type="checkbox"
            value="Right"
            name={keyName}
            onChange={onHandleChangeProp}
          />
          Right
          <span className="checkmark checkmark-checkbox" />
        </Label>
      </li>
    </ul>
  </div>
);
