import { Mark, mergeAttributes } from '@tiptap/react';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    CustomStyle: {
      setCustomStyle: (customStyle?: string) => ReturnType;
      unsetCustomStyle: () => ReturnType;
    };
  }
}

export interface CustomStyleOptions {
  HTMLAttributes: Record<string, any>;
}

export const CustomStyle = Mark.create<CustomStyleOptions>({
  name: 'CustomStyle',
  priority: 102,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      style: { default: null },
      customStyle: {
        default: null,
        parseHTML: (el) => (el as HTMLSpanElement).getAttribute('data-custom-style'),
        renderHTML: (attrs) => ({ 'data-custom-style': attrs.customStyle }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-custom-style]',
        getAttrs: (el) => {
          return !!(el as HTMLSpanElement).getAttribute('data-custom-style')?.trim() && null;
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setCustomStyle:
                (customStyle?: string) =>
                  ({ commands }) => {
                    if (customStyle) commands.setMark('CustomStyle', { customStyle });
                    else commands.unsetMark('CustomStyle');
                    return true;
                  },
      unsetCustomStyle:
                () =>
                  ({ commands }) =>
                    commands.unsetMark('CustomStyle'),
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const { state, view } = editor;
        const { $from } = state.selection;
        const markType = state.schema.marks.CustomStyle;
        const hasMark = markType && editor.isActive('CustomStyle');
        const customStyle = editor.getAttributes('CustomStyle').customStyle;

        if (!hasMark) {
          return false;
        }

        // Return if the cursor is in the middle of a node.
        if ($from.parentOffset > 0 && $from.parentOffset < $from.parent.content.size) {
          return false;
        }

        // Return if the cursor is inside a list element.
        // TODO: There is a type error here but it seems to be a bug in the Tiptap or Prosemirror code.
        if (($from.path || []).some((path) => path.type?.name === 'bulletList')) {
          return false;
        }

        // Check if the cursor is at the start of a line and create a new paragraph above it, and focus it.
        if ($from.parentOffset === 0) {
          const tr = state.tr;
          const newParagraph = state.schema.nodes.paragraph.create(
            { customStyle },
          );

          tr.insert($from.before(), newParagraph);

          // Dispatch the transaction to update the editor view
          view.dispatch(tr);
        } else {
          // Cursor is at the end of a line. Create a new paragraph below it and focus it.
          editor
            .chain()
            .insertContent({ type: 'paragraph', attrs: { customStyle } })
            .focus()
            .run();
        }

        return true;
      },
    };
  },
});

export default CustomStyle;
