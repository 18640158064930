import React from 'react';
import {
  Label,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
} from 'reactstrap';

function PopupWidth(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label>Maximum popup width</Label>
      <InputGroup>
        <Input
          type="number"
          value={params.popup_options.width || 400}
          onChange={(e) => setParams({ ...params, popup_options: { ...params.popup_options, width: e.target.value } })}
        />
        <InputGroupText>
          px
        </InputGroupText>
      </InputGroup>
    </FormGroup>
  );
}

export default PopupWidth;
