import React, { useState, useEffect } from 'react';
import { Input, Row, Col, Alert, Card, CardBody } from 'reactstrap';
import { isEmpty, cloneDeep } from 'lodash-es';
import { displayError, setPageTitle, selectColorStyles, getSelectedOption, selectBoxOptions } from '../../../../../../Utils';
import withRouter from '../../../../helpers/withRouter';
import Select from 'react-select';
import HeaderComponent from '../../../../components/common/header-component';
import { settingRoutes, replacePathParams, siteCenter } from '../../../../constants/routes';
import BottomActionToolbar from '../../../../components/common/toolbar';
import SaveAndContinue from '../../../../components/common/saveAndContinue';
import TemplateEditor from './TemplateEditor';
import TemplateSector from './TemplateSector';
import { useCreateTemplateMutation, useGetSitesQuery, useGetTemplateQuery, useUpdateTemplateMutation } from '../../../../../../common/api/apiSlice';
import { addRecentItem } from '../../../../../../UtilsTS';

const TemplateEdit = (props) => {
  const { params: { siteId, id, instanceId } } = props;
  const [errorVal, setError] = useState('');
  const [getEditorError, setEditorError] = useState(null);
  const [sectorList, setSectorList] = useState([]);
  const [params, setParams] = useState({
    id: '',
    site_id: siteId,
    instance_id: instanceId,
    name: '',
    html: '',
    template_type: '',
  });

  const paramsTemp = { ...params };

  const [createTemplate] = useCreateTemplateMutation();
  const [updateTemplate] = useUpdateTemplateMutation();
  const { data } = useGetTemplateQuery({ id, siteId, instanceId }, { skip: isEmpty(instanceId) || isEmpty(siteId) || isEmpty(id) });

  useEffect(() => {
    if (data) {
      setParams(cloneDeep(data.template));
      setSectorList(cloneDeep(data.sectors));
    }
  }, [data]);

  useEffect(() => {
    if (!isEmpty(id)) {
      setPageTitle('Edit template');
    } else {
      setPageTitle('New template');
    }
  }, []);

  const onTemplateTypeChange = (e) => {
    const { value } = e;
    paramsTemp.template_type = value;
    setParams(paramsTemp);
  };

  const { data: sites } = useGetSitesQuery(instanceId, { skip: isEmpty(instanceId) });

  const onSelectSite = (e) => {
    paramsTemp.site_id = e.value;
    setParams(paramsTemp);
  };

  /* On template name change */
  const onTemplateNameChange = (e) => {
    const { target: { value } } = e;
    paramsTemp.name = value;
    setParams(paramsTemp);
  };

  /* Getting editor data from TemplateEditor component */
  const getEditorData = (data) => {
    paramsTemp.html = `${data}`;
    setParams(paramsTemp);
  };

  /* Validating editor errors */
  const validateEditorError = (error) => {
    if (error.length >= 1) {
      setEditorError(error);
    } else {
      setError('');
      setEditorError(null);
    }
  };

  const onHandleSubmit = async () => {
    try {
      const sectorLabels = sectorList.map((sector) => (sector.label));
      if (!params.name) {
        setError(<span>Template name should not be blank!</span>);
      } else if (!params.site_id) {
        setError(<span>Site should not be blank!</span>);
      } else if (params.template_type === '') {
        setError(<span>Template type should not be blank!</span>);
      } else if (!params.html) {
        setError(<span>Code editor should not be blank!</span>);
      } else if (sectorLabels.includes('')) {
        setError(<span>Sector name should not be blank!</span>);
      } else if (getEditorError !== null) {
        const getErrors = getEditorError.map((item, idx) => (
          <span key={idx}>{`Line ${item.row + 1} Column  ${item.column}, ${item.text}`}</span>
        ));
        setError(getErrors);
      } else {
        setError('');
        setEditorError(null);
        const sectorIds = sectorList.map((sector) => (sector.id));
        params.sector_ids = sectorIds;
        if (isEmpty(params.id)) {
          createTemplate(params);
        } else {
          updateTemplate(params);
        }
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const onHandleChangeSectorData = (index, value, name) => {
    let tempSectorData = [...sectorList];
    if (name === 'is_primary') {
      tempSectorData = tempSectorData.map((obj) => ({ ...obj, [name]: false }));
      tempSectorData[index][name] = !tempSectorData[index][name];
    } else {
      tempSectorData[index][name] = value;
    }
    setSectorList(tempSectorData);
  };

  const setTemplateTypeOptions = [{ value: 'web', label: 'Web' }, { value: 'email', label: 'Email' }];

  const setHeading = isEmpty(id) ? 'Add New Template' : 'Editing Template';

  addRecentItem({
    instance_id: instanceId,
    type: 'edit-template',
    item_id: id,
  });

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Site Centre',
      url: replacePathParams(siteCenter.pageList, [], props),
    },
    {
      name: 'Design Options',
      url: replacePathParams(siteCenter.designOption, [], props),
    },
    {
      name: 'Templates',
      url: replacePathParams(siteCenter.templateList, [], props),
    },
    { name: `${params.name || ''}`, url: '' },
  ];

  const onHandleUpdateSectorOrder = (newState) => {
    setSectorList(newState);
  };

  return (
    <>
      <HeaderComponent
        setPath={{
          addNewPath: '',
          addNewBtnName: '',
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          headingName: `${setHeading}`,

        }}
      />
      {errorVal ? <Alert color="danger" className="custom-alert-span">{errorVal}</Alert> : ''}
      <Row>
        <Col className="col-sm-10">
          <Input autoFocus placeholder="Enter title here" value={params.name || ''} onChange={onTemplateNameChange} />
        </Col>
        <Col className="col-sm-2 text-end">
          <SaveAndContinue
            onSave={() => onHandleSubmit()}
            onContinue={replacePathParams(siteCenter.templateList, [], props)}
          />
        </Col>
      </Row>
      <hr />
      <Card className="mt-4">
        <CardBody>
          <Row>
            <Col lg={6} className="mb-3">
              <p><b>Select site:</b></p>
              <Select
                value={getSelectedOption(sites, params.site_id)}
                placeholder="Sites"
                onChange={onSelectSite}
                options={selectBoxOptions(sites, 'label', 'id')}
                // getOptionValue={options => options.id}
                // getOptionLabel={options => options.site_name}
                styles={selectColorStyles}
              />
            </Col>
            <Col lg={6}>
              <p><b>Select template type:</b></p>
              <Select
                value={getSelectedOption(setTemplateTypeOptions, params.template_type)}
                placeholder="Template types"
                onChange={onTemplateTypeChange}
                options={setTemplateTypeOptions}
                styles={selectColorStyles}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <TemplateSector
        templateId={params.id}
        sectorList={sectorList}
        instanceId={params.instance_id}
        onHandleChangeSectorData={onHandleChangeSectorData}
        onHandleUpdateSectorOrder={onHandleUpdateSectorOrder}
      />
      <Row>
        <Col>
          <TemplateEditor
            editorData={getEditorData}
            validateEditor={validateEditorError}
            defaultComponentValue={params.html}
          />
        </Col>
      </Row>
      <BottomActionToolbar
        component={(
          <SaveAndContinue
            onSave={onHandleSubmit}
            onContinue={replacePathParams(siteCenter.templateList, [], props)}
          />
        )}
      />
    </>
  );
};
export default withRouter(TemplateEdit);
