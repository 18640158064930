import React from 'react';
import { Label, FormGroup, UncontrolledTooltip } from 'reactstrap';
import MediaSelectBox from './MediaSelectBox';

function MicrodataImage(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label className="has-tooltip" id="microdataImageHelp">Business Image</Label>
      <UncontrolledTooltip placement="right" target="microdataImageHelp" autohide={false} style={{ textAlign: 'left' }}>
        Used in
        {' '}
        <a
          href="https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data"
          target="_blank"
          rel="noreferrer"
        >
          page microdata
        </a>
        .
        <br />
        <br />
        <a
          href="https://developers.google.com/search/docs/advanced/structured-data/local-business#:~:text=One%20or%20more%20images%20of%20the%20restaurant."
          target="_blank"
          rel="noreferrer"
        >
          More information
        </a>
      </UncontrolledTooltip>
      <MediaSelectBox
        value={params?.microdata_image_id}
        onchange={(e) => setParams({ ...params, microdata_image_id: e ? e.value : null })}
      />
    </FormGroup>
  );
}

export default MicrodataImage;
