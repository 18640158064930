import React, { useEffect, useState } from 'react';
import withRouter from '../../../../helpers/withRouter';
import FormSetting from './Form';
import { isEmpty } from 'lodash-es';
import { useGetSectionHTMLQuery, useGetSectionQuery, useSectionUpdateMutation } from '../../../../../../common/api/apiSlice';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import SaveAndContinue from '../../../../components/common/saveAndContinue';
import { updateStylesheet } from '../../../../../../Utils';

function SectionSetting(props) {
  const {
    section,
    params,
    onClose,
    params: { instanceId, siteId, pageType, pageId, pageVersionId },
  } = props;

  const [sectionData, setSectionData] = useState({});

  const isSectionFromRails = 'sectionId' in params;

  const sectionId = isSectionFromRails ? params.sectionId : section?.id;

  const isEmailPage = pageType === 'email';

  const { data, refetch: refetchSection } = useGetSectionQuery(
    { instanceId, sectionId }, { skip: isEmpty(instanceId) && isEmpty(sectionId) },
  );

  const { refetch } = useGetSectionHTMLQuery(
    { instanceId, sectionId },
    { skip: isEmpty(instanceId) && isEmpty(sectionId) },
  );

  const [sectionUpdate, { isSuccess: sectionUpdated }] = useSectionUpdateMutation();

  useEffect(() => {
    if (data) {
      setSectionData(data);
    }
  }, [data]);

  useEffect(() => {
    if (sectionUpdated) {
      if (isSectionFromRails) {
        window.parent.postMessage('reload', sectionData?.url);
      } else {
        refetch();
        refetchSection();
        updateStylesheet({ siteId, pageId, pageVersionId });
      }
    }
  }, [sectionUpdated]);

  const sectionSetting = () => {
    switch (sectionData.type) {
      case 'SectionForm':
        return (
          <FormSetting
            sectionData={sectionData}
            updateContentStateData={setSectionData}
          />
        );
      default: return null;
    }
  };

  const onHandleClose = () => {
    if (isSectionFromRails) {
      window.parent.postMessage('close', sectionData?.url);
    } else {
      onClose();
    }
  };

  const onHandleSaveSection = async () => {
    let sendParams = ({ ...sectionData, instance_id: instanceId });
    delete sendParams.break_point;
    const res = await sectionUpdate(sendParams);
    if (!isEmpty(res)) {
      return true;
    }
    return false;
  };

  const style = {
    maxWidth: '100%',
    margin: '0',
  };

  return (
    <Modal
      container={window.modalRef}
      className="section-setting-modal"
      isOpen={true}
      size="xl"
      style={isSectionFromRails ? style : {}}
    >
      <ModalBody>
        {sectionSetting(sectionData, isEmailPage)}
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          color="danger"
          onClick={onHandleClose}
          className="fw-bold"
        >
          CANCEL
        </Button>
        <SaveAndContinue
          onSave={onHandleSaveSection}
          onContinue={onHandleClose}
        />
      </ModalFooter>
    </Modal>
  );
}

export default withRouter(SectionSetting);
