import React, { useState, useEffect } from 'react';
import {
  Input, Row, Col, Card, CardTitle, CardBody, FormGroup, Label, InputGroup, InputGroupText,
} from 'reactstrap';
import Switch from 'rc-switch';
import HeaderComponent from 'Dashboard/src/components/common/header-component';
import { settingRoutes, replacePathParams, siteCenter } from 'Dashboard/src/constants/routes';
import BottomActionToolbar from 'Dashboard/src/components/common/toolbar';
import SaveAndContinue from 'Dashboard/src/components/common/saveAndContinue';
import { preview as buttonPreview } from 'common/api/button';
import { get, isEmpty, map, cloneDeep } from 'lodash-es';
import {
  addGoogleFont,
  colourPalettePreset,
  displaySuccess,
  selectColorStyles,
} from '../../../../../../Utils';
import withRouter from 'Dashboard/src/helpers/withRouter';
import { useParams } from 'react-router';
import webSafeFontOptions from 'common/schema/webSafeFontOptions';
import { BorderStyles, FontWeight, IconSide, TextTransform } from 'Dashboard/src/components/common/textStyles';
import CustomSelect from 'Dashboard/src/components/common/custom-select';
import IconSelector from 'Dashboard/src/components/common/IconSelector';
import { numberFieldWithUnitSelector } from 'Dashboard/src/views/site-centre/design-options/components/component.js';
import {
  useGetButtonStyleByIdQuery,
  useUpdateButtonStyleMutation,
  useGetCustomFontsQuery,
} from 'common/api/apiSlice';
import ColorPicker from 'Dashboard/src/components/common/color-picker';
import 'rc-switch/assets/index.css';
import { addRecentItem } from '../../../../../../UtilsTS';
import { useSite } from '../../../../../../common/hooks';
import './index.scss';

const buttonProperties = [
  { label: 'Font family', value: 'font-family', defaultValue: '' },
  { label: 'Font size', value: 'font-size', defaultValue: '12' },
  { label: 'Font weight', value: 'font-weight', defaultValue: '400' },
  { label: 'Horizontal margin', value: 'margin-horizontal', defaultValue: '0' },
  { label: 'Vertical margin', value: 'margin-vertical', defaultValue: '0' },
  { label: 'Horizontal padding', value: 'padding-horizontal', defaultValue: '6' },
  { label: 'Vertical padding', value: 'padding-vertical', defaultValue: '1' },
  { label: 'Border width', value: 'border-width', defaultValue: '2' },
  { label: 'Border style', value: 'border-style', defaultValue: 'solid' },
  { label: 'Border colour', value: 'border-color', defaultValue: '#efefef' },
  { label: 'Border radius', value: 'border-radius', defaultValue: '0' },
  { label: 'Background colour', value: 'background', defaultValue: 'transparent' },
  { label: 'Text colour', value: 'color', defaultValue: '#303030' },
  { label: 'Space between text and icon', value: 'icon-space', defaultValue: '12' },
  { label: 'Transition time', value: 'transition', defaultValue: '0' },
  { label: 'Letter spacing', value: 'letter-spacing', defaultValue: '0' },
  { label: 'Text transform', value: 'text-transform', defaultValue: 'none' },
  { label: 'Icon', value: 'icon', defaultValue: '' },
  { label: 'Icon side', value: 'icon-side', defaultValue: 'right' },
];

// Properties that we do not want in the Hover Styles box
const hoverSkip = [
  'Transition time',
];

function ButtonEdit(props) {
  const param = useParams();
  const { instanceId, siteId, id } = param;

  /* Initializing states */
  const [params, setParams] = useState({
    id,
    site_id: siteId,
    name: '',
    css: {},
    hover_css: {},
  });

  const site = useSite();
  const { data } = useGetButtonStyleByIdQuery({ site_id: siteId, id }, { skip: isEmpty(id) ? true : false });
  const { data: fonts } = useGetCustomFontsQuery();
  const [updateButtonStyle] = useUpdateButtonStyleMutation();

  useEffect(() => {
    if (!isEmpty(data)) {
      setParams(data);
    }
  }, [data]);

  useEffect(() => {
    if (get(params, 'css.font-family')) {
      addGoogleFont([{
        fontName: get(params, 'css.font-family'),
        fontWeight: get(params, 'css.font_weight') || 400,
      }]);
    }
  }, [params]);

  if (instanceId && siteId && id) {
    addRecentItem({
      instance_id: instanceId,
      site_id: siteId,
      type: 'edit-button',
      item_id: id,
    });
  }

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    {
      name: 'Site Centre',
      url: replacePathParams(siteCenter.pageList, [], props),
    },
    {
      name: 'Design Options',
      url: replacePathParams(siteCenter.designOption, [], props),
    },
    {
      name: 'Buttons',
      url: replacePathParams(siteCenter.buttons, [], props),
    },
    { name: `${params.name || ''}`, url: '' },
  ];

  const att = (hover) => (hover ? 'hover_css' : 'css');

  const onHandleChange = (name, value, hover) => {
    setParams((prevState) => ({
      ...prevState,
      [att(hover)]: {
        ...prevState[att(hover)],
        [name]: value,
      },
    }));
  };

  const elementRender = ({ value: type }, hover) => {
    switch (type) {
      case 'font-size':
      case 'padding-horizontal':
      case 'padding-vertical':
      case 'border-width':
      case 'border-radius':
      case 'icon-space':
      case 'letter-spacing': {
        const sValue = get(params[att(hover)], type, '12');
        return (
          <InputGroup>
            {numberFieldWithUnitSelector(sValue, (e) => onHandleChange(type, e, hover), type)}
          </InputGroup>
        );
      }
      case 'margin-horizontal':
      case 'margin-vertical': {
        const sValue = get(params[att(hover)], type, '0');
        return (
          <InputGroup>
            {numberFieldWithUnitSelector(sValue, (e) => onHandleChange(type, e, hover), type)}
          </InputGroup>
        );
      }
      case 'font-family': {
        const gFonts = (f) => map(f, (item) => (
          { value: item.name, label: item.name, variants: item.variants }
        ));

        const options = fonts ? gFonts([...webSafeFontOptions, ...fonts]) : [];
        const selectedFont = params[att(hover)]['font-family'] || '';
        const selectedValue = options.find((o) => o.value === selectedFont);

        return (
          <InputGroup>
            <CustomSelect
              className="flex-grow-1"
              onChange={(e) => onHandleChange('font-family', e.value, hover)}
              options={options}
              styles={selectColorStyles}
              value={selectedValue}
            />
          </InputGroup>
        );
      }
      case 'font-weight': {
        return (
          <InputGroup>
            <FontWeight
              className="w-100"
              value={params[att(hover)]['font-weight'] || '400'}
              onChange={(e) => onHandleChange('font-weight', e.value, hover)}
            />
          </InputGroup>
        );
      }
      case 'text-transform': {
        return (
          <InputGroup>
            <TextTransform
              className="w-100"
              value={params[att(hover)]['text-transform'] || 'none'}
              onChange={(e) => onHandleChange('text-transform', e.value, hover)}
            />
          </InputGroup>
        );
      }
      case 'border-style': {
        return (
          <InputGroup>
            <BorderStyles
              className="w-100"
              value={get(params[att(hover)], 'border-style', 'solid')}
              onChange={(e) => onHandleChange('border-style', e.value, hover)}
            />
          </InputGroup>
        );
      }
      case 'border-color': {
        return (
          <ColorPicker
            color={get(params[att(hover)], 'border-color', '#efefef')}
            onChange={(e) => onHandleChange('border-color', e.target.value, hover)}
            onDeleteValue={() => onHandleChange('border-color', '#efefef', hover)}
          />
        );
      }
      case 'background': {
        return (
          <ColorPicker
            isBackgroundColor
            color={get(params[att(hover)], 'background', 'transparent')}
            onChange={(e) => onHandleChange('background', e.target.value, hover)}
            onDeleteValue={() => onHandleChange('background', 'transparent', hover)}
          />
        );
      }
      case 'color': {
        return (
          <ColorPicker
            color={get(params[att(hover)], 'color', '#303030')}
            onChange={(e) => onHandleChange('color', e.target.value, hover)}
            onDeleteValue={() => onHandleChange('color', '#303030', hover)}
          />
        );
      }
      case 'icon': {
        return (
          <IconSelector
            value={get(params[att(hover)], 'icon', '')}
            onChange={(value) => onHandleChange('icon', value, hover)}
            disableDuotone={true}
          />
        );
      }
      case 'icon-side': {
        return (
          <InputGroup>
            <IconSide
              className="w-100"
              value={get(params[att(hover)], 'icon-side', 'right')}
              onChange={(e) => onHandleChange('icon-side', e.value, hover)}
            />
          </InputGroup>
        );
      }
      case 'transition': {
        return (
          <InputGroup>
            <Input
              type="number"
              step="any"
              min={0}
              value={(params[att(hover)].transition || '0').replace(/s/, '')}
              onChange={(e) => onHandleChange('transition', `${e.target.value}s`, hover)}
            />
            <InputGroupText>sec</InputGroupText>
          </InputGroup>
        );
      }
      default: return '';
    }
  };

  const onHandleSubmit = async () => {
    const presetColors = get(site, 'colors', []);
    const tempParams = cloneDeep(params);
    tempParams.css['background'] = colourPalettePreset(tempParams.css['background'], presetColors);
    tempParams.css['border-color'] = colourPalettePreset(tempParams.css['border-color'], presetColors);
    tempParams.css.color = colourPalettePreset(tempParams.css.color, presetColors);
    const result = await updateButtonStyle(tempParams);
    if (result && result?.data?.meta?.is_success) {
      displaySuccess(result?.data?.meta?.messages);
    }
  };

  const overRideOptions = buttonProperties.filter((item) => hoverSkip.indexOf(item.label) === -1);

  return (
    <>
      <HeaderComponent
        setPath={{
          addNewPath: '',
          addNewBtnName: '',
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          headingName: `Edit Button - ${params.name || ''}`,
        }}
      />
      <Row className="ButtonStyles">
        <Col className="col-sm-9">
          <Card className="mb-4">
            <CardBody>
              <FormGroup>
                <Label>
                  Button Name
                </Label>
                <Input
                  value={params.name || ''}
                  onChange={(e) => setParams({ ...params, name: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                Full width on phones?
                <Switch
                  className="custom-switch custom-switch-primary custom-switch-small"
                  checked={params.mobile_full_width}
                  onChange={(e) => setParams({ ...params, mobile_full_width: e })}
                />
              </FormGroup>
            </CardBody>
          </Card>
          <Row>
            <Col>
              <Card className="mb-4">
                <CardBody>
                  <CardTitle>Button Styles</CardTitle>
                  <Row>
                    {!isEmpty(params) && (
                      map(buttonProperties, (styleName) => (
                        <Col sm={styleName.value === 'icon' ? 12 : 6} key={styleName.value}>
                          <FormGroup>
                            <Label>{styleName.label}</Label>
                            {elementRender(styleName, false)}
                          </FormGroup>
                        </Col>
                      ))
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="mb-4">
                <CardBody>
                  <CardTitle>Hover Styles</CardTitle>
                  <FormGroup>
                    <Label>Add override</Label>
                    <CustomSelect
                      isMulti
                      onChange={(e) => {
                        if (!isEmpty(e)) {
                          const tempObj = {};
                          e.forEach((element) => {
                            const getVal = element.value in params.hover_css
                              ? params.hover_css[element.value]
                              : element.defaultValue;
                            tempObj[element.value] = getVal;
                          });
                          setParams({ ...params, hover_css: tempObj });
                        } else {
                          setParams({ ...params, hover_css: {} });
                        }
                      }}
                      value={overRideOptions.filter((o) => typeof params.hover_css[o.value] !== 'undefined')}
                      options={overRideOptions}
                      isSearchable
                      className="mb-2"
                      styles={selectColorStyles}
                    />
                  </FormGroup>
                  <Row>
                    {!isEmpty(params) && params?.hover_css && map(buttonProperties, (styleName) => (
                      styleName.value in params.hover_css && (
                        <Col sm={styleName.value === 'icon' ? 12 : 6} key={styleName.value}>
                          <FormGroup>
                            <Label>{styleName.label}</Label>
                            {elementRender(styleName, true)}
                          </FormGroup>
                        </Col>
                      )
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Col>
        <Col className="col-sm-3">
          <Card className="mb-4" style={{ position: 'sticky', top: '124px' }}>
            <CardBody>
              <CardTitle>Button Preview</CardTitle>

              <div style={{ textAlign: 'center' }}>
                {buttonPreview(params, get(site, 'colors', []))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <BottomActionToolbar
        component={(
          <SaveAndContinue
            onSave={onHandleSubmit}
            onContinue={replacePathParams(siteCenter.buttons, [], props)}
          />
        )}
      />
    </>
  );
}
export default withRouter(ButtonEdit);
