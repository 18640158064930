import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import HeaderComponent from '../../../../components/common/header-component';
import { siteCenter, replacePathParams, settingRoutes } from '../../../../constants/routes';
import CustomButton from '../../../../components/common/button';
import { isEmpty, cloneDeep } from 'lodash-es';
import confirm from '../../../../components/common/confirm';
import BottomActionToolbar from '../../../../components/common/toolbar';
import SaveAndContinue from '../../../../components/common/saveAndContinue';
import { displayError } from '../../../../../../Utils';
import { useParams } from 'react-router';
import StyleOverrideRow from './StyleOverrideRow';
import withRouter from '../../../../helpers/withRouter';
import { addRecentItem } from '../../../../../../UtilsTS';
import {
  useGetTemplatesQuery,
  useGetStyleOverridesQuery,
  useCreateStyleOverrideMutation,
  useDeleteStyleOverrideMutation,
  useUpdateStyleOverrideMutation,
} from '../../../../../../common/api/apiSlice';
import { useSite } from '../../../../../../common/hooks';

const sendParams = (data) => data.map(({ selectedTemplates, selectedSectors, ...rest }) => ({ ...rest }));

function StyleOverrides(props) {
  const { siteId, instanceId } = useParams();
  const [styleOverride, setStyleOverride] = useState([]);
  const site = useSite();

  const { data } = useGetStyleOverridesQuery(siteId, { skip: isEmpty(siteId) });
  const { data: templates = [] } = useGetTemplatesQuery(
    { instanceId, siteId },
    { skip: isEmpty(instanceId) || isEmpty(siteId) },
  );

  const [createStyleOverride] = useCreateStyleOverrideMutation();
  const [updateStyleOverride] = useUpdateStyleOverrideMutation();
  const [deleteStyleOverride] = useDeleteStyleOverrideMutation();

  useEffect(() => {
    if (data) {
      setStyleOverride(cloneDeep(data));
    }
  }, [data]);

  const tempState = [...styleOverride];

  const onHandleAddRow = () => {
    createStyleOverride({
      site_id: siteId,
      breakpoints: {
        sector: [-1],
        element: null,
        all_styles: {},
        site_id: siteId,
        template_ids: [-1],
      },
    });
  };

  const onHandleRemoveRow = async (id) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      deleteStyleOverride({ id, site_id: siteId });
    }
  };

  const onHandleMultiDropdownChange = (e, index, name) => {
    if (isEmpty(e)) {
      tempState[index][name] = [];
    }

    const setValue = Array.isArray(e) ? e.map((x) => x.value) : [];
    if (setValue.includes(-1)) {
      const getIndex = setValue.indexOf(-1);
      if (getIndex > -1) {
        setValue.splice(getIndex, 1);
        tempState[index][name] = [-1];
      }
    } else {
      tempState[index][name] = setValue;
    }

    setStyleOverride(tempState);
  };

  const onHandleChange = (value, index, name, styleKey, isImportant) => {
    if (name === 'important') {
      tempState[index][name] = !tempState[index][name];
    } else if (name === 'all_styles') {
      if (!isEmpty(styleKey)) {
        if (isImportant)
          tempState[index].all_styles[styleKey][1] = value;
        else
          tempState[index].all_styles[styleKey][0] = value;
      } else {
        let tempObj = {};
        if (isEmpty(Object.keys(tempState[index].all_styles))) {
          tempObj = { [value?.[0]?.value]: [] };
        } else {
          Object.keys(tempState[index].all_styles).forEach((ele) => {
            value.forEach((e) => {
              if (ele === e.value) {
                tempObj = { [ele]: tempState[index].all_styles[ele], ...tempObj };
              } else {
                tempObj = { [e.value]: [], ...tempObj };
              }
              if (e.value in tempState[index].all_styles) {
                tempObj[e.value] = tempState[index].all_styles[e.value];
              }
            });
          });
        }
        tempState[index].all_styles = tempObj;
      }
    } else {
      tempState[index][name] = value;
    }
    setStyleOverride(tempState);
  };

  const validateRows = (data) => {
    let error;
    data.forEach((item) => {
      if (isEmpty(item.element)) {
        displayError('Please select element');
        error = true;
      } else if (isEmpty(item.all_styles)) {
        displayError('Please select style');
        error = true;
      } else {
        error = false;
      }
    });
    return error;
  };

  const onHandleSubmit = () => {
    if (isEmpty(styleOverride) || validateRows(styleOverride)) {
      return false;
    }

    return updateStyleOverride({
      site_id: siteId,
      breakpoints: sendParams(styleOverride),
    });
  };

  if (instanceId && siteId) {
    addRecentItem({
      instance_id: instanceId,
      site_id: siteId,
      type: 'style-overrides',
    });
  }

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Style Overrides (BETA)',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Dashboard',
        backToPath: settingRoutes.dashboard,
        showBreadcrumb: false,
        staticBreadcrumbData: [
          {
            name: 'Dashboard',
            url: replacePathParams(settingRoutes.dashboard, [], props),
          },
          {
            name: 'Site Centre',
            url: replacePathParams(siteCenter.pageList, [], props),
          },
          {
            name: 'Design Options',
            url: replacePathParams(siteCenter.designOption, [], props),
          },
          { name: 'Style Overrides', url: '' },
        ],
      }}
      />
      <Card>
        <CardBody>
          <Row className="sticky-header">
            <Col md={11}>
              <Row>
                <Col>Template</Col>
                <Col>Sector</Col>
                <Col>Element</Col>
                <Col>Style</Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>
          <StyleOverrideRow
            site={site}
            styles={styleOverride}
            onHandleChange={onHandleChange}
            onHandleRemoveRow={onHandleRemoveRow}
            templates={templates}
            onHandleMultiDropdownChange={onHandleMultiDropdownChange}
          />
        </CardBody>
      </Card>
      <BottomActionToolbar
        component={(
          <>
            <CustomButton
              color="info"
              className="common-info-button button-md fw-bold"
              title="ADD OVERRIDE"
              onClick={() => onHandleAddRow()}
            />
            {' '}
            <SaveAndContinue
              onSave={onHandleSubmit}
              onContinue={replacePathParams(siteCenter.designOption, [], props)}
            />
          </>
        )}
      />
    </>
  );
}

export default withRouter(StyleOverrides);
