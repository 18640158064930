import React, { useState } from 'react';
import Switch from 'rc-switch';
import { Tooltip } from 'reactstrap';
import { defaultTheme, themeStorageKey } from '../../constants/defaultValues';
import { setTheme } from '../../../../Utils';

const getTheme = () => localStorage.getItem(themeStorageKey) || defaultTheme;

const TopnavDarkSwitch = () => {
  const theme = getTheme();

  const [switchChecked, setSwitchChecked] = useState(theme === 'dark');
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const changeMode = () => {
    let theme = getTheme();

    if (theme === 'dark') {
      theme = 'light';
    } else {
      theme = 'dark';
    }

    setSwitchChecked(theme === 'dark');
    setTheme(theme);
    localStorage.setItem(themeStorageKey, theme);
  };

  return (
    <div className="d-none d-md-inline-block align-middle ms-3 me-3">
      <Switch
        id="DarkModeSwitch"
        className="custom-switch custom-switch-primary custom-switch-small"
        checked={switchChecked}
        onChange={changeMode}
      />

      <Tooltip
        placement="left"
        isOpen={tooltipOpen}
        target="DarkModeSwitch"
        toggle={toggle}
      >
        Dark Mode
      </Tooltip>
    </div>
  );
};

export default TopnavDarkSwitch;
