import React, { useMemo, useState, useEffect } from 'react';
import { isArray, isEmpty, cloneDeep } from 'lodash-es';
import CustomSelect from 'Dashboard/src/components/common/custom-select';
import { Container, Row, Col, FormGroup, Label } from 'reactstrap';
import { MaterialReactTable } from 'material-react-table';
import HeaderComponent from 'Dashboard/src/components/common/header-component';
import CustomButton from 'Dashboard/src/components/common/button';
import { settingRoutes, replacePathParams, mainRoutes } from 'Dashboard/src/constants/routes';
import UserFeaturePage from 'Dashboard/src/components/common/user-feature-page';
import withRouter from 'Dashboard/src/helpers/withRouter';
import {
  useGetSavedReportsQuery,
  useCreateSavedReportMutation,
  useUpdateSavedReportMutation,
  useDeleteSavedReportMutation,
  useGetReportConfigQuery,
  useGetReportDataQuery,
} from 'common/api/apiSlice';
import './index.scss';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useUser } from 'common/hooks';
import SavedReport from 'common/models/SavedReport';

function Cell({ bool }) {
  return <span style={{ fontSize: 14, color: bool ? 'green' : 'red' }}>{bool ? '✓' : '✗'}</span>;
}

function Reports(props) {
  const { data: savedReports } = useGetSavedReportsQuery();
  const [createSavedReport] = useCreateSavedReportMutation();
  const [updateSavedReport] = useUpdateSavedReportMutation();
  const [deleteSavedReport] = useDeleteSavedReportMutation();
  const [savedReport, setSavedReport] = useState(null);
  const { data: reportConfig } = useGetReportConfigQuery();
  const [currentReport, setCurrentReport] = useState('Sites');
  const user = useUser();

  // Table state
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (!savedReport) return;

    const details = savedReport.details;
    setCurrentReport(details.currentReport);
    setColumnVisibility(details.columnVisibility);
    setSorting(details.sorting);

    // Need to convert the value field in the filters to a Luxon DateTime object
    const filters = cloneDeep(details.columnFilters);
    filters.forEach((filter, i) => {
      if (!isArray(filter.value)) return;

      if (filter.value[0]) {
        try {
          const dt = DateTime.fromISO(filter.value[0]);
          filter.value[0] = dt;
        } catch {
          // Do nothing
        }
      }

      if (filter.value[1]) {
        try {
          const dt = DateTime.fromISO(filter.value[1]);
          filter.value[1] = dt;
        } catch {
          // Do nothing
        }
      }

      filters[i] = filter;
    });

    setColumnFilters(filters);
  }, [savedReport]);

  const currentReportConfig = reportConfig?.find((r) => r.name === currentReport);

  const initialColumnVisibility = {};
  currentReportConfig?.columns?.forEach((column) => initialColumnVisibility[column.accessorKey] = !column.hidden);

  let fullColumnVisibility;
  if (Object.keys(columnVisibility).length === 0) {
    fullColumnVisibility = initialColumnVisibility;
  } else {
    fullColumnVisibility = columnVisibility;
    currentReportConfig?.columns?.forEach((column) => {
      if (typeof fullColumnVisibility[column.accessorKey] === 'undefined') {
        fullColumnVisibility[column.accessorKey] = !column.hidden;
      }
    });
  }

  const { data: reportData } = useGetReportDataQuery({
    currentReport,
    columnVisibility: fullColumnVisibility,
    columnFilters: cloneDeep(columnFilters),
    pagination,
    sorting,
  }, { skip: isEmpty(currentReport) });

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Settings',
      url: replacePathParams(mainRoutes.setting, [], props),
    },
    { name: 'Reports', url: '' },
  ];

  const columns = useMemo(
    () => {
      if (!reportConfig || !currentReport || !reportData) {
        return [];
      }

      // The columns array is frozen when it comes out of RTK
      return currentReportConfig.columns.map((c) => {
        const column = { ...c };

        if (column.filterVariant === 'checkbox') {
          column.Cell = function CellOuter({ renderedCellValue }) {
            return <Cell bool={renderedCellValue} />;
          };
        } else if (column.link) {
          column.Cell = function CellLink({ renderedCellValue }) {
            if (!renderedCellValue) {
              return '';
            }

            return (
              <a href={renderedCellValue?.match(/^https?:\/\//) ? renderedCellValue : `https://${renderedCellValue}`} target="_blank" rel="noreferrer">
                {renderedCellValue}
              </a>
            );
          };
        }

        return column;
      });
    },
    [reportConfig, currentReport, reportData],
  );

  if (!reportConfig) {
    return <></>;
  }

  const sx = {
    fontSize: 12,
    fontFamily: 'Source Sans Pro',
  };

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Reports',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <UserFeaturePage feature="reports">
        <div className="Reports">
          <Container fluid>
            <Row>
              <Col>
                <FormGroup style={{ maxWidth: '400px' }}>
                  <Label>Saved Reports</Label>
                  <CustomSelect
                    options={savedReports?.map((report) => ({ label: report.name, value: report.id }))}
                    className="flex-grow-1"
                    onChange={(e) => setSavedReport(savedReports.find((r) => r.id === e.value))}
                    value={{ label: savedReport?.name, value: savedReport?.id }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup style={{ maxWidth: '400px' }}>
                  <Label>Report</Label>
                  <CustomSelect
                    options={reportConfig.map((object) => ({ label: object.name, value: object.name }))}
                    className="flex-grow-1"
                    onChange={(e) => setCurrentReport(e.value)}
                    value={{ label: currentReport, value: currentReport }}
                  />
                </FormGroup>
              </Col>
              <Col className="position-relative me-3 mb-4">
                <div className="position-absolute bottom-0 end-0">
                  {savedReport?.id && (
                    <>
                      <CustomButton
                        size="md"
                        color="info"
                        title="Delete Saved Report"
                        onClick={async () => {
                          if (!confirm('Are you sure you want to delete this report?')) return;

                          await deleteSavedReport(savedReport);
                          setSavedReport(null);
                        }}
                      />
                      {' '}
                    </>
                  )}
                  <CustomButton
                    size="md"
                    color="info"
                    title="Save Report"
                    onClick={async () => {
                      const name = prompt('Enter a name for the report', savedReport?.name);

                      if (!name) return;

                      let result;

                      const details = {
                        currentReport,
                        columnVisibility,
                        columnFilters,
                        sorting,
                      };

                      if (savedReport?.id) {
                        result = await updateSavedReport({ ...savedReport, name, details });
                      } else {
                        result = await createSavedReport({ user_id: user.id, name, details });
                      }

                      if (result?.data?.meta?.is_success) {
                        setSavedReport(new SavedReport(result.data.data));
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
          {reportData && (
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <MaterialReactTable
                columns={columns}
                data={reportData.rows}
                enableGlobalFilter={false}
                initialState={{
                  showColumnFilters: true,
                  columnVisibility: initialColumnVisibility,
                }}
                manualFiltering
                manualPagination
                manualSorting
                onColumnVisibilityChange={setColumnVisibility}
                onColumnFiltersChange={setColumnFilters}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={reportData.rowCount}
                state={{
                  columnVisibility: fullColumnVisibility,
                  columnFilters: cloneDeep(columnFilters),
                  pagination,
                  sorting,
                }}
                muiTableHeadCellProps={{ sx }}
                muiFormControlProps={{ sx }}
                muiTableBodyCellProps={{ sx }}
                muiTableHeadCellFilterTextFieldProps={{ sx }}
                muiTableContainerProps={{ sx }}
              />
            </LocalizationProvider>
          )}
        </div>
      </UserFeaturePage>
    </>
  );
}

export default withRouter(Reports);
