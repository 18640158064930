export const galleryStyleOption = [
  { label: 'Fade', value: 'fade' },
  { label: 'Scroll', value: 'scroll' },
];

export const widthTypeOption = [
  { label: 'pixel', value: 'pixel' },
  { label: '%', value: 'percentage' },
];

export const submitType = {
  SAVE: 'save',
  ADD: 'add',
  UPDATE: 'update',
};
