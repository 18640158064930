import React, { useState, useEffect } from 'react';
import {
  Button,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { isEmpty, map } from 'lodash-es';
import './index.scss';
import withRouter from '../../../helpers/withRouter';
import { useIsMobile } from 'common/hooks';

const pageSizes = [10, 25, 50, 100];

const getPager = (totalItems, currentPage, pageSize, totalPages) => {
  // default to first page
  currentPage = currentPage || 1;

  // default page size is 10
  pageSize = pageSize || 10;

  let startPage;
  let endPage;
  if (totalPages <= 10) {
    // less than 10 total pages so show all
    startPage = 1;
    endPage = totalPages;
  } else {
    // more than 10 total pages so calculate start and end pages
    if (currentPage <= 6) {
      startPage = 1;
      endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = currentPage - 5;
      endPage = currentPage + 4;
    }
  }

  // calculate start and end item indexes
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  const pages = [...Array((endPage + 1) - startPage).keys()].map((i) => startPage + i);

  // return object with all pager properties required by the view
  return {
    totalItems,
    currentPage,
    pageSize,
    totalPages,
    startPage,
    endPage,
    startIndex,
    endIndex,
    pages,
  };
};

const Pagination = (props) => {
  const {
    onChangePage,
    getPagination,
    getPagination: { count, per_page, pages },
    records,
    currentPage,
    type,
    pageSize,
    changePageSize,
  } = props;

  const [pager, setPager] = useState({});

  useEffect(() => {
    if (records && records.length && currentPage >= 0) {
      setPager(getPager(count, currentPage, per_page, pages));
    }
  }, [records]);

  const setPage = (page) => {
    if (page < 1 || page > pager.pages) {
      return;
    }
    const updatedPager = getPager(count, page, per_page, pages);
    setPager(updatedPager);
    onChangePage(page);
  };

  const pageButton = (data, isResponsive, max) => map(data.pages, (page) => {
    const disableBtn = data.currentPage === page;
    if ((page < max && page >= data.currentPage) || isResponsive) {
      return (
        <li key={`page_${page}`}>
          <Button
            className={`${disableBtn && 'active'}`}
            onClick={() => setPage(page)}
            disabled={disableBtn}
          >
            {page}
          </Button>
        </li>
      );
    }
    return '';
  });

  let isResponsive = true;
  if (!window.matchMedia('screen and (min-width: 1224px)').matches) {
    isResponsive = false;
  }

  const max = pager.currentPage + 5;

  const start = (getPagination.current * getPagination.per_page + 1) - (getPagination.per_page);
  const total = getPagination.count;
  const endValue = getPagination.current * getPagination.per_page;
  const end = endValue > total ? total : endValue;
  const disablePrevBtn = pager.currentPage === 1;
  const disableNextBtn = pager.currentPage === pager.totalPages;
  const isMobile = useIsMobile();

  if (isEmpty(pager)) return '';

  return (
    <div className={`Pagination ${isMobile ? 'mt-2 mb-2' : 'mt-4 mb-4'}`}>
      <div className="d-flex align-items-center justify-content-center">
        {!isMobile && (
          <span>
            <b>{`Found ${getPagination.count ? getPagination.count : 0} ${type[getPagination.count === 1 ? 0 : 1]}`}</b>
          </span>
        )}
        {!isEmpty(pager)
        && (
          <ul className={`custom-ul ${!isMobile ? 'ms-4 me-4' : ''}`}>
            {!isMobile && (
              <li>
                <Button
                  className={`prev ${disablePrevBtn && 'disabled'}`}
                  onClick={() => setPage(1)}
                  disabled={disablePrevBtn}
                >
                  <i className="fal fa-step-backward" />
                </Button>
              </li>
            )}
            <li>
              <Button
                className={`prev ${disablePrevBtn && 'disabled'}`}
                onClick={() => setPage(pager.currentPage - 1)}
                disabled={disablePrevBtn}
              >
                <i className="fal fa-chevron-left" />
              </Button>
            </li>
            {pageButton(pager, isResponsive, max)}
            <li>
              <Button
                className={`next ${disableNextBtn && 'disabled'}`}
                onClick={() => setPage(pager.currentPage + 1)}
                disabled={disableNextBtn}
              >
                <i className="fal fa-chevron-right" />
              </Button>
            </li>
            {!isMobile && (
              <li>
                <Button
                  className={`next ${disableNextBtn && 'disabled'}`}
                  onClick={() => setPage(pager.totalPages)}
                  disabled={disableNextBtn}
                >
                  <i className="fal fa-step-forward" />
                </Button>
              </li>
            )}
          </ul>
        )}
        {!isMobile && (
          <div className="float-right">
            <span className="text-muted text-small me-1">
              {getPagination.pages === 0 ? '' : `${start}-${end} of ${total}`}
            </span>
            <UncontrolledDropdown className="d-inline-block">
              <DropdownToggle caret color="outline-primary fw-bold">
                {pageSize}
              </DropdownToggle>
              <DropdownMenu end>
                {pageSizes.map((size) => (
                  <DropdownItem
                    key={`pageSize_${size}`}
                    onClick={() => changePageSize(size)}
                  >
                    {size}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Pagination);
