import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import confirm from '../../components/common/confirm';
import { displayError, setPageTitle } from '../../../../Utils';
import HeaderComponent from '../../components/common/header-component';
import { dataCenter, replacePathParams, mainRoutes, settingRoutes } from '../../constants/routes';
import { DBFieldTypeOptions } from '../../../../common/schema';
import CustomButton from '../../components/common/button';
import { useParams, useNavigate } from 'react-router-dom';
import { map, isEmpty, cloneDeep } from 'lodash-es';
import BottomActionToolbar from '../../components/common/toolbar';
import DragDropComponent from '../../components/common/reactSortable';
import withRouter from '../../helpers/withRouter';
import { useCreateDatabaseFieldMutation, useDeleteDatabaseFieldMutation, useGetDatabaseFieldsQuery, usePositionDatabaseFieldMutation } from '../../../../common/api/apiSlice';

function DataFieldListing(props) {
  const { instanceId } = useParams();
  const navigate = useNavigate();
  const [fields, setFields] = useState([]);
  const { data } = useGetDatabaseFieldsQuery({ instanceId }, { skip: isEmpty(instanceId) });

  const [createDatabaseField] = useCreateDatabaseFieldMutation();
  const [deleteDatabaseField] = useDeleteDatabaseFieldMutation();
  const [positionUpdateDatabaseField] = usePositionDatabaseFieldMutation();

  useEffect(() => {
    setPageTitle('Database Fields');
  }, []);

  useEffect(() => {
    if (data) {
      setFields(cloneDeep(data));
    }
  }, [data]);

  const getFieldTypeLabel = (key_name) => {
    const labelName = DBFieldTypeOptions.filter((field) => field.value === key_name)[0] || '';
    return labelName ? labelName.label : null;
  };

  const onDeleteHandler = async (e, fieldId) => {
    try {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });
      if (result) {
        const sendParams = {
          field_id: fieldId,
          instance_id: instanceId,
        };
        deleteDatabaseField(sendParams);
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const onEditDataField = (e, field_id) => {
    navigate(
      replacePathParams(
        dataCenter.editDatabaseField,
        [{ key: 'id', value: field_id }], props,
      ),
    );
  };

  const dataFieldsListing = () => map(fields, (data) => (
    <CardBody
      key={data.id}
      data-id={data.id}
      className="d-flex custom-cursor-pointer-draggable border-bottom"
    >
      <Colxx className="mt-2">
        <Button className="custom-simple-icon" color="info" size="xs"><i className="fal fa-arrows-alt" /></Button>
      </Colxx>
      <Colxx className="mt-2">
        <span className="text-capitalize">{`${data.attributes.field_name}`}</span>
        {' '}
        {data.attributes.default ? <span className="text-danger">*</span> : ''}
      </Colxx>
      <Colxx className="mt-2">
        <span className="text-capitalize">{`${getFieldTypeLabel(data.attributes.field_type)}`}</span>
      </Colxx>
      <Colxx className="mt-2">
        <Button
          className="custom-simple-icon"
          color="info"
          size="xs"
          onClick={(e) => onEditDataField(e, data.id)}
        >
          <i className="fal fa-pen" />
        </Button>
        {' '}
        <Button
          className="custom-simple-icon"
          color="danger"
          size="xs"
          onClick={(e) => onDeleteHandler(e, data.id)}
          disabled={data.attributes.default}
        >
          <i className="fal fa-trash" />
        </Button>
      </Colxx>
    </CardBody>
  ));

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Data Centre',
      url: replacePathParams(mainRoutes.dataCenter, [], props),
    },
    { name: 'Database Fields', url: '' },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        addNewPath: '',
        addNewBtnName: '',
        backBtnName: 'Back',
        staticBreadcrumbData,
        showBreadcrumb: false,
        headingName: 'Database Fields',
        backToPath: mainRoutes.dataCenter,
      }}
      />
      <Card className="rounded mb-4">
        <CardBody className="d-flex padding-10 border-bottom">
          <Colxx className="mb-1 truncate"><b>Sort</b></Colxx>
          <Colxx className="mb-1 truncate"><b>Field Name</b></Colxx>
          <Colxx className="mb-1 truncate"><b>Field Type</b></Colxx>
          <Colxx className="mb-1 truncate"><b>Action</b></Colxx>
        </CardBody>
        <DragDropComponent
          keyName="database_fields"
          params={{
            instance_id: instanceId,
          }}
          list={fields}
          returnData={dataFieldsListing()}
          setNewState={(e) => setFields(e)}
          onSetList={(newState) => setFields(newState)}
          APICall={(payload) => positionUpdateDatabaseField(payload)}
        />
      </Card>
      <BottomActionToolbar
        component={(
          <CustomButton
            color="info"
            className="add-new-button button-md fw-bold"
            title="ADD FIELD"
            icon="fa fa-plus"
            onClick={() => createDatabaseField(instanceId)}
          />
        )}
      />
    </>
  );
}

export default withRouter(DataFieldListing);
