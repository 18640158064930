import React from 'react';
import { Label } from 'reactstrap';
import MasterPageSelectBox from './MasterPageSelectBox';

function UnsubscribePage(props) {
  const {
    params, setParams, siteId, instanceId,
  } = props;

  return (
    <>
      <Label>
        Unsubscribe Master Page
      </Label>
      <MasterPageSelectBox
        siteId={siteId}
        params={params}
        setParams={setParams}
        instanceId={instanceId}
        keyName="unsubscribe_master_page_id"
      />
    </>
  );
}

export default UnsubscribePage;
