import React from 'react';
import { useIsMobile } from 'common/hooks';

const DesktopOnly: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div
        style={{
          textAlign: 'center',
          padding: '20px',
          backgroundColor: '#f8d7da',
          color: '#721c24',
          border: '1px solid #f5c6cb',
          borderRadius: '5px',
        }}
      >
        Sorry, this feature is not available on mobile devices.
      </div>
    );
  }

  return <>{children}</>;
};

export default DesktopOnly;
