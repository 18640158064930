import React, { useState, useEffect, useRef, Fragment } from 'react';
import useAutosave from '../../../helpers/useAutosave';
import { useParams } from 'react-router';
import {
  useGetUsersQuery,
  useGetSiteBriefingQuery,
  useUpdateSiteBriefingMutation,
  useAddImageToBriefingMutation,
  useLazyGenerateQuestionsQuery,
  useGetAllDomainsQuery,
  useGetMasterInstancesQuery,
} from '../../../../../common/api/apiSlice';
import {
  Alert,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Container,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
} from 'reactstrap';
import Checkbox from '../../../components/common/checkBox/';
import CustomSelect from '../../../components/common/custom-select';
import { isEmpty, map, cloneDeep, flatten, filter, debounce, set } from 'lodash-es';
import { setPageTitle, getSelectedOption, displaySuccess, displayError } from '../../../../../Utils';
import UserFeaturePage from '../../../components/common/user-feature-page';
import HeaderComponent from '../../../components/common/header-component';
import Switch from 'rc-switch';
import { mainRoutes, settingRoutes, replacePathParams } from '../../../constants/routes';
import withRouter from '../../../helpers/withRouter';
import BottomActionToolbar from '../../../components/common/toolbar';
import SaveAndContinue from '../../../components/common/saveAndContinue';
import './SiteBriefingForm.scss';

const getUserOptions = (users) => {
  if (!isEmpty(users)) {
    return map(
      filter(users, (u) => u.email.toString().match(/@webgenius\.co\.nz/)),
      (user) => ({
        label: `${user.full_name} (${user.user_name} / ${user.email})`,
        value: user.id,
      }),
    );
  }
  return [];
};

// Automatically set textarea height depending on the number of lines.
const autoHeightTextArea = (e) => {
  e.target.style.height = 'inherit';
  e.target.style.height = `${e.target.scrollHeight}px`;
};

const AutoHeightTextArea = ({ name, value, onChange, style }) => (
  <Input
    type="textarea"
    name={name}
    value={value}
    onChange={onChange}
    style={style}
  />
);

function SiteBriefingForm(props) {
  const { id } = useParams();
  const { data: briefing } = useGetSiteBriefingQuery(id);
  const { data: users } = useGetUsersQuery();
  const [updateBriefing] = useUpdateSiteBriefingMutation();
  const [addImage] = useAddImageToBriefingMutation();

  const [tempBriefing, setTempBriefing] = useState(null);

  const [generatingQuestionsForProductId, setGeneratingQuestionsForProductId] = useState(null);
  const [generatingQuestionsForSubProductId, setGeneratingQuestionsForSubProductId] = useState(null);
  const [triggerGenerateQuestions, { data: { data: generatedQuestions = [] } = {} }] = useLazyGenerateQuestionsQuery();
  useEffect(() => {
    if (generatedQuestions.length > 0 && generatingQuestionsForProductId !== null) {
      const productPages = cloneDeep(tempBriefing.info.product_pages || []);
      productPages[generatingQuestionsForProductId] ||= {};

      if (generatingQuestionsForSubProductId === null) {
        productPages[generatingQuestionsForProductId].other_info = generatedQuestions.join('\n\n');
      } else {
        productPages[generatingQuestionsForProductId].sub_pages[generatingQuestionsForSubProductId].other_info = generatedQuestions.join('\n\n\n\n') + '\n\n';
      }

      setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
    }
  }, [generatedQuestions]);

  const [includePhase2, setIncludePhase2] = useState(true);
  const [tempDomainSkip, setTempDomainSkip] = useState(false);
  const { data: tempSearchDomains } = useGetAllDomainsQuery(
    {
      domainName: `www.${tempBriefing?.info?.temp_domain}.wb.gs`,
      disableSpinner: true,
    },
    { skip: tempDomainSkip || isEmpty(tempBriefing?.info?.temp_domain) },
  );
  const { data: masterInstances } = useGetMasterInstancesQuery();
  const ref = useRef(null);
  const navRef = useRef(null);

  // Highlight the current section in the brief nav
  const scrollHandler = debounce(() => {
    const boxes = document.querySelectorAll('.anchor-box');
    document.querySelectorAll('.briefing-nav a').forEach((a) => a.classList.remove('active'));

    for (let i = 0; i < boxes.length; i++) {
      const box = boxes[i];
      const rect = box.getBoundingClientRect();
      const top = rect.top - 200;
      const windowHeight = (window.innerHeight || document.documentElement.clientHeight) - 200;
      const percentVisible = 5;

      const isInViewport = !(
        Math.floor(100 - (((top >= 0 ? 0 : top) / +-rect.height) * 100)) < percentVisible
        || Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
      );

      if (isInViewport) {
        const boxName = [...box.classList].find((c) => c.match(/anchor-box-/)).replace(/anchor-box-/, '');
        const navLink = document.querySelector(`.briefing-nav a[href="#${boxName}"]`);
        navLink.classList.add('active');
        navLink.scrollIntoViewIfNeeded();

        break;
      }
    }
  }, 100);

  useEffect(() => {
    setPageTitle('Site Briefing');

    document.addEventListener('scroll', scrollHandler);

    return () => document.removeEventListener('scroll', scrollHandler);
  }, []);

  useEffect(() => {
    if (briefing && !tempBriefing) {
      setTempBriefing({ ...briefing });

      setTimeout(() => {
        ref.current.querySelectorAll('textarea').forEach((t) => {
          autoHeightTextArea({ target: t });
        });
      }, 1000);
    }
  }, [briefing]);

  // Set the master_instance field to the first one in the list if it's empty.
  useEffect(() => {
    if (tempBriefing && masterInstances && isEmpty(tempBriefing.info.master_instance)) {
      setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, master_instance: masterInstances.at(0)?.id } });
    }
  }, [tempBriefing, masterInstances]);

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Settings',
      url: replacePathParams(mainRoutes.setting, [], props),
    },
    {
      name: 'Briefings',
      url: replacePathParams(settingRoutes.siteBriefings, [], props),
    },
    { name: 'Briefing', url: '' },
  ];

  useAutosave(() => {
    updateBriefing({ disableSpinner: true, ...tempBriefing });
  }, 30 * 1000);

  const onChange = (e) => {
    let value;

    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else if (e.target.type === 'file') {
      value = e.target.files;
    } else {
      value = e.target.value;
    }

    setTempBriefing({
      ...tempBriefing,
      info: {
        ...tempBriefing.info,
        [e.target.name]: value,
      },
    });

    if (e.target.name === 'temp_domain') {
      setTempDomainSkip(true);
      setTimeout(() => setTempDomainSkip(false), 500);
    }
  };

  const onSave = async () => {
    for (let k in tempBriefing.info) {
      if (tempBriefing.info[k] instanceof FileList) {
        [...tempBriefing.info[k]].forEach(async (file) => {
          const result = await addImage({ briefing: tempBriefing, name: k, file });
          if (result.error) {
            displayError(flatten(Object.values(result.error.data.data)));
          } else {
            displaySuccess(`File ${file.name} uploaded successfully.`);
          }
        });
      }
    }

    const result = await updateBriefing(tempBriefing);
    if (result.error) {
      displayError(flatten(Object.values(result.error.data.data)));
      return false;
    }

    displaySuccess('Briefing saved successfully!');
    return true;
  };

  const Dropdown = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <UncontrolledDropdown
        nav
        inNavbar
        key={props.index}
        isOpen={isOpen}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        onClick={() => window.location = props.href}
      >
        <NavLink href={props.href}>
          <DropdownToggle nav caret={!!props.children}>
            {props.label}
          </DropdownToggle>
        </NavLink>
        {props.children}
      </UncontrolledDropdown>
    );
  };

  return (
    <div ref={ref} className="SiteBriefing">
      <HeaderComponent setPath={{
        headingName: 'Briefing',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Settings',
        backToPath: replacePathParams(mainRoutes.setting, [], props),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <UserFeaturePage feature="view_briefings">
        <Row className={includePhase2 ? 'include-phase-2' : ''}>

          <Navbar expand className="briefing-nav h-auto mb-4" innerRef={navRef}>
            <Collapse isOpen={true} navbar>
              <Nav className="ms-2 align-items-center flex-wrap" navbar>
                <NavItem>
                  <NavLink href="#generalBusinessInfo">
                    General Business Info
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#designDetails">
                    Design Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#websiteStructure">
                    Website Structure
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#businessMarketingInfo">
                    Business Marketing Info
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#homePageBriefInfo">
                    Home Page
                  </NavLink>
                </NavItem>
                {tempBriefing?.info?.include_about_page && (
                  <NavItem>
                    <NavLink href="#aboutPageBriefInfo">
                      About Page
                    </NavLink>
                  </NavItem>
                )}
                {Array.from({ length: tempBriefing?.info?.number_of_product_pages }, (v, i) => i).map((index) => (
                  <Dropdown
                    key={index}
                    href={`#asapPageBriefInfo${index + 1}`}
                    label={tempBriefing.info.product_pages[index]?.name ? `Product Page: ${tempBriefing.info.product_pages[index]?.name}` : `Product/Service Page ${index + 1}`}
                  >
                    {tempBriefing.info.product_pages[index]?.number_of_sub_pages > 0 && (
                      <DropdownMenu right>
                        {Array.from({ length: tempBriefing.info.product_pages[index]?.number_of_sub_pages }, (v, i) => i).map((subIndex) => (
                          <DropdownItem key={subIndex}>
                            <a href={`#subPageBriefInfo_${index + 1}_${subIndex + 1}`}>
                              {tempBriefing.info.product_pages[index]?.sub_pages?.at(subIndex)?.name ? tempBriefing.info.product_pages[index]?.sub_pages?.at(subIndex)?.name : `Sub-Page ${subIndex + 1}`}
                            </a>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    )}
                  </Dropdown>
                ))}
                {tempBriefing?.info?.include_gallery_page && (
                  <NavItem>
                    <NavLink href="#galleryPageBriefInfo">Gallery Page</NavLink>
                  </NavItem>
                )}
                {tempBriefing?.info?.include_faq_page && (
                  <NavItem>
                    <NavLink href="#faqPageBriefInfo">FAQ Page</NavLink>
                  </NavItem>
                )}
                {tempBriefing?.info?.include_enquiry_page && (
                  <NavItem>
                    <NavLink href="#makeAnEnquiryPageBriefInfo">Make an Enquiry Page</NavLink>
                  </NavItem>
                )}
                <NavItem>
                  <NavLink href="#contactPageBriefInfo">Contact Page</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#aceBriefInfo">ACE</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#domainsBriefInfo">Domains</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#keywordPhrases">15 Keyword Phrases</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>

          <Card className="mb-4 hide-pdf">
            <CardBody>
              <FormGroup>
                <Label>Include Phase 2 Fields</Label>
                <Switch
                  className="custom-switch custom-switch-primary"
                  checked={includePhase2}
                  onChange={(e) => setIncludePhase2(e)}
                />
              </FormGroup>
            </CardBody>
          </Card>

          <a className="anchor" id="generalBusinessInfo" />
          <Card className="mb-4 anchor-box anchor-box-generalBusinessInfo">
            <CardBody>
              <CardTitle>General Business Info</CardTitle>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Business name</Label>
                    <aside className="hide-pdf">
                      <span className="prompt">Will be used in copy</span>
                    </aside>
                    <Input name="company_name" value={tempBriefing?.info?.company_name} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Instance name</Label>
                    <aside className="hide-pdf">
                      <span className="prompt">Full business name</span>
                    </aside>
                    <Input name="instance_name" value={tempBriefing?.info?.instance_name} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Base instance</Label>
                    <CustomSelect
                      options={masterInstances?.map((i) => ({ label: i.company_name, value: i.id }))}
                      value={getSelectedOption(
                        masterInstances?.map((i) => ({ label: i.company_name, value: i.id })),
                        tempBriefing?.info?.master_instance || masterInstances?.at(0)?.id,
                      )}
                      isClearable
                      isSearch={true}
                      className="flex-grow-1"
                      onChange={(e) => onChange({ target: { name: 'master_instance', value: e.value } })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Brief date</Label>
                    <Input
                      type="date"
                      name="brief_date"
                      value={tempBriefing?.info?.brief_date}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Project Manager</Label>
                    <CustomSelect
                      options={getUserOptions(users)}
                      value={getSelectedOption(getUserOptions(users), tempBriefing?.info?.project_manager)}
                      isClearable
                      isSearch={false}
                      className="flex-grow-1"
                      onChange={(e) => onChange({ target: { name: 'project_manager', value: e.value } })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Web Marketing Adviser</Label>
                    <CustomSelect
                      isClearable
                      options={getUserOptions(users)}
                      value={getSelectedOption(getUserOptions(users), tempBriefing?.info?.adviser)}
                      isSearch={false}
                      className="flex-grow-1"
                      onChange={(e) => onChange({ target: { name: 'adviser', value: e.value } })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Client&apos;s name</Label>
                    <Input name="client_name" value={tempBriefing?.info?.client_name} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Client contact Number</Label>
                    <Input name="contact_number" value={tempBriefing?.info?.contact_number} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Client email address</Label>
                    <Input type="email" name="client_email" value={tempBriefing?.info?.client_email} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Container className="ms-4">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Other information related to email addresses</Label>
                          <AutoHeightTextArea
                            name="other_info_email"
                            value={tempBriefing?.info?.other_info_email}
                            onChange={onChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Products purchased</Label>
                    <AutoHeightTextArea
                      name="products_purchased"
                      value={tempBriefing?.info?.products_purchased}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Delivery expectations</Label>
                    <AutoHeightTextArea
                      name="delivery_expectations"
                      value={tempBriefing?.info?.delivery_expectations}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Is SEO audit done?</Label>
                    <Input name="seo_audit_done" value={tempBriefing?.info?.seo_audit_done} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Existing website?</Label>
                    <Input name="existing_website" value={tempBriefing?.info?.existing_website} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Can we take info from above?</Label>
                    <AutoHeightTextArea
                      name="can_take_info_from_website"
                      value={tempBriefing?.info?.can_take_info_from_website}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>What other sources are there for website?</Label>
                    <AutoHeightTextArea
                      name="other_sources"
                      value={tempBriefing?.info?.other_sources}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Phone Number</Label>
                    <Input name="phone" value={tempBriefing?.info?.phone} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Call Tracking Number</Label>
                    <aside>
                      This is a unique landline phone number that only appears on your website (it does not appear anywhere else, eg. not on your business cards, vehicles, flyers or posters). It forwards calls to a phone number of your choice - either mobile or landline. Since this phone number only appears on your website, it is used to accurately count the number of calls that are generated from your website for your monthly ROI report (Return on Investment). The report also tracks form submissions, number of visits etc as well. The report only counts unique phone numbers once so any repeat calls by customers are not included in the report.
                    </aside>
                    <Input name="call_tracking_number" value={tempBriefing?.info?.call_tracking_number} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Mobile number for text enquiries</Label>
                    <Input name="mobile_for_sms" value={tempBriefing?.info?.mobile_for_sms} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Email address for forms</Label>
                    <Input name="form_email" value={tempBriefing?.info?.form_email} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Email address(es) for Monthly Report</Label>
                    <Input name="roi_emails" value={tempBriefing?.info?.roi_emails} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Physical address for GBP</Label>
                    <aside className="hide-pdf">
                      Must have a physical address - please state if it&apos;s their home address, or if its a business address but not open to the public etc.
                    </aside>
                    <AutoHeightTextArea
                      name="gbp_address"
                      value={tempBriefing?.info?.gbp_address}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Are you happy for this address to be online?</Label>
                    <Input name="gbp_address_online" value={tempBriefing?.info?.gbp_address_online} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>If you have a GBP, enter the information below</Label>
                    <Container className="ms-4">
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Link</Label>
                            <Input name="gbp_url" value={tempBriefing?.info?.gbp_url} onChange={onChange} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Map code</Label>
                            <Input name="gbp_map_code" value={tempBriefing?.info?.gbp_map_code} onChange={onChange} />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Container>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>GBP status/required action</Label>
                    <aside>
                      (i.e. Advisor verified, Client manages own GMB, Client has verified GMB but has no idea who did it)
                    </aside>
                    <AutoHeightTextArea
                      name="gbp_status"
                      value={tempBriefing?.info?.gbp_status}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>
                      Service location boundary - primarily for GBP but can help with geo statements below
                    </Label>
                    <aside className="hide-pdf">
                      How far North, South, East and West will they service? Eg. North to Silverdale, West Auckland and
                      South to Manukau. Must have a physical address if its a destination like a shop or showroom etc.
                    </aside>
                    <AutoHeightTextArea
                      name="service_boundary"
                      value={tempBriefing?.info?.service_boundary}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Postal address</Label>
                    <AutoHeightTextArea
                      name="postal_address"
                      value={tempBriefing?.info?.postal_address}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Hours of operation</Label>
                    <aside className="hide-pdf">
                      For footer and contact page. If you need it mentioned in page copy e.g. 24/7, open 7 days etc - copy to relevant section.
                    </aside>
                    <AutoHeightTextArea
                      name="hours"
                      value={tempBriefing?.info?.hours}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Payment options</Label>
                    <AutoHeightTextArea
                      name="payment_options"
                      value={tempBriefing?.info?.payment_options}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Social media properties</Label>
                    <AutoHeightTextArea
                      name="social_media_properties"
                      value={tempBriefing?.info?.social_media_properties}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Internal Info Only</Label>
                    <AutoHeightTextArea
                      name="general_other_info"
                      value={tempBriefing?.info?.general_other_info}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <a className="anchor" id="designDetails" />
          <Card className="mb-4 anchor-box anchor-box-designDetails">
            <CardBody>
              <CardTitle>Design Details</CardTitle>

              <Row><Col><h2>Client ideas</h2></Col></Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Did you have anything particular in mind for the look and the feel of the website or are you happy to leave it up to our designers?</Label>
                    <aside>
                      e.g. friendly/approachable feel? More professional/upmarket?
                    </aside>
                    <AutoHeightTextArea
                      name="client_design_ideas"
                      value={tempBriefing?.info?.client_design_ideas}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Specific design details for the Home page or any of the other pages?</Label>
                    <aside>
                      e.g. friendly/approachable feel? More professional/upmarket?
                    </aside>
                    <AutoHeightTextArea
                      name="page_specific_design"
                      value={tempBriefing?.info?.page_specific_design}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Do you have any particular websites you like the design of?</Label>
                    <AutoHeightTextArea
                      name="other_websites_like"
                      value={tempBriefing?.info?.other_websites_like}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row><Col><h2>Website header</h2></Col></Row>
              <Row>
                <Col>
                  The header of a website should answer the following questions:
                  <ul>
                    <li>Who are you? - Logo</li>
                    <li>What do you do? - Orientation statement</li>
                    <li>Where do you do it or what area do you service? - Region</li>
                    <li>How do I get hold of you? - Phone number</li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Logo</Label>
                    <aside>
                      Minimum 800 pixels wide please
                    </aside>
                    {isEmpty(tempBriefing?.info?.logo)
                      ? (
                          <Input
                            type="file"
                            accept="image/png, image/jpeg"
                            name="logo"
                            onChange={onChange}
                          />
                        )
                      : (
                          <>
                            Logo is set --
                            {' '}
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, logo: null } });
                              }}
                            >
                              click here
                            </a>
                            {' '}
                            to add a new logo.
                          </>
                        )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Orientation Statement</Label>
                    <aside className="hide-pdf">
                      <span className="prompt">Will be added verbatim to the website header</span>
                      <br />
                      Plain English phrase or sentence in the header that sums up what your business does so when a site visitor arrives they can work out immediately if they&apos;re in the right place or not - it is not a slogan or tagline although we can add those in too.
                    </aside>
                    <AutoHeightTextArea
                      name="orientation_statement"
                      value={tempBriefing?.info?.orientation_statement}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Region</Label>
                    <aside className="hide-pdf">
                      <span className="prompt">Will be added verbatim to the website header</span>
                      <br />
                      If it&apos;s a shop etc, it&apos;s a good idea to have an address in the header. If they service a location e.g. an electrician, add the furthest north, south, east and west they will travel (realistically) e.g. Servicing Greater Wellington, including Porirua, Hutt Valley and the Kapiti Coast or Tauranga-based, servicing New Zealand-wide.
                    </aside>
                    <Input name="region" value={tempBriefing?.info?.region} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Does this region apply to all pages?</Label>
                    <AutoHeightTextArea
                      name="region_all_pages"
                      value={tempBriefing?.info?.region_all_pages}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Phone number(s) for header</Label>
                    <Input name="header_phone_number" value={tempBriefing?.info?.header_phone_number} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>

              <Row><Col><h2>Website footer or section above the footer</h2></Col></Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Custom footer</Label>
                    <aside>
                      Any association logos? Could be logos of well-known companies you&apos;ve done business with, awards you&apos;ve won, associations you are a member of, suppliers you work with etc. (Logo images min 800px wide).
                    </aside>
                    <AutoHeightTextArea
                      name="custom_footer"
                      value={tempBriefing?.info?.custom_footer}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="d-block">Upload images</Label>
                    {isEmpty(tempBriefing?.info?.footer_images)
                      ? (
                          <Input
                            type="file"
                            accept="image/png, image/jpeg"
                            name="footer_images"
                            onChange={onChange}
                            multiple
                          />
                        )
                      : (
                          <>
                            Images are set --
                            {' '}
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, footer_images: null } });
                              }}
                            >
                              click here
                            </a>
                            {' '}
                            to add new images.
                          </>
                        )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Payment options</Label>
                    <aside>
                      Any logos etc.
                    </aside>
                    <AutoHeightTextArea
                      name="payment_options"
                      value={tempBriefing?.info?.payment_options}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row><Col><h2>Colours and photos</h2></Col></Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Colours for website</Label>
                    <AutoHeightTextArea
                      name="colors"
                      value={tempBriefing?.info?.colors}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Photos for website</Label>
                    <aside>
                      Stock or provided by business or a mix of both.
                    </aside>
                    <AutoHeightTextArea
                      name="photos_for_site"
                      value={tempBriefing?.info?.photos_for_site}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row><Col><h2>Calls to Action</h2></Col></Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Calls to action</Label>
                    <aside className="hide-pdf">
                      Default calls to action in the copy are &ldquo;Get in touch&rdquo; and &ldquo;Contact us&rdquo; - if there are specific CTAs e.g. Book a site visit or consultation, please copy that to relevant fields.
                      <br />
                      <br />
                      What do you want the site visitor to do as a result of visiting your website: e.g.
                      <br />
                      <ul>
                        <li>Call us</li>
                        <li>Text us</li>
                        <li>Contact us</li>
                        <li>Get a quote</li>
                        <li>Book a consultation</li>
                        <li>Contact us for &ldquo;reason here&rdquo;</li>
                        <li>Arrange a site visit</li>
                      </ul>
                      (List in order of importance)
                    </aside>
                    <AutoHeightTextArea
                      name="ctas"
                      value={tempBriefing?.info?.ctas}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Contact form on every page above footer?</Label>
                    <p>
                      <Checkbox
                        title="Yes"
                        name="contact_form_above_footer"
                        value={true}
                        checked={tempBriefing?.info?.contact_form_above_footer}
                        onChange={onChange}
                      />
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Do you want people to submit any other information on the form?</Label>
                    <aside>
                      Other than: first name, last name, email address, contact number, details.
                    </aside>
                    <AutoHeightTextArea
                      name="other_fields"
                      value={tempBriefing?.info?.other_fields}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>How quickly can you get back to customers who fill in a form?</Label>
                    <AutoHeightTextArea
                      name="how_quickly"
                      value={tempBriefing?.info?.how_quickly}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Contact page</Label>
                    <aside>
                      Standard is phone number/s, physical/postal addresses, map, and form. Any other details?
                    </aside>
                    <AutoHeightTextArea
                      name="cta_other_info"
                      value={tempBriefing?.info?.cta_other_info}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Instant Contact Form</Label>
                    <Container className="ms-4">
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Mobile</Label>
                            <Input name="instant_contact_mobile" value={tempBriefing?.info?.instant_contact_mobile} onChange={onChange} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Phrasing</Label>
                            <Input name="instant_contact_phrasing" value={tempBriefing?.info?.instant_contact_phrasing} onChange={onChange} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>24/7 or business hours</Label>
                            <AutoHeightTextArea
                              name="instant_contact_hours"
                              value={tempBriefing?.info?.instant_contact_hours}
                              onChange={onChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Container>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <a className="anchor" id="websiteStructure" />
          <Card className="mb-4 anchor-box anchor-box-websiteStructure">
            <CardBody>
              <CardTitle>Website Structure</CardTitle>
              <Row>
                <Col>
                  <p>
                    <Checkbox
                      title="Include About Page"
                      name="include_about_page"
                      value={true}
                      checked={tempBriefing?.info?.include_about_page}
                      onChange={onChange}
                    />
                  </p>
                  <p>
                    <Checkbox
                      title="Include Testimonials Page"
                      name="include_testimonials_page"
                      value={true}
                      checked={tempBriefing?.info?.include_testimonials_page}
                      onChange={onChange}
                    />
                  </p>
                  <p>
                    <Checkbox
                      title="Include Gallery Page"
                      name="include_gallery_page"
                      value={true}
                      checked={tempBriefing?.info?.include_gallery_page}
                      onChange={onChange}
                    />
                  </p>
                  <p>
                    <Checkbox
                      title="Include FAQ Page"
                      name="include_faq_page"
                      value={true}
                      checked={tempBriefing?.info?.include_faq_page}
                      onChange={onChange}
                    />
                  </p>
                  <p>
                    <Checkbox
                      title={'Include "Make an Enquiry" Page'}
                      name="include_enquiry_page"
                      value={true}
                      checked={tempBriefing?.info?.include_enquiry_page}
                      onChange={onChange}
                    />
                  </p>
                  <p>
                    <Checkbox
                      title="Include Contact Page"
                      name="include_contact_page"
                      value={true}
                      checked={tempBriefing?.info?.include_contact_page}
                      onChange={onChange}
                    />
                  </p>
                  <FormGroup>
                    <Label>Number of ASAP/Product pages</Label>
                    <Input
                      type="number"
                      name="number_of_product_pages"
                      value={tempBriefing?.info?.number_of_product_pages}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <a className="anchor" id="businessMarketingInfo" />
          <Card className="mb-4 anchor-box anchor-box-businessMarketingInfo">
            <CardBody>
              <CardTitle>Business Marketing Info</CardTitle>
              <Row><Col><h2>Products / Services</h2></Col></Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>What are the products/services you provide?</Label>
                    <aside className="hide-pdf">
                      <span className="prompt">MCP headline, MCP copy, About copy</span>
                      <br />
                      Short answers - only a list here. All information is to go on the ASAP pages. This is mainly to get the page structure from.
                    </aside>
                    <AutoHeightTextArea
                      name="products_services"
                      value={tempBriefing?.info?.products_services}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Internal Info Only</Label>
                    <AutoHeightTextArea
                      name="products_services_other_info"
                      value={tempBriefing?.info?.products_services_other_info}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Which of your products/services currently keeps you the busiest/makes you the most money?</Label>
                    <aside className="hide-pdf">
                      Not on website - but can help with page structure. Copy anything to other relevant sections that needs to be used in the copy.
                    </aside>
                    <AutoHeightTextArea
                      name="products_services_make_money"
                      value={tempBriefing?.info?.products_services_make_money}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label>Is there a product/service you would like to increase?</Label>
                    <aside className="hide-pdf">
                      Not on website - but can help with page structure. Copy anything to other relevant sections that needs to be used in the copy
                    </aside>
                    <AutoHeightTextArea
                      name="product_service_increase"
                      value={tempBriefing?.info?.product_service_increase}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row><Col><h2>Customers</h2></Col></Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Current customers</Label>
                    <aside>
                      Describe your current customers eg: over 50, young, male, female, disposable income etc. Any useful info here will need to be copied into the relevant page/s.
                    </aside>
                    <AutoHeightTextArea
                      name="current_customers"
                      value={tempBriefing?.info?.current_customers}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label>New customers</Label>
                    <aside>
                      Are you looking to target these people or new people? If so, who? Not on website. Any useful info here will need to be copied into the relevant page/s.
                    </aside>
                    <AutoHeightTextArea
                      name="new_customers"
                      value={tempBriefing?.info?.new_customers}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row><Col><h2>The BIG Questions - Specific and short answers here</h2></Col></Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>What are your customers looking for in a business like yours?</Label>
                    <aside className="hide-pdf">
                      <span className="prompt">MCP copy, About copy</span>
                      <br />
                      List some of the common things they come to you for.
                    </aside>
                    <AutoHeightTextArea
                      name="customers_looking_for"
                      value={tempBriefing?.info?.customers_looking_for}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label>What problems do you solve for them?</Label>
                    <aside className="hide-pdf">
                      <span className="prompt">MCP copy, About copy</span>
                    </aside>
                    <AutoHeightTextArea
                      name="problems_solve"
                      value={tempBriefing?.info?.problems_solve}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label>What is your big promise to your customers?</Label>
                    <aside className="hide-pdf">
                      <span className="prompt">MCP headline, MCP copy, About headline, About copy</span>
                      <br />
                      Big overview of what you provide and how you do it to your customers.
                    </aside>
                    <AutoHeightTextArea
                      name="big_promise"
                      value={tempBriefing?.info?.big_promise}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label>Internal Info Only</Label>
                    <AutoHeightTextArea
                      name="big_questions_other_info"
                      value={tempBriefing?.info?.big_questions_other_info}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label>What is your Mission Statement?</Label>
                    <aside className="hide-pdf">
                      If you have one. Will need to be added verbatim during styling stage to relevant section/page.
                    </aside>
                    <AutoHeightTextArea
                      name="mission_statement"
                      value={tempBriefing?.info?.mission_statement}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row><Col><h2>Business-wide selling points</h2></Col></Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>7 Business-wide Information/Selling Points</Label>
                    <aside className="hide-pdf">
                      <span className="prompt">MCP copy, About copy 5 reasons why</span>
                      <br />
                      List all features, but also benefits. When describing the products or services offered by the business, and the business as a whole, focus on the benefits to the customer, not just the features of the business&apos;s products and services. List all points the client mentions that describe what the business does.
                      <br />
                      <br />
                      This is business wide. Copy any that are relevant to the ASAP page.
                      <br />
                      <br />
                      Think E-E-A-T: Experience, Expertise, Authority, Trustworthiness. e.g. fast responses, really knew what we were doing, friendly, always clean up, great advice etc.
                    </aside>
                    <Container className="ms-4">
                      <Row>
                        <Col>
                          {Array.from({ length: 7 }, (v, i) => i).map((index) => (
                            <FormGroup key={index}>
                              <AutoHeightTextArea
                                name={`selling_points_${index}`}
                                value={tempBriefing?.info[`selling_points_${index}`]}
                                onChange={onChange}
                              />
                            </FormGroup>
                          ))}
                        </Col>
                      </Row>
                    </Container>
                  </FormGroup>
                </Col>
              </Row>

              <Row><Col><h2>Competitors</h2></Col></Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Who are your key competitors?</Label>
                    <aside className="hide-pdf">
                      <span className="prompt">Will not show on website</span>
                    </aside>
                    <AutoHeightTextArea
                      name="key_competitors"
                      value={tempBriefing?.info?.key_competitors}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label>What makes you different from your key competitors?</Label>
                    <aside className="hide-pdf">
                      <span className="prompt">MCP copy, About copy, About copy 5 reasons why</span>
                      <br />
                      Copy anything relevant to About page or ASAP page.
                    </aside>
                    <AutoHeightTextArea
                      name="differentiator"
                      value={tempBriefing?.info?.differentiator}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row><Col><h2>FAQs</h2></Col></Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Are there commonly asked questions that you could answer on the website?</Label>
                    <aside>
                      (Do you want FAQs)
                    </aside>
                    <AutoHeightTextArea
                      name="want_faqs"
                      value={tempBriefing?.info?.want_faqs}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label>Will these be on their own general FAQ page or on the About/ASAP pages?</Label>
                    <AutoHeightTextArea
                      name="faq_own_page"
                      value={tempBriefing?.info?.faq_own_page}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label>Will you be supplying the FAQs or will we be writing them?</Label>
                    <AutoHeightTextArea
                      name="supplying_faqs"
                      value={tempBriefing?.info?.supplying_faqs}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label>Writing style for the website</Label>
                    <aside>
                      What tone would you like the website written in? e.g. formal, informal, friendly, professional, casual and relaxed etc.
                    </aside>
                    <AutoHeightTextArea
                      name="writing_style"
                      value={tempBriefing?.info?.writing_style}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label>Primary Geo</Label>
                    <aside className="hide-pdf">
                      <span className="prompt">MCP headline, About headline</span>
                      <br />
                      Short, as it&apos;s used in headlines.
                    </aside>
                    <Input name="primary_geo" value={tempBriefing?.info?.primary_geo} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Website Geo statement</Label>
                    <aside className="hide-pdf">
                      <span className="prompt">MCP copy, About copy</span>
                      <br />
                      Statement that describes the area serviced by the business, eg:
                      <ul>
                        <li>Tauranga-based, servicing New Zealand-wide</li>
                        <li>Servicing the Kapiti Coast, including Paraparaumu, Waikanae, Otaki and Paekakariki</li>
                        <li>Servicing Auckland and North Shore</li>
                        <li>Servicing Greater Wellington, including Porirua, Hutt Valley and the Kapiti Coast</li>
                      </ul>
                      <br />
                      <br />
                      Longer, as it&apos;s used in copy.
                    </aside>
                    <AutoHeightTextArea
                      name="geo_statement"
                      value={tempBriefing?.info?.geo_statement}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <a className="anchor" id="homePageBriefInfo" />
          <Card className="mb-4 anchor-box anchor-box-homePageBriefInfo">
            <CardBody>
              <CardTitle>Home Page Brief Info</CardTitle>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>
                      Any specific instructions for the header, banner, MCP, SCPs, form and footer?
                      <span className="hide-pdf">
                        (not design related as there is a space to add these on the Design section above)
                      </span>
                    </Label>
                    <AutoHeightTextArea
                      name="home_page_instructions"
                      value={tempBriefing?.info?.home_page_instructions}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  Do you want to include the following on the Home page:

                  <FormGroup className="indented">
                    <Label>
                      Testimonials
                      <Switch
                        className="custom-switch custom-switch-primary"
                        checked={tempBriefing?.info?.testimonials}
                        onChange={(e) => onChange({ target: { name: 'testimonials', value: e } })}
                      />
                    </Label>
                  </FormGroup>
                  {tempBriefing?.info?.testimonials && (
                    <>
                      <FormGroup className="indented">
                        <Label>
                          Testimonial Slider?
                          <Switch
                            className="custom-switch custom-switch-primary"
                            checked={tempBriefing?.info?.testimonial_slider}
                            onChange={(e) => onChange({ target: { name: 'testimonial_slider', value: e } })}
                          />
                        </Label>
                      </FormGroup>
                      <FormGroup className="indented">
                        <Label>Testimonials</Label>
                        <p>
                          <Button
                            color="success"
                            onClick={() => {
                              const testimonials = cloneDeep(tempBriefing.info.testimonial_list || []);
                              testimonials.push({});
                              setTempBriefing({
                                ...tempBriefing,
                                info: {
                                  ...tempBriefing.info,
                                  testimonial_list: testimonials,
                                },
                              });
                            }}
                            className="common-success-button button-lg fw-bold"
                          >
                            ADD TESTIMONIAL
                          </Button>
                        </p>
                        {tempBriefing?.info?.testimonial_list?.map((testimonial, index) => (
                          <div key={index}>
                            <Row>
                              <Col>
                                <h2>
                                  Testimonial
                                  {' '}
                                  {index + 1}
                                  {' '}
                                  -
                                  {' '}
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      const testimonials = cloneDeep(tempBriefing.info.testimonial_list);
                                      testimonials.splice(index, 1);
                                      setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, testimonial_list: testimonials } });
                                    }}
                                  >
                                    Remove
                                  </a>
                                </h2>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label>Author</Label>
                                  <Input
                                    value={testimonial.author}
                                    onChange={(e) => {
                                      const testimonials = cloneDeep(tempBriefing.info.testimonial_list);
                                      testimonials[index].author = e.target.value;
                                      setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, testimonial_list: testimonials } });
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label>Testimonial</Label>
                                  <AutoHeightTextArea
                                    value={testimonial.text}
                                    onChange={(e) => {
                                      const testimonials = cloneDeep(tempBriefing.info.testimonial_list);
                                      testimonials[index].text = e.target.value;
                                      setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, testimonial_list: testimonials } });
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </FormGroup>
                    </>
                  )}
                  <FormGroup className="indented">
                    <Label>
                      &ldquo;Why Choose Us?&rdquo; Section
                      <Switch
                        className="custom-switch custom-switch-primary"
                        checked={tempBriefing?.info?.why_choose_us}
                        onChange={(e) => onChange({ target: { name: 'why_choose_us', value: e } })}
                      />
                    </Label>
                  </FormGroup>
                  {tempBriefing?.info?.why_choose_us && (
                    <FormGroup className="indented">
                      <Label>Content for &ldquo;Why Choose Us?&rdquo; Section</Label>
                      <aside className="hide-pdf">
                        List 4 short statements - these will appear verbatim on home page.
                      </aside>
                      <AutoHeightTextArea
                        name="why_choose_us_content"
                        value={tempBriefing?.info?.why_choose_us_content}
                        onChange={onChange}
                      />
                    </FormGroup>
                  )}
                </Col>
              </Row>

              {/* <Row>
                <Col>
                  <FormGroup>
                    <Label>&ldquo;What We Do&rdquo; Section?</Label>
                    <p>
                      <Checkbox
                        title="Yes"
                        name="what_we_do"
                        value={true}
                        checked={tempBriefing?.info?.what_we_do}
                        onChange={onChange}
                      />
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              {tempBriefing?.info?.what_we_do && (
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Content for &ldquo;What We Do&rdquo; Section</Label>
                      <AutoHeightTextArea
                        name="what_we_do_content"
                        value={tempBriefing?.info?.what_we_do_content}
                        onChange={onChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )} */}
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Additional Home Page content requirements</Label>
                    <AutoHeightTextArea
                      name="additional_home_page_content_requirements"
                      value={tempBriefing?.info?.additional_home_page_content_requirements}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Page Title</Label>
                    <Input maxLength={60} name="home_page_title" value={tempBriefing?.info?.home_page_title} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="phase-2">
                <Col>
                  <FormGroup>
                    <Label>Primary Keyword Phrase</Label>
                    <aside className="hide-pdf">
                      <span className="prompt">MCP headline, MCP copy</span>
                    </aside>
                    <AutoHeightTextArea
                      name="home_primary_keyword_phrase"
                      value={tempBriefing?.info?.home_primary_keyword_phrase}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="phase-2">
                <Col>
                  <FormGroup>
                    <Label>Secondary Keyword Phrases</Label>
                    <aside className="hide-pdf">
                      <span className="prompt">MCP copy</span>
                    </aside>
                    <AutoHeightTextArea
                      name="home_secondary_keyword_phrase"
                      value={tempBriefing?.info?.home_secondary_keyword_phrase}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {tempBriefing?.info?.include_about_page && (
            <>
              <a className="anchor" id="aboutPageBriefInfo" />
              <Card className="mb-4 anchor-box anchor-box-aboutPageBriefInfo">
                <CardBody>
                  <CardTitle>About Page Brief Info</CardTitle>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Business owner name(s)</Label>
                        <aside className="hide-pdf">
                          <span className="prompt">About copy</span>
                          <br />
                          If the business owner is happy to be identified and featured on the website (highly
                          recommended), what is their first and last names followed by their title e.g: Owner, Business
                          owner, Owner-operator, Founder, Manager, Managing Director, Director, CEO etc.
                        </aside>
                        <Input name="business_owner_names" value={tempBriefing?.info?.business_owner_names} onChange={onChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Business owner profile</Label>
                        <aside className="hide-pdf">
                          <span className="prompt">About copy</span>
                          <br />
                          Bio and photo, work background and experience, qualifications, any personal info.
                        </aside>
                        <AutoHeightTextArea
                          name="business_owner_profile"
                          value={tempBriefing?.info?.business_owner_profile}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Internal Info Only</Label>
                        <AutoHeightTextArea
                          name="about_page_other_info_1"
                          value={tempBriefing?.info?.about_page_other_info_1}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>How would you like to refer to your staff?</Label>
                        <aside className="hide-pdf">
                          <span className="prompt">About copy, ASAP copy</span>
                          <br />
                          e.g. team, technicians, staff, etc
                        </aside>
                        <Input name="staff_title" value={tempBriefing?.info?.staff_title} onChange={onChange} />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Internal Info Only</Label>
                        <AutoHeightTextArea
                          name="about_page_other_info_2"
                          value={tempBriefing?.info?.about_page_other_info_2}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Key team members to profile on the website</Label>
                        <p>
                          <Button
                            color="success"
                            onClick={() => {
                              const members = cloneDeep(tempBriefing.info.team_members || []);
                              members.push({});
                              setTempBriefing({
                                ...tempBriefing,
                                info: {
                                  ...tempBriefing.info,
                                  team_members: members,
                                },
                              });
                            }}
                            className="common-success-button button-lg fw-bold"
                          >
                            ADD TEAM MEMBER
                          </Button>
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  {tempBriefing?.info?.team_members?.map((member, index) => (
                    <div key={index}>
                      <Row>
                        <Col>
                          <h2>
                            Team Member
                            {' '}
                            {index + 1}
                            {' '}
                            -
                            {' '}
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                const members = cloneDeep(tempBriefing.info.team_members);
                                members.splice(index, 1);
                                setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, team_members: members } });
                              }}
                            >
                              Remove
                            </a>
                          </h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Name</Label>
                            <Input
                              value={member.name}
                              onChange={(e) => {
                                const members = cloneDeep(tempBriefing.info.team_members);
                                members[index].name = e.target.value;
                                setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, team_members: members } });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>Role</Label>
                            <Input
                              value={member.role}
                              onChange={(e) => {
                                const members = cloneDeep(tempBriefing.info.team_members);
                                members[index].role = e.target.value;
                                setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, team_members: members } });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>A bit about what they do and how they add value for customers</Label>
                            <Input
                              value={member.what_they_do}
                              onChange={(e) => {
                                const members = cloneDeep(tempBriefing.info.team_members);
                                members[index].what_they_do = e.target.value;
                                setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, team_members: members } });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>A bit about them personally</Label>
                            <Input
                              value={member.personal}
                              onChange={(e) => {
                                const members = cloneDeep(tempBriefing.info.team_members);
                                members[index].personal = e.target.value;
                                setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, team_members: members } });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <hr />
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Business history</Label>
                        <aside className="hide-pdf">
                          <span className="prompt">About copy</span>
                          <br />
                          What year did the business start? Was it you who started the business and if yes, more
                          info. If not, how did you get involved in the business? Any other history we should
                          know? e.g. are you a family business, are you a local legend?
                        </aside>
                        <AutoHeightTextArea
                          name="business_history"
                          value={tempBriefing?.info?.business_history}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>The business today</Label>
                        <aside className="hide-pdf">
                          <span className="prompt">About copy</span>
                          <br />
                          What does your company look like now? e.g. Do you have a premises, office, showroom... or a fleet of machinery?
                        </aside>
                        <AutoHeightTextArea
                          name="business_today"
                          value={tempBriefing?.info?.business_today}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Internal Info Only</Label>
                        <AutoHeightTextArea
                          name="about_page_other_info_3"
                          value={tempBriefing?.info?.about_page_other_info_3}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>How each job is approached</Label>
                        <aside className="hide-pdf">
                          <span className="prompt">About copy</span>
                          <br />
                          How do you approach each job? eg - site visits, over the phone quotes. book in appts. Does size
                          of job change approach? If applicable to any of the ASAP pages, please copy to relevant section.
                        </aside>
                        <AutoHeightTextArea
                          name="business_approach_job"
                          value={tempBriefing?.info?.business_approach_job}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Guarantees</Label>
                        <aside className="hide-pdf">
                          <span className="prompt">About copy</span>
                          <br />
                          Do you offer any guarantees? If yes - what, and how long? If applicable to any of the ASAP pages, please copy to relevant section.
                        </aside>
                        <AutoHeightTextArea
                          name="business_guarantees"
                          value={tempBriefing?.info?.business_guarantees}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Client satisfaction</Label>
                        <aside className="hide-pdf">
                          <span className="prompt">About copy</span>
                          <br />
                          Passion for positive customer outcomes - what gives you or your staff job satisfaction, what
                          makes customers happy, what types of jobs and outcomes? If applicable to any of the ASAP pages,
                          please copy to relevant section.
                        </aside>
                        <AutoHeightTextArea
                          name="client_satisfaction"
                          value={tempBriefing?.info?.client_satisfaction}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Awards/Associations/Certifications</Label>
                        <aside className="hide-pdf">
                          <span className="prompt">About copy</span>
                          <br />
                          Do you have any awards, certifications or industry group associations? If applicable please
                          explain what this certification means. Give full name as well as acronym, and explain what the organisation does.
                        </aside>
                        <AutoHeightTextArea
                          name="awards"
                          value={tempBriefing?.info?.awards}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Community involvement</Label>
                        <aside className="hide-pdf">
                          <span className="prompt">About copy</span>
                          <br />
                          Is the business involved in some way supporting community projects or sports teams, etc? Give
                          full details of what the business involvement is.
                        </aside>
                        <AutoHeightTextArea
                          name="community_involvement"
                          value={tempBriefing?.info?.community_involvement}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Internal Info Only</Label>
                        <AutoHeightTextArea
                          name="about_page_other_info"
                          value={tempBriefing?.info?.about_page_other_info}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Page Title</Label>
                        <Input maxLength={60} name="about_page_title" value={tempBriefing?.info?.about_page_title} onChange={onChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="phase-2">
                    <Col>
                      <FormGroup>
                        <Label>Primary Keyword Phrase</Label>
                        <aside className="hide-pdf">
                          <span className="prompt">About headline</span>
                        </aside>
                        <AutoHeightTextArea
                          name="about_page_primary_keyword_phrase"
                          value={tempBriefing?.info?.about_page_primary_keyword_phrase}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="phase-2">
                    <Col>
                      <FormGroup>
                        <Label>Secondary Keyword Phrases</Label>
                        <aside className="hide-pdf">
                          <span className="prompt">About copy</span>
                        </aside>
                        <AutoHeightTextArea
                          name="about_page_secondary_keyword_phrases"
                          value={tempBriefing?.info?.about_page_secondary_keyword_phrases}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="phase-2">
                    <Col>
                      <FormGroup>
                        <Label>Related Keyword Phrases</Label>
                        <aside className="hide-pdf">
                          <span className="prompt">About copy</span>
                        </aside>
                        <AutoHeightTextArea
                          name="about_page_related_keyword_phrases"
                          value={tempBriefing?.info?.about_page_related_keyword_phrases}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )}

          {Array.from({ length: tempBriefing?.info?.number_of_product_pages }, (v, i) => i).map((index) => {
            const productPages = tempBriefing?.info?.product_pages || [];
            const productPage = productPages[index] || {};

            return (
              <Fragment key={index}>
                <a className="anchor" id={`asapPageBriefInfo${index + 1}`} />
                <Card className={`mb-4 anchor-box anchor-box-asapPageBriefInfo${index + 1}`}>
                  <CardBody>
                    <CardTitle>
                      ASAP Product/Service Page Brief Info -
                      {index + 1}
                    </CardTitle>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Page name</Label>
                          <aside className="hide-pdf">
                            <span className="prompt">SCP subhead</span>
                          </aside>
                          <Input
                            value={productPage.name}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].name = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Page Topic</Label>
                          <AutoHeightTextArea
                            value={productPage.page_topic}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].page_topic = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Seed Keywords</Label>
                          <AutoHeightTextArea
                            value={productPage.seed_keywords}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].seed_keywords = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Info for questions</Label>
                          <AutoHeightTextArea
                            value={productPage.question_info}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].question_info = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {isEmpty(productPage.other_info) && (
                      <Row>
                        <Col>
                          <FormGroup>
                            <Button
                              color="success"
                              onClick={async () => {
                                await onSave();

                                setGeneratingQuestionsForProductId(index);
                                setGeneratingQuestionsForSubProductId(null);
                                triggerGenerateQuestions({
                                  briefingId: briefing.id,
                                  productPageId: index,
                                  subPageId: -1,
                                });
                              }}
                              className="common-success-button button-lg fw-bold"
                            >
                              GENERATE QUESTIONS
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>What information would you like to include on this Product/Service page?</Label>
                          <aside className="hide-pdf">
                            <span className="prompt">ASAP copy - 2nd paragraph onwards</span>
                            <br />
                            <br />
                            Consider what do you do, how do you do it, when do you do it, why should a customer should
                            come to you, is there a different geo for this service? Organise info in topics.
                          </aside>
                          <AutoHeightTextArea
                            style={{ minHeight: '250px' }}
                            value={productPage.other_info}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].other_info = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Internal Info Only</Label>
                          <AutoHeightTextArea
                            value={productPage.notes}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].notes = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Summary of Product/Service</Label>
                          <aside className="hide-pdf">
                            <span className="prompt">SCP copy, 3 x top ASAP section bullet points, ASAP PAS headline and copy</span>
                          </aside>
                          <AutoHeightTextArea
                            value={productPage.summary}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].summary = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Geo Statement</Label>
                          <aside className="hide-pdf">
                            <span className="prompt">
                              Will be added verbatim as the geo statement in the ASAP top section under bullet points.
                            </span>
                            <br />
                            Write a plain English statement which describes the service area.
                          </aside>
                          <AutoHeightTextArea
                            value={productPage.geo_statement}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].geo_statement = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Common customer problem (pain point) and solution</Label>
                          <aside className="hide-pdf">
                            <span className="prompt">ASAP sector 1 headline and copy</span>
                            <br />
                            This needs to be written as Problem: sentence. Solution: sentence.
                          </aside>
                          <AutoHeightTextArea
                            value={productPage.concerns}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].concerns = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Internal Info Only</Label>
                          <AutoHeightTextArea
                            value={productPage.other_info_2}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].other_info_2 = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>FAQ Section</Label>
                          <aside className="hide-pdf">
                            If there are specific FAQs for this page list them here.
                          </aside>
                          <AutoHeightTextArea
                            value={productPage.faqs}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].faqs = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Google keywords</Label>
                          <aside>
                            Info only. What words are people using to search Google to find this specific product/service?
                          </aside>
                          <Input
                            value={productPage.keywords}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].keywords = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="phase-2">
                      <Col>
                        <FormGroup>
                          <Label>
                            7 Product/Service
                            {index + 1}
                            {' '}
                            Information/Selling Points
                          </Label>
                          <aside className="hide-pdf">
                            <span className="prompt">3 x top ASAP section bullet points, ASAP copy</span>
                          </aside>
                          <Container className="ms-4">
                            <Row>
                              <Col>
                                {Array.from({ length: 7 }, (v, i) => i).map((infoIndex) => (
                                  <FormGroup key={infoIndex}>
                                    <AutoHeightTextArea
                                      value={productPage[`info_${infoIndex}`]}
                                      onChange={(e) => {
                                        const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                                        productPages[index] ||= {};
                                        productPages[index][`info_${infoIndex}`] = e.target.value;
                                        setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                                      }}
                                    />
                                  </FormGroup>
                                ))}
                              </Col>
                            </Row>
                          </Container>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Page Title</Label>
                          <Input
                            maxLength={60}
                            value={productPage.page_title}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].page_title = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="phase-2">
                      <Col>
                        <FormGroup>
                          <Label>Primary Keyword Phrase</Label>
                          <aside className="hide-pdf">
                            <span className="prompt">ASAP headline, 3 x top ASAP section bullet points, ASAP sector 1 headline and copy</span>
                          </aside>
                          <AutoHeightTextArea
                            value={productPage.primary_keyword_phrase}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].primary_keyword_phrase = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="phase-2">
                      <Col>
                        <FormGroup>
                          <Label>Secondary Keyword Phrases</Label>
                          <aside className="hide-pdf">
                            <span className="prompt">ASAP copy</span>
                          </aside>
                          <AutoHeightTextArea
                            value={productPage.secondary_keyword_phrases}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].secondary_keyword_phrases = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="phase-2">
                      <Col>
                        <FormGroup>
                          <Label>Related Keyword Phrases</Label>
                          <aside className="hide-pdf">
                            <span className="prompt">ASAP copy</span>
                          </aside>
                          <AutoHeightTextArea
                            value={productPage.related_keyword_phrases}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].related_keyword_phrases = e.target.value;
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Number of sub-pages</Label>
                          <Input
                            type="number"
                            value={productPage.number_of_sub_pages}
                            onChange={(e) => {
                              const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                              productPages[index] ||= {};
                              productPages[index].number_of_sub_pages = e.target.value;
                              productPages[index].sub_pages ||= [];
                              for (let i = 0; i < e.target.value; i++) {
                                productPages[index].sub_pages[i] ||= {};
                              }
                              setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                {Array.from({ length: productPage.number_of_sub_pages }, (v, i) => i).map((subIndex) => {
                  const subPages = productPage.sub_pages || [];
                  const subPage = subPages[subIndex] || {};

                  return (
                    <Fragment key={subIndex}>
                      <a className="anchor" id={`subPageBriefInfo_${index + 1}_${subIndex + 1}`} />
                      <Card className={`indented mb-4 anchor-box anchor-box-subPageBriefInfo_${index + 1}_${subIndex + 1}`}>
                        <CardBody>
                          <CardTitle>
                            Sub-Page Brief Info -
                            {subIndex + 1}
                          </CardTitle>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label>Page name</Label>
                                <Input
                                  value={subPage.name}
                                  onChange={(e) => {
                                    const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                                    set(productPages, `[${index}].sub_pages[${subIndex}].name`, e.target.value);
                                    setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label>Page Topic</Label>
                                <AutoHeightTextArea
                                  value={subPage.page_topic}
                                  onChange={(e) => {
                                    const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                                    set(productPages, `[${index}].sub_pages[${subIndex}].page_topic`, e.target.value);
                                    setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label>Seed Keywords</Label>
                                <AutoHeightTextArea
                                  value={subPage.seed_keywords}
                                  onChange={(e) => {
                                    const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                                    set(productPages, `[${index}].sub_pages[${subIndex}].seed_keywords`, e.target.value);
                                    setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label>Info for questions</Label>
                                <AutoHeightTextArea
                                  value={subPage.question_info}
                                  onChange={(e) => {
                                    const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                                    set(productPages, `[${index}].sub_pages[${subIndex}].question_info`, e.target.value);
                                    setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          {isEmpty(subPage.other_info) && (
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Button
                                    color="success"
                                    onClick={async () => {
                                      await onSave();

                                      setGeneratingQuestionsForProductId(index);
                                      setGeneratingQuestionsForSubProductId(subIndex);
                                      triggerGenerateQuestions({
                                        briefingId: briefing.id,
                                        productPageId: index,
                                        subPageId: subIndex,
                                      });
                                    }}
                                    className="common-success-button button-lg fw-bold"
                                  >
                                    GENERATE QUESTIONS
                                  </Button>
                                </FormGroup>
                              </Col>
                            </Row>
                          )}

                          <Row>
                            <Col>
                              <FormGroup>
                                <Label>What information would you like to include on this sub-page?</Label>
                                <aside className="hide-pdf">
                                  <span className="prompt">Sector 1</span>
                                  <br />
                                  <br />
                                  Organise info from most important topics to least. Copy in any other relevant information
                                  from other parts of the brief doc e.g. key differentiators, honest, reliable etc - from the
                                  Website overview section and the About page etc.
                                </aside>
                                <AutoHeightTextArea
                                  style={{ minHeight: '250px' }}
                                  value={subPage.other_info}
                                  onChange={(e) => {
                                    const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                                    set(productPages, `[${index}].sub_pages[${subIndex}].other_info`, e.target.value);
                                    setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label>Internal Info Only</Label>
                                <AutoHeightTextArea
                                  value={subPage.notes}
                                  onChange={(e) => {
                                    const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                                    set(productPages, `[${index}].sub_pages[${subIndex}].notes`, e.target.value);
                                    setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="phase-2">
                            <Col>
                              <FormGroup>
                                <Label>Primary Keyword Phrase</Label>
                                <aside className="hide-pdf">
                                  <span className="prompt">Sector 1</span>
                                </aside>
                                <AutoHeightTextArea
                                  value={subPage.primary_keyword_phrase}
                                  onChange={(e) => {
                                    const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                                    set(productPages, `[${index}].sub_pages[${subIndex}].primary_keyword_phrase`, e.target.value);
                                    setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="phase-2">
                            <Col>
                              <FormGroup>
                                <Label>Secondary Keyword Phrases</Label>
                                <aside className="hide-pdf">
                                  <span className="prompt">Sector 1</span>
                                </aside>
                                <AutoHeightTextArea
                                  value={subPage.secondary_keyword_phrases}
                                  onChange={(e) => {
                                    const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                                    set(productPages, `[${index}].sub_pages[${subIndex}].secondary_keyword_phrases`, e.target.value);
                                    setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="phase-2">
                            <Col>
                              <FormGroup>
                                <Label>Related Keyword Phrases</Label>
                                <aside className="hide-pdf">
                                  <span className="prompt">Sector 1</span>
                                </aside>
                                <AutoHeightTextArea
                                  value={subPage.related_keyword_phrases}
                                  onChange={(e) => {
                                    const productPages = cloneDeep(tempBriefing.info.product_pages || []);
                                    set(productPages, `[${index}].sub_pages[${subIndex}].related_keyword_phrases`, e.target.value);
                                    setTempBriefing({ ...tempBriefing, info: { ...tempBriefing.info, product_pages: productPages } });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}

          {tempBriefing?.info?.include_gallery_page && (
            <>
              <a className="anchor" id="galleryPageBriefInfo" />
              <Card className="mb-4 anchor-box anchor-box-galleryPageBriefInfo">
                <CardBody>
                  <CardTitle>Gallery Page Brief Info</CardTitle>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Nav Menu Title</Label>
                        <Input name="gallery_page_nav_title" value={tempBriefing?.info?.gallery_page_nav_title} onChange={onChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Page Title</Label>
                        <Input name="gallery_page_title" value={tempBriefing?.info?.gallery_page_title} onChange={onChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="phase-2">
                    <Col>
                      <FormGroup>
                        <Label>Primary Keyword Phrase</Label>
                        <AutoHeightTextArea
                          name="gallery_page_primary_keyword_phrase"
                          value={tempBriefing?.info?.gallery_page_primary_keyword_phrase}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="phase-2">
                    <Col>
                      <FormGroup>
                        <Label>Secondary Keyword Phrases</Label>
                        <AutoHeightTextArea
                          name="gallery_page_secondary_keyword_phrases"
                          value={tempBriefing?.info?.gallery_page_secondary_keyword_phrases}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Intro text</Label>
                        <aside>
                          Write a description of what visitors can expect to see on the Gallery page.
                        </aside>
                        <AutoHeightTextArea
                          name="gallery_page_intro_text"
                          value={tempBriefing?.info?.gallery_page_intro_text}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Gallery description</Label>
                        <aside>
                          What type of gallery is preferred?
                        </aside>
                        <Input name="gallery_page_description" value={tempBriefing?.info?.gallery_page_description} onChange={onChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Internal Info Only</Label>
                        <Input name="gallery_page_other_info" value={tempBriefing?.info?.gallery_page_other_info} onChange={onChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )}

          {tempBriefing?.info?.include_faq_page && (
            <>
              <a className="anchor" id="faqPageBriefInfo" />
              <Card className="mb-4 anchor-box anchor-box-faqPageBriefInfo">
                <CardBody>
                  <CardTitle>FAQ Page Brief Info</CardTitle>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Nav Menu Title</Label>
                        <Input name="faq_page_nav_title" value={tempBriefing?.info?.faq_page_nav_title} onChange={onChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Page Title</Label>
                        <Input name="faq_page_title" value={tempBriefing?.info?.faq_page_title} onChange={onChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="phase-2">
                    <Col>
                      <FormGroup>
                        <Label>Primary Keyword Phrase</Label>
                        <Input
                          name="faq_page_primary_keyword_phrase"
                          value={tempBriefing?.info?.faq_page_primary_keyword_phrase}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="phase-2">
                    <Col>
                      <FormGroup>
                        <Label>Secondary Keyword Phrases</Label>
                        <AutoHeightTextArea
                          name="faq_page_secondary_keyword_phrases"
                          value={tempBriefing?.info?.faq_page_secondary_keyword_phrases}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Intro text</Label>
                        <aside>
                          Write a description of what visitors can expect to see on the FAQ page.
                        </aside>
                        <AutoHeightTextArea
                          name="faq_page_intro_text"
                          value={tempBriefing?.info?.faq_page_intro_text}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Internal Info Only</Label>
                        <Input name="faq_page_other_info" value={tempBriefing?.info?.faq_page_other_info} onChange={onChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )}

          {tempBriefing?.info?.include_enquiry_page && (
            <>
              <a className="anchor" id="makeAnEnquiryPageBriefInfo" />
              <Card className="mb-4 anchor-box anchor-box-makeAnEnquiryPageBriefInfo">
                <CardBody>
                  <CardTitle>Make an Enquiry Page Brief Info</CardTitle>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Nav Menu Title</Label>
                        <Input name="enquiry_page_nav_title" value={tempBriefing?.info?.enquiry_page_nav_title} onChange={onChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Page Title</Label>
                        <Input name="enquiry_page_title" value={tempBriefing?.info?.enquiry_page_title} onChange={onChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="phase-2">
                    <Col>
                      <FormGroup>
                        <Label>Primary Keyword Phrase</Label>
                        <AutoHeightTextArea
                          name="enquiry_page_primary_keyword_phrase"
                          value={tempBriefing?.info?.enquiry_page_primary_keyword_phrase}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="phase-2">
                    <Col>
                      <FormGroup>
                        <Label>Secondary Keyword Phrases</Label>
                        <AutoHeightTextArea
                          name="enquiry_page_secondary_keyword_phrases"
                          value={tempBriefing?.info?.enquiry_page_secondary_keyword_phrases}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Intro text</Label>
                        <aside>
                          Write a description of what visitors can expect to see on the Make an Enquiry page.
                        </aside>
                        <AutoHeightTextArea
                          name="enquiry_page_intro_text"
                          value={tempBriefing?.info?.enquiry_page_intro_text}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Form fields</Label>
                        <aside>
                          Suggestions: Name, Email address, Phone number(s), Enquiry.
                        </aside>
                        <AutoHeightTextArea
                          name="enquiry_page_form_fields"
                          value={tempBriefing?.info?.enquiry_page_form_fields}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Internal Info Only</Label>
                        <AutoHeightTextArea
                          name="enquiry_page_other_info"
                          value={tempBriefing?.info?.enquiry_page_other_info}
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )}

          <a className="anchor" id="contactPageBriefInfo" />
          <Card className="mb-4 anchor-box anchor-box-contactPageBriefInfo">
            <CardBody>
              <CardTitle>Contact Page Brief Info</CardTitle>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Page Title</Label>
                    <Input name="contact_page_title" value={tempBriefing?.info?.contact_page_title} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="phase-2">
                <Col>
                  <FormGroup>
                    <Label>Primary Keyword Phrase</Label>
                    <AutoHeightTextArea
                      name="contact_page_primary_keyword_phrase"
                      value={tempBriefing?.info?.contact_page_primary_keyword_phrase}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="phase-2">
                <Col>
                  <FormGroup>
                    <Label>Secondary Keyword Phrases</Label>
                    <AutoHeightTextArea
                      name="contact_page_secondary_keyword_phrases"
                      value={tempBriefing?.info?.contact_page_secondary_keyword_phrases}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Contact page details</Label>
                    <aside>
                      Would you like anything other than Contact details - phone, email, address, Map , Form on there? Eg upload function. Also detail anything specific you want in the headline or intro copy.
                    </aside>
                    <AutoHeightTextArea
                      name="contact_page_details"
                      value={tempBriefing?.info?.contact_page_details}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <a className="anchor" id="aceBriefInfo" />
          <Card className="mb-4 anchor-box anchor-box-aceBriefInfo">
            <CardBody>
              <CardTitle>ACE Brief Info</CardTitle>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Notes</Label>
                    <AutoHeightTextArea
                      name="ace_notes"
                      value={tempBriefing?.info?.ace_notes}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

            </CardBody>
          </Card>

          <a className="anchor" id="domainsBriefInfo" />
          <Card className="mb-4 anchor-box anchor-box-domainsBriefInfo">
            <CardBody>
              <CardTitle>Domains Brief Info</CardTitle>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="d-block">Domain</Label>
                    https://www.
                    <Input
                      className="d-inline"
                      style={{ width: '300px' }}
                      name="existing_domain"
                      value={tempBriefing?.info?.existing_domain}
                      onChange={onChange}
                    />
                    {tempBriefing?.info?.existing_domain?.match(/www\./) && (
                      <Alert color="danger" className="mt-2">
                        Please enter the domain without the &ldquo;www.&rdquo; part.
                      </Alert>
                    )}
                    {tempBriefing?.info?.existing_domain?.match(/https:?/) && (
                      <Alert color="danger" className="mt-2">
                        Please enter the domain without the &ldquo;https://&rdquo; part.
                      </Alert>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="d-block">Temporary wb.gs Domain</Label>
                    https://www.
                    <Input
                      className="d-inline"
                      style={{ width: '300px' }}
                      name="temp_domain"
                      value={tempBriefing?.info?.temp_domain}
                      onChange={onChange}
                    />
                    .wb.gs
                    {tempSearchDomains?.length > 0 && (
                      <Alert color="danger" className="mt-2">
                        This domain is already in use.
                      </Alert>
                    )}
                    {tempBriefing?.info?.temp_domain?.match(/www\./) && (
                      <Alert color="danger" className="mt-2">
                        Please enter the domain without the &ldquo;www.&rdquo; part.
                      </Alert>
                    )}
                    {tempBriefing?.info?.temp_domain?.match(/wb\.gs/) && (
                      <Alert color="danger" className="mt-2">
                        Please enter the domain without the &ldquo;wb.gs&rdquo; part.
                      </Alert>
                    )}
                    {tempBriefing?.info?.temp_domain?.match(/https:?/) && (
                      <Alert color="danger" className="mt-2">
                        Please enter the domain without the &ldquo;https://&rdquo; part.
                      </Alert>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Who is it registered with?</Label>
                    <Input name="domain_registered" value={tempBriefing?.info?.domain_registered} onChange={onChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Domain details</Label>
                    <aside>
                      Can we get login details? If the client has a brand new domain name ask if they want a landing page setup in the interim.
                    </aside>
                    <AutoHeightTextArea
                      name="domain_details"
                      value={tempBriefing?.info?.domain_details}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <a className="anchor" id="keywordPhrases" />
          <Card className="mb-4 anchor-box anchor-box-keywordPhrases">
            <CardBody>
              <CardTitle>15 Keyword Phrases</CardTitle>
              <Row>
                <Col>
                  <FormGroup>
                    <AutoHeightTextArea
                      name="keyword_phrases"
                      value={tempBriefing?.info?.keyword_phrases}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Notes</Label>
                    <AutoHeightTextArea
                      name="keyword_phrase_notes"
                      value={tempBriefing?.info?.keyword_phrase_notes}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>

        <BottomActionToolbar
          component={(
            <SaveAndContinue
              onSave={onSave}
              onContinue={replacePathParams(settingRoutes.siteBriefings, [], props)}
            />
          )}
        />
      </UserFeaturePage>
    </div>
  );
}

export default withRouter(SiteBriefingForm);
