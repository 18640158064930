import React from 'react';
import {
  Input,
  Label,
  FormGroup,
} from 'reactstrap';

function OrientationStatement(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label for="orientation_statement">Orientation Statement</Label>
      <Input
        type="text"
        name="orientation_statement"
        id="orientation_statement"
        value={params.orientation_statement || ''}
        onChange={(e) => {
          const { value } = e.target;
          setParams({ ...params, orientation_statement: value });
        }}
      />
    </FormGroup>
  );
}

export default OrientationStatement;
