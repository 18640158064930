import React, { useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Label, UncontrolledTooltip, Card, CardBody, Input } from 'reactstrap';
import { isEmpty, cloneDeep, isEqual } from 'lodash-es';
import Select from 'react-select';
import CarbonCopyEmail from '../../../components/common/carbonCopyEmail';
import HeaderComponent from '../../../components/common/header-component';
import { getPageHierarchy } from '../../../components/common/PageHierarchy';
import BottomActionToolbar from '../../../components/common/toolbar';
import SaveAndContinue from '../../../components/common/saveAndContinue';
import { siteCenter, replacePathParams, settingRoutes } from '../../../constants/routes';
import { getSelectedOption, selectColorStyles } from '../../../../../Utils';
import withRouter from '../../../helpers/withRouter';
import { useGetInstanceDataQuery, useGetSectionsByTypeQuery, useSectionUpdateMutation } from '../../../../../common/api/apiSlice';
import UserFeaturePage from '../../../components/common/user-feature-page';
import { addRecentItem } from '../../../../../UtilsTS';
import { useSite } from '../../../../../common/hooks';
import ReactTable8 from 'Dashboard/src/components/common/Table8';
import { createColumnHelper } from '@tanstack/react-table';

const getForm = (id, forms) => {
  const form = forms.find((f) => f.id === id);
  if (!form) {
    return;
  }
  return form;
};

function EditAllForms(props) {
  const { params: { instanceId, siteId } } = props;
  const site = useSite();
  const [version, setVersion] = useState('published');
  const [pageList, setPageList] = useState([]);
  const [allForms, setAllForms] = useState([]);
  const [originalForms, setOriginalForms] = useState([]);
  const { data } = useGetInstanceDataQuery(instanceId, { skip: isEmpty(instanceId) });
  const { data: sectionsByType, refetch } = useGetSectionsByTypeQuery({
    instanceId, siteId, version, type: 'SectionForm',
  });
  const [sectionUpdate, { isSuccess }] = useSectionUpdateMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const siteData = data.filter((item) => item.id == instanceId)[0].sites;

      const pageList = siteData.filter((item) => item.id == siteId)[0].pages;
      const pageHierarchy = getPageHierarchy(pageList, '');

      setPageList(pageHierarchy);
    }
  }, [data, instanceId, siteId]);

  useEffect(() => {
    if (!isEmpty(sectionsByType)) {
      setAllForms(sectionsByType);

      if (isEmpty(originalForms)) {
        setOriginalForms(cloneDeep(sectionsByType));
      }
    }
  }, [sectionsByType]);

  const setFormValue = (field, value, index) => {
    setAllForms((prevForms) =>
      prevForms.map((form, idx) =>
        idx === index ? { ...form, [field]: value } : form,
      ),
    );
  };

  const addEmailAddress = (id, email, index) => {
    const form = getForm(id, allForms);
    if (!form) {
      return;
    }
    setFormValue('email_addresses', email, index);
  };

  const onSave = () => {
    const toSave = {};
    const promises = [];

    allForms.forEach((form) => {
      const originalForm = originalForms.find((f) => f.id === form.id);
      if (!originalForm) {
        return;
      }

      Object.keys(form).forEach((k) => {
        if (k.match(/^temp_/)) {
          return;
        }

        if (isEqual(originalForm[k], form[k])) {
          return;
        }

        toSave[form.id] = form;
      });
    });

    Object.values(toSave).forEach((form) => {
      promises.push(new Promise((resolve) => {
        sectionUpdate({ instance_id: instanceId, section_id: form.id, ...form }).then(resolve);
      }));
    });

    return Promise.all(promises);
  };

  if (instanceId) {
    addRecentItem({
      instance_id: instanceId,
      type: 'edit-all-forms',
    });
  }

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    { name: 'Site Centre', url: replacePathParams(siteCenter.pageList, [], props) },
    { name: 'Edit All Forms', url: '' },
  ];

  const columnHelper = createColumnHelper();

  const tableColumns = useMemo(() => [
    columnHelper.accessor(
      (row) =>
        `${row.page_name} >> ${row.sector} >> ${
          row.column_section
            ? `Section ${row.column_section.order} >> Column ${row.order}`
            : `Section ${row.order}`
        }`,
      {
        id: 'form',
        header: 'Form',
        cell: (info) => info.getValue(),
      },
    ),
    columnHelper.accessor('email_subject_line', {
      id: 'subject',
      header: () =>
        site?.default_form_email_subject
          ? (
              <>
                <Label className="has-tooltip mb-0" id="emailSubjectHelp">
                  Email Subject
                </Label>
                <UncontrolledTooltip
                  placement="right"
                  target="emailSubjectHelp"
                  autohide={false}
                  style={{ textAlign: 'left' }}
                >
                  The default email subject is &quot;
                  <b>{site?.default_form_email_subject}</b>
                  &quot;.
                  <br />
                  Enter a different subject in this field to override the default.
                </UncontrolledTooltip>
              </>
            )
          : (
              'Email Subject'
            ),
      cell: ({ row }) => (
        <Input
          type="text"
          placeholder={site?.default_form_email_subject}
          value={row.original.email_subject_line}
          onChange={(e) => setFormValue('email_subject_line', e.target.value, row.index)}
        />
      ),
    }),
    columnHelper.accessor('add_to_database', {
      id: 'member',
      header: 'Adds member to database',
      cell: ({ row }) => (
        <Input
          type="checkbox"
          checked={row.original.add_to_database}
          onChange={(e) =>
            setFormValue('add_to_database', e.target.checked, row.index)}
        />
      ),
    }),
    columnHelper.accessor('redirect_page_id', {
      id: 'redirectto',
      header: 'Redirects to',
      cell: ({ row }) => (
        <Select
          name="redirect_page_id"
          isSearchable
          options={pageList}
          styles={selectColorStyles}
          onChange={(e) =>
            setFormValue('redirect_page_id', e.value, row.index)}
          value={getSelectedOption(pageList, row.original.redirect_page_id)}
        />
      ),
    }),
    columnHelper.accessor('email_addresses', {
      id: 'emails',
      header: () =>
        site?.default_form_email_addresses?.length > 0
          ? (
              <>
                <Label className="has-tooltip mb-0" id="emailAddressHelp">
                  Email Address
                </Label>
                <UncontrolledTooltip
                  placement="right"
                  target="emailAddressHelp"
                  autohide={false}
                  style={{ textAlign: 'left' }}
                >
                  {site?.default_form_email_addresses.length === 1
                    ? (
                        <>
                          The default email address is
                          {' '}
                          <b>{site?.default_form_email_addresses[0]}</b>
                          .
                        </>
                      )
                    : (
                        <>
                          The default email addresses are:
                          <br />
                          <b>
                            {site?.default_form_email_addresses.map((a, i) => (
                              <div key={i}>{a}</div>
                            ))}
                          </b>
                        </>
                      )}
                  <br />
                  Enter different addresses in this field to override the default.
                </UncontrolledTooltip>
              </>
            )
          : (
              'Email Address'
            ),
      cell: ({ row }) => (
        <CarbonCopyEmail
          placeholder="Add email..."
          id="default_form_email_addresses"
          name="default_form_email_addresses"
          selectedEmails={row.original.email_addresses}
          onChange={(e) => addEmailAddress(row.original.id, e, row.index)}
        />
      ),
    }),
    columnHelper.accessor('mobile_number', {
      id: 'mobile',
      header: () =>
        site?.default_form_mobile_number
          ? (
              <>
                <Label className="has-tooltip mb-0" id="mobileNumberHelp">
                  Mobile Number
                </Label>
                <UncontrolledTooltip
                  placement="right"
                  target="mobileNumberHelp"
                  autohide={false}
                  style={{ textAlign: 'left' }}
                >
                  The default mobile number is
                  {' '}
                  <b>{site?.default_form_mobile_number}</b>
                  .
                  <br />
                  Enter a different mobile number in this field to override the default.
                </UncontrolledTooltip>
              </>
            )
          : (
              'Mobile Number'
            ),
      cell: ({ row }) => (
        <Input
          type="text"
          placeholder={site?.default_form_mobile_number}
          value={row.original.mobile_number}
          onChange={(e) =>
            setFormValue('mobile_number', e.target.value, row.index)}
        />
      ),
    }),
  ], [pageList]);

  return (
    <>
      <HeaderComponent
        setPath={{
          headingName: 'Edit All Forms',
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Back',
          backToPath: settingRoutes.dashboard,
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
      />
      <UserFeaturePage feature="edit_all_forms">
        <Card>
          <CardBody>
            <ButtonGroup>
              <Button
                color="primary"
                outline
                onClick={() => setVersion('published')}
                active={version === 'published'}
              >
                Published
              </Button>
              <Button
                color="primary"
                outline
                onClick={() => setVersion('latest')}
                active={version === 'latest'}
              >
                Latest
              </Button>
            </ButtonGroup>
            <hr />
            <ReactTable8 columns={tableColumns} data={allForms} />
          </CardBody>
        </Card>
        <BottomActionToolbar
          component={(
            <SaveAndContinue
              onSave={onSave}
              onContinue={replacePathParams(siteCenter.pageList, [], props)}
            />
          )}
        />
      </UserFeaturePage>
    </>
  );
}

export default withRouter(EditAllForms);
