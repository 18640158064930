import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { cloneDeep, isEmpty } from 'lodash-es';
import withRouter from '../../../../helpers/withRouter';
import { useGetSectionQuery, useSectionUpdateMutation } from '../../../../../../common/api/apiSlice';
import AdvancedSettings from '../../../../components/common/advancedSettings';
import SectionCustom from 'common/models/SectionCustom';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/snippets/ruby';
import 'ace-builds/src-noconflict/theme-github';

function Custom(props) {
  const {
    section: { id: sectionId },
    isEmailPage,
    saveCounter,
    params: { instanceId, siteId },
    updateStylesheet,
  } = props;

  const [tempSection, setTempSection] = useState<SectionCustom>({});

  const { data: section } = useGetSectionQuery({ instanceId, sectionId });

  const [sectionUpdate] = useSectionUpdateMutation();

  useEffect(() => {
    if (section) {
      setTempSection(cloneDeep(section));
    }
  }, [section]);

  const onSave = async (payload) => {
    await sectionUpdate({
      ...payload,
      instance_id: instanceId,
    });

    updateStylesheet();
  };

  useEffect(() => {
    if (saveCounter) {
      (async () => {
        await onSave(tempSection);
      })();
    }
  }, [saveCounter]);

  if (isEmpty(tempSection)) {
    return <></>;
  }

  return (
    <>
      <AdvancedSettings
        siteId={siteId}
        isEmailPage={isEmailPage}
        section={tempSection}
        setSection={setTempSection}
        handleSaveBreakpoint={(e) => setTempSection({ ...tempSection, break_point: e })}
        onHandleSectionIsOffline={() => setTempSection({ ...tempSection, is_offline: !tempSection?.is_offline })}
        onChangeInitialVisibility={() => setTempSection({ ...tempSection, initial_visibility: !tempSection?.initial_visibility })}
        onHandleChangeVisibility={(e) => setTempSection({ ...tempSection, visible_to: e.value })}
      />
      <Row className="mt-4">
        <Col>
          <AceEditor.default
            className="ace-editor"
            mode="ruby"
            theme="xcode"
            name="template-editor"
            onChange={(code) => setTempSection({ ...tempSection, code })}
            height="600px"
            width="100%"
            value={tempSection.code}
            highlightActiveLine
            showGutter
            fontSize={16}
            setOptions={{
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
            }}
            editorProps={{ $blockScrolling: true }}
          />
        </Col>
      </Row>
    </>
  );
}

export default withRouter(Custom);
