import React, { useState } from 'react';
import withRouter from '../../../../helpers/withRouter';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import SaveAndContinue from '../../../../components/common/saveAndContinue';
import SectionEdit from '../SectionEdit';

function SectionEditModal(props) {
  const {
    section,
    onClose,
    params: { pageType },
  } = props;

  const [saveCounter, setSaveCounter] = useState(0);

  const isPublicFacing = window.wg.env === 'public';
  const isEmailPage = pageType === 'email';

  const onSave = () => {
    setSaveCounter(saveCounter + 1);
  };

  const fullscreenEditModal = ['SectionComponent', 'SectionColumn'].indexOf(section?.type) > -1;

  const style = {
    maxWidth: '100%',
  };

  return (
    <Modal
      container={window.modalRef}
      className="edit-section-modal theme-light"
      isOpen={true}
      size={fullscreenEditModal ? 'lg' : 'xl'}
      fullscreen={fullscreenEditModal && !isEmailPage}
      style={isPublicFacing ? style : {}}
    >
      <ModalBody>
        <SectionEdit
          {...props}
          onClose={onClose}
          saveCounter={saveCounter}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          color="danger"
          onClick={onClose}
          className="fw-bold"
        >
          CANCEL
        </Button>
        <SaveAndContinue
          onSave={onSave}
          onContinue={onClose}
        />
      </ModalFooter>
    </Modal>
  );
}

export default withRouter(SectionEditModal);
