import CustomSelect from '../../../components/common/custom-select';
import React from 'react';
import {
  Label,
  FormGroup,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import Zone from '../../../helpers/zone_list';
import { getSelectedOption } from '../../../../../Utils';

function TimeZone(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label>Time zone</Label>
      <InputGroup>
        <InputGroupText><i className="fal fa-location-dot" aria-hidden="true" /></InputGroupText>
        <CustomSelect
          options={Zone}
          isSearch={false}
          name="time_zone"
          className="flex-grow-1 mb-0"
          onChange={(e) => {
            const { value } = e;
            setParams({ ...params, time_zone: value });
          }}
          value={getSelectedOption(Zone, params.time_zone)}
        />
      </InputGroup>
    </FormGroup>
  );
}

export default TimeZone;
