import { isEmpty, filter, uniq } from 'lodash-es';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Button, InputGroup } from 'reactstrap';
import ColorPicker from '../../../components/common/color-picker';
import './index.scss';
import CustomSelect from '../../../components/common/custom-select';
import { getSelectedOption, selectColorStyles } from '../../../../../Utils';
import { BackgroundPositionX, BackgroundPositionY, BackgroundRepeat, BackgroundSize } from '../textStyles';
import { numberFieldWithUnitSelector } from '../../../views/site-centre/design-options/components/component';
import { MediaSelectBox } from '../../../components/fields/site';

const imageAlignOptions = [
  { label: 'Default', value: 0 },
  { label: 'Left', value: 1 },
  { label: 'Centre', value: 2 },
  { label: 'Right', value: 3 },
  { label: 'Inline', value: 4 },
];

const displayOptions = [
  { label: 'True', value: 'block' },
  { label: 'False', value: 'none' },
];

export const convertNegativeToHex = (presetColors, sValue) => {
  if (typeof sValue !== 'string') sValue = '';
  const presetObj = { isPreset: false, sValue };
  if (presetColors && sValue.match(/^-\d+$/)) {
    const id = parseInt(sValue, 10);
    if (id < 0) {
      const preset = presetColors.find((c) => Math.abs(sValue) === c.id);
      if (preset) {
        presetObj.sValue = preset.hex;
        presetObj.isPreset = true;
      }
    }
  }
  return presetObj;
};

export const getColorVal = (val, presetColors) => {
  if (!val) {
    return null;
  }

  if (parseInt(val, 10) < 0) {
    // preset colour
    return presetColors.find((c) => Math.abs(parseInt(val, 10)) === c.id)?.hex;
  }

  if (val === 'transparent') {
    return val;
  }

  if (val.includes('linear') || val.includes('radial')) {
    return val;
  }

  if (!val.match(/^#/)) {
    return `#${val}`;
  }

  return val;
};

function BreakPoint(props) {
  const {
    siteId,
    objectId,
    objectType,
    isEmailPage,
    settingName,
    breakpoint,
    showSettingType,
    activeDeviceBtn,
    handleSaveBreakpoint,
  } = props;

  const mounted = useRef(false);

  // An internal copy of the breakpoint from the props
  const [breakpointInt, setBreakpointInt] = useState(breakpoint || {
    all_styles: {},
    desktop_styles: {},
    tablet_styles: {},
    phone_styles: {},
    sector: [],
    important: true,
    object_id: objectId,
    object_type: objectType,
    site_id: siteId,
  });

  useEffect(() => {
    if (!isEmpty(breakpoint)) {
      setBreakpointInt(breakpoint);
    }
  }, [breakpoint]);

  if (!breakpointInt.all_styles) {
    setBreakpointInt({ ...breakpointInt, all_styles: {} });
  }
  if (!breakpointInt.desktop_styles) {
    setBreakpointInt({ ...breakpointInt, desktop_styles: {} });
  }
  if (!breakpointInt.tablet_styles) {
    setBreakpointInt({ ...breakpointInt, tablet_styles: {} });
  }
  if (!breakpointInt.phone_styles) {
    setBreakpointInt({ ...breakpointInt, phone_styles: {} });
  }

  // Much hassle about setting the initial active device. We want to set it to "all" if all three devices use the same
  // value, or if there is only an "all" value set. We want a specific device to be intiially active if there is a
  // value for that device but there isn't an "all" value set. We are also using JSON.stringify() below because uniq
  //  doesn't work with arrays: ie uniq([1], [1]) == [[1], [1]] -- this means that we need to filter out '[""]' and '[]'.
  const s = {
    a: JSON.stringify((breakpointInt.all_styles || {})[settingName]),
    d: JSON.stringify((breakpointInt.desktop_styles || {})[settingName]),
    t: JSON.stringify((breakpointInt.tablet_styles || {})[settingName]),
    m: JSON.stringify((breakpointInt.phone_styles || {})[settingName]),
  };

  Object.keys(s).forEach((k) => {
    if (s[k] === '[""]' || s[k] === '[]') {
      delete s[k];
    }
  });

  const uniqueStyles = uniq(filter(Object.values(s), (v) => v !== undefined));

  let initialActiveDevice = 'all';

  if (isEmpty(s.a) || s.a === '[""]') {
    if (!isEmpty(s.d) && s.d !== '[""]') {
      initialActiveDevice = 'desktop';
    } else if (!isEmpty(s.t) && s.t !== '[""]') {
      initialActiveDevice = 'tablet';
    } else if (!isEmpty(s.m) && s.m !== '[""]') {
      initialActiveDevice = 'phone';
    }
  } else if (uniqueStyles.length > 1) {
    initialActiveDevice = 'desktop';
  }

  const [activeDevice, setActiveDevice] = useState(initialActiveDevice);

  const setSettingValue = (v) => {
    const tempStyles = { ...breakpointInt[`${activeDevice}_styles`] };

    if (v === null) {
      delete tempStyles[settingName];
    } else {
      tempStyles[settingName] = [v];
    }

    if (activeDevice === 'all') {
      delete breakpointInt.desktop_styles[settingName];
      delete breakpointInt.tablet_styles[settingName];
      delete breakpointInt.phone_styles[settingName];

      setBreakpointInt({
        ...breakpointInt,
        all_styles: tempStyles,
        desktop_styles: breakpointInt.desktop_styles,
        tablet_styles: breakpointInt.tablet_styles,
        phone_styles: breakpointInt.phone_styles,
      });
    } else {
      delete breakpointInt.all_styles[settingName];

      setBreakpointInt({
        ...breakpointInt,
        [`${activeDevice}_styles`]: tempStyles,
        all_styles: breakpointInt.all_styles,
      });
    }
  };

  const settingValue = ((breakpointInt[`${activeDevice}_styles`] || {})[settingName] || [])[0];

  const save = () => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    handleSaveBreakpoint(breakpointInt);
  };

  useEffect(save, [breakpointInt]);

  useEffect(
    () => { if (activeDeviceBtn && activeDevice !== 'all') setActiveDevice(activeDeviceBtn); },
    [activeDeviceBtn],
  );

  const onSettingValueChange = (e, inputType) => {
    if (e === null) {
      setSettingValue(null);
    } else {
      const { value } = inputType === 'select' ? e : e.target;
      setSettingValue(value);
    }
  };

  const onDeleteValue = () => {
    setSettingValue(null);
    save();
  };

  const getOptions = () => {
    if (isEmailPage) {
      return imageAlignOptions.filter((el) => el.value !== 4);
    }
    return imageAlignOptions;
  };

  const setting = (sName, sValue) => {
    switch (sName) {
      case 'display':
        return (
          <CustomSelect
            className="w-100"
            options={displayOptions}
            onChange={(e) => onSettingValueChange(e, 'select')}
            value={displayOptions.filter((el) => el.value === sValue)}
          />
        );
      case 'image-align':
        return (
          <CustomSelect
            className="w-100"
            options={getOptions()}
            onChange={(e) => onSettingValueChange(e, 'select')}
            value={getSelectedOption(getOptions(), sValue)}
          />
        );
      case 'background':
      case 'border-color':
        return (
          <ColorPicker
            isEmailPage={isEmailPage}
            isBackgroundColor={sName === 'background'}
            color={sValue === null || sValue === undefined ? '' : sValue}
            onChange={(e) => onSettingValueChange(e)}
            onDeleteValue={() => onDeleteValue()}
          />
        );
      case 'background-image':
        return (
          <MediaSelectBox
            isClearable
            value={sValue}
            onchange={(e) => onSettingValueChange(e, 'select')}
          />
        );
      case 'background-position-x':
        return (
          <BackgroundPositionX
            value={sValue}
            className="w-100"
            styles={selectColorStyles}
            onChange={(e) => onSettingValueChange(e, 'select')}
          />
        );
      case 'background-position-y':
        return (
          <BackgroundPositionY
            value={sValue}
            className="w-100"
            styles={selectColorStyles}
            onChange={(e) => onSettingValueChange(e, 'select')}
          />
        );
      case 'background-size':
        return (
          <BackgroundSize
            value={sValue}
            className="w-100"
            styles={selectColorStyles}
            onChange={(e) => onSettingValueChange(e, 'select')}
          />
        );
      case 'background-repeat':
        return (
          <BackgroundRepeat
            value={sValue}
            className="w-100"
            styles={selectColorStyles}
            onChange={(e) => onSettingValueChange(e, 'select')}
          />
        );
      case 'max-width':
        return numberFieldWithUnitSelector(
          sValue,
          (value) => setSettingValue(value === '' ? null : value),
          sName,
          showSettingType,
          true,
        );
      default:
        return numberFieldWithUnitSelector(sValue, setSettingValue, sName, showSettingType);
    }
  };

  let tempBreakpointInt = { ...breakpointInt };

  return (
    <Row className="BreakPoint align-items-center">
      <ul>
        <li className="w-100">
          <InputGroup>
            {setting(settingName, settingValue)}
          </InputGroup>
        </li>
        {!isEmailPage
        && (
          <li className="bp-responsivebtn d-flex" style={{ alignItems: 'center' }}>
            {activeDevice !== 'all' && (
              <>
                <Button
                  className={`breakpoint-txt-btn ${activeDevice === 'desktop' ? 'custom-text-color-blue' : ''}`}
                  onClick={() => setActiveDevice('desktop')}
                >
                  <i className="fal fa-fw fa-desktop fa-2x" aria-hidden="true" />
                </Button>
                <Button
                  className={`breakpoint-txt-btn ${activeDevice === 'tablet' ? 'custom-text-color-blue' : ''}`}
                  onClick={() => setActiveDevice('tablet')}
                >
                  <i className="fal fa-fw fa-tablet-alt fa-2x" aria-hidden="true" />
                </Button>
                <Button
                  className={`breakpoint-txt-btn ${activeDevice === 'phone' ? 'custom-text-color-blue' : ''}`}
                  onClick={() => setActiveDevice('phone')}
                >
                  <i className="fal fa-fw fa-mobile-alt fa-2x" aria-hidden="true" />
                </Button>
                <span style={{ margin: '0 2.5px', fontSize: '12px' }}>┊</span>
              </>
            )}
            <Button
              className={
                `breakpoint-txt-btn ${isEmpty(activeDevice) || activeDevice === 'all'
                  ? 'custom-text-color-blue'
                  : ''}`
              }
              onClick={() => {
                if (activeDevice === 'all') {
                  tempBreakpointInt = {
                    ...breakpointInt,
                    desktop_styles: {
                      ...breakpointInt.desktop_styles,
                      [settingName]: breakpointInt.all_styles[settingName],
                    },
                    tablet_styles: {
                      ...breakpointInt.tablet_styles,
                      [settingName]: breakpointInt.all_styles[settingName],
                    },
                    phone_styles: {
                      ...breakpointInt.phone_styles,
                      [settingName]: breakpointInt.all_styles[settingName],
                    },
                    all_styles: {
                      ...breakpointInt.all_styles,
                    },
                  };
                  delete tempBreakpointInt.all_styles[settingName];

                  setActiveDevice('desktop');
                } else {
                  setActiveDevice('all');

                  tempBreakpointInt = {
                    ...breakpointInt,
                    all_styles: {
                      ...breakpointInt.all_styles,
                      [settingName]: breakpointInt.desktop_styles[settingName],
                    },
                    desktop_styles: {
                      ...breakpointInt.desktop_styles,
                    },
                    tablet_styles: {
                      ...breakpointInt.tablet_styles,
                    },
                    phone_styles: {
                      ...breakpointInt.phone_styles,
                    },
                  };

                  delete tempBreakpointInt.desktop_styles[settingName];
                  delete tempBreakpointInt.tablet_styles[settingName];
                  delete tempBreakpointInt.phone_styles[settingName];
                }
                setBreakpointInt({
                  ...tempBreakpointInt,
                  all_styles: tempBreakpointInt.all_styles,
                  desktop_styles: tempBreakpointInt.desktop_styles,
                  tablet_styles: tempBreakpointInt.tablet_styles,
                  phone_styles: tempBreakpointInt.phone_styles,
                });
              }}
            >
              <i className="fal fa-fw fa-link fa-2x" aria-hidden="true" />
            </Button>
          </li>
        )}
      </ul>
    </Row>
  );
}
export default BreakPoint;
