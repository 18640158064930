import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { compact, map } from 'lodash-es';
import './index.scss';
import withRouter from '../../../helpers/withRouter';

function HeaderComponent(props) {
  const {
    setPath, navigate,
  } = props;

  const onAddNewButtonClick = (e) => {
    navigate(e);
  };

  const onBackButtonClick = () => {
    navigate(-1);
  };

  const addNewButton = () => (
    setPath.addNewPath
      ? (
          <Button color="info" className="custom-simple-icon" onClick={() => onAddNewButtonClick(setPath.addNewPath)}>
            <i className="fal fa-folder-plus" />
            <b>{` ${setPath.addNewBtnName}`}</b>
          </Button>
        )
      : ''
  );
  const backButton = () => (
    <Button color="info" className="back-button" onClick={() => onBackButtonClick()}>
      <i className="fa fa-arrow-circle-left" />
      <b> Back</b>
    </Button>
  );

  const getBreadcrumb = () => map(compact(setPath.staticBreadcrumbData), (data, index) => (data.url.length === 0
    ? (
        <li key={index} className="active breadcrumb-item pe-none">
          <span style={{ textDecoration: 'none', color: 'var(--secondary-color)' }}>
            {data.name}
          </span>
        </li>
      )
    : (
        <li className="breadcrumb-item" key={index}>
          <Link to={data.url} style={{ textDecoration: 'none' }}>
            {data.name}
          </Link>
        </li>
      )));

  return (
    <div className="mb-2 HeaderBreadcrumb">
      <h1>{setPath.headingName}</h1>
      <div className="text-zero top-right-button-container">
        {addNewButton()}
        {' '}
        {backButton()}
      </div>
      <nav
        className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
        aria-label="breadcrumb"
      >
        <ol className="breadcrumb ms-3">{getBreadcrumb()}</ol>
      </nav>
    </div>
  );
}
export default withRouter(HeaderComponent);
