export const formSectionInputType = {
  TEXT: 'text',
  HIDDEN: 'hidden',
  RADIO: 'radio_button',
  CHECKBOX: 'checkboxes',
  DROPDOWN: 'drop_down_list',
  FILE_UPLOAD: 'file_upload',
  MULTI_LINE: 'multi_line_text',
  SINGLE_LINE: 'single_line_text',
  DATE: 'date',
};

export const formSectionFields = {
  TYPE: 'type',
  VALUES: 'values',
  IS_REQUIRED: 'is_required',
  OTHER_OPTION: 'other_option',
  DB_FIELD: 'database_field_id',
  INCLUDE_IN_SMS: 'include_in_sms',
  BREAK_LINE: 'break_values_in_newline',
};

export const formItemFields = {
  BUTTON_STYLE: 'button_style',
  TEXT: 'text',
  IMAGE: 'image',
  ROLLOVER: 'rollover',
  ABOVE_FIELD: 'above_field',
  INSIDE_FIELD: 'inside_field',
};

export const submitType = {
  ADD: 'add',
  SAVE: 'save',
  DELETE: 'delete',
  UPDATE: 'update',
};

export const formFieldType = [
  { label: 'Single Line Text', value: 'single_line_text' },
  { label: 'Multi Line Text', value: 'multi_line_text' },
  { label: 'Check Boxes', value: 'checkboxes' },
  { label: 'Radio Buttons', value: 'radio_button' },
  { label: 'Drop Down List', value: 'drop_down_list' },
  { label: 'Hidden', value: 'hidden' },
  { label: 'Text', value: 'text' },
  { label: 'File Upload', value: 'file_upload' },
  { label: 'Date', value: 'date' },
];

export const deviceType = {
  PHONE: 'phone_layout',
  TABLET: 'tablet_layout',
  DESKTOP: 'desktop_layout',
};

export const databaseFieldType = {
  URL: 'url',
  NONE: 'none',
  DATE: 'date',
  EMAIL: 'email',
  NUMERIC: 'numeric',
  CHECKBOX: 'checkboxes',
  LONG_TEXT: 'long_text',
  SHORT_TEXT: 'short_text',
  SELECT_LIST: 'select_list',
  RADIO_BUTTON: 'radio_button',
};

export const includeFieldOptions = [
  { label: 'Include in SMS', value: 'include' },
  { label: 'Include field value only in SMS', value: 'include_field_value_only' },
  { label: 'Do not include in SMS', value: 'do_not_include' },
];
