import React, { useState } from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button,
} from 'reactstrap';

const ConfirmModal = (props) => {
  const {
    message,
    title,
    confirmText,
    cancelText,
    confirmColor,
    cancelColor,
    className,
    onOpened,
    onClose,
  } = props;

  const [modalOpen, setModalOpen] = useState(true);

  const toggle = (result = true) => {
    setModalOpen(!modalOpen);

    if (typeof onClose === 'function') {
      onClose(result);
    }
  };

  const confirm = () => {
    toggle(true);
  };

  const cancel = () => {
    toggle(false);
  };

  let modalHeader = null;
  let cancelButton = null;

  if (title) {
    modalHeader = (
      <ModalHeader toggle={() => {
        toggle(false);
      }}
      >
        {title}
      </ModalHeader>
    );
  }

  if (cancelText) {
    cancelButton = (
      <Button color={cancelColor} onClick={cancel}>
        {cancelText}
      </Button>
    );
  }

  return (
    <Modal
      isOpen={modalOpen}
      toggle={() => {
        toggle(false);
      }}
      className={className}
      onOpened={onOpened}
      container={window.modalRef}
    >
      {modalHeader}
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        {cancelButton}
        {' '}
        <Button color={confirmColor} onClick={confirm}>
          {confirmText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
