import React from 'react';
import { Row, Col, Label } from 'reactstrap';

function CSSBoxModel({
  labelName, eleTop, eleLeft, eleRight, eleBottom,
}) {
  return (
    <div className="box-modal">
      <Label>{labelName}</Label>
      <Row className="mb-2">
        <Col lg={3} md={8} sm={6} xs={6}>
          {eleTop}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col lg={3} md={6} sm={6} xs={6}>
          {eleLeft}
        </Col>
        <Col lg={3} md={6} sm={6} xs={6}>
          {eleRight}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col lg={3} md={8} sm={6} xs={6}>
          {eleBottom}
        </Col>
      </Row>
    </div>
  );
}

export default CSSBoxModel;
