import React from 'react';
import {
  Label,
  FormGroup,
} from 'reactstrap';
import AceEditor from 'react-ace';
import { get } from 'lodash-es';

function PrivacyPolicyText(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label for="privacy_policy_text">Text</Label>
      <AceEditor.default
        mode="html"
        theme="xcode"
        onChange={(e) => {
          setParams({ ...params, privacy_policy_text: e });
        }}
        height="250px"
        width="100%"
        highlightActiveLine
        fontSize={16}
        editorProps={{ $blockScrolling: true }}
        value={get(params, 'privacy_policy_text', '')}
      />
    </FormGroup>
  );
}

export default PrivacyPolicyText;
