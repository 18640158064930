import React, { Fragment, ReactNode } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
  SortingState,
  flexRender,
} from '@tanstack/react-table';
import { Table } from 'reactstrap';
import { useUser } from 'common/hooks';

type CustomColumnDef<TData> = ColumnDef<TData> & {
  display?: boolean;
};

type TableProps<TData> = {
  data: TData[];
  columns: CustomColumnDef<TData>[];
  enableSorting?: boolean;
  subRow?: (visibleColumns: ColumnDef<TData, any>[], row: TData) => ReactNode;
};

const CTATable8 = <TData,>({ data, columns, enableSorting = false, subRow }: TableProps<TData>) => {
  const user = useUser();
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const assignReactKeyToChildren = (d) => React.Children.toArray(d);

  const getColumns = (d) => (!user?.hasFeature('view_spam_ctas') ? d.filter((el) => el.id !== 'Spam' && el.id !== 'Actions') : d);

  const getRowCells = (cells) => (
    !user?.hasFeature('view_spam_ctas') ? cells.filter((el) => el.column.id !== 'Spam' && el.column.id !== 'Actions') : cells
  );

  const filteredColumns = React.useMemo(() => {
    return columns.filter((column) => column.display !== false);
  }, [columns]);

  const table = useReactTable({
    data,
    columns: filteredColumns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    ...(enableSorting ? { getSortedRowModel: getSortedRowModel() } : {}),
  });

  return (
    <Table className="cta-table">
      <thead>
        {assignReactKeyToChildren(table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {getColumns(headerGroup.headers).map((header) => (
              <th
                key={header.id}
                style={enableSorting ? { cursor: header.column.getCanSort() ? 'pointer' : 'default' } : {}}
                onClick={enableSorting ? header.column.getToggleSortingHandler() : () => {}}
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getIsSorted() === 'asc' ? ' 🔼' : header.column.getIsSorted() === 'desc' ? ' 🔽' : null}
              </th>
            ))}
          </tr>
        )))}
      </thead>
      <tbody>
        {assignReactKeyToChildren(table.getRowModel().rows.map((row) => {
          const subRowEl = typeof subRow === 'function' ? subRow(row.getVisibleCells(), row.original) : null;
          const isReactElement = React.isValidElement(subRowEl);
          const hasSubRow = isReactElement && React.Children.count(subRowEl.props?.children) > 0;

          return (
            <Fragment key={row.id}>
              <tr key={row.id}>
                {getRowCells(row.getVisibleCells()).map((cell) => {
                  let rowSpan;
                  if (typeof cell.column.rowSpan === 'function') {
                    rowSpan = cell.column.rowSpan(cell);
                  } else if (cell.column.rowSpan) {
                    rowSpan = cell.column.rowSpan;
                  } else {
                    rowSpan = 1;
                  }

                  return (
                    <td
                      key={cell.id}
                      rowSpan={rowSpan}
                      style={{ borderBottomWidth: hasSubRow ? 'inherit' : '1px' }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
              {subRowEl}
            </Fragment>
          );
        }))}
      </tbody>
    </Table>
  );
};

export default CTATable8;
