import React from 'react';
import PropTypes from 'prop-types';
import { isDescendant } from 'react-sortable-tree';
import './utils/node-renderer-default.css';
import classnames from './utils/classnames';

const NodeRendererDefault = ({
  scaffoldBlockPxWidth,
  toggleChildrenVisibility = null,
  connectDragPreview,
  connectDragSource,
  isDragging,
  canDrop = false,
  canDrag = false,
  node,
  title = null,
  subtitle = null,
  path,
  treeIndex,
  isSearchMatch = false,
  isSearchFocus = false,
  buttons = [],
  className = '',
  style = {},
  didDrop,
  treeId,
  isOver, // Not needed, but preserved for other renderers
  rowDirection = 'ltr',
  draggedNode = null,
  ...otherProps
}) => {
  const nodeTitle = title || node.page_version.name;
  const nodeSubtitle = subtitle || node.subtitle;
  const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null;

  let handle;
  if (canDrag) {
    if (typeof node.children === 'function' && node.expanded) {
      handle = (
        <div className="rst__loadingHandle">
          <div className="rst__loadingCircle">
            {[...new Array(12)].map((_, index) => (
              <div
                key={index}
                className={classnames(
                  'rst__loadingCirclePoint',
                  rowDirectionClass,
                )}
              />
            ))}
          </div>
        </div>
      );
    } else {
      handle = connectDragSource(<div className="site-tree-move"><i className="fa fa-arrows" /></div>, {
        dropEffect: 'copy',
      });
    }
  }

  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  const isLandingPadActive = !didDrop && isDragging;

  let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth };
  if (rowDirection === 'rtl') {
    buttonStyle = { right: -0.5 * scaffoldBlockPxWidth };
  }

  return (
    <div className="rst__nodeContentInner" style={{ height: '100%' }} {...otherProps}>
      {toggleChildrenVisibility && node.children && (node.children.length > 0 || typeof node.children === 'function') && (
        <div>
          <button
            type="button"
            aria-label={node.expanded ? 'Collapse' : 'Expand'}
            className={classnames(
              node.expanded ? 'rst__collapseButton' : 'rst__expandButton',
              rowDirectionClass,
            )}
            style={buttonStyle}
            onClick={() => toggleChildrenVisibility({
              node,
              path,
              treeIndex,
            })}
          />

          {node.expanded && !isDragging && (
            <div
              style={{ width: scaffoldBlockPxWidth }}
              className={classnames('rst__lineChildren', rowDirectionClass)}
            />
          )}
        </div>
      )}

      <div className={classnames('rst__rowWrapper', rowDirectionClass)}>
        {connectDragPreview(
          <div
            className={classnames(
              'rst__row',
              isLandingPadActive && 'rst__rowLandingPad',
              isLandingPadActive && !canDrop && 'rst__rowCancelPad',
              isSearchMatch && 'rst__rowSearchMatch',
              isSearchFocus && 'rst__rowSearchFocus',
              rowDirectionClass,
              className,
            )}
            style={{
              opacity: isDraggedDescendant ? 0.5 : 1,
              ...style,
            }}
          >
            <div
              className={classnames(
                'rst__rowContents',
                'custom-cursor-pointer-draggable',
                !canDrag && 'rst__rowContentsDragDisabled',
                rowDirectionClass,
              )}
            >
              {handle}
              <div className={classnames('rst__rowLabel', rowDirectionClass)}>
                <span
                  className={classnames(
                    'rst__rowTitle',
                    node.subtitle && 'rst__rowTitleWithSubtitle',
                  )}
                >
                  {typeof nodeTitle === 'function'
                    ? nodeTitle({ node, path, treeIndex })
                    : nodeTitle}
                </span>

                {nodeSubtitle && (
                  <span className="rst__rowSubtitle">
                    {typeof nodeSubtitle === 'function'
                      ? nodeSubtitle({ node, path, treeIndex })
                      : nodeSubtitle}
                  </span>
                )}
              </div>

              <div className="rst__rowToolbar ms-auto btn-opt-toolbar">
                {buttons.map((btn, index) => (
                  <div
                    key={index}
                    className="rst__toolbarButton"
                  >
                    {btn}
                  </div>
                ))}
              </div>
            </div>
          </div>,
        )}
      </div>
    </div>
  );
};

NodeRendererDefault.propTypes = {
  node: PropTypes.shape({}).isRequired,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  path: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,
  isSearchMatch: PropTypes.bool,
  isSearchFocus: PropTypes.bool,
  canDrag: PropTypes.bool,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  toggleChildrenVisibility: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  style: PropTypes.shape({}),

  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,

  rowDirection: PropTypes.string,
};

export default NodeRendererDefault;
