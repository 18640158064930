import React, { useState, useEffect, ChangeEvent, Fragment, useRef } from 'react';
import { replacePathParams, statsCenterRoutes, settingRoutes, contentCenterRoutes } from 'Dashboard/src/constants/routes';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  Table,
} from 'reactstrap';
import { displayError } from 'Utils';
import { addRecentItem } from 'UtilsTS';
import { isEmpty } from 'lodash-es';
import HeaderComponent from '../../components/common/header-component';
import confirm from '../../components/common/confirm';
import { useParams } from 'react-router';
import withRouter from '../../helpers/withRouter';
import {
  useGetContentTopicBriefingQuery,
  useUpdateContentTopicBriefingMutation,
  useCreateContentTopicMutation,
  useGenerateTopicSuggestionsMutation,
} from 'common/api/apiSlice';
import { default as ContentTopicBriefingModel } from 'common/models/ContentTopicBriefing';
import { default as ContentTopic } from 'common/models/ContentTopic';
import BottomActionToolbar from '../../components/common/toolbar';
import CustomButton from '../../components/common/button';
import SaveAndContinue from '../../components/common/saveAndContinue';
// import CustomSelect from '../../components/common/custom-select';
import { useInstance } from 'common/hooks';

// Automatically set textarea height depending on the number of lines.
const autoHeightTextArea = (e) => {
  e.target.style.height = 'inherit';
  e.target.style.height = `${e.target.scrollHeight}px`;
};

const AutoHeightTextArea = (
  { name, value, onChange, style }: { name: string; value: string; onChange: (e: ChangeEvent<HTMLInputElement>) => void; style?: any },
) => (
  <Input
    type="textarea"
    name={name}
    value={value}
    onChange={onChange}
    style={style}
  />
);

function ContentTopicBriefing(props) {
  const { navigate } = props;
  const params = useParams();
  const { instanceId, siteId } = params;
  const instance = useInstance();
  const { data: ctb } = useGetContentTopicBriefingQuery(
    {
      siteId: siteId || '',
      id: params.id || '',
    },
    { skip: isEmpty(siteId) || isEmpty(params.id) },
  );
  const [editingCtb, setEditingCtb] = useState<ContentTopicBriefingModel | null>(ctb);
  const [updateContentTopicBriefing] = useUpdateContentTopicBriefingMutation();
  const [createContentTopic] = useCreateContentTopicMutation();
  const [generateTopicSuggestions] = useGenerateTopicSuggestionsMutation();
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (ctb) {
      setEditingCtb(ctb);
    }
  }, [ctb]);

  useEffect(() => {
    if (ref.current) {
      ref.current.querySelectorAll('textarea').forEach((t) => {
        autoHeightTextArea({ target: t });
      });
    }
  }, [editingCtb]);

  addRecentItem({
    instance_id: instanceId,
    site_id: siteId,
    type: 'content-center',
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditingCtb((prev) => new ContentTopicBriefingModel({
      ...prev,
      [name]: value,
    }));
  };

  const update = async () => {
    if (!editingCtb) {
      return false;
    }

    const result = await updateContentTopicBriefing({ ...editingCtb, content_topics_attributes: editingCtb.content_topics });
    if (result) {
      return true;
    }
  };

  const onGenerateTopicSuggestions = async () => {
    if (!editingCtb) {
      return false;
    }

    const updateResult = await update();
    if (!updateResult) {
      displayError('Failed to update content topic briefing');
      return;
    }

    generateTopicSuggestions(editingCtb);
  };

  // const toneOptions = [
  //   { label: 'Friendly and professional', value: 'friendly_professional' },
  //   { label: 'Formal', value: 'formal' },
  //   { label: 'Casual and conversational', value: 'casual_conversational' },
  //   { label: 'Other', value: 'other' },
  // ];

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Content Centre',
      url: replacePathParams(contentCenterRoutes.contentCenter, [], props),
    },
    { name: 'Content Topic Briefing', url: '' },
  ];

  if (!ctb || !editingCtb) {
    return <></>;
  }

  const cts = editingCtb.content_topics?.filter((t) => !t._destroy);

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Content Topic Brief Form',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Dashboard',
        backToPath: statsCenterRoutes.contentCenter,
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card className="mt-3 mb-4 rounded" innerRef={ref}>
        <CardBody>
          <Row>
            <Col>
              <FormGroup>
                <Label>Business name</Label>
                <Input name="business_name" value={editingCtb.business_name || ''} onChange={onChange} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Business type</Label>
                <Input name="business_type" value={editingCtb.business_type || ''} onChange={onChange} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Service location</Label>
                <Input name="service_location" value={editingCtb.service_location || ''} onChange={onChange} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Potential target keywords</Label>
                <AutoHeightTextArea
                  name="target_keywords"
                  value={editingCtb.target_keywords || ''}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Products and services</Label>
                <AutoHeightTextArea
                  name="products_services"
                  value={editingCtb.products_services || ''}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Existing product/service pages</Label>
                <AutoHeightTextArea
                  name="existing_product_service_pages"
                  value={editingCtb.existing_product_service_pages || ''}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>
                  New product/service (ASAP) page ideas
                </Label>
                <AutoHeightTextArea
                  name="new_product_ideas"
                  value={editingCtb.new_product_ideas || ''}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>
                  New article page topic ideas
                </Label>
                <AutoHeightTextArea
                  name="new_article_ideas"
                  value={editingCtb.new_article_ideas || ''}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
          </Row>
          {instance?.platform_status === 'old' && (
            <>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Existing website content</Label>
                    <AutoHeightTextArea
                      name="existing_website_content"
                      value={editingCtb.existing_website_content || ''}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Home page content</Label>
                    <AutoHeightTextArea
                      name="home_page_content"
                      value={editingCtb.home_page_content || ''}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>About page content</Label>
                    <AutoHeightTextArea
                      name="about_page_content"
                      value={editingCtb.about_page_content || ''}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Other website content</Label>
                    <AutoHeightTextArea
                      name="other_site_content"
                      value={editingCtb.other_site_content || ''}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col>
              <FormGroup>
                <Label>Other information</Label>
                <AutoHeightTextArea
                  name="other_info"
                  value={editingCtb.other_info || ''}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <FormGroup>
                <Label>Content tone</Label>
                <CustomSelect
                  options={toneOptions}
                  value={toneOptions.find((o) => o.value === editingCtb.content_tone)}
                  onChange={(o) => setEditingCtb((p) => new ContentTopicBriefingModel({ ...p, content_tone: o.value }))}
                />
              </FormGroup>
            </Col>
          </Row> */}
          {/* {editingCtb.content_tone === 'other' && (
            <Row>
              <Col>
                <FormGroup className="ms-4">
                  <Input name="content_tone_other" value={editingCtb.content_tone_other || ''} onChange={onChange} />
                </FormGroup>
              </Col>
            </Row>
          )} */}
        </CardBody>
      </Card>

      <Card className="mt-3 mb-4 rounded">
        <CardBody>
          {[
            ['Product/Service (ASAP) Page Content Topics', 'product_service'],
            ['Article Page Topic Ideas', 'article'],
          ].map((options) => (
            <Fragment key={options[0]}>
              <CardTitle>{options[0]}</CardTitle>
              {editingCtb.content_topics?.filter((t) => t.type === options[1])?.length !== 0 && (
                <>
                  <Table>
                    <thead>
                      <tr>
                        <th>Topic</th>
                        <th>Content Brief Form</th>
                        <th>% Complete</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cts?.filter((t) => t.type === options[1])?.map((ct) => new ContentTopic(ct)).map((ct) => (
                        <tr key={ct.id}>
                          <td>
                            {ct.name}
                          </td>
                          <td>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(replacePathParams(contentCenterRoutes.contentBriefingForm, [{ key: 'id', value: ct.id || '' }], props));
                              }}
                            >
                              Content Brief Form
                            </a>
                          </td>
                          <td>
                            {ct.percentComplete()}
                            %
                          </td>
                          <td>
                            <a
                              href="#"
                              onClick={async (e) => {
                                e.preventDefault();

                                let result = true;

                                if (ct.percentComplete() > 0) {
                                  result = await confirm({
                                    title: <b>Confirm!</b>,
                                    message: `The form for this topic is ${ct.percentComplete()}% complete. Are you sure you want to delete it?`,
                                    confirmText: 'Yes',
                                    confirmColor: 'success',
                                    cancelColor: 'btn btn-danger',
                                    cancelText: 'No',
                                  });
                                }

                                if (result) {
                                  const cts = [...editingCtb.content_topics || []];
                                  const index = cts.findIndex((t) => t.id === ct.id);
                                  cts[index] = new ContentTopic({ ...cts[index], _destroy: true });
                                  setEditingCtb(new ContentTopicBriefingModel({ ...editingCtb, content_topics: cts }));
                                }
                              }}
                            >
                              Delete
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
              <CustomButton
                color="success"
                className="common-success-button fw-bold mb-4"
                title="ADD BLANK TOPIC"
                onClick={async () => {
                  const result = await createContentTopic({ content_topic_briefing_id: ctb.id, type: options[1] });
                  if (!result)
                    displayError('Failed to add topic');
                }}
              />
            </Fragment>
          ))}
          {cts && cts.length < 20 && (
            <>
              <br />
              <br />
              <CustomButton
                color="success"
                className="common-success-button fw-bold"
                title={`${cts.length > 0 ? 'RE-' : ''}GENERATE TOPIC SUGGESTIONS`}
                onClick={onGenerateTopicSuggestions}
              />
            </>
          )}
        </CardBody>
      </Card>

      <BottomActionToolbar
        component={(
          <SaveAndContinue
            onSave={update}
            onContinue={() => {
              navigate(replacePathParams(contentCenterRoutes.contentCenter, [], props));
            }}
          />
        )}
      />
    </>
  );
}

export default withRouter(ContentTopicBriefing);
