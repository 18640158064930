import React, { useEffect, useRef, useState } from 'react';
import { Row, Card, CardTitle, Label, Button, Form, FormGroup, Input } from 'reactstrap';
import zxcvbn from 'zxcvbn';
import { get, isEmpty } from 'lodash-es';
import { Colxx } from '../../components/common/CustomBootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { displayError, displaySuccess, setCookie } from '../../../../Utils';
import IntlMessages from '../../helpers/IntlMessages';
import './index.scss';
import { createPasswordLabel, validatePasswords } from '../profile/UserProfile';
import { useSetNewPasswordMutation, useVerifyResetTokenQuery } from '../../../../common/api/apiSlice';
import withRouter from 'Dashboard/src/helpers/withRouter';

function NewPassword() {
  const [data, setData] = useState({
    resetToken: null,
    password: null,
    password_confirmation: null,
  });

  const { token } = useParams();
  const navigate = useNavigate();
  const [testedResult, setTestedResult] = useState({});
  const { data: verifyToken = {} } = useVerifyResetTokenQuery(token, { skip: isEmpty(token) });
  const [setNewPassword] = useSetNewPasswordMutation();
  const isHandled = useRef(false);

  useEffect(() => {
    if (!isHandled.current) {
      if (verifyToken?.verify) {
        isHandled.current = true;
      } else if (!verifyToken?.verify && !isEmpty(token)) {
        displayError('Invalid token for reset password, we\'re redirecting you to the login page in 3 seconds.');
        isHandled.current = true;
        setTimeout(() => {
          navigate('user/login');
        }, 3000);
      }
    }
  }, [verifyToken, token]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (validatePasswords({ ...data, ...testedResult })) {
      const response = await setNewPassword(data);
      if (response && response?.data?.meta?.is_success) {
        setCookie('USER_TOKEN', response?.data?.data?.token, 9999, '/');
        window.location = '/';
        displaySuccess(response?.data?.meta?.messages);
      }
    }
  };

  const onChange = (e) => {
    const { target: { value, name } } = e;
    if (name === 'password') {
      const result = zxcvbn(value);
      setTestedResult(result);
    }
    setData({ ...data, [name]: value });
  };

  const password = get(data, 'password', '');
  const passwordConfirmation = get(data, 'password_confirmation', '');

  const renderNewPasswordForm = () => (
    <Row className="h-100 ChangePassword">
      <Colxx xxs="12" md="10" className="mx-auto my-auto">
        <Card className="auth-card">
          <div className="position-relative image-side" />
          <div className="form-side">
            <Form onSubmit={onSubmit}>
              <NavLink to="/" className="white">
                <span className="logo-single" />
              </NavLink>
              <CardTitle className="mb-4">
                <IntlMessages id="user.set-password" />
              </CardTitle>
              <FormGroup>
                <Label className="form-group has-float-label mb-0">
                  <Input
                    className="form-control"
                    name="password"
                    type="password"
                    value={password}
                    onChange={onChange}
                    autoFocus
                  />
                  <IntlMessages id="user.new-password" />
                </Label>
                {password && (
                  <div className="password-strength-meter">
                    <progress
                      className={
                        `password-strength-meter-progress strength-${createPasswordLabel(testedResult)}`
                      }
                      value={testedResult.score || 1}
                      max="4"
                    />
                    {password && (
                      <span
                        className={`password-strength-meter-label strength-${createPasswordLabel(testedResult)}`}
                      >
                        {createPasswordLabel(testedResult)}
                      </span>
                    )}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="form-group has-float-label mb-4">
                  <Input
                    className="form-control"
                    name="password_confirmation"
                    type="password"
                    value={passwordConfirmation}
                    onChange={onChange}
                  />
                  <IntlMessages id="user.confirm-new-password" />
                </Label>
              </FormGroup>
              <Button
                color="primary"
                className="btn-shadow"
                size="lg"
                type="submit"
                onClick={onSubmit}
              >
                <IntlMessages id="user.set-password" />
              </Button>
            </Form>
          </div>
        </Card>
      </Colxx>
    </Row>

  );

  if (verifyToken && verifyToken?.verify) {
    return renderNewPasswordForm();
  }

  return (
    <Row className="h-100">
      <Colxx xxs="12" md="10" className="mx-auto my-auto">
        <Card className="auth-card">
          Loading...
        </Card>
      </Colxx>
    </Row>
  );
}

export default withRouter(NewPassword);
