import React from 'react';
import { Container, Button, Row, Col } from 'reactstrap';
import DropdownButtonGroup from 'Dashboard/src/components/common/DropdownButtonGroup';
import { useIsMobile } from 'common/hooks';
import { isEmpty } from 'lodash-es';
import { useGetDatabaseSearchFieldsQuery } from '../../../../../common/api/apiSlice';
import DynamicControl from './DynamicControl';
import DataFieldSelectBox from './DataFieldSelectBox';
import { displayError, getSelectedOption } from '../../../../../Utils';

const getOperatorOptions = (args) => args.map((opt) => ({ label: opt, value: opt }));

const MemberSearchField = ({
  instanceId,
  searchBehavior,
  field,
  index,
  removeSearchField,
  onChange,
}) => {
  const isMobile = useIsMobile();

  const databaseFieldId = field?.database_field_id;
  const operator = field?.operator ?? null;
  const value = field?.value ?? null;

  const { data: fieldCollection = [] } = useGetDatabaseSearchFieldsQuery(
    { instanceId },
    { skip: isEmpty(instanceId) },
  );

  const databaseField = fieldCollection.find((f) => f.id === databaseFieldId);
  const operatorOptions = databaseField?.attributes?.operator_options ?? [];
  const values = databaseField?.attributes?.values ?? [];
  const isCustom = searchBehavior && searchBehavior === 'custom';

  const handleRangeChange = (e) => {
    const arr = [...(value ?? [])];
    const idx = Number(e.target.name);
    arr[idx] = Number(e.target.value);

    if (arr.length > 1 && (arr[1] <= arr[0] || arr[1] === 0)) {
      displayError('Invalid range: Ensure "To" is greater than "From".');
    } else {
      onChange(arr, 'value');
    }
  };

  const handleDateRangeChange = (e, type) => {
    const arr = [...(value ?? [])];
    arr[type] = e;
    onChange(arr, 'value');
  };

  const renderFieldRow = () => (
    <DataFieldSelectBox
      instanceId={instanceId}
      placeholder="Select Field"
      selectedId={databaseFieldId}
      onChange={(e) => onChange(e.value, 'database_field_id')}
    />
  );

  const renderOperatorRow = () =>
    !isEmpty(operatorOptions) && (
      <DropdownButtonGroup
        outline
        placeholder="Select..."
        options={getOperatorOptions(operatorOptions)}
        selectedOption={getSelectedOption(getOperatorOptions(operatorOptions), operator)}
        onOptionSelect={(e) => onChange(e.value, 'operator')}
      />
    );

  const renderValueRow = () =>
    operator && operator !== 'is blank' && (
      <DynamicControl
        onHandleChange={(e, type) => onChange(type === 'checkboxes' || type === 'select_list' ? e.map((x) => x.value) : e.target.value, 'value')}
        valueOptions={values}
        databaseField={databaseField}
        initialState={field}
        onRangeChange={handleRangeChange}
        onDateRangeChange={handleDateRangeChange}
      />
    );

  const removeButton = (
    <Col md="1" className="mt-2 mb-2 text-end">
      <Button
        className="custom-simple-icon"
        color="danger"
        size="xs"
        onClick={removeSearchField}
      >
        <i className="fal fa-minus-circle" />
      </Button>
    </Col>
  );

  const mobileLayout = () => {
    return (
      <>
        {isCustom && (
          <Row>
            <Col className="fw-bold">
              <b>
                #
                {Number(index) + 1}
              </b>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="fw-bold">Field</Col>
          <Col className="text-end">{renderFieldRow()}</Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col className="fw-bold">Operator</Col>
          <Col className="text-end">{renderOperatorRow()}</Col>
        </Row>
        <Row>
          <Col className="fw-bold">Value</Col>
          <Col className="text-end">
            {renderValueRow()}
          </Col>
        </Row>
        <Row>
          {removeButton}
        </Row>
      </>
    );
  };

  const desktopLayout = () => {
    return (
      <Row>
        {isCustom && (
          <Col md="1">
            <b>{index + 1}</b>
          </Col>
        )}
        <Col md="3">{renderFieldRow()}</Col>
        <Col md="3">{renderOperatorRow()}</Col>
        <Col md="4">{renderValueRow()}</Col>
        {removeButton}
      </Row>
    );
  };

  return (
    <Container fluid className={`border-bottom mb-2 ${isMobile ? 'p-0 ' : 'pb-2'}`}>
      {isMobile ? mobileLayout() : desktopLayout()}
    </Container>
  );
};

export default MemberSearchField;
