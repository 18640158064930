import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { isEmpty, get } from 'lodash-es';
import { DateTime } from 'luxon';
import DateTimeFormat from '../../components/common/DateTimeFormat';
import CustomButton from '../../components/common/button';
import confirm from '../../components/common/confirm';
import withRouter from '../../helpers/withRouter';
import CtaReport from './index';
import { useInstance, useUser } from '../../../../common/hooks';
import { validatePhoneNumber } from '../../../../Utils';
import { useDeleteCtaLogMutation, useGetChatTranscriptQuery } from '../../../../common/api/apiSlice';

function CtaReportChat() {
  const [orderBy, setOrderBy] = useState('created_at');
  const [orderDirection, setOrderDirection] = useState('desc');
  const [currentCTA, setCurrentCTA] = useState(null);
  const [showModal, setModal] = useState(false);
  const instance = useInstance();
  const instanceId = instance?.id || null;

  const user = useUser();

  const { data: chatTranscript } = useGetChatTranscriptQuery({ instanceId, id: currentCTA?.id }, { skip: !instanceId || !currentCTA });

  const ctaLog = {};

  const [deleteCtaLog] = useDeleteCtaLogMutation();

  useEffect(() => {
    if (!isEmpty(ctaLog)) {
      setModal(true);
      setCurrentCTA(ctaLog);
    }
  }, [ctaLog]);

  const onHandleDateField = (cta) => {
    setCurrentCTA(cta);
    setModal(true);
  };

  const modalBox = () => {
    if (!showModal) {
      return <></>;
    }

    return (
      <Modal size="lg" isOpen={true} toggle={() => setModal(false)}>
        <ModalHeader toggle={() => setModal(false)}>
          Chat Details
        </ModalHeader>
        <ModalBody>
          <Table>
            <tbody>
              {chatTranscript?.map((transcript, index) => (
                <tr key={index}>
                  <td style={{ whiteSpace: 'pre-wrap' }}>
                    <div className="float-end">
                      <DateTimeFormat datetime={transcript.created_at} localeString={DateTime.DATETIME_MED} />
                    </div>
                    <span style={{ color: 'var(--secondary-color)', paddingRight: '4px' }}>
                      From:
                    </span>
                    {' '}
                    {transcript.from === 'ai' ? 'AI' : 'User'}
                    <br />
                    <span style={{ color: 'var(--secondary-color)', paddingRight: '4px' }}>
                      Text:
                    </span>
                    {' '}
                    {transcript.text}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter className="pt-3 pe-3 pb-3 ps-3">
          <Button size="sm" color="danger" onClick={() => setModal(false)}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  };

  const onHandleDelete = async (id) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      const params = {
        id,
        instance_id: instanceId,
      };
      await deleteCtaLog(params);
    }
  };

  const dataTableColumns = [
    {
      header: (
        <Button
          onClick={() => {
            setOrderBy('created_at');
            setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Date '}
            {orderBy === 'created_at'
              ? (
                  <i
                    className={` ${orderDirection === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`}
                  />
                )
              : ''}
          </span>
        </Button>
      ),
      id: 'Date',
      className: 'text-nowrap',
      disableSortBy: true,
      cell: ({ row: { original } }) => (
        <Button
          onClick={() => onHandleDateField(original)}
          disabled={original.attributes.type === 'CTALogOutboundClick'}
          className="custom-simple-txt-btn cta-log-more-info"
        >
          <DateTimeFormat datetime={get(original, 'attributes.created_at', '')} localeString={DateTime.DATETIME_SHORT} />
        </Button>
      ),
      isVisible: true,
    },
    {
      header: 'Name',
      id: 'Name',
      className: 'd-none d-lg-table-cell',
      cell: ({ row: { original: { attributes } } }) => attributes.name,
    },
    {
      header: 'Email',
      id: 'Email',
      className: 'd-none d-lg-table-cell',
      cell: ({ row: { original: { attributes } } }) => attributes.email,
    },
    {
      header: 'Phone Number',
      id: 'PhoneNumber',
      className: 'd-none d-lg-table-cell',
      cell: ({ row: { original: { attributes } } }) => {
        if (validatePhoneNumber(attributes.phone_number)) {
          return (
            <a href={`tel:${attributes.phone_number}`}>
              <i className="far fa-phone-alt fw-bold" />
              {' '}
              {attributes.phone_number}
            </a>
          );
        } else {
          return attributes.phone_number;
        }
      },
    },
    {
      header: 'Actions',
      id: 'Actions',
      disableSortBy: true,
      cell: (d) => (
        user?.hasFeature('delete_cta_logs')
        && (
          <CustomButton
            size="xs"
            title=" "
            color="danger"
            icon="fal fa-trash"
            className="custom-simple-icon"
            style={{ background: '#dc3545' }}
            onClick={() => onHandleDelete(d.id)}
          />
        )
      ),
    },
  ];

  return (
    <>
      <CtaReport
        instanceId={instanceId}
        dataTableColumns={dataTableColumns}
        orderBy={orderBy}
        orderDirection={orderDirection}
        ctaType="CTALogChat"
        typeName={['chat', 'chats']}
        subRow={(columns, chat) => (
          <tr>
            <td colSpan={columns.length + 1}>
              <span style={{ color: 'var(--secondary-color)', paddingRight: '4px' }}>
                Summary:
              </span>
              {' '}
              {chat.attributes.summary}
            </td>
          </tr>
        )}
      />
      {modalBox()}
    </>
  );
}

export default withRouter(CtaReportChat);
