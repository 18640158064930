// import appLocaleData from 'react-intl/locale-data/en';
import enMessages from '../locales/en_US';

const EnLangRtl = {
  messages: {
    ...enMessages,
  },
  locale: 'en-US',
  // data: appLocaleData,
};
export default EnLangRtl;
