import Base from './Base';

interface Page {
  name: string;
  topic: string;
  extra_info: string;
  info_from_client: string;
  questions: string;
  internal_info: string;
  primary_keyword: string;
  secondary_keywords: string;
}

class ContentBriefing extends Base {
  id?:         string;
  business_name?:                   string;
  business_type?:                   string;
  service_location?:                string;
  target_keywords?:                 string;
  products_services?:               string;
  existing_product_service_pages?:  string;
  new_product_ideas?:               string;
  new_article_ideas?:               string;
  existing_website_content?:        string;
  home_page_content?:               string;
  about_page_content?:              string;
  other_site_content?:              string;
  other_info?:                      string;
  content_tone?:                    string;
  content_tone_other?:              string;
  pages?:                           Page[];

  constructor(props: object) {
    super(props);
  }
}

export default ContentBriefing;
