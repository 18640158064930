import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardTitle, CardBody } from 'reactstrap';
import { displayError, getPageType, removeStylesheet, updateStylesheet } from '../../../../../Utils';
import { addRecentItem } from 'UtilsTS';
import HeaderComponent from '../../../components/common/header-component';
import {
  siteCenter,
  settingRoutes,
  getPageEditUrl,
  replacePathParams,
  messageCenterRoutes,
} from '../../../constants/routes';
import confirm from '../../../components/common/confirm';
import withRouter from '../../../helpers/withRouter';
import PageEditForm from './PageEditForm';
import PageEditOption from './PageEditOption';
import SectionListing from '../Section/SectionListing';
import DuplicatePageModal from '../PageTree/DuplicatePageModal';
import {
  useGetPageQuery,
  useGetPageVersionQuery,
  useGetPageVersionsQuery,
  useGetTemplatesQuery,
  useGetMasterPagesQuery,
  useGetURLByIdQuery,
  useGetDomainsQuery,
  usePageVersionCreateMutation,
  useDuplicatePageMutation,
} from '../../../../../common/api/apiSlice';
import { useNavigate } from 'react-router';
import { isEmpty, filter, get } from 'lodash-es';
import { useInstance, useSite } from '../../../../../common/hooks';

export const filterTemplateOption = (options, pageType) => filter(
  options,
  (el) => (el.type || el.template_type) === pageType,
);
function PageEdit(props) {
  const {
    params: {
      pageType, pageVersionId, pageId,
    }, params,
  } = props;
  const [modal, setModal] = useState(false);
  const [showMasterSection, setShowMasterSection] = useState(JSON.parse(localStorage.getItem('showMasterSection')));

  const isEmailPage = pageType === 'email';
  const navigate = useNavigate();

  const site = useSite();
  const instance = useInstance();
  const siteId = get(site, 'id', null);
  const instanceId = get(instance, 'id', null);
  const { data: page } = useGetPageQuery({ id: pageId, siteId, instanceId }, { skip: isEmpty(instanceId) || isEmpty(siteId) });
  const { data: templates } = useGetTemplatesQuery({ siteId, instanceId }, { skip: isEmpty(instanceId) || isEmpty(siteId) });
  const { data: masterPages } = useGetMasterPagesQuery({ siteId, instanceId }, { skip: isEmpty(instanceId) || isEmpty(siteId) });
  const { data: pageVersion } = useGetPageVersionQuery({
    id: pageVersionId, pageId, siteId, instanceId,
  }, { skip: isEmpty(instanceId) || isEmpty(siteId) });

  const [duplicatePage] = useDuplicatePageMutation();

  // The page_versions route shows only 10 versions by default, but in the version list dropdown box on the section
  // list page we need to show all versions. Currently capping at 1,000, that should be plenty.
  const { data: { page_versions: pageVersions } = {} } = useGetPageVersionsQuery({ pageId, siteId, instanceId, page_size: 1000 });

  const { data: domains } = useGetDomainsQuery(siteId, { skip: isEmpty(siteId) });
  const { data: url } = useGetURLByIdQuery(page?.primary_url_id, { skip: isEmpty(page?.primary_url_id) });

  const [pageVersionCreate] = usePageVersionCreateMutation();

  const isMasterPage = getPageType(page) === 'master';
  const type = getPageType(page);

  useState(() => () => {
    // We want to remove the stylesheet so it doesn't interfere with other pages that don't use the site stylesheet, like emails in Message Centre.
    removeStylesheet();
  }, []);

  useEffect(() => {
    if (pageVersionId) {
      if (pageType === 'web') {
        updateStylesheet(params);
      }
    }
  }, [pageVersionId]);

  if (isEmpty(site) || page === undefined || templates === undefined || pageVersions === undefined) {
    return <></>;
  }

  const primaryDomain = domains?.find((d) => d.id === site.primary_domain_id);
  const servingDomain = domains?.filter((d) => d.serve_page_id === pageId)?.at(0);
  const previewUrl = `https://${servingDomain ? servingDomain.domain_name : primaryDomain?.domain_name}/preview?page_version_id=${pageVersion?.id}`;

  const getTemplates = filterTemplateOption(templates, type);

  const createPageDraft = async () => {
    const apiParams = {
      id: pageVersionId,
      site_id: siteId,
      page_id: pageId,
    };
    const { data: { data } = {} } = await pageVersionCreate(apiParams);
    if (data) {
      navigate(getPageEditUrl(data.id, pageId, 'web', props));
    } else {
      displayError({ message: 'Error creating new version, please try again.' });
    }
  };

  const onChangePageVersion = (e) => {
    const { id } = e;
    navigate(getPageEditUrl(id, pageId, 'web', props));
  };

  const goToHistory = () => {
    navigate(
      replacePathParams(
        siteCenter.pageVersionHistory,
        [
          { key: 'pageId', value: pageId },
          { key: 'pageNo', value: 1 },
          { key: 'pageVersionId', value: pageVersionId },
        ],
        props,
      ),
    );
  };

  const onCloseModal = () => {
    setModal(!modal);
  };

  const onAdvancedSettingChange = async (value) => {
    if (value === 'page_url') {
      navigate(
        replacePathParams(siteCenter.urlList, [
          { key: 'pageId', value: pageId },
          { key: 'pageVersionId', value: pageVersionId },
        ], props),
      );
    } else if (value === 'duplicate_page' && isMasterPage) {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });

      const sendParams = {
        id: pageId,
        site_id: siteId,
        instance_id: instanceId,
        copy_child_pages: false,
      };
      if (result) {
        duplicatePage(sendParams);
      }
    } else {
      setModal(!modal);
    }
  };

  const onHandleShowMasterSection = (e) => {
    setShowMasterSection(e);
  };

  if (!isEmpty(page) && !isEmpty(pageVersion)) {
    // When we are switching from one page to another we sometimes have a pageVersion loaded from a different page. If
    // that happens we just abort rendering, the new versions should be loaded soon.
    if (page?.id && pageVersion?.page_id && page?.id !== pageVersion?.page_id) {
      return <></>;
    }

    addRecentItem({
      instance_id: instance.id,
      site_id: site.id,
      type: isEmailPage ? 'edit-email' : 'edit-page',
      item_id: pageVersion.id,
    });

    const siteCenterUrl = replacePathParams(siteCenter.pageList, [], props);

    const staticBreadcrumbData = [
      { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
      isEmailPage
        ? {
            name: 'Message Centre',
            url: replacePathParams(messageCenterRoutes.messageCenter, [], props),
          }
        : { name: 'Site Centre', url: siteCenterUrl },
      { name: pageVersion.name, url: '' },
    ];

    return (
      <>
        <HeaderComponent setPath={{
          headingName: `${pageVersion.name} ${pageType.toUpperCase() === 'MASTER'
            ? `(${pageType.toUpperCase()})`
            : ''
          }`,
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Back',
          backToPath: siteCenterUrl,
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
        />
        <Row>
          <Col lg={isEmailPage ? 12 : 8}>
            <PageEditForm
              url={url}
              page={page}
              previewUrl={previewUrl}
              masterPages={masterPages}
              page_version={pageVersion}
              getTemplates={getTemplates}
              showMasterSection={onHandleShowMasterSection}
              onAdvancedSettingChange={onAdvancedSettingChange}
            />
          </Col>
          {!isEmailPage && (
            <Col lg={4}>
              <Card body className="rounded h-100">
                <Row className="mb-3">
                  <Col>
                    <h2>Versioning</h2>
                  </Col>
                </Row>
                <PageEditOption
                  page_versions={pageVersions}
                  pageDraftHandler={createPageDraft}
                  page={page}
                  page_version={pageVersion}
                  handleSelect={onChangePageVersion}
                  handleHistoryPage={goToHistory}
                  previewUrl={previewUrl}
                />
              </Card>
            </Col>
          )}
        </Row>
        <Row className="mt-4">
          <Col className="col-12">
            <SectionListing
              page={page}
              pageVersion={pageVersion}
              isEmailPage={isEmailPage}
              pageName={pageVersion.name}
              showMasterSection={showMasterSection}
            />
          </Col>
        </Row>
        {modal && (
          <DuplicatePageModal
            siteId={siteId}
            setModal={modal}
            page={page}
            instanceId={instanceId}
            toggle={onCloseModal}
            onSuccessDuplicatePage={onCloseModal}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="mb-0">Loading...</CardTitle>
        </CardBody>
      </Card>
    </>
  );
}

export default withRouter(PageEdit);
