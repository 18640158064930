import React from 'react';
import {
  Input,
  Label,
  FormGroup,
} from 'reactstrap';

function InitialVisibilityPoints(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label>Initial Visibility Points</Label>
      <Input
        type="number"
        value={params.initial_visibility_points}
        onChange={(e) => {
          const { value } = e.target;
          setParams({ ...params, initial_visibility_points: value });
        }}
      />
    </FormGroup>
  );
}

export default InitialVisibilityPoints;
