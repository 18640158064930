import React, { useState, useEffect } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/snippets/html';
import 'ace-builds/src-noconflict/theme-github';

function TemplateEditor({
  editorData,
  validateEditor,
  defaultComponentValue,
}) {
  const [annotations] = useState([]);
  const [editor] = useState();
  const [templateData, setTemplateData] = useState('');
  const nextAnnotations = [...annotations.filter(({ custom }) => !custom)];

  useEffect(() => {
    if (editor) {
      editor.getSession().setAnnotations(nextAnnotations);
    }
  }, [editor, JSON.stringify(nextAnnotations)]);

  /* On editor blur event */
  const onUpdateEditorValue = (e, code) => {
    const data = code.getValue();
    editorData(data);
  };

  /* On change editor */
  const setContent = (data) => {
    setTemplateData(data);
  };

  return (
    <AceEditor.default
      className="ace-editor"
      mode="html"
      theme="xcode"
      name="template-editor"
      onChange={(value) => setContent(value)}
      onBlur={(e, code) => onUpdateEditorValue(e, code)}
      height="600px"
      width="100%"
      value={templateData || defaultComponentValue}
      highlightActiveLine
      showGutter
      fontSize={16}
      setOptions={{
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,

        // This disables validation but keeps syntax highlighting.
        // Validation would be great, but unfortunately we can't do things like this:
        // <head>
        //   {{{fragment-Head}}}
        // </head>
        // because Ace thinks that's not a valid format.
        useWorker: false,

      }}
      editorProps={{ $blockScrolling: true }}
      onValidate={validateEditor}
    />
  );
}
export default TemplateEditor;
