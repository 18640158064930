import React from 'react';
import {
  Label,
  FormGroup,
} from 'reactstrap';
import RadioButton from '../../../components/common/radioButton';

function SEOVisibilityModel(props) {
  const { params, setParams } = props;

  return (
    <>
      <Label>Visibility Points Model</Label>
      <FormGroup>
        <RadioButton
          name="Visibility Points Model"
          title="Old"
          checked={params.seo_visibility_model === 1}
          onChange={() => {
            setParams({
              ...params,
              seo_visibility_model: 1,
            });
          }}
        />
        <RadioButton
          name="Visibility Points Model"
          title="New"
          checked={params.seo_visibility_model === 2}
          onChange={() => {
            setParams({
              ...params,
              seo_visibility_model: 2,
            });
          }}
        />
      </FormGroup>
    </>
  );
}

export default SEOVisibilityModel;
