import React from 'react';
import {
  Input,
  Label,
  FormGroup,
} from 'reactstrap';

function InstanceName(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label for="company_name">Instance Name</Label>
      <Input
        type="text"
        value={params.company_name || ''}
        onChange={(e) => {
          const { value } = e.target;
          setParams({ ...params, company_name: value });
        }}
      />
    </FormGroup>
  );
}

export default InstanceName;
