import React from 'react';
import { Card, CardBody } from 'reactstrap';
import withRouter from '../../helpers/withRouter';
import './index.scss';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

function Test() {
  const defaultData = [
    {
      firstname: 'Matt',
      lastname: 'Parlane',
      age: 40,
      designation: 'Boss',
    },
    {
      firstname: 'Govind',
      lastname: 'Soni',
      age: 31,
      designation: 'Lead Engineer',
    },
  ];

  const userColumns = [
    {
      accessorKey: 'firstname',
      header: 'First Name',
    },
    {
      accessorKey: 'lastname',
      header: 'Last Name',
    },
  ];

  return (
    <Card>
      <CardBody>
        <h1>Testing</h1>
        <ReactTable8 data={defaultData} columns={userColumns} />
      </CardBody>
    </Card>
  );
}

export default withRouter(Test);
