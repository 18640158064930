import React from 'react';
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { useGetUserProfileQuery } from '../../common/api/apiSlice';
import PlainLayout from 'Dashboard/src/layout/PlainLayout.js';
import {
  userRoutes,
  siteCenter,
  mainRoutes,
  settingRoutes,
  statsCenterRoutes,
  contentCenterRoutes,
  mediaCenterRoutes,
  reviewCenterRoutes,
  messageCenterRoutes,
  deliveryCenterRoutes,
  dataCenter,
} from './constants/routes';
import withRouter from './helpers/withRouter';
import ClientTerms from './views/ClientTerms';
import MediaFolder from './views/media-centre/MediaFolder';
import MediaCenterList from './views/media-centre/MediaListing';
import AppLayout from './layout/AppLayout';
import EmailPageEdit from './views/message-centre/email-page-edit';
import EmailPages from './views/message-centre/email-pages';
import ReviewCenterLog from './views/review-centre/reviewCenter';
import EmailSeries from './views/delivery-centre/emailSeries';
import EmailSeriesListing from './views/delivery-centre/emailSeriesListing';
import DeliveryCenterHome from './views/delivery-centre/deliveryCenter';
import DashboardView from './views/dashboards/dashboardView';
import DataCenterHome from './views/data-centre/dataCenter';
import SavedSearches from './views/data-centre/savedSearches';
import DatabaseFields from './views/data-centre/databaseFields';
import DataFieldListing from './views/data-centre/databaseFieldListing';
import ImportMembers from './views/data-centre/importMembers';
import MemberSearch from './views/data-centre/member-search/MemberSearch';
import Members from './views/data-centre/member-search/Members';
import EditDataField from './views/data-centre/editDatabaseField';
import PageTree from './views/site-centre/PageTree';
import PageEdit from './views/site-centre/PageEdit/PageEdit';
import PageHistory from './views/site-centre/PageEdit/PageHistory';
import PageUrl from './views/site-centre/PageUrl';
import BusinessDirectory from './views/site-centre/business-directory';
import InstanceSettings from './views/settings/instance-settings';
import SiteSettingGeneral from './views/settings/sites/SiteSettingGeneral';
import SiteSettingProducts from './views/settings/sites/SiteSettingProducts';
import SiteSettingPhoneNumbers from './views/settings/sites/SiteSettingPhoneNumbers';
import SiteSettingSEO from './views/settings/sites/SiteSettingSEO';
import SiteSettingGoogleAnalytics from './views/settings/sites/SiteSettingGoogleAnalytics';
import SiteSettingOtherGoogleServices from './views/settings/sites/SiteSettingOtherGoogleServices';
import SiteSettingGoogleReviewEngine from './views/settings/sites/SiteSettingGoogleReviewEngine';
import SiteSettingReCAPTCHA from './views/settings/sites/SiteSettingReCAPTCHA';
import SiteSettingInstantContact from './views/settings/sites/SiteSettingInstantContact';
import SiteSettingPrivacyPolicy from './views/settings/sites/SiteSettingPrivacyPolicy';
import SiteSettingPopup from './views/settings/sites/SiteSettingPopup';
import SiteSettingSiteTextSearch from './views/settings/sites/SiteSettingSiteTextSearch';
import SiteSettingListing from './views/settings/sites/SiteSettingListing';
import Trash from './views/settings/trash';
import DuplicateInstance from './views/settings/duplicate-instance';
import ImportInstance from './views/settings/import-instance';
import Logs from './views/settings/logs';
import AutomatedReport from './views/automated-report';
import SettingHome from './views/settings/setting-home';
import SiteBriefings from './views/settings/site-briefing/SiteBriefingList';
import SiteBriefingForm from './views/settings/site-briefing/SiteBriefingForm';
import UserList from './views/profile/UserList';
import UserProfile from './views/profile/UserProfile';
import QrCodeImage from './views/qr-code/qrCodeImage';
import Login from './views/user/login';
import Register from './views/user/register';
import ForgotPassword from './views/user/forgot-password';
import NewPassword from './views/user/NewPassword';
import UserLayout from './layout/UserLayout';
import ComponentCategoryList from './views/site-centre/design-options/components/ComponentCategoryList';
import ComponentList from './views/site-centre/design-options/components/ComponentList';
import TemplateList from './views/site-centre/design-options/templates/TemplateList';
import TemplateEdit from './views/site-centre/design-options/templates/TemplateEdit';
import TemplateEditGrid from './views/site-centre/design-options/templates/TemplateEditGrid';
import FragmentListings from './views/site-centre/design-options/template-fragments/fragment-listing';
import FragmentCreate from './views/site-centre/design-options/template-fragments/fragment-create';
import FragmentEdit from './views/site-centre/design-options/template-fragments/fragment-edit';
import TextStyleListing from './views/site-centre/design-options/text-styles';
import TextStyle from './views/site-centre/design-options/text-styles/textStyle';
import StyleOverrides from './views/site-centre/design-options/style-overrides';
import ColourPalette from './views/site-centre/design-options/colour-palette';
import Buttons from './views/site-centre/design-options/buttons';
import ButtonEdit from './views/site-centre/design-options/buttons/ButtonEdit';
import DesignHome from './views/site-centre/design-options/design-home';
import ROIReports from './views/roi-reports';
import Tools from './views/tools';
import ToolGoogleVisibility from './views/tools/ToolGoogleVisibility';
import ToolCurrentVisibilityScore from './views/tools/ToolCurrentVisibilityScore';
import Reports from './views/reports';
import DomainListing from './views/site-centre/Domain/DomainListing';
import StatsCentre from './views/stats-centre/main';
import ContentCentre from './views/ContentCentre';
import ContentBriefingForm from './views/ContentCentre/ContentBriefingForm';
import GenerateDraftContent from './views/ContentCentre/GenerateDraftContent';
import User from './views/user';
import BulkEmail from './views/delivery-centre/bulkEmail';
import BackgroundJobs from './views/settings/backgroundJobs';
import JobReports from './views/settings/backgroundJobs/reports';
import Component from './views/site-centre/design-options/components/component';
import EditAllForms from './views/site-centre/edit-all-forms';
import Services from './views/settings/services';
import SectionEdit from './views/site-centre/Section/SectionEdit';
import KeywordResearchTool from './views/KeywordResearchTool';
import Test from './views/Test';
import UnsubscribeFromAll from './views/UnsubscribeFromAll';
import Reset from './views/Reset';
import CustomCodeListing from './views/site-centre/design-options/customCode';
import CustomCode from './views/site-centre/design-options/customCode/customCode';
import { useInstance, useSite } from '../../common/hooks';
import DesktopOnly from './components/common/DesktopOnly';

interface AuthRoutesProps {
  location: Location;
  params:   Params;
}

interface Location {
  pathname: string;
  search:   string;
  hash:     string;
  state:    null;
  key:      string;
}

interface Params {
  [key: string]: any;
}
const AuthRoutes: React.FC<AuthRoutesProps> = (props) => {
  const instance = useInstance();
  const site = useSite();

  const desktopOnly = (view) => {
    return (
      <DesktopOnly>
        {view}
      </DesktopOnly>
    );
  };
  const renderAppLayout = (component: React.ReactNode) => (
    <Reset>
      <AppLayout>{component}</AppLayout>
    </Reset>
  );

  const renderUserLayout = (component: React.ReactNode) => (
    <UserLayout>{component}</UserLayout>
  );

  const renderPlainLayout = (component: React.ReactNode) => (
    <Reset>
      <PlainLayout>{component}</PlainLayout>
    </Reset>
  );

  const { data: user, isLoading } = useGetUserProfileQuery();

  if (isLoading) {
    return <></>;
  }

  if (!user) {
    return (
      <Routes>
        <Route path="user/" element={<User />}>
          <Route path="login" element={renderUserLayout(<Login {...props} />)} />
          <Route path="register" element={renderUserLayout(<Register />)} />
          <Route path="forgot-password" element={renderUserLayout(<ForgotPassword />)} />
          <Route path="new-password/:token" element={renderUserLayout(<NewPassword {...props} />)} />
        </Route>
        <Route
          path="*"
          element={<Navigate to="/user/login" replace />}
        />
      </Routes>
    );
  }

  if (!user.agreed_to_terms) {
    return (
      <Routes>
        <Route
          path="*"
          element={renderUserLayout(<ClientTerms />)}
        />
      </Routes>
    );
  }

  return (
    <Routes>
      {/* Qr-Code */}
      <Route path={mainRoutes.qrCode} element={renderAppLayout(<QrCodeImage props={props} />)} />

      {/* Profile */}
      <Route path={userRoutes.usersList} element={renderAppLayout(desktopOnly(<UserList props={props} />))} />
      <Route path={userRoutes.userCreate} element={renderAppLayout(desktopOnly(<UserProfile props={props} />))} />
      <Route path={userRoutes.userProfile} element={renderAppLayout(<UserProfile props={props} />)} />

      {/* Dashboard */}
      <Route path={settingRoutes.dashboard} element={renderAppLayout(<DashboardView />)} />
      <Route path="/" element={renderAppLayout(<DashboardView />)} />

      {/* Site-Center */}
      <Route path={siteCenter.pageList} element={renderAppLayout(desktopOnly(<PageTree props={props} />))} />
      <Route path={siteCenter.domainList} element={renderAppLayout(desktopOnly(<DomainListing props={props} />))} />
      <Route path={siteCenter.pageVersionEdit} element={renderAppLayout(desktopOnly(<PageEdit props={props} />))} />
      <Route path={siteCenter.pageVersionHistory} element={renderAppLayout(desktopOnly(<PageHistory />))} />
      <Route path={siteCenter.urlList} element={renderAppLayout(desktopOnly(<PageUrl props={props} />))} />
      <Route path={siteCenter.businessDirectory} element={renderAppLayout(desktopOnly(<BusinessDirectory props={props} />))} />
      <Route path={siteCenter.editAllForms} element={renderAppLayout(desktopOnly(<EditAllForms props={props} />))} />

      {/* Site-Center/DesignOptions */}
      <Route path={siteCenter.templateList} element={renderAppLayout(desktopOnly(<TemplateList props={props} />))} />
      <Route path={siteCenter.editTemplate} element={renderAppLayout(desktopOnly(<TemplateEdit props={props} />))} />
      <Route path={siteCenter.editTemplateGrid} element={renderAppLayout(desktopOnly(<TemplateEditGrid props={props} />))} />
      <Route path={siteCenter.templateFragmentList} element={renderAppLayout(desktopOnly(<FragmentListings props={props} />))} />
      <Route path={siteCenter.addTemplateFragment} element={renderAppLayout(desktopOnly(<FragmentCreate />))} />
      <Route path={siteCenter.editTemplateFragment} element={renderAppLayout(desktopOnly(<FragmentEdit />))} />
      <Route path={siteCenter.textStyleList} element={renderAppLayout(desktopOnly(<TextStyleListing props={props} />))} />
      <Route path={siteCenter.addTextStyle} element={renderAppLayout(desktopOnly(<TextStyle props={props} />))} />
      <Route path={siteCenter.editTextStyle} element={renderAppLayout(desktopOnly(<TextStyle props={props} />))} />
      <Route path={siteCenter.styleOverrides} element={renderAppLayout(desktopOnly(<StyleOverrides props={props} />))} />
      <Route path={siteCenter.colourPalette} element={renderAppLayout(desktopOnly(<ColourPalette props={props} />))} />
      <Route path={siteCenter.buttons} element={renderAppLayout(desktopOnly(<Buttons props={props} />))} />
      <Route path={siteCenter.editButton} element={renderAppLayout(desktopOnly(<ButtonEdit props={props} />))} />
      <Route path={siteCenter.sectionEditing} element={renderPlainLayout(desktopOnly(<SectionEdit props={props} />))} />
      <Route path={siteCenter.customCodeListing} element={renderAppLayout(desktopOnly(<CustomCodeListing props={props} />))} />
      <Route path={siteCenter.customCodeAdd} element={renderAppLayout(desktopOnly(<CustomCode props={props} />))} />
      <Route path={siteCenter.customCodeUpdate} element={renderAppLayout(desktopOnly(<CustomCode props={props} />))} />

      <Route path={siteCenter.designOption} element={renderAppLayout(desktopOnly(<DesignHome props={props} />))} />

      {/* Stats-Center */}
      <Route path={statsCenterRoutes.statsCenter} element={renderAppLayout(<StatsCentre props={props} />)} />

      {/* Content Centre */}
      <Route path={contentCenterRoutes.contentCenter} element={renderAppLayout(desktopOnly(<ContentCentre props={props} />))} />
      <Route path={contentCenterRoutes.contentBriefing} element={renderAppLayout(desktopOnly(<ContentBriefingForm props={props} />))} />
      <Route path={contentCenterRoutes.generateDraftContent} element={renderAppLayout(desktopOnly(<GenerateDraftContent props={props} />))} />

      {/* Data-Center */}
      <Route path={dataCenter.editDatabaseField} element={renderAppLayout(<EditDataField props={props} />)} />
      <Route path={dataCenter.showMemberSearch} element={renderAppLayout(<Members props={props} />)} />
      <Route path={dataCenter.allMembers} element={renderAppLayout(<Members props={props} />)} />
      <Route path={dataCenter.memberSearchById} element={renderAppLayout(<MemberSearch props={props} />)} />
      <Route path={dataCenter.memberSearch} element={renderAppLayout(<MemberSearch props={props} />)} />
      <Route path={dataCenter.importMembers} element={renderAppLayout(<ImportMembers props={props} />)} />
      <Route path={dataCenter.databaseFields} element={renderAppLayout(<DataFieldListing props={props} />)} />
      <Route path={dataCenter.editMember} element={renderAppLayout(<DatabaseFields props={props} />)} />
      <Route path={dataCenter.addMember} element={renderAppLayout(<DatabaseFields props={props} />)} />
      <Route path={dataCenter.savedSearch} element={renderAppLayout(<SavedSearches props={props} />)} />
      <Route path={mainRoutes.dataCenter} element={renderAppLayout(<DataCenterHome props={props} />)} />

      {/* Setting */}
      <Route path={settingRoutes.instanceSettings} element={renderAppLayout(desktopOnly(<InstanceSettings props={props} />))} />
      <Route path={settingRoutes.siteList} element={renderAppLayout(desktopOnly(<SiteSettingListing props={props} />))} />
      <Route path={settingRoutes.siteSettingsGeneral} element={renderAppLayout(desktopOnly(<SiteSettingGeneral props={props} />))} />
      <Route path={settingRoutes.siteSettingsProducts} element={renderAppLayout(desktopOnly(<SiteSettingProducts props={props} />))} />
      <Route path={settingRoutes.siteSettingsPhoneNumbers} element={renderAppLayout(desktopOnly(<SiteSettingPhoneNumbers props={props} />))} />
      <Route path={settingRoutes.siteSettingsSEO} element={renderAppLayout(desktopOnly(<SiteSettingSEO props={props} />))} />
      <Route path={settingRoutes.siteSettingsGoogleAnalytics} element={renderAppLayout(desktopOnly(<SiteSettingGoogleAnalytics props={props} />))} />
      <Route path={settingRoutes.siteSettingsOtherGoogleServices} element={renderAppLayout(desktopOnly(<SiteSettingOtherGoogleServices props={props} />))} />
      <Route path={settingRoutes.siteSettingsGoogleReviewEngine} element={renderAppLayout(desktopOnly(<SiteSettingGoogleReviewEngine props={props} />))} />
      <Route path={settingRoutes.siteSettingsReCAPTCHA} element={renderAppLayout(desktopOnly(<SiteSettingReCAPTCHA props={props} />))} />
      <Route path={settingRoutes.siteSettingsInstantContact} element={renderAppLayout(desktopOnly(<SiteSettingInstantContact props={props} />))} />
      <Route path={settingRoutes.siteSettingsPrivacyPolicy} element={renderAppLayout(desktopOnly(<SiteSettingPrivacyPolicy props={props} />))} />
      <Route path={settingRoutes.siteSettingsPopup} element={renderAppLayout(desktopOnly(<SiteSettingPopup props={props} />))} />
      <Route path={settingRoutes.siteSettingsSiteTextSearch} element={renderAppLayout(desktopOnly(<SiteSettingSiteTextSearch props={props} />))} />
      <Route path={settingRoutes.siteBriefings} element={renderAppLayout(desktopOnly(<SiteBriefings props={props} />))} />
      <Route path={settingRoutes.siteBriefingForm} element={renderAppLayout(desktopOnly(<SiteBriefingForm props={props} />))} />
      <Route path={settingRoutes.trash} element={renderAppLayout(desktopOnly(<Trash props={props} />))} />
      <Route path={settingRoutes.duplicateInstance} element={renderAppLayout(desktopOnly(<DuplicateInstance props={props} />))} />
      <Route path={settingRoutes.importInstance} element={renderAppLayout(desktopOnly(<ImportInstance props={props} />))} />
      <Route path={settingRoutes.logs} element={renderAppLayout(desktopOnly(<Logs props={props} />))} />
      <Route path={settingRoutes.automatedReports} element={renderAppLayout(desktopOnly(<AutomatedReport props={props} />))} />
      <Route path={settingRoutes.roiReports} element={renderAppLayout(desktopOnly(<ROIReports props={props} />))} />
      <Route path={settingRoutes.backgroundJobs} element={renderAppLayout(desktopOnly(<BackgroundJobs props={props} />))} />
      <Route path={settingRoutes.backgroundJobsReport} element={renderAppLayout(desktopOnly(<JobReports props={props} />))} />
      <Route
        path={settingRoutes.toolGoogleVisibility}
        element={renderAppLayout(desktopOnly(<ToolGoogleVisibility props={props} />))}
      />
      <Route
        path={settingRoutes.toolCurrentVisibilityScore}
        element={renderAppLayout(desktopOnly(<ToolCurrentVisibilityScore props={props} />))}
      />
      <Route path={settingRoutes.tools} element={renderAppLayout(desktopOnly(<Tools props={props} />))} />
      <Route path={settingRoutes.reports} element={renderAppLayout(desktopOnly(<Reports props={props} />))} />
      <Route path={mainRoutes.setting} element={renderAppLayout(desktopOnly(<SettingHome props={props} />))} />
      <Route path={mainRoutes.unsubscribeFromAll} element={renderAppLayout(desktopOnly(<UnsubscribeFromAll />))} />
      <Route path={mainRoutes.keywordResearchTool} element={renderAppLayout(desktopOnly(<KeywordResearchTool props={props} />))} />
      <Route path={mainRoutes.test} element={renderAppLayout(<Test props={props} />)} />
      <Route path={settingRoutes.services} element={renderAppLayout(desktopOnly(<Services props={props} />))} />
      <Route
        path={settingRoutes.componentCategoryList}
        element={renderAppLayout(desktopOnly(<ComponentCategoryList props={props} />))}
      />
      <Route path={settingRoutes.componentList} element={renderAppLayout(desktopOnly(<ComponentList props={props} />))} />
      <Route path={settingRoutes.componentEdit} element={renderAppLayout(desktopOnly(<Component props={props} />))} />
      <Route path={settingRoutes.componentCreate} element={renderAppLayout(desktopOnly(<Component props={props} />))} />

      {/* Delivery-Center */}
      <Route path={deliveryCenterRoutes.editSeries} element={renderAppLayout(desktopOnly(<EmailSeries props={props} />))} />
      <Route path={deliveryCenterRoutes.newSeries} element={renderAppLayout(desktopOnly(<EmailSeries props={props} />))} />
      <Route path={deliveryCenterRoutes.bulkEmail} element={renderAppLayout(desktopOnly(<BulkEmail props={props} />))} />
      <Route
        path={deliveryCenterRoutes.emailSeries}
        element={renderAppLayout(desktopOnly(<EmailSeriesListing props={props} />))}
      />
      <Route
        path={deliveryCenterRoutes.deliveryCenter}
        element={renderAppLayout(desktopOnly(<DeliveryCenterHome props={props} />))}
      />

      {/* Media-Center */}
      <Route
        path={mediaCenterRoutes.mediaCenterDefault}
        element={renderAppLayout(desktopOnly(<MediaCenterList props={props} />))}
      />
      <Route path={mediaCenterRoutes.mediaCenterFolder} element={renderAppLayout(desktopOnly(<MediaFolder props={props} />))} />

      {/* Message-Center */}
      <Route
        path={messageCenterRoutes.messageCenterEmailPage}
        element={renderAppLayout(desktopOnly(<EmailPageEdit props={props} />))}
      />
      <Route path={messageCenterRoutes.messageCenter} element={renderAppLayout(desktopOnly(<EmailPages props={props} />))} />

      {/* Review-Center */}
      <Route path={reviewCenterRoutes.reviewCenter} element={renderAppLayout(desktopOnly(<ReviewCenterLog props={props} />))} />

      {/* Default Redirect */}
      {instance?.id && site?.id
        ? (
            <Route
              path="*"
              element={<Navigate to={`/dashboards/instance/${instance.id}/site/${site.id}`} replace />}
            />
          )
        : (
            <Route path="*" element={<Navigate to="/" />} />
          )}
    </Routes>
  );
};

export default withRouter(AuthRoutes);
