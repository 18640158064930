import React, { useState } from 'react';
import {
  Row, Card, CardTitle, Form, Label, Input, Button,
} from 'reactstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import IntlMessages from '../../helpers/IntlMessages';
import { Colxx } from '../../components/common/CustomBootstrap';
import './index.scss';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');

  const navigate = useNavigate();

  const onUserRegister = () => {
    if (email !== '' && password !== '') {
      navigate('/');
    }
  };

  return (
    <Row className="h-100">
      <Colxx xxs="12" md="10" className="mx-auto my-auto">
        <Card className="auth-card">
          <div className="position-relative image-side ">
            <p className="white mb-0">
              Please use this form to register.
              {' '}
              <br />
              If you already have an account, please
              {' '}
              <NavLink to="/login" className="white">
                login
              </NavLink>
              .
            </p>
          </div>
          <div className="form-side">
            <NavLink to="/" className="white">
              <span className="logo-single" />
            </NavLink>
            <CardTitle className="mb-4">
              <IntlMessages id="user.register" />
            </CardTitle>
            <Form>
              <Label className="form-group has-float-label mb-4">
                <Input type="name" value={name} onChange={(e) => setName(e.target.value)} />
                <IntlMessages id="user.fullname" />
              </Label>
              <Label className="form-group has-float-label mb-4">
                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <IntlMessages id="user.email" />
              </Label>
              <Label className="form-group has-float-label mb-4">
                <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <IntlMessages id="user.password" />
              </Label>
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  color="primary"
                  className="btn-shadow"
                  size="lg"
                  onClick={() => onUserRegister()}
                >
                  <IntlMessages id="user.register-button" />
                </Button>
              </div>
            </Form>
          </div>
        </Card>
      </Colxx>
    </Row>
  );
};

export default Register;
