import React from 'react';
import { Outlet } from 'react-router-dom';
import UserLayout from '../../layout/UserLayout';

function User() {
  return (
    <UserLayout>
      <Outlet />
    </UserLayout>
  );
}

export default User;
