import Base from './Base';

export interface Oauth2Details {
  [key: string]: any;
}

interface InstanceProps {
  id?: string | number;
  master?:                         boolean;
  company_name?:                   string;
  platform_status?:                string;
  cta_types?:                      string[];
  notes?:                          string;
  firstname_field_id?:             null | string;
  lastname_field_id?:              null | string;
  email_field_id?:                 null | string;
  mobile_number_field_id?:         null | string;
  oauth2_details?:                 Oauth2Details;
  leave_review_page_id?:           null;
  review_request_email_series_id?: null;
  sms_name?:                       null;
  new_editors?:                    boolean;
  xero_send_email?:                boolean;
  xero_send_sms?:                  boolean;
  content_section_model?:          number;
}

class Instance extends Base {
  id?: string | number;
  master?:                         boolean;
  company_name?:                   string;
  platform_status?:                string;
  cta_types?:                      string[];
  notes?:                          string;
  firstname_field_id?:             null | string;
  lastname_field_id?:              null | string;
  email_field_id?:                 null | string;
  mobile_number_field_id?:         null | string;
  oauth2_details?:                 Oauth2Details;
  leave_review_page_id?:           null;
  review_request_email_series_id?: null;
  sms_name?:                       null;
  new_editors?:                    boolean;
  xero_send_email?:                boolean;
  xero_send_sms?:                  boolean;
  content_section_model?:          number;

  constructor(props: InstanceProps) {
    super(props);
    Object.assign(this, props);
  }
}

export default Instance;
