import React from 'react';
import { Button } from 'reactstrap';

function DeviceButtons(props) {
  const { onClick, activeDevice } = props;
  return (
    <ul className="custom-ul text-center">
      <Button
        className={
          `custom-simple-txt-btn mx-3 mt-1 ${activeDevice === 'desktop' ? 'custom-text-color-blue' : ''}`
        }
        onClick={() => onClick('desktop')}
      >
        <li>
          <i className="fa fa-desktop custom-font-s20p" />
        </li>
      </Button>
      <Button
        className={
          `custom-simple-txt-btn mx-3 mt-1 ${activeDevice === 'tablet' ? 'custom-text-color-blue' : ''}`
        }
        onClick={() => onClick('tablet')}
      >
        <li>
          <i className="fa fa-tablet-alt custom-font-s20p" />
        </li>
      </Button>
      <Button
        className={
          `custom-simple-txt-btn mx-3 mt-1 ${activeDevice === 'phone' ? 'custom-text-color-blue' : ''}`
        }
        onClick={() => onClick('phone')}
      >
        <li>
          <i className="fa fa-mobile-alt custom-font-s20p" />
        </li>
      </Button>
    </ul>
  );
}
export default DeviceButtons;
