import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Collapse,
  Badge,
  Alert,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { cloneDeep, isEmpty, map } from 'lodash-es';
import confirm from '../../../../components/common/confirm';
import EmailProof from '../../../message-centre/email-proof';
import EmailPreview from '../../../email-preview';
import BottomActionToolbar from '../../../../components/common/toolbar';
import { sectionTypes } from '../../../../../../common/schema';
import withRouter from '../../../../helpers/withRouter';
import SectionSetting from '../SectionSetting';
import SectionNotes from '../SectionNotes';
import DragDropComponent from '../../../../components/common/reactSortable';
import SectionEditModal from '../SectionEditModal';
import './index.scss';
import UserFeatureComponent from 'Dashboard/src/components/common/user-feature-component';
import DeviceButtons from '../../../../components/common/deviceButtons';
import AddSection from './AddSection';
import {
  useGetMasterSectionsQuery,
  useSectionDeleteMutation,
  useSectionUpdateMutation,
  useSectionPositionUpdateMutation,
  useGetSectionQuery,
  useGetPageDataQuery,
  setPageData,
} from '../../../../../../common/api/apiSlice';
import SectionPreview from './SectionPreview';
import { addGoogleFont, displayError } from '../../../../../../Utils';
import { useUser } from '../../../../../../common/hooks';

const getBpId = (ele) => [...ele.classList].filter((c) => c.match(/bp-[0-9a-f]{24}/))[0];

function SectionListing(props) {
  const {
    page,
    pageVersion,
    pageName,
    isEmailPage,
    showMasterSection,
    params: {
      instanceId, pageVersionId, siteId, pageId,
    },
  } = props;

  const dispatch = useDispatch();

  const [accordion, setAccordion] = useState([]);
  const [activeDevice, setActiveDevice] = useState('desktop');
  const [sectorIndex, setSectorIndex] = useState(null);
  const [newSectionOrder, setNewSectionOrder] = useState(null);
  const [modal, setModal] = useState(false);
  const [notesModal, setNotesModal] = useState(false);
  const [editSectionModal, setEditSectionModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const user = useUser();
  const [editingSection, setEditingSection] = useState(null);
  const [proofDetails, setProofDetails] = useState(null);
  const [previewDetails, setPreviewDetails] = useState(null);
  const sectionType = sectionTypes(false, isEmailPage ? 'email' : 'web', user);

  const { data: masterSections } = useGetMasterSectionsQuery({ siteId, pageId: page.id, pageVersionId: pageVersion.id });
  const { data: sectionData } = useGetSectionQuery({ instanceId, sectionId: editingSection?.id }, { skip: isEmpty(editingSection) });

  const { data } = useGetPageDataQuery({ siteId, pageId, pageVersionId });

  useEffect(() => {
    if (data) {
      dispatch(setPageData(data));
    }
  }, [data]);

  const sectors = data?.sector || [];
  const sections = data?.section || [];
  const textStyleList = data?.text_style || [];

  const [updateSection] = useSectionUpdateMutation();
  const [deleteSection] = useSectionDeleteMutation();
  const [sectionPositionUpdate] = useSectionPositionUpdateMutation();

  useEffect(() => {
    if (!isEmpty(textStyleList)) {
      addGoogleFont(map(textStyleList, (e) => ({
        fontName: e.fonts,
        fontWeight: e.font_weight,
      })));
    }
  }, [textStyleList]);

  useEffect(() => {
    if (!isEmpty(sectors)) {
      setAccordion(Array(sectors.length).fill(true));
    }
  }, [sectors]);

  const onHandleClick = (id, isSetting = false) => {
    setEditingSection({ id, isSetting });
  };

  useEffect(() => {
    if (sectionData && editingSection) {
      if (['SectionReviewForm'].includes(sectionData.type)) {
        setEditSectionModal(false);
      } else if (['SectionForm'].includes(sectionData.type) && editingSection && editingSection?.isSetting) {
        setSettingsModal(true);
      } else if (notesModal) {
        setEditSectionModal(false);
      } else {
        setEditSectionModal(true);
      }
    }
  }, [sectionData, editingSection]);

  useEffect(() => {
    const domSections = document.querySelectorAll('.SectionListing section[class*="bp-"], .SectionContent[class*="bp-"]');
    if (!isEmpty(domSections)) {
      for (let index = 0; index < domSections.length; index += 1) {
        const domSection = domSections[index];

        if (domSection.classList.contains('SectionColumn')) {
          continue;
        }

        const sectionId = getBpId(domSection).replace('bp-', '');

        if (!domSection.querySelector('.hover-btn') && !domSection.classList.contains('SectionReviewForm')) {
          const div = document.createElement('div');
          div.classList.add('hover-btn');
          div.addEventListener('click', () => onHandleClick(sectionId, false));

          const editButton = document.createElement('button');
          editButton.classList.add('custom-simple-txt-btn', 'btn', 'btn-secondary');
          editButton.innerHTML = '<i class=\'fa fa-pencil\' aria-hidden=\'true\'></i>';
          div.appendChild(editButton);

          if (domSection.classList.contains('SectionForm')) {
            const settingButton = document.createElement('button');
            settingButton.classList.add('custom-simple-txt-btn', 'btn', 'btn-secondary', 'ms-2');
            settingButton.innerHTML = '<i class=\'fa fa-cog\' aria-hidden=\'true\'></i>';
            settingButton.addEventListener('click', (e) => {
              // Stop the click bubbling up and opening the edit section box.
              e.stopPropagation();

              onHandleClick(sectionId, true);
            });
            div.appendChild(settingButton);
          }

          domSection.appendChild(div);
        }
      }
    }
  });

  if (sectors === undefined || sections === undefined) {
    return <></>;
  }

  const onHandleAddSection = (sectorIndex, sectionIndex) => {
    setSectorIndex(sectorIndex);
    setNewSectionOrder(sectionIndex);
    setModal(!modal);
  };

  const modalClose = () => {
    setModal(false);
  };

  const getSectionTypeName = (value) => {
    const getName = sectionType.filter((opt) => value === opt.value);
    return getName[0] ? getName[0].label : '';
  };

  const onHandleDeleteSection = async (e) => {
    try {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });
      if (result) {
        deleteSection({ instance_id: instanceId, id: e });
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const sectorToggleAccordion = (tab) => {
    const tempSector = [...accordion];
    if (tempSector[tab] === true) {
      tempSector[tab] = false;
    } else {
      tempSector[tab] = true;
    }
    setAccordion(tempSector);
  };

  const onHandleSectionIsOffline = async (section) => {
    try {
      updateSection({ instance_id: instanceId, id: section.id, is_offline: !section.is_offline });
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const onHandleEditSection = (e, s) => {
    const { type, id } = s;
    const { target } = e;

    if (['SectionReviewForm'].includes(type)) {
      setEditSectionModal(false);
      setEditingSection(null);
      e.preventDefault();
    } else if (type === 'SectionColumn') {
      const closestSection = isEmailPage ? target.closest('[class*=mj-column]') : target.closest('section');

      if (closestSection) {
        // user has clicked on a cell inside a column section
        const cellSectionId = getBpId(closestSection).replace('bp-', '');
        onHandleClick(cellSectionId);
      } else {
        // user has clicked on the pencil icon on the column section itself
        onHandleClick(id);
      }
    } else {
      onHandleClick(id);
    }
  };

  const onHandleMove = async (sector, section, moveUp) => {
    const fieldIdsArray = [];
    const thisSectorSections = sections.filter((s) => isEmpty(s.column_section) && s.sector === sector.label);
    const sectionIndex = thisSectorSections.findIndex((s) => s.id === section.id);
    const moveElement = thisSectorSections.splice(sectionIndex, 1)[0];
    const setIndex = moveUp ? sectionIndex - 1 : sectionIndex + 1;
    thisSectorSections.splice(setIndex, 0, moveElement);
    thisSectorSections.map((ele) => fieldIdsArray.push({ id: ele.id }));
    const sendParams = {
      instance_id: instanceId,
      page_version_id: pageVersionId,
      sections: fieldIdsArray,
      sector_name: sector.label,
    };
    sectionPositionUpdate(sendParams);
  };

  const sectionsView = (
    sectorIndex,
    sector,
    index,
    label,
    section,
    isMaster,
    pageName,
  ) => (
    <div className="sortable-container ps-3 pe-3" key={section.id}>
      <Row id={section.id} className="custom-section-block">
        <div className="custom-top-section-block w-100">
          <Row>
            <Col lg={4}>
              {getSectionTypeName(section.type)}
              {isMaster ? <Badge color="dark ms-4 font-size-12px">IN MASTER</Badge> : ''}
            </Col>
            <Col lg={4} className="text-center">
              {pageName}
                &nbsp;&nbsp;&nbsp;
              <span style={{ color: '#999' }}>
                &gt;
              </span>
                &nbsp;&nbsp;
              <span style={{ color: '#999' }}>
                Sector
              </span>
                &nbsp;&nbsp;
              {undefined !== label ? label : ''}
                &nbsp;&nbsp;&nbsp;
              <span style={{ color: '#999' }}>
                &gt;
              </span>
              &nbsp;&nbsp;
              Section
              {' '}
              {index + 1}
            </Col>
            <Col lg={4}>
              <ul className="custom-ul text-end">
                <UserFeatureComponent feature="edit_section_notes">
                  <Button
                    className="custom-simple-txt-btn me-3"
                    onClick={() => {
                      onHandleClick(section?.id);
                      setNotesModal(true);
                    }}
                  >
                    <li>
                      <i className="fa-regular fa-note-sticky" />
                    </li>
                  </Button>
                  <Button
                    className="custom-simple-txt-btn me-3"
                    onClick={() => false}
                  >
                    <li>
                      <i className="fa-light fa-pipe" style={{ color: '#ccc' }} />
                    </li>
                  </Button>
                </UserFeatureComponent>
                {index !== 0 && (
                  <Button
                    className="custom-simple-txt-btn me-3"
                    onClick={() => onHandleMove(sector, section, true)}
                  >
                    <li>
                      <i className="fa-solid fa-arrow-up" />
                    </li>
                  </Button>
                )}
                {sections.filter((s) => s.sector === sector.label).length !== index + 1 && (
                  <Button
                    className="custom-simple-txt-btn me-3"
                    onClick={() => onHandleMove(sector, section, false)}
                  >
                    <li>
                      <i className="fa-solid fa-arrow-down" />
                    </li>
                  </Button>
                )}
                {((index !== 0) || (sections.filter((s) => s.sector === sector.label).length !== index + 1)) && (
                  <Button
                    className="custom-simple-txt-btn me-3"
                    onClick={() => false}
                  >
                    <li>
                      <i className="fa-light fa-pipe" style={{ color: '#ccc' }} />
                    </li>
                  </Button>
                )}
                <Button
                  className="custom-simple-txt-btn me-3"
                  onClick={(e) => onHandleEditSection(e, section)}
                  disabled={['SectionReviewForm'].includes(section.type)}
                >
                  <li>
                    <i className="fa fa-pencil" />
                  </li>
                </Button>
                <Button
                  className="custom-simple-txt-btn me-3"
                  onClick={() => onHandleSectionIsOffline(section)}
                >
                  <li>
                    <span className="custom-strike-through">
                      {section.is_offline
                        ? (
                            <i className="fa fa-eye-slash" />
                          )
                        : (
                            <i className="fa fa-eye" />
                          )}
                    </span>
                  </li>
                </Button>
                <Button
                  className="custom-simple-txt-btn me-3"
                  onClick={() => onHandleDeleteSection(section.id)}
                >
                  <li>
                    <i className="fa fa-trash text-secondary" />
                  </li>
                </Button>
              </ul>
            </Col>
          </Row>
        </div>
        {!isEmpty(section.notes) && (
          <UserFeatureComponent feature="view_section_notes">
            <div className="section-notes">
              <Alert>
                {section.notes}
              </Alert>
            </div>
          </UserFeatureComponent>
        )}
        <div
          className={`
            custom-bottom-section-block
            style-prefix-container
            ${section.is_offline ? 'custom-fade-text' : ''}
          `}
        >
          <div className={`device-container device-${activeDevice}`}>
            {/* Add a sector wrapper so that style overrides apply to the section previews */}
            <div
              className={`sector sector-${sector.label}`}

              // Adding the margin and padding from the sector makes the preview look strange, so we remove them.
              style={{ margin: '0', padding: '0' }}
            >
              <SectionPreview
                page={page}
                section={section}
                onClick={(e) => onHandleClick(e?.id)}
              />
            </div>
          </div>
        </div>
      </Row>
      {addNewSectionView(sectorIndex, index + 2)}
    </div>
  );

  const masterSectionBlock = (sectorIndex, sector) => {
    if (masterSections === undefined) {
      return [];
    }

    const { label } = sector;

    return masterSections.filter((s) => s.sector === sector.label).map((section, index) => sectionsView(
      sectorIndex,
      sector,
      index,
      label,
      section,
      true,
      pageName,
      isEmailPage,
    ));
  };

  const sectionBlock = (sectorIndex, sector) => {
    if (sections === undefined) {
      return [];
    }

    const { label } = sector;

    return sections.filter((s) => {
      if (s.sector !== sector.label) {
        return false;
      }

      if (!isEmpty(s.column_section)) {
        return false;
      }

      // The sections array can include sections from other pages because they are included if there are macros that
      // reference them, so we need to filter them out.
      if (s.page_version_id !== pageVersion.id) {
        return false;
      }

      return true;
    }).map((section, index) => sectionsView(
      sectorIndex,
      sector,
      index,
      label,
      section,
      false,
      pageName,
      isEmailPage,
    ));
  };

  const getSectionBlocks = (index, sector) => {
    const sortableComponent = (
      <DragDropComponent
        forceFallback
        delay={100}
        animation={150}
        keyName="sections"
        filter=".add-new-section"
        returnData={sectionBlock(index, sector)}
        list={cloneDeep(sections.filter((s) => s.sector === sector.label))}
        setNewState={(thisSectorSections) => {
          const sendParams = {
            instance_id: instanceId,
            page_version_id: pageVersionId,
            sections: thisSectorSections.map((ele) => ({ id: ele.id })),
            sector_name: sector.label,
          };
          sectionPositionUpdate(sendParams);
        }}
      />
    );

    if (sector.master_content_position === 'top' && showMasterSection) {
      return (
        <>
          {masterSectionBlock(index, sector)}
          {sortableComponent}
        </>
      );
    }

    if (sector.master_content_position === 'bottom' && showMasterSection) {
      return (
        <>
          {sortableComponent}
          {masterSectionBlock(index, sector)}
        </>
      );
    }

    return sortableComponent;
  };

  const addNewSectionView = (sectorIndex, sectionIndex) => (
    <Row style={{ justifyContent: 'center' }} className="add-new-section">
      <Col className="text-center">
        <Button
          className="add-new-button button-lg w-100"
          style={{
            maxWidth: '100px', lineHeight: '28px', marginTop: '15px', marginBottom: '15px',
          }}
          onClick={() => onHandleAddSection(sectorIndex, sectionIndex)}
          color="info"
        >
          <i className="fa-solid fa-plus" />
        </Button>
      </Col>
    </Row>
  );

  const sectorList = () => {
    if (!isEmpty(sectors)) {
      return sectors.map((item, index) => (
        <Card body key={item.id} className="mb-4 rounded">
          <Row>
            <Col className="text-center">
              <h2>
                <span style={{ color: '#999' }}>
                  Sector
                </span>
                {' '}
                {item.label}

                <div className="float-end">
                  <Button
                    className="custom-simple-txt-btn"
                    onClick={() => sectorToggleAccordion(index)}
                    aria-expanded={accordion[index]}
                  >
                    <h4 className="d-flex align-items-center justify-content-center">
                      <i
                        className={`${accordion[index]
                          ? 'fa fa-arrow-circle-down'
                          : 'fa fa-arrow-circle-right'
                        }`}
                      />
                    </h4>
                  </Button>
                </div>
              </h2>
            </Col>
          </Row>
          <Collapse isOpen={accordion[index]} className="w-100">
            {addNewSectionView(index, 1)}
            {getSectionBlocks(index, item)}
          </Collapse>
        </Card>
      ));
    }
    return (
      <Card body>
        <h4>No sector found!</h4>
      </Card>
    );
  };

  const onClose = () => {
    setEditingSection(null);
    setEditSectionModal(false);
    setNotesModal(false);
  };

  const onCloseSettings = () => {
    setEditingSection(null);
    setSettingsModal(false);
  };

  return (
    <div className="SectionListing">
      {notesModal && sectionData && (
        <SectionNotes onClose={onClose} section={sectionData} />
      )}

      {editSectionModal && sectionData && (
        <SectionEditModal onClose={onClose} section={sectionData} />
      )}

      {settingsModal && sectionData && (
        <SectionSetting section={sectionData} onClose={onCloseSettings} />
      )}

      {sectorList()}

      {modal && (
        <AddSection order={newSectionOrder} onClose={modalClose} sector={sectors[sectorIndex]} />
      )}

      {proofDetails && (
        <EmailProof
          pageId={proofDetails.id}
          subject={proofDetails.subject}
          closeModal={() => setProofDetails(null)}
          instanceId={instanceId}
        />
      )}

      {previewDetails && (
        <EmailPreview
          id={previewDetails.id}
          closeModal={() => setPreviewDetails(null)}
          instanceId={instanceId}
        />
      )}

      {!isEmailPage && (
        <BottomActionToolbar
          component={(
            <DeviceButtons
              onClick={(device) => {
                setActiveDevice(device);
                dispatch(setPageData({ ...data, deviceOverride: device }));
              }}
              activeDevice={activeDevice}
            />
          )}
        />
      )}

      {isEmailPage && (
        <BottomActionToolbar
          component={(
            <>
              <Button
                type="submit"
                onClick={() => setProofDetails({ id: page.id, subject: page.page_version?.default_subject })}
                className="fw-bold button-md common-success-button me-2"
              >
                PROOF
              </Button>
              <Button
                type="submit"
                onClick={() => setPreviewDetails({ id: page.id })}
                className="fw-bold button-md common-success-button"
              >
                PREVIEW
              </Button>
            </>
          )}
        />
      )}
    </div>
  );
}

export default withRouter(SectionListing);
