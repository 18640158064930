import React, { useEffect, useState } from 'react';
import TableComponent from '../../../../components/common/Table';
import {
  Card, CardBody, Row, Input,
} from 'reactstrap';
import HeaderComponent from '../../../../components/common/header-component';
import { settingRoutes, siteCenter, replacePathParams } from '../../../../constants/routes';
import CustomButton from '../../../../components/common/button';
import CustomSelect from '../../../../components/common/custom-select';
import { isEmpty, get, concat, cloneDeep } from 'lodash-es';
import SaveAndContinue from '../../../../components/common/saveAndContinue';
import withRouter from '../../../../helpers/withRouter';
import { useUpdateSiteMutation } from '../../../../../../common/api/apiSlice';
import BottomActionToolbar from '../../../../components/common/toolbar';
import ColorPicker from '../../../../components/common/color-picker';
import { useInstance, useSite } from '../../../../../../common/hooks';
import { addRecentItem } from '../../../../../../UtilsTS';

export const colorNames = [
  'Primary',
  'Secondary',
  'Tertiary',
  'Black',
  'White',
  'Grey',
];

function ColourPalette(props) {
  const site = useSite();
  const instance = useInstance();
  const siteId = get(site, 'id', null);
  const instanceId = get(instance, 'id', null);
  const [updateSite] = useUpdateSiteMutation();

  const [colors, setColors] = useState([]);

  const tempColors = [...colors];

  useEffect(() => {
    if (site) {
      setColors(cloneDeep(site?.colors));
    }
  }, [site]);

  const onHandleAdd = () => {
    const sendParams = {
      id: colors.length + 1,
      label: '',
      hex: '#ffffff',
      gradient: '',
      isGradient: false,
    };
    setColors([...colors, sendParams]);
  };

  const onHandleChange = (e, index, keyName) => {
    const { value, isGradient } = e.target;
    tempColors[index][keyName] = value;
    tempColors[index]['is_gradient'] = isGradient;
    setColors(tempColors);
  };

  const dataTableColumns = [
    {
      Header: 'Label',
      id: 'label',
      accessor: (d, index) => (
        <>
          <CustomSelect
            value={(d.custom || colorNames.indexOf(get(d, 'label', '')) === -1) ? { label: 'Custom', value: 'Custom' } : { label: get(d, 'label', ''), value: get(d, 'label', '') }}
            onChange={(e) => {
              if (e.value === 'Custom') {
                d.custom = true;
                onHandleChange({ target: { value: '' } }, index, 'label');
              } else {
                d.custom = false;
                onHandleChange({ target: { value: e.value } }, index, 'label');
              }
            }}
            options={concat(colorNames.map((n) => ({ label: n, value: n })), [{ label: 'Custom', value: 'Custom' }])}
          />
        </>
      ),
    },
    {
      Header: '',
      id: 'realLabel',
      accessor: (d, index) => (d.custom || colorNames.indexOf(get(d, 'label', '')) === -1) && (
        <Input
          placeholder="Label"
          className="h-40px"
          type="text"
          value={get(d, 'label', '')}
          onChange={(e) => onHandleChange(e, index, 'label')}
        />
      ),
    },
    {
      Header: 'Colour',
      id: 'colours',
      accessor: (d, index) => (
        <ColorPicker
          isColourPalette
          color={get(d, d.is_gradient ? 'gradient' : 'hex', '')}
          onChange={(e) => onHandleChange(e, index, e.target.isGradient ? 'gradient' : 'hex')}
          onDeleteValue={() => onHandleChange({ target: { value: '#ffffffff', isGradient: false, gradient: '' } }, index, d.isGradient ? 'gradient' : 'hex')}
        />
      ),
    },
  ];

  const onSave = () => {
    tempColors.forEach((color, index) => {
      if (color.is_gradient) {
        tempColors[index].hex = '';
      } else {
        tempColors[index].gradient = '';
      }
    });
    const sendParams = {
      colors,
      id: siteId,
      instance_id: instanceId,
    };
    updateSite(sendParams);
  };

  if (instanceId && siteId) {
    addRecentItem({
      instance_id: instanceId,
      site_id: siteId,
      type: 'colour-palette',
    });
  }

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Colour Palette',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Dashboard',
        backToPath: settingRoutes.dashboard,
        showBreadcrumb: false,
        staticBreadcrumbData: [
          {
            name: 'Dashboard',
            url: replacePathParams(settingRoutes.dashboard, [], props),
          },
          {
            name: 'Site Centre',
            url: replacePathParams(siteCenter.pageList, [], props),
          },
          {
            name: 'Design Options',
            url: replacePathParams(siteCenter.designOption, [], props),
          },
          { name: 'Colour Palette', url: '' },
        ],
      }}
      />
      <Card className="mb-4">
        <CardBody>
          <Row className="BreakPoint align-items-center">
            <TableComponent
              columns={dataTableColumns}
              data={colors}
            />
          </Row>
        </CardBody>
      </Card>
      <BottomActionToolbar
        component={(
          <>
            <CustomButton
              color="info"
              className="common-info-button button-md fw-bold"
              title="ADD COLOUR"
              icon="fa fa-plus"
              onClick={() => onHandleAdd()}
            />
            {' '}
            <SaveAndContinue
              isDisabled={isEmpty(colors)}
              onSave={onSave}
              onContinue={replacePathParams(siteCenter.designOption, [], props)}
            />
          </>
        )}
      />
    </>
  );
}

export default withRouter(ColourPalette);
