import React from 'react';
import {
  Label,
  FormGroup,
} from 'reactstrap';
import RadioButton from '../../../components/common/radioButton';

function PopupFrequency(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label>Popup Frequency</Label>
      <ul className="custom-ul-layout d-flex gap-4">
        <li>
          <RadioButton
            title="Once per visitor"
            checked={params.popup_options.frequency === 'once_per_visitor'}
            onHandleChange={(e) => {
              e.preventDefault();
              setParams({ ...params, popup_options: { ...params.popup_options, frequency: 'once_per_visitor' } });
            }}
          />
        </li>
        <li>
          <RadioButton
            title="Every visit"
            checked={!params.popup_options.frequency || params.popup_options.frequency === 'every_visit'}
            onHandleChange={(e) => {
              e.preventDefault();
              setParams({ ...params, popup_options: { ...params.popup_options, frequency: 'every_visit' } });
            }}
          />
        </li>
      </ul>
    </FormGroup>
  );
}

export default PopupFrequency;
