import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import { DateTime } from 'luxon';
import HeaderComponent from 'Dashboard/src/components/common/header-component';
import { settingRoutes, replacePathParams, mainRoutes } from 'Dashboard/src/constants/routes';
import UserFeaturePage from 'Dashboard/src/components/common/user-feature-page';
import CustomSelect from 'Dashboard/src/components/common/custom-select';
import { useLazyRunReportQuery } from 'common/api/apiSlice';
import { Line } from 'react-chartjs-2';
import withRouter from 'Dashboard/src/helpers/withRouter';
import { useGetSitesQuery } from 'common/api/apiSlice';
import { isEmpty, map } from 'lodash-es';

function ToolGoogleVisibility(props) {
  const { params: { instanceId } } = props;
  const [siteId, setSiteId] = useState(null);
  const [model, setModel] = useState(2);
  const [data, setData] = useState([]);

  const { data: sites } = useGetSitesQuery(instanceId, { skip: isEmpty(instanceId) });
  const [runReport] = useLazyRunReportQuery();

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Settings',
      url: replacePathParams(mainRoutes.setting, [], props),
    },
    {
      name: 'Tools',
      url: replacePathParams(settingRoutes.tools, [], props),
    },
    { name: 'Google Visibility Report', url: '' },
  ];

  const siteOptions = map(sites, (site) => ({
    label: site.label || '<no domain>',
    value: site.id,
  }));

  const modelOptions = [
    { value: 1, label: 'Old' },
    { value: 2, label: 'New' },
  ];

  useEffect(() => {
    if (!sites || !siteId) {
      return;
    }

    const params = {

      instance_id: sites.find((site) => site.id == siteId).instance_id,
      site_id: siteId,
      report: 'Reports::GoogleVisibility',
      start: '2010-01-01',
      end: `${(new Date()).getFullYear()}-${(new Date()).getMonth() + 1}-01`,
      model,
    };

    runReport(params).then((response) => {
      setData(response.data.data);
    });
  }, [siteId, model]);

  const formatDay = (month) => DateTime.fromJSDate(new Date(month[0])).toLocaleString(DateTime.DATE_MED);

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Google Visibility Report',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card>
        <CardBody>
          <UserFeaturePage feature="misc_tools">
            <Form>
              <FormGroup>
                <Label>
                  Site:
                </Label>
                <CustomSelect
                  onChange={(e) => setSiteId(e.value)}
                  options={siteOptions}

                  value={siteOptions.find((o) => o.value == siteId)}
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  Model:
                </Label>
                <CustomSelect
                  onChange={(e) => setModel(e.value)}
                  options={modelOptions}
                  value={modelOptions.find((o) => o.value === model)}
                />
              </FormGroup>
            </Form>

            <Line
              height={80}
              data={{
                labels: data.map(formatDay),
                datasets: [
                  {
                    label: 'Visibility Score (Left)',
                    yAxisID: 'y1',
                    backgroundColor: '#45afd0',
                    borderColor: '#45afd0',
                    data: data.map((month) => month[1]),
                    pointBackgroundColor: data.map((month) => month[3] ? 'green' : 'red'),
                    pointRadius: data.map((month) => month[3] ? 4 : 6),
                    pointStyle: data.map((month) => month[3] ? 'circle' : 'triangle'),
                  },
                  {
                    label: 'Visibility Percentage (Right)',
                    yAxisID: 'y2',
                    backgroundColor: '#58bc6d',
                    borderColor: '#58bc6d',
                    data: data.map((month) => month[2]),
                    pointBackgroundColor: data.map((month) => month[3] ? 'green' : 'red'),
                    pointRadius: data.map((month) => month[3] ? 4 : 6),
                    pointStyle: data.map((month) => month[3] ? 'circle' : 'triangle'),
                  },
                ],
              }}
              options={{
                scales: {
                  y1: {
                    position: 'left',
                  },
                  y2: {
                    position: 'right',
                    min: 0,
                    max: 100,
                  },
                },
              }}
            />
          </UserFeaturePage>
        </CardBody>
      </Card>
    </>
  );
}
export default withRouter(ToolGoogleVisibility);
