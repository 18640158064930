import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Modal,
  Label,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import Select from 'react-select';
import { displaySuccess, selectColorStyles } from '../../../../../Utils';
import RadioButton from '../../../components/common/radioButton';
import CheckBox from '../../../components/common/checkBox';
import { isEmpty, get } from 'lodash-es';
import { getPageHierarchy, getSiblingPageOption } from '../../../components/common/PageHierarchy';
import { useDuplicatePageMutation, useGetInstanceDataQuery } from '../../../../../common/api/apiSlice';
import { useInstance } from '../../../../../common/hooks';

const initialState = {
  position: 'after',
  orphanPage: false,
  siblingPage: null,
  siblingPageOption: [],
  copyChildPages: false,
};

function DuplicatePageModal(props) {
  const {
    toggle,
    setModal,
    siteId,
    page,
    onSuccessDuplicatePage,
  } = props;

  const [params, setParams] = useState(initialState);
  const [pageData, setPageData] = useState([]);

  // Bit annoying... I want to have a state variable with the same name as a prop.

  const [parentId, setParentId] = useState(props.parentId || null);

  const instance = useInstance();

  const instanceId = get(instance, 'id', '');

  const { data } = useGetInstanceDataQuery(instanceId, { skip: isEmpty(instanceId) });

  const [duplicatePage] = useDuplicatePageMutation();

  useEffect(() => {
    if (!isEmpty(data)) {
      // Some sites have numeric IDs, and comparing with === doesn't work because the URL parameter is a string.

      const siteData = data.filter((item) => item.id == instanceId)[0].sites;
      // Some sites have numeric IDs, and comparing with === doesn't work because the URL parameter is a string.

      setPageData(siteData.filter((item) => item.id == siteId)[0].pages);
    }
  }, [data]);

  useEffect(() => {
    if (pageData.length === 0) {
      return;
    }

    setParentId(pageData[0].id);
  }, [pageData]);

  useEffect(() => {
    if (!parentId) {
      return;
    }

    const getOpt = getSiblingPageOption(parentId, pageData);

    setParams({
      ...params,
      siblingPageOption: getOpt,
      siblingPage: getOpt.length > 0 ? getOpt[0].value : null,
    });
  }, [parentId]);

  const onHandleSubmit = async () => {
    const {
      position,
      orphanPage,
      siblingPage,
      copyChildPages,
    } = params;

    const payload = {
      position,
      id: page.id,
      site_id: siteId,
      parent_id: parentId,
      orphan_page: orphanPage,
      instance_id: instanceId,
      sibling_page: siblingPage,
      copy_child_pages: copyChildPages,
    };

    const response = await duplicatePage(payload);
    if (response && response?.data?.meta?.is_success) {
      onSuccessDuplicatePage();
      setParams(initialState);
      toggle();
      displaySuccess(response?.data?.meta?.messages);
    }
  };

  const getSelectedOption = (options, selected) => options.filter(
    (opt) => opt.value === selected,
  )[0];

  const {
    position,
    orphanPage,
    siblingPage,
    copyChildPages,
    siblingPageOption,
  } = params;

  const pageOptionData = getPageHierarchy(pageData, '');

  return (
    <Modal isOpen={setModal} toggle={toggle} autoFocus={false}>
      <ModalHeader toggle={toggle}>
        <b>Duplicate Page</b>
      </ModalHeader>
      <ModalBody>
        <Row style={{ color: '#000' }}>
          <Col lg={12}>
            <RadioButton
              title="Make this page part of the navigation system:"
              checked={!orphanPage}
              onHandleChange={() => setParams({ ...params, orphanPage: false })}
            />
            {!orphanPage && (
              <Col lg={12}>
                <Select
                  placeholder="Select parent of new page position"
                  onChange={(e) => setParentId(e.value)}
                  options={pageOptionData}
                  className="mb-2"
                  value={getSelectedOption(pageOptionData, parentId)}
                  styles={selectColorStyles}
                />
                {siblingPageOption.length > 0 && (
                  <>
                    <Label><b>Select placement of new page position:</b></Label>
                    <div>
                      <RadioButton
                        title="After"
                        checked={position === 'after'}
                        onHandleChange={() => setParams({ ...params, position: 'after' })}
                      />
                      {' '}
                      <RadioButton
                        title="Before"
                        checked={position === 'before'}
                        onHandleChange={() => setParams({ ...params, position: 'before' })}
                      />
                    </div>
                    <Select
                      placeholder="#"
                      onChange={(e) => setParams({ ...params, siblingPage: e.value })}
                      options={siblingPageOption}
                      className="mb-2"
                      value={getSelectedOption(siblingPageOption, siblingPage)}
                      styles={selectColorStyles}
                    />
                  </>
                )}
                <CheckBox
                  title="Copy child pages"
                  checked={copyChildPages}
                  onChange={(e) => setParams({ ...params, copyChildPages: e.target.checked })}
                />
              </Col>
            )}
            <RadioButton
              title="Make this page an Orphan page."
              checked={orphanPage}
              onHandleChange={() => {
                setParams({ ...params, orphanPage: true });
                setParentId(null);
              }}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={toggle} type="submit">
          <b>Cancel</b>
        </Button>
        <Button
          color="success"
          onClick={onHandleSubmit}
        >
          <b>Save</b>
        </Button>
      </ModalFooter>
    </Modal>
  );
}
export default DuplicatePageModal;
