import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetSiteBriefingsQuery,
  useCreateSiteBriefingMutation,
  useDeleteSiteBriefingMutation,
  useGenerateInstanceFromSiteBriefingMutation,
  useGenerateContentFromSiteBriefingMutation,
} from '../../../../../common/api/apiSlice';
import { Row, Card, CardBody, Button } from 'reactstrap';
import CustomButton from '../../../components/common/button';
import { setPageTitle, displaySuccess, displayError } from '../../../../../Utils';
import { useUser } from '../../../../../common/hooks';
import UserFeaturePage from '../../../components/common/user-feature-page';
import UserFeatureComponent from '../../../components/common/user-feature-component';
import HeaderComponent from '../../../components/common/header-component';
import CustomSelect from '../../../components/common/custom-select';
import { DateTime } from 'luxon';
import { dateTimeString } from '../../../components/common/DateTimeFormat';
import confirm from '../../../components/common/confirm';
import { mainRoutes, settingRoutes, replacePathParams } from '../../../constants/routes';
import withRouter from '../../../helpers/withRouter';
import BottomActionToolbar from '../../../components/common/toolbar';
import { get } from 'lodash-es';
import Pagination from '../../../components/common/custom-pagination';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

function SiteBriefingList(props) {
  const navigate = useNavigate();
  const { pageNo } = useParams();
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState('updated_at');
  const [orderDirection, setOrderDirection] = useState('desc');

  const sendParams = {
    page_size: pageSize,
    page: pageNo,
    order_by: orderBy,
    order_direction: orderDirection,
  };

  const { data } = useGetSiteBriefingsQuery(sendParams);
  const briefings = get(data, 'briefings', []);
  const pagination = get(data, 'pagination', {});

  const [createSiteBriefing] = useCreateSiteBriefingMutation();
  const [deleteBriefing] = useDeleteSiteBriefingMutation();
  const [generateInstance] = useGenerateInstanceFromSiteBriefingMutation();
  const [generateContent] = useGenerateContentFromSiteBriefingMutation();

  const user = useUser();

  useEffect(() => {
    setPageTitle('Site Briefing List');
  }, []);

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Settings',
      url: replacePathParams(mainRoutes.setting, [], props),
    },
    { name: 'Briefings', url: '' },
  ];

  const headerButton = (title, key) => (
    <Button
      onClick={() => {
        setOrderBy(key);
        setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
      }}
      className="custom-simple-txt-btn fw-bold"
    >
      <span>
        {`${title} `}
        {orderBy === key
          ? (
              <i
                className={` ${orderDirection === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`}
              />
            )
          : ''}
      </span>
    </Button>
  );

  const tableColumns = [
    {
      header: headerButton('Instance Created', 'site_id'),
      id: 'instance_created',
      accessorFn: (b) => b.site_id,
      cell: ({ getValue }) => {
        const value = getValue();
        return (
          <>
            {value
              ? (
                  <span style={{ fontSize: 14, color: 'green' }}>✓</span>
                )
              : (
                  <span style={{ fontSize: 14, color: 'red' }}>✗</span>
                )}
          </>
        );
      },
    },
    {
      header: 'Instance Created At',
      id: 'instance_created_at',
      accessorFn: (b) => b.site_created_at,
      cell: ({ getValue }) => {
        const value = getValue();
        return value ? dateTimeString(value, user?.time_zone, DateTime.DATE_SHORT) : '';
      },
    },
    {
      header: headerButton('Content Created', 'content_created'),
      id: 'content_created',
      accessorFn: (b) => b.content_created,
      cell: ({ getValue }) => {
        const value = getValue();
        return (
          <>
            {value
              ? (
                  <span style={{ fontSize: 14, color: 'green' }}>✓</span>
                )
              : (
                  <span style={{ fontSize: 14, color: 'red' }}>✗</span>
                )}
          </>
        );
      },
    },
    {
      header: headerButton('Company Name', 'info.company_name'),
      accessorKey: 'info.company_name',
    },
    {
      header: headerButton('Domain', 'info.temp_domain'),
      id: 'domain',
      accessorFn: (b) =>
        b.info.temp_domain ? `www.${b.info.temp_domain}.wb.gs` : '',
    },
    {
      header: 'Project Manager',
      accessorKey: 'project_manager_name',
    },
    {
      header: 'Adviser',
      accessorKey: 'adviser_name',
    },
    {
      header: headerButton('Brief Date', 'info.brief_date'),
      id: 'brief_date',
      accessorFn: (b) => b.info.brief_date,
      cell: ({ getValue }) =>
        dateTimeString(getValue(), user?.time_zone, DateTime.DATE_SHORT),
    },
    {
      header: headerButton('Updated', 'updated_at'),
      id: 'updated_at',
      accessorFn: (b) => b.updated_at,
      cell: ({ getValue }) =>
        dateTimeString(getValue(), user?.time_zone, DateTime.DATE_SHORT),
    },
    {
      header: 'Actions',
      id: 'actions',
      size: 180,
      cell: ({ row }) => {
        const b = row.original;
        const options = [
          { label: 'Edit', value: 'Edit' },
          { label: 'Delete', value: 'Delete' },
        ];

        if (b.site_id) {
          if (!b.content_created) {
            options.push({ label: 'Generate content', value: 'Generate content' });
          }
        } else {
          options.push(
            { label: 'Generate instance (NO content)', value: 'Generate instance (NO content)' },
            { label: 'Generate instance (WITH content)', value: 'Generate instance (WITH content)' },
          );
        }

        options.push({ label: 'Download PDF', value: 'Download PDF' });

        return (
          <CustomSelect
            options={options}
            onChange={(e) => {
              switch (e.value) {
                case 'Edit':
                  return onEdit(b);
                case 'Delete':
                  return onDelete(b);
                case 'Generate content':
                  return onGenerateContent(b);
                case 'Generate instance (NO content)':
                  return onGenerateInstance({ briefing: b, content: false });
                case 'Generate instance (WITH content)':
                  return onGenerateInstance({ briefing: b, content: true });
                case 'Download PDF':
                  window.location.assign(`/api/v1/briefings/${b.id}/pdf`);
                  break;
                default:
                  return null;
              }
            }}
          />
        );
      },
    },
  ];

  const onEdit = (briefing) => {
    navigate(replacePathParams(settingRoutes.siteBriefingForm, [{ key: 'id', value: briefing.id }], props));
  };

  const onDelete = async (briefing) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: <p>Are you sure?</p>,
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      const res = await deleteBriefing(briefing);
      if (res && res?.data?.meta?.is_success) {
        displaySuccess(res?.data?.meta?.messages);
      }
    }
  };

  const onGenerateInstance = async (options) => {
    const confirmed = await confirm({
      title: <b>Confirm!</b>,
      message: <p>Are you sure?</p>,
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (confirmed) {
      const result = await generateInstance(options);
      if (result.error) {
        displayError(`Errors in instance generation:\n\n${Object.keys(result.error.data.data).map((k) => result.error.data.data[k])}`);
      } else {
        displaySuccess('Instance generation started.');
      }
    }
  };

  const onGenerateContent = async (briefing) => {
    const confirmed = await confirm({
      title: <b>Confirm!</b>,
      message: <p>Are you sure?</p>,
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (confirmed) {
      const result = await generateContent(briefing);
      if (result.error) {
        displayError(`Errors in content generation:\n\n${Object.keys(result.error.data.data).map((k) => result.error.data.data[k])}`);
      } else {
        displaySuccess('Content generation started.');
      }
    }
  };

  const onChangePage = (e) => {
    navigate(
      replacePathParams(
        settingRoutes.siteBriefings,
        [
          { key: 'pageNo', value: e },
        ],
        props,
      ),
    );
  };

  const changePageSize = (size) => {
    setPageSize(size);
  };

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Briefings',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Settings',
        backToPath: replacePathParams(mainRoutes.setting, [], props),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      {pagination && pagination?.count && (
        <Pagination
          type={['brief', 'briefs']}
          currentPage={Number(pageNo)}
          totalRecord={pagination.count}
          perPage={pagination.per_page}
          totalPages={pagination.pages}
          records={briefings}
          onChangePage={onChangePage}
          getPagination={pagination}
          pageSize={pageSize}
          changePageSize={changePageSize}
        />
      )}
      <UserFeaturePage feature={['view_briefings', 'create_edit_briefings']}>
        <UserFeatureComponent feature="view_briefings">
          <Row className="SiteBriefing">
            <Card className="mb-4">
              <CardBody>
                <ReactTable8
                  columns={tableColumns}
                  data={briefings || []}
                />
              </CardBody>
            </Card>
          </Row>
        </UserFeatureComponent>

        <UserFeatureComponent feature="create_edit_briefings">
          <BottomActionToolbar
            component={(
              <CustomButton
                color="info"
                className="add-new-button button-md fw-bold"
                title="ADD BRIEFING"
                icon="fa fa-plus"
                onClick={createSiteBriefing}
              />
            )}
          />
        </UserFeatureComponent>
      </UserFeaturePage>
    </>
  );
}

export default withRouter(SiteBriefingList);
