import React, { useEffect, useMemo, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Label,
  Input,
} from 'reactstrap';
import { isEmpty, isEqual } from 'lodash-es';
import { displayError } from '../../../../../Utils';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

const disallowMetaTags = ['description', 'viewport', 'robots', 'og:url'];

const parseMetaTag = (str) => {
  let parser = new DOMParser();
  const parseMeta = parser.parseFromString(str, 'text/html');
  return parseMeta.getElementsByTagName('meta');
};

const PageMetaTags = (props) => {
  const { page, setPage } = props;

  const [metaTags, setMetaTags] = useState([]);

  useEffect(() => {
    if (!isEmpty(page?.meta_tags)) {
      const newMetaTags = Object.keys(page.meta_tags).map((e) => ({
        name: e,
        content: page.meta_tags[e],
      }));

      if (!isEqual(metaTags, newMetaTags)) {
        setMetaTags(newMetaTags);
      }
    }
  }, [page]);

  useEffect(() => {
    const updatedMetaTags = Object.fromEntries(
      metaTags.map((tag) => [tag.name, tag.content]),
    );
    if (!isEqual(page.meta_tags, updatedMetaTags)) {
      setPage({ ...page, meta_tags: updatedMetaTags });
    }
  }, [metaTags]);

  const onAddMetaTag = () => {
    if (
      !metaTags.some((tag) => tag.name === '' || tag.content === '')
      && !metaTags.some((tag) => disallowMetaTags.includes(tag.name))
    ) {
      setMetaTags([...metaTags, { name: '', content: '' }]);
    } else {
      displayError('Please enter a valid meta tag name and content.');
    }
  };

  const onChangeMetaTagsValue = (e, index) => {
    const { value, name } = e.target;

    if (disallowMetaTags.includes(value)) {
      displayError(
        `You cannot add "${value}" meta tag as it is automatically added by the system.`,
      );
      return;
    }

    setMetaTags((prevMetaTags) => {
      const newMetaTags = [...prevMetaTags];
      if (newMetaTags[index][name] !== value) {
        newMetaTags[index][name] = value;
      }
      return newMetaTags;
    });
  };

  const onRemoveMetaTag = (index) => {
    setMetaTags((prevMetaTags) => {
      const newMetaTags = [...prevMetaTags];
      newMetaTags.splice(index, 1);
      return newMetaTags;
    });
  };

  const metaTagsColumns = useMemo(() => [
    {
      id: 'name',
      header: 'Name',
      cell: ({ row, column }) => (
        <Input
          autoFocus={column.id === 'name'}
          type="text"
          name="name"
          value={row.original.name || ''}
          onChange={(e) => onChangeMetaTagsValue(e, row.index)}
        />
      ),
    },
    {
      id: 'content',
      header: 'Content',
      cell: ({ row }) => (
        <Input
          type="text"
          name="content"
          value={row.original.content || ''}
          onChange={(e) => onChangeMetaTagsValue(e, row.index)}
        />
      ),
    },
    {
      id: 'action',
      header: '',
      cell: ({ row }) => (
        <Button
          className="custom-simple-icon"
          color="danger"
          size="xs"
          onClick={() => onRemoveMetaTag(row.index)}
        >
          <i className="fa-solid fa-minus" />
        </Button>
      ),
    },
  ], []);

  const onPaste = (e) => {
    e.preventDefault();

    const getMeta = parseMetaTag(e.clipboardData.getData('text'));

    if (isEmpty(getMeta)) {
      displayError('Could not parse meta tag.');
    } else {
      const key = getMeta[0].name || getMeta[0].getAttribute('property');

      if (disallowMetaTags.includes(key)) {
        displayError(`You can not add ${key} meta tag as this tag automatically added by the system.`);
      } else {
        setMetaTags([
          ...metaTags,
          { name: key, content: getMeta[0].content },
        ]);
      }
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h2>Meta Tags</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactTable8 data={metaTags} columns={metaTagsColumns} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className="ms-2 common-success-button button-md btn-success"
            color="success"
            onClick={onAddMetaTag}
          >
            <i className="fa-solid fa-plus" />
            {' Add meta tag'}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="mt-3">
          <Label className="w-100">
            Or, paste the full meta tag here:
            <Input onChange={(e) => e.preventDefault()} onPaste={onPaste} />
          </Label>
        </Col>
      </Row>
    </Container>
  );
};

export default PageMetaTags;
