import React from 'react';
import { ReactSortable } from 'react-sortablejs';

export default function DragDropComponent(props) {
  const { list, onSetList, params = {}, keyName, returnData, APICall, setNewState } = props;

  const onDragDrop = async (e) => {
    const oldIndex = e.oldDraggableIndex;
    const newIndex = e.newDraggableIndex;
    // Ignore drag/drop if the position has not changed.
    if (e.oldDraggableIndex === e.newDraggableIndex) {
      return;
    }

    const tempArr = [...list];
    const element = tempArr.splice(oldIndex, 1)[0];
    tempArr.splice(newIndex, 0, element);

    setNewState(tempArr);

    if (typeof APICall === 'function') {
      const fieldIdsArray = [];
      tempArr.map((ele) => fieldIdsArray.push({ id: ele.id }));

      const sendParams = {
        ...params,
        [keyName]: fieldIdsArray,
      };
      await APICall(sendParams);
    }
  };

  return (
    <ReactSortable
      {...props}
      list={list}
      onEnd={(e) => onDragDrop(e)}
      setList={(newState) => onSetList ? onSetList(newState) : null}
    >
      {returnData}
    </ReactSortable>
  );
}
