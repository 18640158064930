import Base from './Base';

type SectionTypes =
  | 'SectionContent'
  | 'SectionComponent'
  | 'SectionColumn'
  | 'SectionForm'
  | 'SectionImageGallery'
  | 'SectionMirror'
  | 'SectionReviewList'
  | 'SectionReviewForm'
  | 'SectionCustom';

interface BPStyles {
  [key: string]: string[];
}

interface IVars {
  [key: string]: string;
}

export interface BreakPoint {
  id?:              string;
  all_styles:       BPStyles;
  desktop_styles:   BPStyles;
  tablet_styles:    BPStyles;
  phone_styles:     BPStyles;
  object_type?:     string;
  object_id?:       string;
  site_id?:         string;
  element?:         string;
  sector?:          any[];
  template_ids?:    any[];
}

export interface SectionProps {
  id?:                       string | number;
  type?:                     SectionTypes;
  page_id?:                  string | number;
  page_name?:                string;
  page_version_id?:          string | number;
  sector?:                   string;
  sector_color?:             string;
  order?:                    number;
  is_offline?:               boolean;
  width?:                    null;
  visible_to?:               string;
  origin_id?:                string;
  initial_visibility?:       boolean;
  url?:                      string;
  notes?:                    string;
  css_classes?:              any[];
  column_section?:           null;
  content?:                  string;
  component_id?:             null | string | number;
  html_vars?:                IVars;
  scss_vars?:                IVars;
  layout?:                   string;
  desktop_layout?:           any[];
  tablet_layout?:            any[];
  phone_layout?:             any[];
  column_sections?:          ColumnSectionElement[];
  send_email?:               boolean;
  email_addresses?:          any[];
  email_subject_line?:       string;
  send_sms?:                 boolean;
  mobile_number?:            string;
  add_to_database?:          boolean;
  redirect_to_page?:         boolean;
  button_text?:              string;
  submit_button_type?:       string;
  field_label_position?:     string;
  redirect_page_id?:         null | string | number;
  button_image_id?:          null | string | number;
  button_hover_image_id?:    null | string | number;
  button_style_id?:          null | string | number;
  button_style_css?:         null;
  button_image_url?:         null;
  button_hover_image_url?:   null;
  enable_phone_breakpoint?:  boolean;
  enable_tablet_breakpoint?: boolean;
  include_privacy_policy?:   boolean;
  privacy_policy_color?:     string;
  email_series_ids?:         any[];
  display_dialog_box?:       boolean;
  dialog_box_message?:       string;
}

interface ColumnSectionColumnSection {
  id:    string;
  order: number;
}

interface ColumnSectionElement {
  id:                 string | number;
  type:               SectionTypes;
  page_id:            string | number;
  page_name:          string;
  page_version_id:    string | number;
  sector:             string;
  sector_color:       string;
  order:              number;
  is_offline:         boolean;
  width:              null;
  visible_to:         string;
  origin_id:          string;
  initial_visibility: boolean;
  url:                string;
  notes:              string;
  css_classes:        any[];
  column_section:     ColumnSectionColumnSection;
  break_point:        BreakPoint;
  text_blocks:        any[];
}

class Section extends Base {
  id?:                       string | number;
  instance_id?:              string | number;
  type?:                     SectionTypes;
  page_id?:                  string | number;
  page_name?:                string;
  page_version_id?:          string | number;
  sector?:                   string;
  sector_color?:             string;
  order?:                    number;
  is_offline?:               boolean;
  width?:                    null;
  visible_to?:               string;
  origin_id?:                string;
  initial_visibility?:       boolean;
  url?:                      string;
  notes?:                    string;
  css_classes?:              any[];
  column_section?:           null;
  content?:                  string;
  component_id?:             null | string | number;
  html_vars?:                IVars;
  scss_vars?:                IVars;
  layout?:                   string;
  desktop_layout?:           any[];
  tablet_layout?:            any[];
  phone_layout?:             any[];
  column_sections?:          ColumnSectionElement[];
  send_email?:               boolean;
  email_addresses?:          any[];
  email_subject_line?:       string;
  send_sms?:                 boolean;
  mobile_number?:            string;
  add_to_database?:          boolean;
  redirect_to_page?:         boolean;
  button_text?:              string;
  submit_button_type?:       string;
  field_label_position?:     string;
  redirect_page_id?:         null | string | number;
  button_image_id?:          null | string | number;
  button_hover_image_id?:    null | string | number;
  button_style_id?:          null | string | number;
  button_style_css?:         null;
  button_image_url?:         null;
  button_hover_image_url?:   null;
  enable_phone_breakpoint?:  boolean;
  enable_tablet_breakpoint?: boolean;
  include_privacy_policy?:   boolean;
  privacy_policy_color?:     string;
  email_series_ids?:         any[];
  display_dialog_box?:       boolean;
  dialog_box_message?:       string;
  break_point?:              BreakPoint;

  constructor(props: SectionProps) {
    super(props);
    Object.assign(this, props);
  }
}

export default Section;
