import { map } from 'lodash-es';

export const getPageHierarchy = (childrenArray, name = '', result = []) => {
  map(childrenArray, (d) => {
    if (typeof d.name === 'undefined' && d.page_version) {
      d.name = d.page_version.name;
    }

    result.push({
      value: d.id,
      label: name + d.name,
    });
    if ('children' in d && d.children.length) {
      getPageHierarchy(d.children, `${name + d.name} >> `, result);
    }
    return '';
  });
  return result;
};

export const getPageSections = (data, sections = []) => {
  map(data, (d) => {
    if (undefined !== d.sections && d.sections.length > 0) {
      sections.push(d);
    }
    if ('children' in d && d.children.length) {
      getPageSections(d.children, sections);
    }
    return '';
  });
  return sections;
};

export const getSiteTree = (data, treeArr = []) => {
  map(data, (d) => {
    treeArr.push({
      id: d.id,
      children: getSiteTree(d.children),
    });
  });
  return treeArr;
};

export const getSiblingPageOption = (id, data, siblingArr = []) => {
  map(data, (d) => {
    if (d.id === id && 'children' in d) {
      map(d.children, (e) => {
        siblingArr.push({
          label: e.name,
          value: e.id,
        });
      });
    }
    if ('children' in d && d.children.length) {
      getSiblingPageOption(id, d.children, siblingArr);
    }
  });
  return siblingArr;
};
