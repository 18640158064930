import CustomSelect from '../../../components/common/custom-select';
import { getSelectedOption } from '../../../../../Utils';
import { get, map } from 'lodash-es';
import React from 'react';
import {
  Label,
  FormGroup,
} from 'reactstrap';

const selectOptionArr = (options) => options.map((opt) => ({ label: opt.name, value: opt.name }));

function LibraryVersions(props) {
  const { params, setParams } = props;

  return (
    map(get(params, 'libraries', []), (library, rowIndex) => (
      <FormGroup key={rowIndex}>
        <Label for={rowIndex}>{rowIndex}</Label>
        <CustomSelect
          isClearable
          options={selectOptionArr(library)}
          isSearch={false}
          className="flex-grow-1"
          value={getSelectedOption(selectOptionArr(library), params.library_version[rowIndex])}
          onChange={(e) => {
            setParams({ ...params, library_version: { ...params.library_version, [rowIndex]: e ? e.value : e } });
          }}
        />
      </FormGroup>
    ))
  );
}

export default LibraryVersions;
