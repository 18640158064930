import React from 'react';
import {
  Input,
  Label,
  FormGroup,
} from 'reactstrap';

function SMSName({ params, setParams }) {
  return (
    <FormGroup>
      <Label for="sms_name">SMS Name</Label>
      <Input
        type="text"
        value={params.sms_name || ''}
        onChange={(e) => {
          const { value } = e.target;
          setParams({ ...params, sms_name: value });
        }}
      />
    </FormGroup>
  );
}

export default SMSName;
