/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const searchPath = '/app/pages/search';
export const servicePath = 'https://api.coloredstrategies.com';
export const defaultDirection = 'ltr';
export const defaultTheme = 'light';
export const themeStorageKey = '__theme_color';
