import React, { Fragment } from 'react';
import {
  Label,
  FormGroup,
  InputGroup,
  Input,
  Button,
} from 'reactstrap';
import { cloneDeep } from 'lodash-es';
import { products } from '../../../../../common/schema';
import Switch from 'rc-switch';
import { getSelectedOption } from '../../../../../Utils';
import CustomSelect from '../../common/custom-select';
import './Products.scss';

function Products(props) {
  const { params, setParams } = props;

  const options = products.map((p) => ({ label: p, value: p }));

  return (
    <FormGroup className="product-list">
      <table className="w-100 mb-2">
        <tbody>
          {params.products?.map((product, i) => {
            // We don't want to allow adding the same product twice.
            const thisOptions = options.filter((o) => {
              if (o.value === product.name) {
                return true;
              }

              if (!params.products.some((p) => p.name === o.value)) {
                return true;
              }
            });

            const isSEO = [
              'SEO Pro',
              'SEO Pro FREE',
              'SEO Premium',
              'SEO Total',
            ].indexOf(product.name) > -1;

            return (
              <Fragment key={product.name}>
                <tr>
                  <td className="py-2">
                    <CustomSelect
                      options={thisOptions}
                      isSearch={false}
                      className="me-2"
                      value={getSelectedOption(options, product.name)}
                      onChange={(e) => {
                        const tempProducts = cloneDeep(params.products);
                        tempProducts[i].name = e.value;
                        setParams({ ...params, products: tempProducts });
                      }}
                    />
                  </td>
                  <td className="py-2 text-end">
                    <Button
                      color="danger"
                      size="xs"
                      onClick={() => {
                        const tempProducts = cloneDeep(params.products);
                        tempProducts.splice(i, 1);
                        setParams({ ...params, products: tempProducts });
                      }}
                    >
                      <i className="fal fa-trash" />
                    </Button>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2" className="ps-4">
                    <FormGroup>
                      <Label>Go-Live Date</Label>
                      <InputGroup>
                        <Input
                          type="date"
                          style={{ maxWidth: '200px' }}
                          value={product.go_live_date || ''}
                          onChange={(e) => {
                            const tempProducts = cloneDeep(params.products);
                            tempProducts[i].go_live_date = e.target.value;
                            setParams({ ...params, products: tempProducts });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>

                    {isSEO && (
                      <>
                        <FormGroup>
                          <Label>
                            Guarantee waived?
                          </Label>
                          <Switch
                            className="custom-switch custom-switch-primary"
                            checked={product.guarantee_waived}
                            onChange={(e) => {
                              const tempProducts = cloneDeep(params.products);
                              tempProducts[i].guarantee_waived = e;
                              setParams({ ...params, products: tempProducts });
                            }}
                          />
                        </FormGroup>
                      </>
                    )}
                  </td>
                </tr>

                <tr>
                  <td colSpan="2" className="border-bottom" />
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
      <Button
        color="info"
        onClick={() => {
          setParams({
            ...params,
            products: [...(params.products || []), {}],
          });
        }}
      >
        Add product
      </Button>
    </FormGroup>
  );
}

export default Products;
