import React from 'react';
import { camelCase } from 'lodash-es';
import { getColorVal } from '../../Dashboard/src/components/common/breakpoint';
import iconList from 'Dashboard/src/icons-trimmed.json';

const special = ['icon', 'icon-space'];

export const inline = (button, text) => {
  const map = (att) => {
    const props = {
      'margin-horizontal': ['margin-left', 'margin-right'],
      'margin-vertical': ['margin-top', 'margin-bottom'],
      'padding-horizontal': ['padding-left', 'padding-right'],
      'padding-vertical': ['padding-top', 'padding-bottom'],
    };
    return props[att] || [att];
  };

  const css = {};
  Object.keys(button.css || {}).forEach((k) => {
    if (special.indexOf(k) > -1) return;
    map(k).forEach((att) => {
      css[camelCase(att)] = button.css[k];
    });
  });

  return (
    <button
      type="button"
      style={css}
      dangerouslySetInnerHTML={{ __html: `<span class=button-inner>${text}</span>` }}
    />
  );
};

export const preview = (button, presetColors) => {
  const map = (att, val) => {
    const props = {
      'margin-horizontal': ['margin-left', 'margin-right'],
      'margin-vertical': ['margin-top', 'margin-bottom'],
      'padding-horizontal': ['padding-left', 'padding-right'],
      'padding-vertical': ['padding-top', 'padding-bottom'],
    };
    return props[att] ? props[att].map((a) => `${a}:${val};`).join('') : `${att}:${val};`;
  };

  const iconFont = (family) => {
    switch (family) {
      case 'sharp':
        return 'Font Awesome 6 Sharp';
      case 'brands':
        return 'Font Awesome 6 Brands';
      case 'classic':
      default:
        return 'Font Awesome 6 Pro';
    }
  };

  const iconStyle = (style) => {
    switch (style) {
      case 'solid':
        return 900;
      case 'light':
        return 300;
      case 'thin':
        return 100;
      case 'regular':
      default:
        return 400;
    }
  };

  const iconRegex = /^fa-([^\s]*) fa-([^\s]*)(\s+fa-([^\s]*))?$/;

  let css = `.button-${button.id} {`;
  Object.keys(button.css || {}).forEach((k) => {
    if (special.indexOf(k) > -1) return;
    css += map(k, button.css[k]);
  });
  css += '}';

  if (button.css['background']) {
    css += `.button-${button.id} { background: ${getColorVal(button.css['background'], presetColors)}; }`;
  }

  if (button.css['border-color']) {
    css += `.button-${button.id} {border-color: ${getColorVal(button.css['border-color'], presetColors)}; }`;
  }

  if (button.css['color'] ) {
    css += `.button-${button.id} { color: ${getColorVal(button.css['color'], presetColors)}; }`;
  }

  if (button.css['icon']) {
    const left = button.css['icon-side'] === 'left';
    const matches = button.css['icon'].match(iconRegex);
    if (matches) {
      const style = matches[1];
      const icon = matches[2];
      const family = matches[4] || 'classic';
      if (iconList[icon]) {
        css += `
          .button-${button.id}:${left ? 'before' : 'after'} {
            font-family: "${iconFont(family)}";
            content: "\\${iconList[icon].unicode}";
            padding-${left ? 'right' : 'left'}: ${button.css['icon-space']};
            font-weight: ${iconStyle(style)};
          }
        `;
      }
    }
  }

  css += `.button-${button.id}:hover {`;
  Object.keys(button.hover_css || {}).forEach((k) => {
    if (special.indexOf(k) > -1) return;
    css += map(k, button.hover_css[k]);
  });
  css += '}';

  if (button.hover_css['icon']) {
    const left = (button.hover_css['icon-side'] || button.css['icon-side']) === 'left';
    const matches = (button.hover_css['icon'] || button.css['icon']).match(iconRegex);
    if (matches) {
      const style = matches[1];
      const icon = matches[2];
      const family = matches[4] || 'classic';
      if (iconList[icon]) {
        css += `
          .button-${button.id}:hover:${left ? 'before' : 'after'} {
            font-family: "${iconFont(family)}";
            content: "\\${iconList[icon].unicode}";
            padding-${left ? 'right' : 'left'}: ${button.hover_css['icon-space'] || button.css['icon-space']};
            font-weight: ${iconStyle(style)};
          }
        `;
      }
    }
  }

  if (button.hover_css['background']) {
    css += `.button-${button.id}:hover { background: ${getColorVal(button.hover_css['background'], presetColors)}; }`;
  }

  if (button.hover_css['border-color']) {
    css += `.button-${button.id}:hover {border-color: ${getColorVal(button.hover_css['border-color'], presetColors)}; }`;
  }

  if (button.hover_css['color'] ) {
    css += `.button-${button.id}:hover { color: ${getColorVal(button.hover_css['color'], presetColors)}; }`;
  }

  return (
    <>
      <style>
        {css}
      </style>
      <button type="button" className={`button-${button.id}`}>
        {button?.name || 'More Info'}
      </button>
    </>
  );
};
