import React, { useState } from 'react';
import { isEmpty, filter, get } from 'lodash-es';
import './InstanceSelection.scss';
import withRouter from '../../helpers/withRouter';
import { useGetSitesQuery } from '../../../../common/api/apiSlice';
import { getCookie, setCookie } from '../../../../Utils';
import { useInstance, useIsMobile, useSite } from '../../../../common/hooks';
import { MenuList } from './InstanceSelection';
import CustomSelect from 'Dashboard/src/components/common/custom-select';

function SiteSelection() {
  const [siteSelectorHover, setSiteSelectorHover] = useState(false);

  const instance = useInstance();
  const site = useSite();
  const isMobile = useIsMobile();
  const [hover, setHover] = useState(false);

  let currentSite = site || {};

  const { data: sites } = useGetSitesQuery(instance?.id, { skip: isEmpty(instance) || !instance?.id });

  if (isEmpty(site) && !isEmpty(sites)) {
    const siteId = getCookie('SITE_ID');

    currentSite = sites.filter((e) => e.id == siteId)?.[0];
  }

  if (!sites || sites.length === 0 || !currentSite) {
    return <></>;
  }

  // For the site selector, we want the current site to be at the top of the list.

  const sortedSites = [...sites].sort((s1) => (s1.id == currentSite.id ? -1 : 0));
  const hasMultipleSites = sortedSites.length > 1;

  return (
    <div
      className={`SiteSelection ${isMobile ? 'ms-2' : ''}`}
      onMouseOver={() => {
        if (!isMobile) return;
        setHover(true);
      }}
      onMouseLeave={() => {
        if (!isMobile) return;
        setHover(false);
      }}
    >
      {isMobile
        ? (
            <>
              <span
                style={{
                  cursor: hasMultipleSites ? 'pointer' : 'auto',
                  borderBottom: hasMultipleSites ? '1px dashed' : 'none',
                }}
              >
                {currentSite.label || '(no domain)'}
              </span>
              {hasMultipleSites && (
                <i style={{ color: 'var(--muted-color)' }} className="fa fa-caret-down ms-2" />
              )}

              {hasMultipleSites && hover && (
                <div className="instance-selection-hover-cont">
                  <div className="instance-selection-select-cont">
                    <CustomSelect
                      className="InstanceSelection"
                      autoFocus
                      isSearchable
                      menuPlacement="auto"
                      menuPosition="fixed"
                      menuIsOpen
                      value={{ label: get(currentSite, 'label', ''), value: get(currentSite, 'id', '') }}
                      components={{ MenuList }}
                      filterOption={null}
                      options={sortedSites}
                      onChange={(e) => {
                        setCookie('SITE_ID', e.id, 9999, '/');
                        if (!window.location.href.match(e.id)) {
                          window.location.href = `/dashboards/instance/${instance.id}/site/${e.id}`;
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )
        : (
            <>
              <a
                href={`https://${currentSite.label}`}
                target="_blank"
                onMouseOver={() => setSiteSelectorHover(true)}
                onFocus={() => setSiteSelectorHover(true)}
                rel="noreferrer"
              >
                {currentSite.label || '(no domain)'}
                {' '}
                <i className="fa-light fa-fw fa-up-right-from-square open" />
              </a>
              {siteSelectorHover && sites && sites.length > 1 && (
                <div className="site-selector-list" onMouseLeave={() => setSiteSelectorHover(false)}>
                  <div>
                    <a
                      href={`https://${currentSite.label}`}
                      onClick={(e) => {
                        if (isEmpty(currentSite.label)) {
                          e.preventDefault();
                        }
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {currentSite.label || '(no domain)'}
                      {' '}
                      <i className="fa-light fa-fw fa-up-right-from-square open" />
                    </a>
                  </div>

                  <br />
                  <div>
                    Change site:
                  </div>
                  { }
                  {filter(sortedSites, (s) => s.id != currentSite.id).map((otherSite) => (
                    <div key={otherSite.id}>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();

                          setCookie('SITE_ID', otherSite.id, 9999, '/');

                          if (!window.location.href.match(otherSite.id)) {
                            window.location.href = `/dashboards/instance/${instance.id}/site/${otherSite.id}`;
                          }
                        }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {otherSite.label || '(no domain)'}
                      </a>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
    </div>
  );
}

export default withRouter(SiteSelection);
