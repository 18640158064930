import React, { useState } from 'react';
import { Input, Card, CardBody, Form, FormGroup, Label, Row, Col, Button } from 'reactstrap';
import { Wizard, Steps, Step } from 'react-albus';
import WizardTopNavigation from '../../components/common/wizardNavigations/topNavigation';
import CustomSelect from '../../components/common/custom-select';
import { displayError, displaySuccess, selectColorStyles } from '../../../../Utils';
import { map, get, isEmpty } from 'lodash-es';
import TableComponent from '../../components/common/Table';
import HeaderComponent from '../../components/common/header-component';
import { settingRoutes, mainRoutes, replacePathParams } from '../../constants/routes';
import confirm from '../../components/common/confirm';
import withRouter from '../../helpers/withRouter';
import { useGetDatabaseFieldsQuery, useGetEmailSeriesQuery, useImportMemberMutation, useUploadCSVFileMutation } from '../../../../common/api/apiSlice';

const getFileExtensions = (fileName) => fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;

const ignoreOption = { label: 'Ignore', value: 'ignore' };

function ImportMembers(props) {
  const { params: { instanceId }, navigate } = props;
  const [params, setParams] = useState([]);
  const [currentStep, setStep] = useState('1');
  const [emailOptions, setEmails] = useState([]);
  const [importName, setImportName] = useState('');
  const [selectedFile, setFile] = useState(null);
  const [fieldsName, setFieldsName] = useState([]);
  const [uniqueIdentifier, setUniqueIdentifier] = useState(null);
  const { data: emailSeries = [] } = useGetEmailSeriesQuery(instanceId, { skip: isEmpty(instanceId) });
  const { data: fieldsData = [] } = useGetDatabaseFieldsQuery({ instanceId }, { skip: isEmpty(instanceId) });

  const [uploadCSVFile] = useUploadCSVFileMutation();
  const [importMember] = useImportMemberMutation();

  const topNavClick = (stepItem, push) => {
    push(stepItem.id);
  };

  const onClickPrev = (goToPrev, steps, step) => {
    if (steps.indexOf(step) <= 0) {
      return;
    }
    setStep(step.id - 1);
    goToPrev();
  };

  const importMemberAction = async (resubscribe) => {
    const tempData = params.map((item) => ({ field_name: item.field_name, data_id: item.data_id }));
    const sendParams = {
      data: tempData.filter((item) => item.data_id !== null),
      import_name: importName,
      instance_id: instanceId,
      email_series: emailOptions,
      unique_identifier: uniqueIdentifier,
      resubscribe_members: resubscribe,
    };

    const getEmailField = fieldsData.filter((ele) => ele.attributes.field_type === 'email');
    const checkEmailField = sendParams.data.find((ele) => getEmailField && getEmailField[0].id === ele.data_id);

    if (isEmpty(sendParams.data)) {
      displayError('Please select at least one data field.');
    } else if (isEmpty(checkEmailField)) {
      displayError('The email data field is required.');
    } else {
      setStep('2');
      const res = await importMember(sendParams);
      if (res && res?.data?.meta?.is_success) {
        displaySuccess(res?.data?.meta?.messages);
        navigate(replacePathParams(settingRoutes.backgroundJobs, [
          { key: 'pageNo', value: '1' },
        ], props));
      }
    }
  };

  const onHandleSubmit = async (goToNext, steps, step) => {
    switch (step.id) {
      case '1':
        if (!selectedFile) {
          displayError('Please select a CSV file to import.');
        } else if (!isEmpty(selectedFile) && getFileExtensions(selectedFile.name) !== 'csv') {
          displayError('File must be in CSV format.');
        } else {
          const sendParams = {
            instance_id: instanceId,
            file: selectedFile,
          };

          const res = await uploadCSVFile(sendParams);

          if (res && res?.data?.meta?.is_success) {
            setStep(step.id);
            const { data: { data: { headers, unique_identifier } } } = res;

            const tempArrParams = [];
            const tempArrFields = [];

            headers.forEach((ele) => {
              tempArrParams.push({
                ...ignoreOption,
                field_name: ele,
                data_id: null,
              });
              tempArrFields.push({
                fName: ele,
                dataField: [...fieldsData.map((item) => ({
                  label: get(item, 'attributes.field_name'),
                  value: item.id,
                  field_name: ele,
                })), ignoreOption],
              });
            });

            setParams(tempArrParams);
            setUniqueIdentifier(unique_identifier);
            setFieldsName(tempArrFields);
            step.isDone = true;
            if (steps.length - 1 <= steps.indexOf(step)) {
              return;
            }
            goToNext();
          }
        }
        break;
      default: {
        const result = await confirm({
          title: (<strong>Confirm!</strong>),
          message: 'Are you sure you want to import these members?',
          confirmText: 'Yes',
          confirmColor: 'success',
          cancelColor: 'btn btn-danger',
          cancelText: 'No',
        });

        if (result) {
          importMemberAction(false);
        }
        break;
      }
    }
  };

  const onChangeDataField = (e, rowIndex, fieldName) => {
    const { value, label } = e;
    const tempState = [...params];

    if (params.find((ele) => ele.value === value) && value !== 'ignore') {
      displayError(`${label} data field already taken.`);
      setParams((prevState) => [...prevState, {
        field_name: fieldName, data_id: value, label: null, value: null,
      }]);
    } else if (rowIndex >= 0) {
      tempState[rowIndex].data_id = value;
      tempState[rowIndex].label = label;
      tempState[rowIndex].value = value;
      setParams(tempState);
    } else {
      setParams((prevState) => [...prevState, {
        field_name: fieldName, data_id: value, label, value,
      }]);
    }
  };

  const dataTableColumns = () => [
    {
      Header: 'Field Name',
      id: 'fName',
      disableSortBy: true,
      accessor: (d) => get(d, 'fName', ''),
    },
    {
      Header: 'Data Field',
      id: 'dataField',
      disableSortBy: true,
      accessor: (d, rowIndex) => (
        <CustomSelect
          value={params[rowIndex]}
          styles={selectColorStyles}
          options={get(d, 'dataField', [])}
          onChange={(e) => onChangeDataField(e, rowIndex, d.fName)}
        />
      ),
    },
  ];

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Data Centre',
      url: replacePathParams(mainRoutes.dataCenter, [], props),
    },
    { name: 'Import Members', url: '' },
  ];

  const onChangeEmail = (e) => {
    const setValue = Array.isArray(e) ? e.map((x) => x.value) : [];
    setEmails(setValue);
  };

  const backButton = (previous, step, steps) => {
    if (currentStep >= '2') {
      return '';
    }

    return (
      <Button
        color="primary"
        className={`me-1 ${steps.indexOf(step) <= 0 ? 'disabled' : ''}`}
        onClick={() => { onClickPrev(previous, steps, step); }}
      >
        Back
      </Button>
    );
  };

  const nextButton = (next, step, steps, btnLabel) => (
    <Button color="primary" onClick={() => onHandleSubmit(next, steps, step)}>
      {btnLabel}
    </Button>
  );

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Import Members',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: mainRoutes.dataCenter,
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card className="mb-4">
        <CardBody className="Wizard">
          <Wizard>
            <WizardTopNavigation disableNav topNavClick={topNavClick} className="justify-content-center" />
            <Steps>
              <Step
                id="1"
                name="Step 1"
                render={({
                  next, previous, step, steps,
                }) => (
                  <>
                    <Form>
                      <Row className="mb-3">
                        <Col xxl="4">
                          <Label className="w-100">
                            File must be in CSV format, and the first row must be column headings.
                            <Input
                              type="file"
                              name="fileName"
                              accept="text/csv, application/csv, application/vnd.ms-excel"
                              onChange={(e) => setFile(e.target.files[0])}
                            />
                          </Label>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col xxl="4">
                          <Label className="w-100">
                            Do you want to subscribe these members to an Email Series?
                            <CustomSelect
                              isMulti
                              closeMenuOnSelect={false}
                              styles={selectColorStyles}
                              options={map(emailSeries, (item) => (
                                { label: item.name, value: item.id }
                              ))}
                              onChange={(e) => onChangeEmail(e)}
                            />
                          </Label>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col xxl="4">
                          <Label className="w-100">
                            Give this import a name (optional)
                            <Input type="text" value={importName} onChange={(e) => setImportName(e.target.value)} />
                          </Label>
                        </Col>
                      </Row>
                    </Form>
                    <div className="text-center">
                      {backButton(previous, step, steps)}
                      {nextButton(next, step, steps, 'Next')}
                    </div>
                  </>
                )}
              />
              <Step
                id="2"
                name="Step 2"
                render={({
                  next, previous, step, steps,
                }) => (
                  <>
                    <Form>
                      <FormGroup>
                        <Label className="mb-4">
                          Please select which fields in your database should correspond with the fields in the file.
                          Below is a listing of the first record in the file.
                        </Label>
                        <TableComponent
                          columns={dataTableColumns()}
                          data={fieldsName}
                        />
                      </FormGroup>
                    </Form>
                    <div className="text-center">
                      {backButton(previous, step, steps)}
                      {nextButton(next, step, steps, 'Import')}
                    </div>
                  </>
                )}
              />
            </Steps>
          </Wizard>
        </CardBody>
      </Card>
    </>
  );
}

export default withRouter(ImportMembers);
