import React from 'react';
import {
  Col,
  Row,
  Label,
  FormGroup,
} from 'reactstrap';
import { isEmpty } from 'lodash-es';
import CarbonCopyEmail from '../../../components/common/carbonCopyEmail';

function DefaultFormEmailAddresses(props) {
  const { params, setParams } = props;

  const onHandleEmailChange = (value) => {
    setParams({ ...params, default_form_email_addresses: value });
  };

  if (isEmpty(params)) {
    return;
  }

  return (
    <Row>
      <Col lg={12} md={12}>
        <FormGroup>
          <Label for="default_form_email_addresses">Default Form Email Addresses</Label>
          <CarbonCopyEmail
            placeholder="To"
            id="default_form_email_addresses"
            name="default_form_email_addresses"
            selectedEmails={params.default_form_email_addresses}
            onChange={(e) => onHandleEmailChange(e)}
          />
        </FormGroup>
      </Col>
    </Row>
  );
}

export default DefaultFormEmailAddresses;
