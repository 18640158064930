import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import HeaderComponent from '../../../components/common/header-component';
import { settingRoutes, mainRoutes, replacePathParams } from '../../../constants/routes';
import TableComponent from '../../../components/common/Table';
import confirm from '../../../components/common/confirm';
import { isEmpty, get } from 'lodash-es';
import { displayError, displaySuccess } from '../../../../../Utils';
import Pagination from '../../../components/common/custom-pagination';
import { useNavigate, useParams } from 'react-router-dom';
import DateTimeFormat from '../../../components/common/DateTimeFormat';
import { DateTime } from 'luxon';
import withRouter from '../../../helpers/withRouter';
import UserFeaturePage from '../../../components/common/user-feature-page';
import { useDeleteTrashMutation, useGetTrashesQuery, useRestoreTrashMutation } from '../../../../../common/api/apiSlice';

function Trash(props) {
  const { instanceId, pageNo } = useParams();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(pageNo);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState('deleted_at');
  const [showReplacePopup, setShowReplacePopup] = useState(false);
  const [restoreData, setRestoreData] = useState({});
  const [orderDirection, setOrderDirection] = useState('desc');

  const sendParams = {
    instance_id: instanceId,
    page_size: pageSize,
    page: pageNo,
    order_by: orderBy,
    order_direction: orderDirection,
  };

  const { data: { trashes = [], pagination = {} } = {} } = useGetTrashesQuery(sendParams, { skip: isEmpty(instanceId) });
  const [deleteTrash] = useDeleteTrashMutation();
  const [restoreTrash] = useRestoreTrashMutation();

  const replaceSkip = async () => {
    if (showReplacePopup && !isEmpty(restoreData)) {
      const sendParams = { ...restoreData, replace_record: true };
      const result = await confirm({
        title: (<strong>Confirm!</strong>),
        message: 'Record exists already, Do you want to replace?',
        confirmText: 'Replace',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'Skip',
      });
      if (result) {
        onHandleRestore(sendParams);
      } else {
        setShowReplacePopup(false);
        setRestoreData({});
      }
    }
  };

  useEffect(() => {
    replaceSkip();
  }, [showReplacePopup]);

  const onHandleRestore = async (e) => {
    try {
      let result = await restoreTrash(e);
      const { data: { meta: { is_success: isSuccess, messages } = {} } = {} } = result;
      if (isSuccess) {
        displaySuccess(messages);
      }
      if (result?.data?.meta?.data) {
        setShowReplacePopup(false);
        setRestoreData({});
      } else {
        setShowReplacePopup(true);
        setRestoreData(e);
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const onHandleDelete = async (e) => {
    try {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });
      if (result) {
        const result = await deleteTrash(e);
        const { data: { meta: { is_success: isSuccess, messages } = {} } = {} } = result;
        if (isSuccess) {
          displaySuccess(messages);
        }
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const dataTableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (d) => get(d, 'name', ''),
      disableSortBy: true,
    },
    {
      Header: (
        <Button
          onClick={() => {
            setOrderBy('friendly_name');
            setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Type '}
            {orderBy === 'friendly_name'
              ? (
                  <i
                    className={` ${orderDirection === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`}
                  />
                )
              : ''}
          </span>
        </Button>
      ),
      id: 'friendly_name',
      accessor: (d) => get(d, 'friendly_name', ''),
      disableSortBy: true,
    },
    {
      Header: 'Deleted By',
      id: 'deleted_by',
      accessor: (d) => get(d, 'deleted_by', ''),
      disableSortBy: true,
    },
    {
      Header: (
        <Button
          onClick={() => {
            setOrderBy('deleted_at');
            setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Deleted Date '}
            {orderBy === 'deleted_at'
              ? (
                  <i
                    className={` ${orderDirection === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`}
                  />
                )
              : ''}
          </span>
        </Button>
      ),
      disableSortBy: true,
      id: 'deleted_at',
      accessor: (d) => (
        <DateTimeFormat datetime={get(d, 'deleted_at', '')} localeString={DateTime.DATETIME_SHORT} />
      ),
    },
    {
      Header: 'Action',
      id: 'action',
      accessor: (d) => (
        <>
          <Button className="custom-simple-icon" color="success" size="xs" onClick={() => onHandleRestore(d)}>
            <i className="fal fa-recycle" />
          </Button>
          {' '}
          <Button className="custom-simple-icon" color="danger" size="xs" onClick={() => onHandleDelete(d)}>
            <i className="fal fa-trash" />
          </Button>
        </>
      ),
    },
  ];

  const commonPathParams = (path) => replacePathParams(path, [
    { key: 'instanceId', value: instanceId },
  ], props);

  const onChangePage = (e) => {
    setCurrentPage(e);
    navigate(
      replacePathParams(
        settingRoutes.trash,
        [
          { key: 'pageNo', value: e },
        ], props,
      ),
    );
  };

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: commonPathParams(settingRoutes.dashboard) },
    { name: 'Settings', url: commonPathParams(mainRoutes.setting) },
    { name: 'Trash', url: '' },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Trash',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: commonPathParams(settingRoutes.dashboard),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <UserFeaturePage feature="trash">
        <Card>
          <CardBody>
            <Pagination
              type={['item', 'items']}
              currentPage={Number(currentPage)}
              records={trashes}
              onChangePage={onChangePage}
              getPagination={pagination}
              pageSize={pageSize}
              changePageSize={(e) => setPageSize(e)}
            />
            <Row>
              <Col>
                <TableComponent
                  columns={dataTableColumns}
                  data={trashes}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </UserFeaturePage>
    </>
  );
}

export default withRouter(Trash);
