import React from 'react';
import { useGetMemberEmailLogsQuery } from '../../../../../common/api/apiSlice';
import { isEmpty } from 'lodash-es';
import { useParams } from 'react-router';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DateTime } from 'luxon';
import DateTimeFormat from '../../../components/common/DateTimeFormat';
import TableComponent from '../../../components/common/Table';

const MemberEmailLogs = (props) => {
  const { member, toggle } = props;
  const { instanceId } = useParams();
  const { data } = useGetMemberEmailLogsQuery(
    { member_id: member?.id, instance_id: instanceId },
    { skip: isEmpty(member) },
  );
  const modal = !isEmpty(member);

  const dataTableColumns = [
    {
      Header: 'Time sent',
      id: 'time',
      accessor: (d) => <DateTimeFormat datetime={d.created_at} localeString={DateTime.DATETIME_MED} />,
    },
    {
      Header: 'Type',
      id: 'Type',
      accessor: (d) => {
        switch (d.batch_object_type) {
          case 'BulkEmail':
            return 'Bulk Email';
          case 'EmailSeriesEmail':
            return 'Email Series';
          default:
            return 'Unknown';
        }
      },
    },
    {
      Header: 'Subject',
      id: 'subject',
      accessor: (d) => d.subject,
    },
    {
      Header: 'Opened',
      id: 'opened',
      accessor: (d) => <i className={`fa-solid ${d.opened ? 'fa-check success-green-color' : 'fa-xmark text-danger'}`} />,
    },
  ];

  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Emails sent</ModalHeader>
      <ModalBody>
        <TableComponent columns={dataTableColumns} data={data || []} />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={toggle}>
          <b>CLOSE</b>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MemberEmailLogs;
