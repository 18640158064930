import React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import './index.scss';

function Loader() {
  const spinCount = useSelector((state) => state.spin.spinCount);
  if (spinCount > 0) {
    return (
      <div className="spinner-outer">
        <div className="spinner-inner">
          <Spinner />
        </div>
      </div>
    );
  }
  return '';
}

export default Loader;
