import { Mark, mergeAttributes } from '@tiptap/react';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    CustomStyle: {
      setCustomStyle: (customStyle?: string) => ReturnType;
      unsetCustomStyle: () => ReturnType;
    };
  }
}

export interface CustomStyleOptions {
  HTMLAttributes: Record<string, any>;
}

export const CustomStyle = Mark.create<CustomStyleOptions>({
  name: 'CustomStyle',
  priority: 102,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      style: { default: null },
      customStyle: {
        default: null,
        parseHTML: (el) => (el as HTMLSpanElement).getAttribute('data-custom-style'),
        renderHTML: (attrs) => ({ 'data-custom-style': attrs.customStyle }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-custom-style]',
        getAttrs: (el) => {
          return !!(el as HTMLSpanElement).getAttribute('data-custom-style')?.trim() && null;
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setCustomStyle:
                (customStyle?: string) =>
                  ({ commands }) => {
                    if (customStyle) commands.setMark('CustomStyle', { customStyle });
                    else commands.unsetMark('CustomStyle');
                    return true;
                  },
      unsetCustomStyle:
                () =>
                  ({ commands }) =>
                    commands.unsetMark('CustomStyle'),
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const { state, view } = editor;
        const { $from } = state.selection;
        const markType = state.schema.marks.CustomStyle;
        const hasMark = markType && editor.isActive('CustomStyle');
        const customStyle = editor.getAttributes('CustomStyle').customStyle;

        // Check if the cursor is at the start of a line
        if ($from.parentOffset === 0) {
          if (hasMark) {
            const tr = state.tr;
            const newParagraph = state.schema.nodes.paragraph.create(
              { customStyle },
            );

            tr.insert($from.before(), newParagraph);

            // Dispatch the transaction to update the editor view
            view.dispatch(tr);

            return true;
          }
        }

        return false;
      },
    };
  },
});

export default CustomStyle;
