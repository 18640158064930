import React, { useState, useMemo } from 'react';
import { Card, CardBody, Table, Input, Button } from 'reactstrap';
import withRouter from '../../helpers/withRouter';
import { useKeywordResearchToolMutation } from 'common/api/apiSlice';
import { MaterialReactTable } from 'material-react-table';

interface IKeywordData {
  keywords: string;
  avg: number;
  competition: string;
}

function KeywordResearchTool() {
  const [customerId, setCustomerId] = useState('487-990-1735');
  const [seedKeywords, setSeedKeywords] = useState<string[]>([]);
  const [keywordResearchTool] = useKeywordResearchToolMutation();
  const [results, setResults] = useState({
    metrics: [] as IKeywordData[],
    ideas: [] as IKeywordData[],
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'seed',
        header: 'Seed Keyword',
        filterVariant: 'multi-select' as const,
        filterSelectOptions: seedKeywords,
      },
      {
        accessorKey: 'text',
        header: 'Keywords',
      },
      {
        accessorKey: 'avg',
        Cell: ({ renderedCellValue }) => new Intl.NumberFormat().format(renderedCellValue),
        header: 'Search Volume',
      },
      {
        accessorKey: 'competition',
        header: 'Competition',
        filterVariant: 'multi-select' as const,
        filterSelectOptions: [
          'LOW',
          'MEDIUM',
          'HIGH',
          'UNSPECIFIED',
        ],
      },
    ], [results]);

  return (
    <Card>
      <CardBody>
        <h1>Google Ads Keyword Research Tool</h1>
        <p>Customer ID</p>
        <Input
          type="text"
          className="mb-3"
          value={customerId}
          onChange={(e) => setCustomerId(e.target.value)}
        />
        <p>Seed keywords</p>
        <Input
          type="textarea"
          className="mb-3"
          style={{ height: '200px' }}
          value={seedKeywords.join('\n')}
          onChange={(e) => setSeedKeywords(e.target.value.split('\n'))}
        />
        <Button
          color="success"
          type="submit"
          className="button-sm fw-bold btn btn-success btn-lg common-success-button mb-3"
          onClick={async () => {
            const result = await keywordResearchTool({ seed_keywords: seedKeywords, customer_id: customerId });
            setResults(result.data.data);
          }}
        >
          GET DATA
        </Button>
        {results.metrics.length > 0 && (
          <>
            <h2>Metrics</h2>
            <Table>
              <thead>
                <tr>
                  <th>Keywords</th>
                  <th>Average Monthly Searches (last 12 months)</th>
                  <th>Competition</th>
                </tr>
              </thead>
              <tbody>
                {results.metrics.map((keywordData) => (
                  <tr key={keywordData.keywords}>
                    <td>{keywordData.keywords}</td>
                    <td>{new Intl.NumberFormat().format(keywordData.avg)}</td>
                    <td>{keywordData.competition}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
        {results.ideas.length > 0 && (
          <>
            <h2>Ideas</h2>
            <MaterialReactTable
              columns={columns}
              data={results.ideas}
              initialState={{
                showColumnFilters: true,
                sorting: [{ id: 'avg', desc: true }],
                columnFilters: [{ id: 'competition', value: ['LOW'] }],
                pagination: { pageSize: 50, pageIndex: 0 },
              }}
            />
          </>
        )}
      </CardBody>
    </Card>
  );
}

export default withRouter(KeywordResearchTool);
