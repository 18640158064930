import React from 'react';
import { Card, CardBody } from 'reactstrap';
import withRouter from '../../helpers/withRouter';
import './index.scss';

function Test() {
  return (
    <Card>
      <CardBody>
        <h1>Test</h1>
      </CardBody>
    </Card>
  );
}

export default withRouter(Test);
