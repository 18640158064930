import React, { useState } from 'react';
import { isEmpty, filter } from 'lodash-es';
import './InstanceSelection.scss';
import withRouter from '../../helpers/withRouter';
import { useGetSitesQuery } from '../../../../common/api/apiSlice';
import { getCookie, setCookie } from '../../../../Utils';
import { useInstance, useSite } from '../../../../common/hooks';

function SiteSelection() {
  const [siteSelectorHover, setSiteSelectorHover] = useState(false);

  const instance = useInstance();
  const site = useSite();

  let currentSite = site || {};

  const { data: sites } = useGetSitesQuery(instance?.id, { skip: isEmpty(instance) || !instance?.id });

  if (isEmpty(site) && !isEmpty(sites)) {
    const siteId = getCookie('SITE_ID');

    currentSite = sites.filter((e) => e.id == siteId)?.[0];
  }

  if (!sites || sites.length === 0 || !currentSite) {
    return <></>;
  }

  // For the site selector, we want the current site to be at the top of the list.

  const sortedSites = [...sites].sort((s1) => (s1.id == currentSite.id ? -1 : 0));

  return (
    <div className="site-selector">
      <a
        href={`https://${currentSite.label}`}
        target="_blank"
        onMouseOver={() => setSiteSelectorHover(true)}
        onFocus={() => setSiteSelectorHover(true)}
        rel="noreferrer"
      >
        {currentSite.label || '(no domain)'}
        {' '}
        <i className="fa-light fa-fw fa-up-right-from-square open" />
      </a>
      {siteSelectorHover && sites && sites.length > 1 && (
        <div className="site-selector-list" onMouseLeave={() => setSiteSelectorHover(false)}>
          <div>
            <a
              href={`https://${currentSite.label}`}
              onClick={(e) => {
                if (isEmpty(currentSite.label)) {
                  e.preventDefault();
                }
              }}
              target="_blank"
              rel="noreferrer"
            >
              {currentSite.label || '(no domain)'}
              {' '}
              <i className="fa-light fa-fw fa-up-right-from-square open" />
            </a>
          </div>

          <br />
          <div>
            Change site:
          </div>
          { }
          {filter(sortedSites, (s) => s.id != currentSite.id).map((otherSite) => (
            <div key={otherSite.id}>
              <a
                href="#"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  setCookie('SITE_ID', otherSite.id, 9999, '/');

                  if (!window.location.href.match(otherSite.id)) {
                    window.location.href = `/dashboards/instance/${instance.id}/site/${otherSite.id}`;
                  }
                }}
                target="_blank"
                rel="noreferrer"
              >
                {otherSite.label || '(no domain)'}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default withRouter(SiteSelection);
