import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { isEmpty, get, cloneDeep } from 'lodash-es';
import AdvancedSettings from '../../../../components/common/advancedSettings';
import RichTextEditor from '../../../../components/RichTextEditor';
import RichTextEditor2 from '../../../../components/RichTextEditor2';
import RichTextEditor3 from '../../../../components/RichTextEditor3';
import CustomButton from '../../../../components/common/button';
import withRouter from '../../../../helpers/withRouter';
import './index.scss';
import DragDropComponent from '../../../../components/common/reactSortable';
import { useGetSectionQuery, useSectionUpdateMutation } from '../../../../../../common/api/apiSlice';
import { useInstance, useUser } from '../../../../../../common/hooks';

const handleOnDragEnterOver = (e) => {
  e.preventDefault();
  e.dataTransfer.dropEffect = 'none';
};

function ContentEditor(props) {
  const {
    section: { id: sectionId },
    params: { instanceId, siteId },
    isEmailPage,
    saveCounter,
    updateStylesheet,
  } = props;

  const instance = useInstance();
  const user = useUser();

  const { data: section } = useGetSectionQuery({ instanceId, sectionId }, { skip: isEmpty(instanceId) && isEmpty(sectionId) });

  const [sectionUpdate] = useSectionUpdateMutation();

  const [tempSection, setTempSection] = useState(null);

  const textBlocks = get(tempSection, 'text_blocks', []);

  const tempTextBlocks = [...textBlocks];

  useEffect(() => {
    if (section) {
      setTempSection(cloneDeep(section));
    }
  }, [section]);

  const tempData = { ...tempSection };

  useEffect(() => {
    if (saveCounter === 0)
      return;

    (async () => {
      const sendParams = {
        id: tempSection?.id,
        instance_id: instanceId,
        page_version_id: tempSection?.page_version_id,
      };

      const tempPayload = { ...tempSection, ...sendParams };
      tempPayload.text_blocks_attributes = tempSection.text_blocks;
      delete tempPayload.text_blocks;
      await sectionUpdate(tempPayload);

      updateStylesheet();
    })();
  }, [saveCounter]);

  const onHandleAddNew = async (type, order) => {
    const newTb = {
      type,
      block_text: '',
      line_break: 0,
      order,
      file_id: null,
      section_content_id: tempData.id,
      text_style_id: '',
    };

    sectionUpdate({
      ...tempSection,
      instance_id: instance.id,
      text_blocks_attributes: [...tempSection.text_blocks, newTb],
    });
  };

  const updateParams = (newState) => {
    newState.forEach((tb, i) => tb.order = i + 1);
    tempData.text_blocks = newState;
    setTempSection(tempData);
  };

  const onUpdateTextBlock = (textBlock, index) => {
    if (!isEmpty(tempTextBlocks[index])) {
      tempTextBlocks[index] = textBlock;
      setTempSection({ ...tempSection, text_blocks: tempTextBlocks });
    }
  };

  const onHandleMove = (index, moveUp) => {
    const setIndex = moveUp ? index - 1 : index + 1;
    if (!isEmpty(tempTextBlocks[index])) {
      tempTextBlocks[index] = {
        ...tempTextBlocks[index],
        order: setIndex + 1,
      };

      tempTextBlocks[setIndex] = {
        ...tempTextBlocks[setIndex],
        order: index + 1,
      };

      sectionUpdate({ ...tempSection, text_blocks_attributes: tempTextBlocks });
    }
  };

  const renderEditors = () => {
    if (isEmpty(textBlocks) && isEmpty(instance)) {
      return <></>;
    }

    const editors = [];
    textBlocks.forEach((textBlock, index) => {
      editors.push(
        <div key={textBlock.id}>
          {(isEmailPage || textBlock.type === 'raw' || textBlock.type === 'normal') && (
            <RichTextEditor
              params={props?.params}
              index={index}
              textBlockObj={textBlock}
              isEmailPage={isEmailPage}
              onHandleMove={onHandleMove}
              onUpdateTextBlock={(e) => onUpdateTextBlock(e, index)}
              onDragOver={(e) => handleOnDragEnterOver(e)}
              onDragEnter={(e) => handleOnDragEnterOver(e)}
              countTextBlocks={textBlocks.length}
              style={{ backgroundColor: `${tempSection.sector_color}` }}
              features={['text_style', 'media', 'line_breaks', 'delete', 'reorder', 'offline', 'ai', 'history']}
            />
          )}
          {!isEmailPage && textBlock.type === 'ms' && (
            <RichTextEditor2
              params={props?.params}
              index={index}
              textBlockObj={textBlock}
              isEmailPage={isEmailPage}
              onHandleMove={onHandleMove}
              onUpdateTextBlock={(e) => onUpdateTextBlock(e, index)}
              onDragOver={(e) => handleOnDragEnterOver(e)}
              onDragEnter={(e) => handleOnDragEnterOver(e)}
              countTextBlocks={textBlocks.length}
              style={{ backgroundColor: `${tempSection.sector_color}` }}
              features={['text_style', 'media', 'line_breaks', 'delete', 'reorder', 'offline', 'ai', 'history']}
            />
          )}
          {!isEmailPage && textBlock.type === 'ms3' && (
            <RichTextEditor3
              params={props?.params}
              index={index}
              textBlockObj={textBlock}
              isEmailPage={isEmailPage}
              onHandleMove={onHandleMove}
              onUpdateTextBlock={(e) => onUpdateTextBlock(e, index)}
              onDragOver={(e) => handleOnDragEnterOver(e)}
              onDragEnter={(e) => handleOnDragEnterOver(e)}
              countTextBlocks={textBlocks.length}
              features={['text_style', 'media', 'line_breaks', 'delete', 'reorder', 'offline', 'ai', 'history']}
            />
          )}
          {addTextBlockButtonRender(index + 2)}
        </div>,
      );
    });

    return (
      <DragDropComponent
        className="ContentType"
        keyName="text_block_ids"
        handle=".handle-sortable"
        returnData={editors}
        list={cloneDeep(textBlocks)}
        APICall={() => sectionUpdate(tempSection)}
        onSetList={(newState) => updateParams(newState)}
        setNewState={(newState) => updateParams(newState)}
      />
    );
  };

  const addTextBlockButtonRender = (index) => (
    <Row className="add-new-textblock text-center mt-3 mb-3">
      <Col>
        <CustomButton
          color="info"
          className="add-new-button button-xlg fw-normal me-2"
          title="NORMAL TEXTBLOCK"
          icon="fa fa-plus"
          onClick={() => onHandleAddNew('normal', index)}
        />
        {' '}
        <CustomButton
          color="info"
          className="add-new-button button-xlg fw-normal me-2"
          title="MULTI-STYLE TEXTBLOCK"
          icon="fa fa-plus"
          onClick={() => onHandleAddNew('ms', index)}
        />
        {user?.hasFeature('ms3') && (
          <>
            {' '}
            <CustomButton
              color="info"
              className="add-new-button button-xlg fw-normal me-2"
              title="NEW MS TEXTBLOCK"
              icon="fa fa-plus"
              onClick={() => onHandleAddNew('ms3', index)}
            />
          </>
        )}
        {' '}
        <CustomButton
          color="info"
          className="add-new-button button-lg fw-normal me-2"
          title="RAW TEXTBLOCK"
          icon="fa fa-plus"
          onClick={() => onHandleAddNew('raw', index)}
        />
      </Col>
    </Row>
  );

  if (isEmpty(tempSection)) {
    return <></>;
  }

  return (
    <div id={sectionId}>
      <AdvancedSettings
        siteId={siteId}
        instanceId={instanceId}
        isEmailPage={isEmailPage}
        section={tempSection}
        setSection={setTempSection}
        handleSaveBreakpoint={(e) => setTempSection({ ...tempSection, break_point: e })}
        onHandleSectionIsOffline={() => setTempSection({ ...tempSection, is_offline: !tempSection.is_offline })}
        onChangeInitialVisibility={() => setTempSection({ ...tempSection, initial_visibility: !tempSection.initial_visibility })}
        onHandleChangeVisibility={(e) => setTempSection({ ...tempSection, visible_to: e.value })}
      />

      {addTextBlockButtonRender(1)}
      {renderEditors()}
    </div>
  );
}

export default withRouter(ContentEditor);
