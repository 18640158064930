import React, { useEffect } from 'react';
import { setPageTitle } from '../../../../../Utils';
import Upload from '../../media-uploads/Upload';

const MediaCenterList = (props) => {
  useEffect(() => {
    setPageTitle('Media Centre');
  }, []);

  return (
    <Upload type="media-center" props={props} />
  );
};

export default MediaCenterList;
