import React, { useState, useRef } from 'react';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  useGetCustomTooltipsQuery,
  useCreateCustomTooltipMutation,
  useUpdateCustomTooltipMutation,
} from '../../../../common/api/apiSlice';
import { displaySuccess } from '../../../../Utils';
import { useUser } from '../../../../common/hooks';

function CustomTooltip({ name, children }) {
  const user = useUser();
  const { data: tooltips, isLoading } = useGetCustomTooltipsQuery();
  const [modal, setModal] = useState(false);
  const [text, setText] = useState(null);
  const [createCustomTooltip] = useCreateCustomTooltipMutation();
  const [updateCustomTooltip] = useUpdateCustomTooltipMutation();
  const ref = useRef(null);

  if (isLoading) {
    return <></>;
  }

  const canEdit = user?.hasFeature('update_dashboard_tooltip_text');
  const tooltip = tooltips.find((t) => t.name === name);

  if (!canEdit && !tooltip) {
    return children;
  }

  const onSave = async () => {
    let response;

    if (tooltip?.id) {
      response = await updateCustomTooltip({ ...tooltip, text });
    } else {
      response = await createCustomTooltip({ name, text });
    }

    if (response) {
      displaySuccess('Tooltip updated!');
      setModal(false);
    }
  };

  return (
    <>
      {modal && (
        <Modal isOpen={true} autoFocus={false}>
          <ModalBody>
            <FormGroup>
              <Label>
                Enter some text:
              </Label>
              <Input
                type="textarea"
                autoFocus
                value={text === null ? tooltip?.text : text}
                onChange={(e) => setText(e.target.value)}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => setModal(false)}>
              <b>CANCEL</b>
            </Button>
            <Button color="success" onClick={onSave}>
              <b>SAVE</b>
            </Button>
          </ModalFooter>
        </Modal>
      )}

      <div ref={ref} style={{ display: 'inline-block' }}>
        {children}
      </div>
      <UncontrolledTooltip
        target={ref}
        autohide={false}
        style={{ textAlign: 'left' }}
      >
        {tooltip && (
          <>
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {tooltip.text}
            </div>
            {canEdit && (
              <>
                <br />
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setModal(true);
                  }}
                >
                  Change this text
                </a>
              </>
            )}
          </>
        )}
        {!tooltip && (
          <>
            This tooltip does not currently have any text.
            <br />
            <br />
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setModal(true);
              }}
            >
              Set some text now!
            </a>
            <br />
            <br />
            Tooltip ID:
            {' '}
            {name}
          </>
        )}
      </UncontrolledTooltip>
    </>
  );
}

export default CustomTooltip;
