import React, { useState } from 'react';
import { isEmpty, get } from 'lodash-es';
import { DateTime } from 'luxon';
import TimeFrameSelector from '../../components/common/timeFrameSelector';
import withRouter from '../../helpers/withRouter';
import { useInstance, useUser } from '../../../../common/hooks';
import { useGetCtaLogsQuery } from '../../../../common/api/apiSlice';
import './index.scss';
import CTATable8 from './CTATable8';

function CtaReport(props) {
  const {
    ctaType,
    typeName,
    orderBy,
    orderDirection,
    dataTableColumns,
    subRow,
  } = props;
  const [pageSize, setPageSize] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [siteId, setSiteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [includeSpam, setIncludeSpam] = useState(false);

  const instance = useInstance();
  const instanceId = instance?.id || null;

  const user = useUser();

  const timeZone = get(user, 'time_zone', 'Pacific/Auckland');

  const onHandleTimeFrameChange = (dates) => {
    setStartDate(dates.startDt);
    setEndDate(dates.endDt);
  };

  let sendParams = {
    instance_id: instanceId,
    types: [ctaType],
    start_date: DateTime.fromISO(startDate, { zone: timeZone }),
    end_date: endDate,
    page: currentPage,
    page_size: pageSize,
    order_by: orderBy,
    order_direction: orderDirection,
  };

  if (['CTALogFormSubmission'].includes(ctaType)) {
    sendParams = { ...sendParams, include_spam: includeSpam };
  }

  if (siteId) {
    sendParams = { ...sendParams, site_id: siteId };
  }

  const { data } = useGetCtaLogsQuery(sendParams, { skip: isEmpty(instanceId) || isEmpty(startDate) || isEmpty(endDate) });
  const ctaLogs = get(data, 'cta_log.data', []);
  const pagination = get(data, 'pagination', {});

  const onHandleSiteChange = (e) => {
    setSiteId(e ? e.value : e);
  };

  const onChangePage = (e) => {
    setCurrentPage(e);
  };

  const changePageSize = (size) => {
    setPageSize(size);
  };

  const onHandleIncludeSpam = () => {
    setIncludeSpam(!includeSpam);
  };

  return (
    <>
      <TimeFrameSelector
        ctaType={ctaType}
        typeName={typeName}
        CTALogs={ctaLogs}
        pageSize={pageSize}
        currentPage={currentPage}
        includeSpam={includeSpam}
        onChangePage={onChangePage}
        getPagination={pagination}
        changePageSize={changePageSize}
        onHandleSiteChange={onHandleSiteChange}
        onHandleSubmit={onHandleTimeFrameChange}
        onHandleIncludeSpam={onHandleIncludeSpam}
      />
      <CTATable8
        subRow={subRow}
        data={ctaLogs}
        showColumnListTh
        columns={dataTableColumns}
      />
    </>
  );
}

export default withRouter(CtaReport);
