import React, { useEffect, useState } from 'react';
import {
  Button, Row, Col, Card, Input, Alert,
} from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { displayError, setPageTitle, selectColorStyles, getSelectedOption, displaySuccess } from '../../../../../Utils';
import Select from 'react-select';
import HeaderComponent from '../../../components/common/header-component';
import { dataCenter, replacePathParams, mainRoutes, settingRoutes } from '../../../constants/routes';
import { get, isEmpty, map, cloneDeep } from 'lodash-es';
import withRouter from '../../../helpers/withRouter';
import MemberSearchField from './MemberSearchField';
import SearchExpressionValidator from './SearchExpressionValidator';
import './index.scss';
import { useNavigate } from 'react-router';
import { useGetSavedSearchQuery, useMemberSearchCreateMutation, useMemberSearchUpdateMutation } from '../../../../../common/api/apiSlice';

/* On keypress it accepts only AND, OR, (, ), 0-9 */
const onKeyDown = (event) => {
  const { which, code } = event;

  if (['Space', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Backspace'].indexOf(code) > -1) {
    return true;
  }

  if (
    which === 65 // A
    || which === 78 // N
    || which === 68 // D
    || which === 79 // O
    || which === 82 // R
    || which === 40 // (
    || which === 41 // )
    || (which >= 48 && which <= 57) // 0-9
    || which === 32 // space
  ) {
    return true;
  }

  event.preventDefault();
  return false;
};

const searchBehaviorOptions = [
  { value: 'and', label: 'match ALL of the conditions' },
  { value: 'or', label: 'match ANY of the conditions' },
  { value: 'custom', label: 'match custom logic' },
];

const MemberSearch = (props) => {
  const { params: { instanceId, searchId } } = props;
  const [errorResponse, setErrorResponse] = useState('');
  const [params, setParams] = useState({
    name: '',
    search_behavior: 'and',
    search_expression: '1',
    search_filters: [{
      operator: '',
      value: '',
      name: '',
    }],
  });

  const searchName = get(params, 'name', '');
  const searchBehavior = get(params, 'search_behavior', 'and');
  const searchExpression = get(params, 'search_expression', '1');
  const searchFilters = get(params, 'search_filters', []);

  const tempState = { ...params };

  const isError = errorResponse !== '';

  const navigate = useNavigate();

  const { data } = useGetSavedSearchQuery(searchId, { skip: isEmpty(searchId) });
  const [searchCreate] = useMemberSearchCreateMutation();
  const [searchUpdate] = useMemberSearchUpdateMutation();

  useEffect(() => {
    if (data) {
      setParams(cloneDeep(data.attributes));
    }
  }, [data]);

  useEffect(() => {
    setPageTitle('Member Search');
  }, []);

  const onChangeField = (value, key, index) => {
    const tempArr = [...searchFilters];
    tempArr[index][key] = value;
    setParams({ ...params, search_filters: tempArr });
  };

  const renderListOfMemberSearch = () => map(searchFilters, (obj, index) => (
    <MemberSearchField
      instanceId={instanceId}
      searchBehavior={searchBehavior}
      index={index}
      key={index}
      field={obj}
      onChange={(e, k) => onChangeField(e, k, index)}
      removeSearchField={() => removeSearchParams(index)}
    />
  ));

  const addSearchField = () => {
    setParams({
      ...params,
      search_filters: [...searchFilters, {
        operator: '',
        value: '',
        name: '',
      }],
    });
  };

  const removeSearchParams = (index) => {
    const tempArr = [...searchFilters];
    tempArr.splice(index, 1);
    tempState.search_filters = tempArr;
    setParams(tempState);
  };

  /* Get search member data */
  const getSearchMemberData = async () => {
    try {
      const searchExpValue = searchBehavior === 'custom' ? searchExpression : '';
      const searchData = {
        search_params: searchFilters,
        instance_id: instanceId,
        search_behavior: searchBehavior,
        search_expression: searchExpValue,
      };
      let result = null;
      if (searchId) {
        searchData.id = searchId;
        searchData.name = searchName;
        searchData.search_expression = searchExpValue;
        result = await searchUpdate(searchData);
      } else {
        result = await searchCreate(searchData);
      }
      if (result && result?.data?.meta?.is_success) {
        displaySuccess(result?.data?.meta?.messages);
        navigate(
          replacePathParams(
            dataCenter.showMemberSearch,
            [
              { key: 'searchId', value: result?.data?.data?.id },
              { key: 'pageNo', value: 1 },
            ], props,
          ),
        );
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  /* Search fields validations */
  const searchMemberValidate = async () => {
    const error = { type: '', status: false, message: '' };
    searchFilters.map((keyName) => {
      if (keyName.operator === '') {
        error.type = 'Operator';
        error.status = true;
      } else if (keyName.operator === 'is between') {
        if (!keyName.value.length > 0) {
          error.type = 'From and To value';
          error.status = true;
        } else if (keyName.value[0] === null) {
          error.type = 'From value';
          error.status = true;
        } else if (keyName.value.length === 1 || keyName.value[1] === null) {
          error.type = 'To value';
          error.status = true;
        }
      } else if (keyName.value === '') {
        if (keyName.operator === 'is blank') {
          error.status = false;
        } else {
          error.type = 'Value';
          error.status = true;
        }
      }
      return error;
    });

    if (error.status) {
      error.message = `${error.type} should not be blank!`;
      displayError(`${error?.name}: ${error?.message}`);
    } else {
      getSearchMemberData();
    }
  };

  /* On change advance search behavior */
  const onChangeSearchBehavior = (e) => {
    setParams({
      ...params,
      search_behavior: e.value,
      search_expression: e.value === 'custom' ? '1' : '',
    });
  };

  const onChangeExpression = (e) => {
    if (e.currentTarget.value === '') {
      setErrorResponse('');
    }
    setParams({ ...params, search_expression: e.currentTarget.value.toUpperCase() });
  };

  /* Validating expression */
  const onValidateExpression = (event) => {
    const returnData = SearchExpressionValidator(event, searchFilters);
    if (returnData === false) {
      setErrorResponse('');
    } else if (returnData.errorStatus) {
      setErrorResponse(returnData.errorMessage.message);
    } else {
      setErrorResponse(returnData.expressionStr);
    }
  };

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Data Centre',
      url: replacePathParams(mainRoutes.dataCenter, [], props),
    },
    { name: 'Member Search', url: '' },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Member Search',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: mainRoutes.dataCenter,
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card className="custom-bg-card">
        <Row>
          <Col lg={{ size: 4, offset: 8 }}>
            <Select
              value={getSelectedOption(searchBehaviorOptions, searchBehavior)}
              placeholder="Advanced search"
              onChange={onChangeSearchBehavior}
              options={searchBehaviorOptions}
              name="searchBehavior"
              isSearchable={false}
              styles={selectColorStyles}
            />
          </Col>
        </Row>
        <Row>
          {searchBehavior === 'custom'
            ? (
                <Colxx xxs="12" md="1" className="list-item-heading mb-1 truncate mt-2">
                  <b>#</b>
                </Colxx>
              )
            : null}
          <Colxx xxs="12" md="3" className="list-item-heading mb-1 truncate mt-2"><b>Field</b></Colxx>
          <Colxx xxs="12" md="3" className="list-item-heading mb-1 truncate mt-2"><b>Operator</b></Colxx>
          <Colxx xxs="12" md="4" className="list-item-heading mb-1 truncate mt-2"><b>Value</b></Colxx>
          <Colxx xxs="12" md="1" className="list-item-heading mb-1 truncate mt-2" />
        </Row>
        <div className="member-search-fields-outer">
          {renderListOfMemberSearch()}
        </div>

        {searchBehavior === 'custom'
          ? (
              <Row className="custom-search-expression-outer mb-4">
                <Col xs="6">
                  <Input
                    className={`custom-search-expression-input ${isError ? 'invalid-expression' : ''}`}
                    type="text"
                    placeholder="Please set custom logic here"
                    onKeyDown={onKeyDown}
                    onBlur={onValidateExpression}
                    onChange={onChangeExpression}
                    value={searchExpression || ''}
                  />
                  {isError ? <Alert color="danger">{errorResponse}</Alert> : ''}
                </Col>
              </Row>
            )
          : null}
        <Row>
          <Col>
            <Button className="custom-simple-icon" color="info" size="xs" onClick={addSearchField}>
              <i className="fal fa-plus-circle" />
            </Button>
            <Button
              id="searchBtn"
              disabled={isError}
              className="custom-simple-icon ms-2"
              color="success"
              size="xs"
              onClick={searchMemberValidate}
            >
              <i className="fal fa-search" />
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default withRouter(MemberSearch);
